import React, { useState, useEffect } from 'react';
import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Input, Checkbox } from 'antd';
import $, { now } from 'jquery';
import moment from 'moment';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import ModalPopup from './ModalPopup';
import Box from '@mui/material/Box';

import axios from 'axios';
import { audience, urlapi } from '../../auth/Configuration';
import TableDataHistory from './TableDataHistory';
import AuditTable from './AuditTable';
const FailAddressIndi = () => {
  const [user1, setUser1] = useState();
  const [user2, setUser2] = useState();
  const [loader, setLoader] = useState();
  const [modal12, setModal12] = useState(false);
  const toggle12 = () => setModal12(!modal12);
  const show12 = () => setModal12(!modal12);
  const [amltype12, setAmltype12] = useState('electronics');
  const [verifyFordoc6412, setVerifyFordoc6412] = useState();
  const [userID1, setUserID1] = useState();
  const [documentFile6412, setDocumentFile6412] = useState();
  const [userCompany, SetUserCompany] = useState();
  const [addresCheck1, SetAddresCheck1] = useState(true);

  const [investType, setInvestType] = useState();

  const [documentFile6422, setDocumentFile6422] = useState();

  const [modals1Ur2, setModals1Ur2] = useState(false);


  const [modalur2, setModalur2] = useState(false);

  const togglsUr2 = () => setModals1Ur2(!modals1Ur2);

  const showUr2 = () => setModalur2(!modalur2);
  const [assignur, setAssignur] = useState();

  const [userID2, setUserID2] = useState();
  const [isLoaderVerify, setIsLoaderVerify] = useState(false);

  const [geturs, setGeturs] = useState();






  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated
  } = useAuth0();
  const [token, setToken] = useState();
  const { id } = useParams();

  const getUserMetadata = async () => {
    try {
      await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;

          //    formData.token =
          // sessionStorage.setItem('token', token);
          getur(token);

          application(token);

        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/user/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {

          setLoader(false);
          setInvestType(applicationdata12.data[0].investmentType);

          if (applicationdata12.data[0].investmentType == "Company" || applicationdata12.data[0].investmentType == "Trust") {
            SetUserCompany(applicationdata12.data);

          }
          else {
            setUser1(applicationdata12.data[0]);
            setUserID1(applicationdata12.data[0].id);
          }




          // alert(applicationdata12.data[0].fullLegalName);
          if (user.profile == 'UR3') {
            // applicationdiretor(token);
          }

          // setIdTypee(applicationdata12.data[0].documentIdType);

          if (applicationdata12.data.length > 1) {

            setUser2(applicationdata12.data[1]);
            setUserID2(applicationdata12.data[1].id);

          }
        })
        .catch((error) => {
          setLoader(false);

          Swal.fire('Something went wrong');
        });
      // }
    }
  };
  const onFinish12 = async (values) => {
    var dateOfBirth = $('.ui.icon.input.addateOfBirth>input').val();
    // alert(dateOfBirth);
    let add = {
      addressDetails: {
        addressLine1: values.addressLine1,
        suburb: values.suburb,
        city: values.city,
        postalCode: values.postalCode,
        countryCode: 'NZ',
        country: values.country
      },
      // fileName: 'VerifyAmlAddress',

      userResidentialAddress:
        values.addressLine1 +
        ', ' +
        values.suburb +
        ', ' +
        values.city +
        ', ' +
        values.postalCode +
        ', ' +
        values.country,
      verificationType: amltype12,

      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dateOfBirth: dateOfBirth,
      verifyFor: 'address'
    };
    if (
      verifyFordoc6412 !== null &&
      verifyFordoc6412 !== undefined &&
      verifyFordoc6412 !== 'undefined' &&
      amltype12 === 'viaDoc'
    ) {
      setLoader(true);
      add['doc64'] = verifyFordoc6412;
      // alert("kjkh", verifyFordoc6412)

      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID1}`, add, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            // window.location.reload();

            application(token);
            setModal12(!modal12);

          } else {
            setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else if (amltype12 !== 'viaDoc') {
      setLoader(true);

      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID1}`, add, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            // window.location.reload();

            application(token);
            setModal12(!modal12);
          } else {
            setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else {
      Swal.fire({
        text: 'Please Attach Document'
      });
    }
  };
  function setDateformateToValid(date1) {
    if (
      date1 !== null &&
      date1 !== 'undefined' &&
      typeof date1 !== 'undefined' &&
      date1.includes('/')
    ) {
      let dat = date1.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }
  const getur = (token) => {
    const NotessData = axios
      .get(`${urlapi}/admin/team/member`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setGeturs(result.data);
        }
      });
  };
  const NoteClick = (data) => {
    // setNloader(true);
    let postadv = {
      downloadUrl: data
    };
    axios
      .post(`${urlapi}/admin/download/image`, postadv, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        window.open(res.data.url);
        // setNloader(false);
      });
  };
  async function uploadWithJSON12(file, e) {
    var stra5 = file.type;

    const fileTypew5 = stra5.slice(-3);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };
    // next();
    if (file.size > 2202009) {
      Swal.fire('Please attach address proof (maximum file size is 2MB).');
      setVerifyFordoc6412('');
      setDocumentFile6412('');
    } else if (fileTypew5 && (fileTypew5 == "pdf" || fileTypew5 == "jpg" || fileTypew5 == "png")) {
      setDocumentFile6412(file.name);

      setVerifyFordoc6412(data.img64);
    } else {
      Swal.fire({
        text: "Only pdf jpg png file attached."
      });
    }

    // setErrror('');
  }

  const applicationTrust = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/user/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          SetUserCompany(applicationdata12.data);
          setInvestType(applicationdata12.data[0].investmentType)

          if (user.profile == 'UR3') {
            // applicationdiretor(token);
          }
        })
        .catch((error) => {
          setLoader(false);
          Swal.fire('Something went wrong');
        })
        .finally((e) => {
          setLoader(false);
        });
      // }
    }
  };
  const verifyEmails = async (user_id) => {
    setIsLoaderVerify(true);
    const changeStage1 = await axios
      .put(
        `${urlapi}/admin/verify/email/user/${user_id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setIsLoaderVerify(false);
          Swal.fire({
            text: 'Request sent!'
          });
        } else {
          setIsLoaderVerify(false);
          // Swal.fire(result.data.message)
          Swal.fire({
            text: result.data.message
          });
        }
      })
      .catch((error) => {
        setIsLoaderVerify(false);
        Swal.fire({
          text: 'Something went wrong!'
        });
      });
  };
  const verifyEmailss = async (user_id) => {
    setIsLoaderVerify(true);
    const changeStage1 = await axios
      .put(
        `${urlapi}/admin/verify/email/user/${user_id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setIsLoaderVerify(false);
          Swal.fire({
            text: 'Request sent!'
          });
        } else {
          setIsLoaderVerify(false);
          // Swal.fire(result.data.message)
          Swal.fire({
            text: result.data.message
          });
        }
      })
      .catch((error) => {
        setIsLoaderVerify(false);
        Swal.fire({
          text: 'Something went wrong!'
        });
      });
  };
  const updateaccount = async (id) => {
    let databody;

    if (assignur == null || assignur == '' || assignur == undefined) {
      databody = {
        memberName: geturs[0].fullLegalName
      };
    } else {
      databody = {
        memberName: assignur
      };
    }

    setLoader(true);
    await axios
      .put(`${urlapi}/admin/team/member/${id}`, databody, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        Swal.fire({
          html: 'Updated',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#3085D6'
        }).then((e) => {
          setLoader(false);
          setModals1Ur2(!modals1Ur2);
          applicationTrust(token);
        });
      })
      .catch(function (error) {
        setLoader(false);
        Swal.fire('', 'Unfortunately an error has occurred, Please try again.', '');
      });
  };
  return (

    <>
      <div className='right_content'>
        <div className='heading__top ht_heading'>
          <h2>
            <Link to="/client">
              <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </Link>
            Updated Details
          </h2>
        </div>
        <div className=' form app_view_form'>
          <div className='app_view'>
            <div className='app_view_row flex-wrap'>
              <div className=" w-100 ">
                {investType == "Individual" || investType == "Joint" ?
                  <div className="app_view_box app_view_box5">
                    <h4>Overview</h4>
                    <div className="row responsive__align">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Entity Type:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? user1.investmentType : ''}</p>
                      </div>
                    </div>

                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Entity Name:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 && user1.entityName !== null ? user1.entityName : ''}</p>
                      </div>
                    </div>
                    <div className="row responsive__align">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Investor Type:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? user1.investmentType : ''}</p>
                      </div>
                    </div>
                    <div className="row responsive__align">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Client Type:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        {/* <p>{user1 ? user1.clientType : ''}</p> */}
                        <p>{'Wholesale Investor'}</p>
                      </div>
                    </div>

                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Primary Contact Name: </label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        {/* <p>{user1 ? user1.priContactNumber : ''}</p> */}
                        <p>
                          {' '}
                          {user1 && user1.fullLegalName !== null && user1.fullLegalName != ''
                            ? user1.fullLegalName
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Email:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? user1.userEmail : ''}</p>
                        <span className="status">
                          {user1 && user1.isEmailVerified ? (
                            <>
                              <span className="Success Success_icon">
                                <i class="fa fa-check" aria-hidden="true"></i>
                                Verified
                              </span>
                            </>
                          ) : (
                            <span className="danger danger_icon">
                              <p>
                                {' '}
                                <i class="fa fa-times cross_icon" aria-hidden="true"></i> Not
                                Verified
                              </p>
                              {isLoaderVerify == true ? (
                                <div className="note-main-loader verify_loader">
                                  <div className="loader zip_exp_loader"></div>
                                </div>
                              ) : (
                                <>
                                  <button
                                    className="Send_Email"
                                    onClick={() => verifyEmailss(user1.id)}
                                  >
                                    Send Email
                                  </button>
                                  {/* <button
                                 className=" Manually"
                                 onClick={() => verifyEmail1(user1.id)}
                               >
                                 Update Manually
                               </button> */}
                                </>
                              )}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Phone :</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        {user1 &&
                          user1.priContactNumber != null &&
                          user1 &&
                          user1.priContactNumber != '' ? (
                          <>
                            {user1 ? (
                              user1.priContactNumber !== null &&
                                user1.priContactCountryCode !== null ? (
                                <p>
                                  {user1 && user1.priContactCountryCode !== ''
                                    ? '+' + user1.priContactCountryCode + ' '
                                    : ' '}
                                  {/* {user1 ? user1.priContactCountryCode : ''}{' '} */}
                                  {user1 ? user1.priContactNumber : ''}
                                </p>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    {/* <div className="row responsive__align">
                   <div className="col-md-6  col-sm-6 col-6">
                     <label>Fund: </label>
                   </div>
                   <div className="col-md-6  col-sm-6 col-6">
                     <p>{user1 ? user1.fundCode : ''}</p>
                   </div>
                 </div> */}
                    {/* 
                 <div className="row responsive__align">
                   <div className="col-md-6  col-sm-6 col-6">
                     <label>Investment:</label>
                   </div>
                   <div className="col-md-6  col-sm-6 col-6">
                     <p>
                       {user1 && user1.totalInvestment != null ? <>$</> : ''}
                     

                       {user1 && user1.totalInvestment != null ? user1.totalInvestment : ''}
                     </p>
                   </div>
                 </div> */}

                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Application Reference Number:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? (user1.isPrimary == 1 ? user1.id : '') : ''}</p>
                        {/* <p>{user1 ? user1.paymentReference : ''}</p> */}
                      </div>
                    </div>
                    <div className="row responsive__align align-items-start">
                      <div className="col-md-6">
                        <label>MF Investments Team Member</label>
                      </div>
                      {user1 &&
                        user1.overAllApplicationsStage !== 'Approved' &&
                        user.profile.includes("UR2") ? (
                        <div className="col-md-6 d-flex justify-content-between align-items-center">
                          <p>{user1 && user1.memberName}</p>
                          <div class="ur_edite">
                            <i
                              title="Edit"
                              class="fa fa-pencil-square-o hover__Edit"
                              aria-hidden="true"
                              // onClick={() => urPopup()}
                              onClick={() => togglsUr2()}
                            ></i>
                            <span class="sr-only">Edit</span>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <Modal isOpen={modals1Ur2} show={showUr2} className={""}>
                        {/* <ModalHeader toggle={togglsUr2}>Edit Address</ModalHeader> */}
                        <Box className="formPopUp">
                          <h4>Change MF Investments Team Member</h4>
                          <div className="UpdateApplication edit_form">
                            <Row>
                              <Col md={6}>
                                <label>MF Investments Team Member</label>
                              </Col>

                              <Col md={6}>
                                <select
                                  className="w-75 m-0 fw-normal mb-1 pt-0 pb-0 pl-0"
                                  // name="fullLegalName"
                                  onChange={(e) => {
                                    // urChangepopup();
                                    // alert(e.target.value);
                                    setAssignur(e.target.value);
                                  }}
                                  value={assignur}
                                >
                                  {geturs &&
                                    geturs.map((geturdata) => {
                                      return (
                                        <>
                                          <option>{geturdata.fullLegalName}</option>
                                        </>
                                      );
                                    })}
                                </select>
                              </Col>
                            </Row>

                            <Row className="UpdateBtn">
                              <Col md={6}>
                                <div onClick={togglsUr2} className="cancelBTN">
                                  Cancel
                                </div>
                              </Col>

                              <Col md={6}>
                                <button
                                  onClick={() => {
                                    // changeStage2(userr.id, 'main');
                                    updateaccount(user1.id);

                                    // urPopup(user1.id);
                                  }}
                                >
                                  Yes, Update Now
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                    {/* <div className="row responsive__align">
                   <div className="col-md-6">
                     <label>MF Investments Team Member</label>
                   </div>
                   {user1 &&
                   user1.overAllApplicationsStage !== 'Approved' &&
                   user.profile.includes("UR2") ? (
                     <div className="col-md-6 d-flex justify-content-between align-items-center">
                       <select className="w-75 m-0 fw-normal mb-1 pt-0 pb-0 pl-0">
                         {geturs &&
                           geturs.map((geturdata) => {
                             return (
                               <>
                                 <option>{geturdata.fullLegalName}</option>
                               </>
                             );
                           })}
                       </select>
                       <div class="ur_edite">
                         <i
                           title="Edit"
                           class="fa fa-pencil-square-o hover__Edit"
                           aria-hidden="true"
                         ></i>
                         <span class="sr-only">Edit</span>
                       </div>
                     </div>
                   ) : (
                     ''
                   )}
                 </div> */}
                    {/* <div className="row responsive__align">
                   <div className="col-md-6  col-sm-6 col-6">
                     <label>Investor Relations Associate :</label>
                   </div>
                   <div className="col-md-6  col-sm-6 col-6">
                     <p>{user1 ? user1.investorRelation : ''}</p>
                   </div>
                 </div> */}
                  </div>
                  : <div className="app_view_box app_view_box5">
                    <h4>Overview</h4>
                    {userCompany &&
                      userCompany.map((usert) => {
                        return (
                          usert.personal &&
                          usert.personal
                            .filter((usert) => usert.isPrimary === 1 && usert.isPrimary !== null)
                            .map((userr, key) => {
                              return (
                                <>
                                  <div className="row responsive__align">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Entity type:</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p key={key}>
                                        {userr.investmentType != null ? userr.investmentType : ''}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row responsive__align">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Entity Name:</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      {investType && investType == "Trust" ?
                                        <p>
                                          {userr.entityName != null ? userr.entityName : ''}
                                        </p> :
                                        <p >
                                          {usert.companyDetails != null && usert.companyDetails.companyName != undefined ? usert.companyDetails.companyName : ""}
                                        </p>
                                      }

                                    </div>
                                  </div>
                                  <div className="row responsive__align">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Investor Type:</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p key={key}>
                                        {userr.investmentType != null ? userr.investmentType : ''}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row responsive__align">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Client Type:</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      {/* <p key={key}>
                                          {userr.clientType != null
                                            ? userr.clientType
                                            : ''}
                                        </p> */}
                                      <p key={key}>{'Wholesale Investor'}</p>
                                    </div>
                                  </div>
                                  <div className="row responsive__align">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Primary Contact Name: </label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p>
                                        {/* MF Investments Team Member{' '} */}
                                        {userr &&
                                          userr.fullLegalName !== null &&
                                          userr.fullLegalName != ''
                                          ? userr.fullLegalName
                                          : ''}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row responsive__align">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Email:</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p>{userr.userEmail != null ? userr.userEmail : ''}</p>
                                      <span className="status">
                                        {userr && userr.isEmailVerified ? (
                                          <>
                                            <span className="Success Success_icon">
                                              <i class="fa fa-check" aria-hidden="true"></i>
                                              Verified
                                            </span>
                                          </>
                                        ) : (
                                          <span className="danger danger_icon">
                                            <p>
                                              {' '}
                                              <i class="fa fa-times cross_icon" aria-hidden="true"></i> Not
                                              Verified
                                            </p>
                                            {isLoaderVerify == true ? (
                                              <div className="note-main-loader verify_loader">
                                                <div className="loader zip_exp_loader"></div>
                                              </div>
                                            ) : (
                                              <>
                                                <button
                                                  className="Send_Email"
                                                  onClick={() => verifyEmails(userr.id)}
                                                >
                                                  Send Email
                                                </button>
                                                {/* <button
                                    className=" Manually"
                                    onClick={() => verifyEmail1(user1.id)}
                                  >
                                    Update Manually
                                  </button> */}
                                              </>
                                            )}
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="row responsive__align">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Phone :</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      {userr &&
                                        userr.priContactNumber != null &&
                                        userr.priContactNumber != '' ? (
                                        <>
                                          {userr ? (
                                            userr.priContactNumber !== null &&
                                              userr.priContactNumber !== null ? (
                                              <p>
                                                {userr && userr.priContactNumber !== ''
                                                  ? '+' +
                                                  userr.priContactCountryCode +
                                                  ' ' +
                                                  userr.priContactNumber
                                                  : ' '}
                                              </p>
                                            ) : (
                                              ''
                                            )
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>

                                  {/* <div className="row responsive__align">
                                      <div className="col-md-6">
                                        <label>Fund: </label>
                                      </div>
                                      <div className="col-md-6">
                                        <p>{userr.fundCode != null ? userr.fundCode : ''}</p>
                                      </div>
                                    </div> */}
                                  {/* 
                                    <div className="row responsive__align">
                                      <div className="col-md-6">
                                        <label>Investment:</label>
                                      </div>
                                      <div className="col-md-6">
                                        <p>
                                          {userr && userr.totalInvestment != null ? <>$</> : ''}
                                          {userr ? userr.totalInvestment : ''}
                                        </p>
                                      </div>
                                    </div> */}

                                  <div className="row responsive__align">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Application Reference Number:</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p>{userr ? userr.id : ''}</p>
                                    </div>
                                  </div>
                                  {/* <div className="row responsive__align">
                                      <div className="col-md-6">
                                        <label>Investor Relations Associate :</label>
                                      </div>
                                      <div className="col-md-6">
                                        <p>{userr ? userr.investorRelation : ''}</p>
                                      </div>
                                    </div> */}
                                  <div className="row responsive__align align-items-start">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>MF Investments Team Member</label>
                                    </div>
                                    {
                                      // user1 &&
                                      // user1.overAllApplicationsStage !== 'Approved' &&
                                      user.profile.includes("UR2") ? (
                                        <div className="col-md-6 d-flex justify-content-between align-items-center">
                                          <p>{userr ? userr.memberName : ''}</p>
                                          <div class="ur_edite">
                                            <i
                                              title="Edit"
                                              class="fa fa-pencil-square-o hover__Edit"
                                              aria-hidden="true"
                                              // onClick={() => urPopup()}
                                              onClick={() => togglsUr2()}
                                            ></i>
                                            <span class="sr-only">Edit</span>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )
                                    }

                                    <Modal
                                      isOpen={modals1Ur2}
                                      show={showUr2}
                                      className={""}
                                    >
                                      {/* <ModalHeader toggle={togglsUr2}>Edit Address</ModalHeader> */}
                                      <Box className="formPopUp">
                                        <h4>Change MF Investments Team Member</h4>
                                        <div className="UpdateApplication edit_form">
                                          <Row>
                                            <Col md={6}>
                                              <label>MF Investments Team Member</label>
                                            </Col>

                                            <Col md={6}>
                                              <select
                                                className="w-75 m-0 fw-normal mb-1 pt-0 pb-0 pl-0"
                                                // name="fullLegalName"
                                                onChange={(e) => {
                                                  // urChangepopup();
                                                  // alert(e.target.value);
                                                  setAssignur(e.target.value);
                                                }}
                                                value={assignur}
                                              >
                                                {geturs &&
                                                  geturs.map((geturdata) => {
                                                    return (
                                                      <>
                                                        <option>{geturdata.fullLegalName}</option>
                                                      </>
                                                    );
                                                  })}
                                              </select>
                                            </Col>
                                          </Row>

                                          <Row className="UpdateBtn">
                                            <Col md={6}>
                                              <div onClick={togglsUr2} className="cancelBTN">
                                                Cancel
                                              </div>
                                            </Col>

                                            <Col md={6}>
                                              <button
                                                onClick={() => {
                                                  // changeStage2(userr.id, 'main');
                                                  updateaccount(userr.id);
                                                  // urPopup(userr.id);
                                                }}
                                              >
                                                Yes, Update Now
                                              </button>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Box>
                                    </Modal>
                                  </div>
                                </>
                              );
                            })
                        );
                      })}
                  </div>}
                <div>

                </div>
              </div>
              <div className=" w-100 ">
                <div className="app_view_box app_view_box5">
                  <h4>Details of Client Information Updated</h4>

                  <div className="row position-relative">
                    <div className="col-md-11">
                      <div className="row">

                        <div className="myfarm_com" key={2}>
                          {' '}
                          <AuditTable
                            apiPath1={`/admin/audittrails/${id}`}
                            tableTitle={'Audit TABLE'}
                            tableid={'table_id4'}
                            key={2}
                          // userName={userH ? userH[0].entityName : ''}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='app_view_box w-100'>
                <div className="main-wrapper_error">
                  <h4>  Application Errors & Outstanding Items</h4>
                  <div className="primary_text">Primary</div>
                  <Table bordered className="table_dash">

                    <tbody>

                      {investType && investType == "Trust" || investType == "Company" ?

                        (userCompany && userCompany[0].personal[0].addressCheck == 0 ? (
                          <tr>
                            <td className="td__width">AML Address</td>

                            <td className="td__width">
                              <p>Fail</p>
                            </td>
                          </tr>
                        ) : (
                          ''
                        ))

                        : (user1 && user1.addressCheck === 0 ? (
                          <tr>
                            <td className="td__width">AML Address</td>

                            <td className="td__width">
                              <p>Fail</p>
                            </td>
                          </tr>
                        ) : (
                          ''
                        ))
                      }




                    </tbody>
                  </Table>

                  {/* {user2 ? (
                <>
                  <div className="">
                    <div className="primary_text">Secondary</div>
                    <Table bordered className="table_dash">


                      <tbody>


                        {user2 && user2.addressCheck === 0 ? (
                          <tr>
                            <td className="td__width">AML Address</td>

                            <td className="td__width">
                              <p>Fail</p>
                            </td>
                          </tr>
                        ) : (
                          ''
                        )}



                      </tbody>
                    </Table>
                  </div>
                </>
              ) : null} */}
                </div>
              </div>

              <div className="app_view_box w-100 ">

                <div>
                  <h4>Address</h4>
                  {user1 ? (

                    <div className="row position-relative">
                      <div className="col-md-11">
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <label>AML Address </label>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            {user1 && user1.addressCheck == 1 ? (
                              <>
                                <div className="aml_pass">ADDRESS AML PASS</div>
                              </>
                            ) : (
                              <div className="aml_fail">ADDRESS AML FAIL</div>
                            )}
                          </div>
                        </div>
                        {user1 && user1.isMyFarm == 1 ? (
                          ''
                        ) : (
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label>Address </label>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p>
                                {user1
                                  ? user1.isMyFarm == 1
                                    ? ''
                                    : user1.userResidentialAddress
                                  : ''}
                              </p>
                            </div>

                            {user1 &&
                              user1.addressDetails != null &&
                              user1.addressProof != null &&
                              user1 &&
                              user1.addressProof != '' ? (
                              <>
                                {' '}
                                <div className="col-md-6 col-sm-6">
                                  <label> Manual Approval</label>
                                </div>
                                {user1.isMyFarm == 1 ? (
                                  ''
                                ) : (
                                  <div className="col-md-6 col-sm-6">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => NoteClick(user1.addressProof)}
                                      className=""
                                    >
                                      <div className="main-downloade-wrapper">
                                        {user1 &&
                                          user1.addressDetails != null &&
                                          user1.addressProof !== null &&
                                          user1.addressProof !== '' &&
                                          user1.addressProof &&
                                          'undefined' &&
                                          user1.addressProof !== undefined ? (
                                          <p className="downloadeMsg">
                                            <div className="downloads">
                                              <i className="fa fa-download downloadeStyle"></i>
                                            </div>{' '}
                                            <p className="View__file">VIEW FILE</p>{' '}
                                          </p>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </a>

                                    <a href="javascript:void(0)">
                                      {/* <img src={UploadfileData} /> */}
                                    </a>
                                  </div>
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </div>
                      <div className="col-md-1 edit_icon">
                        {

                          (user.profile.includes("UR1") ||
                            user.profile.includes("UR2"))


                            ? (
                              <>
                                <i
                                  onClick={toggle12}
                                  title="Edit"
                                  class="fa fa-pencil-square-o hover__Edit"
                                  aria-hidden="true"
                                ></i>

                                <Modal isOpen={modal12} show={show12} className={""}>
                                  <ModalHeader toggle={toggle12}>Edit Address</ModalHeader>
                                  <ModalBody>
                                    <label>Please select AML verification type </label>

                                    <select
                                      id="select_option1"
                                      name="amltype"
                                      className="mb-3"
                                      onChange={(e) => setAmltype12(e.target.value)}
                                    >
                                      <option value="electronics">
                                        Re-Check Electronic Verification
                                      </option>
                                      <option value="viaDoc">Manual Approval</option>
                                    </select>

                                    <Form
                                      className="pop_width-100"
                                      name="basic"
                                      initialValues={{ remember: true }}
                                      onFinish={onFinish12}
                                    // onFinishFailed={onFinishFailed}
                                    >
                                      <Form.Item
                                        label="First Name:"
                                        name="firstName"
                                        initialValue={user1 ? user1.firstName : ''}
                                        className="quantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter First Name'
                                          }
                                        ]}
                                      >
                                        <Input
                                          placeholder="Enter first name(s)"
                                          defaultValue={user1 ? user1.firstName : ''}
                                          maxlength="50"
                                          className="mb-2 quantity"
                                          onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          required
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Middle Name:"
                                        name="middleName"
                                        initialValue={user1 ? user1.middleName : ''}
                                      >
                                        <Input
                                          placeholder="Enter middle name(s)"
                                          defaultValue={user1 ? user1.middleName : ''}
                                          maxlength="50"
                                          className="mb-2 quantity"
                                          onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Last Name:"
                                        name="lastName"
                                        initialValue={user1 ? user1.lastName : ''}
                                        className="quantity"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please Enter last name'
                                          }
                                        ]}
                                      >
                                        <Input
                                          placeholder="Enter last name(s)"
                                          defaultValue={user1 ? user1.lastName : ''}
                                          maxlength="50"
                                          required
                                          className="mb-2 quantity"
                                          onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                        />
                                      </Form.Item>

                                      <Row>
                                        <Col sm={12}>
                                          <label className="label">Date Of Birth</label>

                                          <Form.Item
                                            name="dateOfBirth"
                                            className="date_width"
                                          >
                                            <div class="custom_datepiker">
                                              <SemanticDatepicker
                                                id="addateOfBirth"
                                                format="DD/MM/YYYY"
                                                className="addateOfBirth mb-2 widthSET"
                                                allowOnlyNumbers
                                                onPaste={(e) => {
                                                  e.preventDefault();
                                                  return false;
                                                }}
                                                required
                                                maxDate={
                                                  new Date(moment().subtract(18, 'years'))
                                                }
                                                value={
                                                  user1 && user1.dateOfBirth
                                                    ? new Date(
                                                      setDateformateToValid(
                                                        user1.dateOfBirth
                                                      )
                                                    )
                                                    : ''
                                                }
                                              />
                                            </div>
                                          </Form.Item>
                                        </Col>
                                      </Row>

                                      <Form.Item
                                        className="formtext"
                                        label="Address:"
                                        name="addressLine1"
                                        initialValue={
                                          user1
                                            ? user1.addressDetails !== null &&
                                              user1.addressDetails[0].addressLine1 !==
                                              null &&
                                              user1.addressDetails[0].addressLine1 != ' '
                                              ? user1.addressDetails[0].addressLine1
                                              : ''
                                            : ''
                                        }
                                      // rules={[{ required: true, message: "Please enter First Name" }]}
                                      >
                                        <Input
                                          placeholder="Enter AddressLine"
                                          maxlength="200"
                                          defaultValue={
                                            user1
                                              ? user1.addressDetails !== null &&
                                                user1.addressDetails[0].addressLine1 !==
                                                null &&
                                                user1.addressDetails[0].addressLine1 != ' '
                                                ? user1.addressDetails[0].addressLine1
                                                : ''
                                              : ''
                                          }
                                          required
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Suburb:"
                                        name="suburb"
                                        initialValue={
                                          user1
                                            ? user1.addressDetails !== null &&
                                              user1.addressDetails[0].suburb !== null
                                              ? user1.addressDetails[0].suburb
                                              : ''
                                            : ''
                                        }
                                      >
                                        <Input
                                          placeholder="Enter  Suburb"
                                          defaultValue={
                                            user1
                                              ? user1.addressDetails !== null &&
                                                user1.addressDetails[0].suburb !== null
                                                ? user1.addressDetails[0].suburb
                                                : ''
                                              : ''
                                          }
                                          maxlength="20"
                                          className="mb-2 numbers"
                                        // className="numbers"
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        className="formtext"
                                        label="City"
                                        name="city"
                                        initialValue={
                                          user1
                                            ? user1.addressDetails !== null &&
                                              user1.addressDetails[0].city !== null
                                              ? user1.addressDetails[0].city
                                              : ''
                                            : ''
                                        }
                                      >
                                        <Input
                                          placeholder="Enter  City"
                                          maxlength="50"
                                          defaultValue={
                                            user1
                                              ? user1.addressDetails !== null &&
                                                user1.addressDetails[0].city !== null
                                                ? user1.addressDetails[0].city
                                                : ''
                                              : ''
                                          }
                                          required
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        className="formtext"
                                        label="Postal Code "
                                        name="postalCode"
                                        initialValue={
                                          user1
                                            ? user1.addressDetails !== null &&
                                              user1.addressDetails[0].postalCode !== null
                                              ? user1.addressDetails[0].postalCode
                                              : ''
                                            : ''
                                        }
                                      >
                                        <Input
                                          // className="mb-3"
                                          placeholder="Enter  Postal Code"
                                          maxlength="10"
                                          defaultValue={
                                            user1
                                              ? user1.addressDetails !== null &&
                                                user1.addressDetails[0].postalCode !== null
                                                ? user1.addressDetails[0].postalCode
                                                : ''
                                              : ''
                                          }
                                          className="numbers"
                                          // maxlength="4"
                                          required
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Country:"
                                        name="country"
                                        initialValue={
                                          user1
                                            ? user1.addressDetails !== null &&
                                              user1.addressDetails[0].country !== null
                                              ? user1.addressDetails[0].country
                                              : ''
                                            : ''
                                        }
                                      >
                                        <Input
                                          type="text"
                                          placeholder="Enter  Country"
                                          maxlength="20"
                                          defaultValue={
                                            user1
                                              ? user1.addressDetails !== null &&
                                                user1.addressDetails[0].country !== null
                                                ? user1.addressDetails[0].country
                                                : ''
                                              : ''
                                          }
                                          required
                                          className="mb-2"
                                        />
                                      </Form.Item>

                                      {amltype12 === 'viaDoc' ? (
                                        <>
                                          <Row className="fullinput mt-2 mb-2">
                                            <Col>
                                              <Col xs={12}>
                                                <div class="yes1 attach_aml">
                                                  <a className="click-hover Edit_button_style">
                                                    {' '}
                                                    <span class="btn_upload">
                                                      <input
                                                        type="file"
                                                        id="imag2"
                                                        title=""
                                                        name="image"
                                                        // onClick={removeError}
                                                        onChange={(e) => {
                                                          uploadWithJSON12(
                                                            e.target.files[0]
                                                          );
                                                          // removeError();
                                                        }}
                                                      />
                                                      {/* <img src={attch} alt="fileuploade" /> */}
                                                    </span>
                                                    Attach Address Proof
                                                  </a>
                                                  <p>{documentFile6412}</p>
                                                </div>
                                              </Col>
                                            </Col>
                                          </Row>
                                        </>
                                      ) : null}

                                      <Form.Item>
                                        <Button
                                          className="coffee-color right-side overwrite d-table ml-auto mt-3 text__aml_button"
                                          type="primary"
                                          htmlType="submit"
                                        >
                                          {amltype12 === 'viaDoc'
                                            ? 'Submit & Manually Approve Address'
                                            : 'Re-Check Electronic AML'}
                                        </Button>
                                      </Form.Item>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </>
                            ) : (
                              ""
                            )}
                      </div>
                    </div>

                  ) : (
                    ''
                  )}
                </div>


                {userCompany && userCompany.map((usert) => {
                  return (
                    usert.personal && usert.personal.map((userr, ind) => {
                      return (
                        <>
                          {ind == 0 ?
                            <>
                              {userr ? (

                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>
                                          <span className="text_upercas">AML</span>{' '}
                                          Address{' '}
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        {userr.addressCheck == 1 ? (
                                          <>
                                            <div className="aml_pass">
                                              ADDRESS AML PASS
                                            </div>
                                          </>
                                        ) : (
                                          <div className="aml_fail">
                                            ADDRESS AML FAIL
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Address </label>
                                      </div>
                                      <div className="col-md-6">
                                        <p>{userr.userResidentialAddress}</p>
                                      </div>

                                      {userr &&
                                        userr.addressDetails != null &&
                                        userr.addressProof != null &&
                                        userr &&
                                        userr.addressProof != '' ? (
                                        <>
                                          {' '}
                                          <div className="col-md-6">
                                            <label> Manual Approval</label>
                                          </div>
                                          <div className="col-md-6">
                                            <a href={userr.addressProof} className="">
                                              <div className="main-downloade-wrapper">
                                                {userr &&
                                                  userr.addressDetails != null &&
                                                  userr.addressProof !== null &&
                                                  userr.addressProof !== '' &&
                                                  userr.addressProof &&
                                                  'undefined' &&
                                                  userr.addressProof !== undefined ? (
                                                  <p className="downloadeMsg">
                                                    <div className="downloads">
                                                      <i className="fa fa-download downloadeStyle"></i>
                                                    </div>{' '}
                                                    <p className="View__file">
                                                      VIEW FILE
                                                    </p>{' '}
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </a>

                                            <a href={documentFile6422}>
                                              <img src={documentFile6422} />
                                            </a>
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12 edit_icon iconn">
                                    {(user.profile.includes("UR1") || user.profile.includes("UR2")) &&
                                      (userr.applicationStage != 'Active Client' &&
                                        userr.addressCheck != 1)
                                      ? (
                                        <>
                                          <ModalPopup
                                            iid={userr.id}
                                            userr={userr}
                                            addresss={addresCheck1}
                                            getAPI={application}
                                            setLoader={setLoader}
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                  </div>
                                </div>

                              ) : (
                                ''
                              )}
                            </>
                            : ""}
                        </>
                      )
                    })
                  )
                })}
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}
export default FailAddressIndi;