

// complete task
import { result } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { mainUrl } from 'src/auth/Configuration';
// import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
// import { format } from 'date-fns'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const ExpirePopupId = (props) => {
    const [modal, setModal] = useState(true);
    const show = () => setModal(!modal);
    const toggle = () => setModal(!modal);
    const popupOf = () => {
        setModal(false);
    }
    let invest = props.invest;
    let datess = props.expirationDatesId;
    const [day, month, year] = datess.split("/")
    var expiration = year + "-" + month + "-" + day
    var current_date = moment().format("YYYY-MM-DD");
    var days = moment(expiration).diff(current_date, 'days');


    sessionStorage.setItem('isIdPop', false);

    // useMemo(() => aa(), []);
    const SendUrl = () => {
        window.open(`${mainUrl}?name=true&update=${invest}&type=identityVerify`)
    }
    return (

        <>{
            (days < 5 && days > 0) ?

                <Modal isOpen={modal} show={show} >
                    <ModalHeader className='dashbord_popup' toggle={toggle}>
                        {/* Update Email */}
                    </ModalHeader>

                    <ModalBody className='email_updated_pro email_updated'>
                        Some of the compliance information we hold about your account is due to expire in {days} day (this may be your photo ID, or your Wholesale Certification status).

                        You can update this now to ensure that your account continues to operate smoothly. Alternatively, once this information has expired, you will be required to update the relevant information before you can make any new investments.
                    </ModalBody>
                    <ModalFooter className='dashbord_popup_footer'>
                        {/* <Link to={"/profilePage"}>Update Now</Link> */}
                        {/* http://localhost:3000/dashboard?name=true&update=joint&type="wholesale" */}
                        <button onClick={() => {
                            SendUrl();
                        }}>Update Now</button>
                        <button onClick={popupOf}>Remind Me Again Later</button>
                    </ModalFooter>
                </Modal>
                : ""
        }
            {(days < 0) ? <>
                <Modal isOpen={modal} show={show} className="w-50" >
                    <ModalHeader className='dashbord_popup' toggle={toggle}>
                        {/* Update Email */}
                    </ModalHeader>

                    <ModalBody className='email_updated_pro email_updated text-center pt-0'>
                        Your ID has Expired.
                    </ModalBody>
                    <ModalFooter className='dashbord_popup_footer'>
                        {/* <Link to={"/profilePage"}>Update Now</Link> */}
                        <button onClick={() => {
                            SendUrl();
                        }}>Update Now</button>
                        {/* <button>Remind Me Again Later</button> */}
                    </ModalFooter>
                </Modal>
            </> : ""
            }
        </>
    )
}

export default ExpirePopupId;




