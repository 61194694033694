import React from 'react';

import { Link } from 'react-router-dom';

import InternalUserTable from './InternalUserTable';

const Internalusers = () => {
  return (
    <>
      <div className="right_content">
        <div className="heading__top ht_heading">
          <h2>
            {/* <Link to="/Dashboard1">
              <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </Link> */}
            Admin Users
          </h2>
        </div>
        <div className="form director">
          <div className="myfarm_com" key={5}>
            {' '}
            <InternalUserTable
              apiPath1={'/admin/actions/active'}
              tableTitle={'Active User'}
              tableid={'table_id5'}
              AddUserData={'activeAdd'}
            />
          </div>
          <div className="myfarm_com" key={6}>
            {' '}
            <InternalUserTable
              apiPath1={'/admin/actions/inactive'}
              tableTitle={'Deactive User'}
              tableid={'table_id6'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Internalusers;
