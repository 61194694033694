/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Row, Col } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
// import { getCode, getName } from 'country-list';
import { audience, urlapi } from 'src/auth/Configuration';
import Swal from 'sweetalert2';


const WholesaleCertificate = (props) => {
  const { user,
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated } = useAuth0();
  const [imageLoader, setImageLoader] = useState();
  const [token, setToken] = useState();
  const [documentFileAttach264, setDocumentFileAttach264] = useState();


  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;

          // applicationTrust(token);
          // Notessfunc(token);
          // getur(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    getUserMetadata();
  }, []);

  async function uploadWithJSON_attach2(file, userIdf) {

    setDocumentFileAttach264(file.name);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };
    let datadocument = {
      columnName: 'confirmationDocument',
      fileName: documentFileAttach264,
      document: data.img64
    };
    setImageLoader(true);
    axios
      .post(`${urlapi}/admin/confirmation/document/${userIdf}`, datadocument, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        let urlFile = (datadocument.columnName = result.data.url);
        if (result.status === 200) {
          setImageLoader(false);
          // applicationTrust(token);
        } else {
          Swal.fire('Something went wrong');
        }
      });
  }
  const certifiacte_pdf = (pdff) => {
    window.open(pdff);
  };
  return (
    <>
      {props.data &&
        props.data.certificateDetails &&
        (props.data.certificateDetails.isMinimumInvest == 0 ||
          props.data.certificateDetails.isMinimumInvest == false) ? (
        <div className="row whole_cert">
          <div className="col-md-12">
            {props.data.certificateDetails &&
              props.data.certificateDetails.certificateNumber == '1' ? (
              <>
                {(props.data &&
                  props.data.certificateDetails &&
                  props.data.certificateDetails.largePersonValue !=
                  null &&
                  props.data &&
                  props.data.certificateDetails.largePersonValue !==
                  'undefined' &&
                  props.data.certificateDetails.largePersonValue !=
                  'None' &&
                  props.data.certificateDetails.largePersonValue !=
                  '') ||
                  (props.data &&
                    props.data.certificateDetails &&
                    props.data.certificateDetails.twoYearInvestEntity2 !=
                    null &&
                    props.data &&
                    props.data.certificateDetails
                      .twoYearInvestEntity2 !== 'undefined' &&
                    props.data.certificateDetails.twoYearInvestEntity2 !=
                    'None' &&
                    props.data.certificateDetails.twoYearInvestEntity2 !=
                    '') ? (
                  <>
                    {' '}
                    <h3>
                      Wholesale Certification: Large Investor
                    </h3>
                    <p>
                      {props.data.certificateDetails.largePersonValue}
                    </p>
                    <p>
                      {
                        props.data.certificateDetails
                          .twoYearInvestEntity2
                      }
                    </p>
                  </>
                ) : (
                  ''
                )}
                {/* 2nd */}

                <>
                  {props.data &&
                    ((props.data.certificateDetails
                      .investorPrincipalBusiness != null &&
                      props.data.certificateDetails
                        .investorPrincipalBusiness != '') ||
                      (props.data.certificateDetails
                        .investingFinancialProducts != null &&
                        props.data.certificateDetails
                          .investingFinancialProducts != '') ||
                      (props.data.certificateDetails.actingUnderwriter !=
                        null &&
                        props.data.certificateDetails
                          .actingUnderwriter != '') ||
                      (props.data.certificateDetails
                        .financialAdviceService != null &&
                        props.data.certificateDetails
                          .financialAdviceService != '') ||
                      (props.data.certificateDetails
                        .clientMoneyPropertyService != null &&
                        props.data.certificateDetails
                          .clientMoneyPropertyService != '') ||
                      (props.data.certificateDetails
                        .tradingFinancialProducts != null &&
                        props.data.certificateDetails
                          .tradingFinancialProducts != '') ||
                      (props.data.certificateDetails
                        .registeredBankRBNZ != null &&
                        props.data.certificateDetails
                          .registeredBankRBNZ != '') ||
                      (props.data.certificateDetails.NBDTInvestor !=
                        '' &&
                        props.data.certificateDetails.NBDTInvestor !=
                        null) ||
                      (props.data.certificateDetails
                        .licensedIssuerInsurance != '' &&
                        props.data.certificateDetails
                          .licensedIssuerInsurance != null) ||
                      (props.data.certificateDetails
                        .registeredSchemeManager != '' &&
                        props.data.certificateDetails
                          .registeredSchemeManager != null) ||
                      (props.data.certificateDetails.derivativesIssuer !=
                        '' &&
                        props.data.certificateDetails
                          .derivativesIssuer != null) ||
                      (props.data.certificateDetails.FinancialAdvisor !=
                        null &&
                        props.data.certificateDetails.FinancialAdvisor !=
                        '')) ? (
                    <>
                      <h3>
                        Wholesale Certification: Investment
                        Business
                      </h3>

                      <p>
                        {
                          props.data.certificateDetails
                            .investorPrincipalBusiness
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .investingFinancialProducts
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .actingUnderwriter
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .financialAdviceService
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .clientMoneyPropertyService
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .tradingFinancialProducts
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .registeredBankRBNZ
                        }
                      </p>
                      <p>
                        {props.data.certificateDetails.NBDTInvestor}
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .licensedIssuerInsurance
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .registeredSchemeManager
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .derivativesIssuer
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .FinancialAdvisor
                        }
                      </p>
                    </>
                  ) : (
                    ''
                  )}
                </>

                {/* 3rd page certificate */}

                <>
                  {props.data &&
                    ((props.data.certificateDetails.ownedPortfolio2yrs !=
                      null &&
                      props.data.certificateDetails.ownedPortfolio2yrs !=
                      '') ||
                      (props.data.certificateDetails
                        .carriedTransactions2yrs != null &&
                        props.data.certificateDetails
                          .carriedTransactions2yrs != '') ||
                      (props.data.certificateDetails
                        .investmentBusiness10yrs != null &&
                        props.data.certificateDetails
                          .investmentBusiness10yrs != '') ||
                      (props.data.certificateDetails.equitySecurities !=
                        null &&
                        props.data.certificateDetails.equitySecurities !=
                        '') ||
                      (props.data.certificateDetails.managedFunds !==
                        null &&
                        props.data.certificateDetails.managedFunds !==
                        '') ||
                      (props.data.certificateDetails
                        .derivativeInstruments !== null &&
                        props.data.certificateDetails
                          .derivativeInstruments !== '') ||
                      (props.data.certificateDetails.debtSecurities !==
                        null &&
                        props.data.certificateDetails.debtSecurities !==
                        '')) ? (
                    <>
                      <h3>
                        Wholesale Certification: Investement
                        Criteria
                      </h3>
                      <p>
                        {
                          props.data.certificateDetails
                            .ownedPortfolio2yrs
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .carriedTransactions2yrs
                        }
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .investmentBusiness10yrs
                        }
                      </p>

                      <p>
                        {
                          props.data.certificateDetails
                            .equitySecurities
                        }
                      </p>
                      <p>
                        {props.data.certificateDetails.managedFunds}
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .derivativeInstruments
                        }
                      </p>
                      <p>
                        {props.data.certificateDetails.debtSecurities}
                      </p>
                    </>
                  ) : (
                    ''
                  )}
                </>
              </>
            ) : (
              ''
            )}

            <>
              {props.data &&
                props.data.certificateDetails &&
                props.data.certificateDetails.certificateNumber ==
                '3' ? (
                <>
                  {' '}
                  {props.data &&
                    ((props.data.certificateDetails.startupOrBusiness !=
                      null &&
                      props.data.certificateDetails.startupOrBusiness !=
                      '') ||
                      (props.data.certificateDetails
                        .investorPresentation != null &&
                        props.data.certificateDetails
                          .investorPresentation != '') ||
                      (props.data.certificateDetails
                        .capitalRaisingDirector != null &&
                        props.data.certificateDetails
                          .capitalRaisingDirector != '') ||
                      (props.data.certificateDetails
                        .investingSharesSecurities != null &&
                        props.data.certificateDetails
                          .investingSharesSecurities != '') ||
                      (props.data.certificateDetails
                        .myOwnInvestmentDecisions != null &&
                        props.data.certificateDetails
                          .myOwnInvestmentDecisions != '') ||
                      (props.data.certificateDetails
                        .valueAndRisksOfInvesting != null &&
                        props.data.certificateDetails
                          .valueAndRisksOfInvesting != '') ||
                      (props.data.certificateDetails
                        .acquiringDisposingFinancialProducts !==
                        null &&
                        props.data.certificateDetails
                          .acquiringDisposingFinancialProducts !==
                        '') ||
                      (props.data.certificateDetails
                        .acquiringDisposingFinancialProductsOther !==
                        null &&
                        props.data.certificateDetails
                          .acquiringDisposingFinancialProductsOther !==
                        '')) ? (
                    <>
                      <h3>
                        Wholesale Certification: Eligible Investor
                      </h3>

                      <p>
                        <p>
                          {
                            props.data.certificateDetails
                              .startupOrBusiness
                          }
                        </p>
                        <p>
                          {
                            props.data.certificateDetails
                              .investorPresentation
                          }
                        </p>
                        <p>
                          {
                            props.data.certificateDetails
                              .capitalRaisingDirector
                          }
                        </p>
                        <p>
                          {
                            props.data.certificateDetails
                              .investingSharesSecurities
                          }
                        </p>
                        <p>
                          {
                            props.data.certificateDetails
                              .myOwnInvestmentDecisions
                          }
                        </p>
                        <p>
                          {
                            props.data.certificateDetails
                              .valueAndRisksOfInvesting
                          }
                        </p>

                        {/* change */}

                        <p>
                          {
                            props.data.certificateDetails
                              .acquiringDisposingFinancialProducts
                          }
                        </p>
                      </p>
                      <p>
                        {
                          props.data.certificateDetails
                            .acquiringDisposingFinancialProductsOther
                        }
                      </p>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
            </>

            {props.data &&
              props.data.certificateDetails !== null &&
              props.data.certificateDetails.signatureA ? (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-auto">
                        <h5>SIGNED for and on behalf of</h5>
                      </div>
                      <div className="col-auto">
                        <h5>
                          <span className="behalf_text">
                            {props.data &&
                              props.data.fullLegalName !== null &&
                              props.data.fullLegalName != ''
                              ? props.data.fullLegalName
                              : ''}
                          </span>
                          as Investor.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <strong>
                  Wholesale Certification: Signature
                </strong>

                <p>
                  <img
                    alt="dummy"
                    src={
                      props.data &&
                        props.data.certificateDetails !== null &&
                        props.data.certificateDetails.signatureA
                        ? props.data.certificateDetails.signatureA
                        : ''
                    }
                  />
                </p>

                <p>
                  <img
                    src={
                      props.data &&
                        props.data.certificateDetails !== null &&
                        props.data.certificateDetails.signatureB
                        ? props.data.certificateDetails.signatureB
                        : ''
                    }
                  />
                </p>
              </>
            ) : (
              ''
            )}

            {(props.data.certificateDetails &&
              props.data.certificateDetails.nominatedUserType == '') ||
              props.data.certificateDetails.nominatedUserType == null ? (
              ''
            ) : (
              <div className="row">
                <div className="col-md-6 col-sm-auto">
                  <label>Personal adviser type</label>
                </div>
                <div className="col-md-6 col-sm-auto">
                  <p>
                    {props.data &&
                      props.data.certificateDetails.nominatedUserType !=
                      'Select'
                      ? props.data.certificateDetails.nominatedUserType
                      : ''}
                  </p>
                </div>
              </div>
            )}

            {(props.data.certificateDetails &&
              props.data.certificateDetails.nominatedUserName == '') ||
              props.data.certificateDetails.nominatedUserName == null ? (
              ''
            ) : (
              <div className="row">
                <div className="col-md-6 col-sm-7">
                  <label>Personal advisor name</label>
                </div>
                <div className="col-md-6 col-sm-5">
                  <p>
                    {props.data
                      ? props.data.certificateDetails.nominatedUserName
                      : ''}
                  </p>
                </div>
              </div>
            )}

            {(props.data.certificateDetails &&
              props.data.certificateDetails.nominatedUserEmail ==
              '') ||
              props.data.certificateDetails.nominatedUserEmail ==
              null ? (
              ''
            ) : (
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <label>Email</label>
                </div>
                <div className="col-md-6 col-sm-6">
                  <p>
                    {props.data
                      ? props.data.certificateDetails
                        .nominatedUserEmail
                      : ''}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="col-md-1 edit_icon"></div>
        </div>
      ) : (
        ''
      )}

      <>
        {props.data &&
          props.data.certificateDetails &&
          props.data.certificateDetails.certificateNumber == '2' ? (
          <>
            <h3>Wholesale Certification: Amount Invested</h3>

            <p>
              {props.data &&
                props.data.certificateDetails.usualLegalRules !== null
                ? props.data.certificateDetails.usualLegalRules
                : ''}
            </p>
            <p>
              {props.data &&
                props.data.certificateDetails.notCompleteInformation !==
                null
                ? props.data.certificateDetails.notCompleteInformation
                : ''}
            </p>
            <p>
              {props.data &&
                props.data.certificateDetails.materialExtent10yrs !==
                null
                ? props.data.certificateDetails.materialExtent10yrs
                : ''}
            </p>
            <p>
              {props.data &&
                props.data.certificateDetails.fewerLegalProtection !==
                null
                ? props.data.certificateDetails.fewerLegalProtection
                : ''}
            </p>
            <p>
              {props.data &&
                props.data.certificateDetails.notSuitableInvestment !==
                null
                ? props.data.certificateDetails.notSuitableInvestment
                : ''}
            </p>
            <p>
              {props.data &&
                props.data.certificateDetails.seekFinancialAdvice !==
                null
                ? props.data.certificateDetails.seekFinancialAdvice
                : ''}
            </p>

            {/* <div className="row">
                                    <div className="col-md-6 col-sm-auto">
                                      <label>Wholesale Certificate office held</label>
                                    </div>
                                    <div className="col-md-6 col-sm-auto">
                                      <p>
                                        {props.data &&
                                        props.data.certificateDetails !== null &&
                                        props.data.certificateDetails.WholesaleCertificateOfficeHeld
                                          ? props.data.certificateDetails.WholesaleCertificateOfficeHeld
                                          : ''}
                                      </p>
                                    </div>
                                  </div> */}

            {props.data &&
              props.data.certificateDetails !== null &&
              props.data.certificateDetails.signatureA ? (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-auto">
                        <h5>SIGNED for and on behalf of</h5>
                      </div>
                      <div className="col-auto">
                        <h5>
                          <span className="behalf_text">
                            {props.data &&
                              props.data.fullLegalName !== null &&
                              props.data.fullLegalName != ''
                              ? props.data.fullLegalName
                              : ''}
                          </span>
                          as Investor.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <strong>
                  Wholesale Certification: Signature
                </strong>

                <p>
                  <img
                    src={
                      props.data &&
                        props.data.certificateDetails !== null &&
                        props.data.certificateDetails.signatureA
                        ? props.data.certificateDetails.signatureA
                        : ''
                    }
                  />
                </p>

                {/* <p>
                                        <img
                                          src={
                                            props.data &&
                                            props.data.certificateDetails !== null &&
                                            props.data.certificateDetails.signatureB
                                              ? props.data.certificateDetails.signatureB
                                              : ''
                                          }
                                        />
                                      </p> */}
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </>

      {user.profile.includes("UR1") ||
        user.profile == 'UR8' ||
        user.profile.includes("UR2") ? (
        < div className="row responsive__align align_item_Eligible" >
          <div className="col-md-6 col-sm-6">
            <label className="text_trns">
              Attach Confirmation of Eligible Status (if
              relevant):
            </label>
          </div>

          <div className="col-md-5 col-sm-5">
            <Row className="fullinput mt-2 mb-2">
              <Col>
                <div class="yes1_attach">
                  <a className="click-hover Edit_button_style">
                    <span class="btn_upload">
                      <input
                        type="file"
                        id="imag2"
                        title=""
                        name="image"
                        onChange={(e) => {
                          uploadWithJSON_attach2(
                            e.target.files[0],
                            props.data.id
                          );
                        }}
                      />
                    </span>
                    Attach
                    {/* Attach */}
                  </a>
                </div>

                <div className="Attch_file">
                  <span id="name_tag" class="preview2"></span>
                  <input
                    type="button"
                    id="removeImage2"
                    value="x"
                    class="btn-rmv2"
                  />
                </div>

                {imageLoader === true ? (
                  <div class="progress">
                    <span
                      class="progress-bar"
                      style={{ width: '100%' }}
                    ></span>
                  </div>
                ) : null}
              </Col>
            </Row>

            {/* <button>ATTACH</button> */}
          </div>
        </div>
      ) : (
        ''
      )
      }
      {
        props.data ? (
          <div className="row">
            {props.data && props.data.confirmationDocument != null ? (
              <div className="col-md-6 col-sm-6">
                <label className="text_trns">
                  Confirmation of Eligible Status:
                </label>
              </div>
            ) : (
              ''
            )}

            <div className="col-md-6 col-sm-6">
              <a
                href={props.data && props.data.confirmationDocument}
                className="documentProof-downloade confirmationDocument"
              >
                <div className="main-downloade-wrapper align__end">
                  {props.data &&
                    props.data.confirmationDocument !== null &&
                    props.data.confirmationDocument !== '' &&
                    props.data.confirmationDocument &&
                    'undefined' &&
                    props.data.confirmationDocument !== undefined ? (
                    <p className="downloadeMsg">
                      <div className="downloads">
                        <i className="fa fa-download downloadeStyle"></i>
                      </div>{' '}
                      <p className="View__file">VIEW FILE</p>{' '}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </a>
            </div>
          </div>
        ) : (
          ''
        )
      }

      {
        (props.data && props.data.applicationStage != 'In Progress') && (user.profile.includes("UR1") ||
          user.profile.includes("UR2")) ? (
          <button
            onClick={() => {
              // wholesale_certifiacte_pdf(props.data.id);
              certifiacte_pdf(
                props.data &&
                  props.data.certificateDetails !== null &&
                  props.data.certificateDetails.pdfUrl
                  ? props.data.certificateDetails.pdfUrl
                  : ''
              );
            }}
            className="PAdding__button"
          >
            Generate Wholesale Certification PDF
          </button>
        ) : (
          ''
        )
      }
    </>
  );
};
export default WholesaleCertificate;
