import React from 'react';

import { Link } from 'react-router-dom';
import ClientManintaTable from './ClientManintaTable';

import TableData from './TableData';

const Client = () => {
  return (
    <>
      <div className="right_content">
        <div className="heading__top ht_heading">
          <h2>
            <Link to="/dashboard">
              <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </Link>
            Client Updates
          </h2>
        </div>
        <div className="form director">
          <div className="myfarm_com myfarm_Active" key={12}>
            {' '}
            <ClientManintaTable
              apiPath1={'/admin/clients/maintenance'}
              tableTitle={'Client Maintainance'}
              key={12}
              tableid={'table_id87'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Client;
