/*eslint-disable eqeqeq*/

import React from 'react';
// import { Row, Col } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { Row, Col, Nav, Tab, Accordion } from 'react-bootstrap';
import BankAccount from './BankAccount';
import WholesaleCertificate from './WholesaleCertificate';

// import { getCode, getName } from 'country-list';
// import ModalPopup from './signatures/ModalPopup';

const TrustDetails = (props) => {
  const { user } = useAuth0();

  return (
    <>

      <div className="app_view_box">
        <div className="app_view">
          <h4 className="">Trust Details</h4>
          <div className='show_responsive'>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Trust Overview</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Trust Name </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.trustDetails.trustName : ''}</p>
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Trust Address Proof</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="document_download">
                        <a
                          href={
                            props.data &&
                              props.data.trustDetails.trustAddressProof !== null &&
                              props.data.trustDetails.trustAddressProof !== '' &&
                              props.data.trustDetails.trustAddressProof !== undefined
                              ? props.data.trustDetails.trustAddressProof
                              : ''
                          }
                          className="licedocument-downloade"
                        >
                          {' '}
                          <div className="main-downloade-wrapper">
                            {props.data &&
                              props.data.trustDetails.trustAddressProof !== null &&
                              props.data.trustDetails.trustAddressProof !== '' &&
                              props.data.trustDetails.trustAddressProof !== undefined ? (
                              <p className="downloadeMsg">
                                <div className="downloads">
                                  <i className="fa fa-download downloadeStyle"></i>
                                </div>{' '}
                                VIEW FILE{' '}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </a>

                        <span id="name_tag" class="preview2"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Trust Deed Proof</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="document_download">
                        <a
                          href={
                            props.data &&
                              props.data.trustDetails.trustDeedProof !== null &&
                              props.data.trustDetails.trustDeedProof !== '' &&
                              props.data.trustDetails.trustDeedProof &&
                              'undefined' &&
                              props.data.trustDetails.trustDeedProof !== undefined
                              ? props.data.trustDetails.trustDeedProof
                              : ''
                          }
                          className="licedocument-downloade"
                        >
                          {' '}
                          <div className="main-downloade-wrapper">
                            {props.data &&
                              props.data.trustDetails.trustDeedProof !== null &&
                              props.data.trustDetails.trustDeedProof !== '' &&
                              props.data.trustDetails.trustDeedProof &&
                              'undefined' &&
                              props.data.trustDetails.trustDeedProof !== undefined ? (
                              <p className="downloadeMsg">
                                <div className="downloads">
                                  <i className="fa fa-download downloadeStyle"></i>
                                </div>{' '}
                                VIEW FILE{' '}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </a>

                        <span id="name_tag" class="preview2"></span>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Tax</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>
                        Trust <span className="capital"> IRD </span>Number
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.trustDetails.trustIrdNumber : ''}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>
                        Trust <span className="capital"> IRD </span>Proof
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="document_download">
                        <a
                          href={
                            props.data &&
                              props.data.trustDetails.trustIrdNumberProof !== null &&
                              props.data.trustDetails.trustIrdNumberProof !== '' &&
                              props.data.trustDetails.trustIrdNumberProof &&
                              'undefined' &&
                              props.data.trustDetails.trustIrdNumberProof !== undefined
                              ? props.data.trustDetails.trustIrdNumberProof
                              : ''
                          }
                          className="licedocument-downloade"
                        >
                          {' '}
                          <div className="main-downloade-wrapper">
                            {props.data &&
                              props.data.trustDetails.trustIrdNumberProof !== null &&
                              props.data.trustDetails.trustIrdNumberProof !== '' &&
                              props.data.trustDetails.trustIrdNumberProof &&
                              'undefined' &&
                              props.data.trustDetails.trustIrdNumberProof !== undefined ? (
                              <p className="downloadeMsg">
                                <div className="downloads">
                                  <i className="fa fa-download downloadeStyle"></i>
                                </div>{' '}
                                VIEW FILE{' '}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </a>

                        <span id="name_tag" class="preview2"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Trust Register Address</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.trustDetails.trustRegisterAddress : ''}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Trust Type</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.trustDetails.trustType : ''}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Country of Incorporation</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.trustDetails.trustcountryOfIncorporation : ''}</p>
                    </div>
                  </div>
                  {props.data && props.data.trustDetails.trustType == 'Other' ? (
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Trust Other Type</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-6">
                        <p>{props.data ? props.data.trustDetails.trustTypeOther : ''}</p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label className=""> Tax Type </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="rwttax">
                        <div className="Main-wrapper-circle">
                          <p className="circle">{props.data && props.data.trustDetails.trustTaxType}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {props.data && props.data.trustDetails.trustTaxType == 'Resident Withholding Tax' ? (
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label className="">Resident Withholding Tax Rate</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-6">
                        <div className="rwttax">
                          <div className="Main-wrapper-circle">
                            <p className="circle">
                              {props.data ? props.data.trustDetails.trustRwtValue : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {props.data && props.data.trustDetails.trustRwtValue == 'Exempt' ? (
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Trust Proof of Exemption</label>
                      </div>

                      <div className="col-md-6 col-sm-6 col-6 document_download">
                        <a
                          href={
                            props.data &&
                              props.data.trustDetails.trustExamptProof !== null &&
                              props.data.trustDetails.trustExamptProof !== '' &&
                              props.data.trustDetails.trustExamptProof !== undefined
                              ? props.data.trustDetails.trustExamptProof
                              : ''
                          }
                          className="licedocument-downloade"
                        >
                          {' '}
                          <div className="main-downloade-wrapper">
                            {props.data &&
                              props.data.trustDetails.trustExamptProof !== null &&
                              props.data.trustDetails.trustExamptProof !== '' &&
                              props.data.trustDetails.trustExamptProof !== undefined ? (
                              <p className="downloadeMsg">
                                <div className="downloads">
                                  <i className="fa fa-download downloadeStyle"></i>
                                </div>{' '}
                                VIEW FILE{' '}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {props.data &&
                    props.data.trustDetails.trustTaxType == 'Non Resident Withholding Tax' ? (
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Resident Witholding Tax Exemption</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-6">
                        <div className="rwttax">
                          <div className="Main-wrapper-circle">
                            <p>
                              {props.data.trustDetails.trustTaxExemption == 0 ||
                                props.data.trustDetails.trustTaxExemption == false
                                ? 'No'
                                : 'Yes'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label>
                        Is the Trust U.S. Citizen, Or U.S. Resident, Or A Tax Resident in Any Other
                        Country
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="">
                        <div class="switch-field nospace">
                          <div className="check_YN_Bank1">
                            <p>
                              {props.data && props.data.trustDetails.isTrustCitizen == true
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Name of corporate trustee company</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="">
                        <div class="switch-field nospace">
                          <div className="check_YN_Bank1">
                            <p>
                              {props.data && props.data.trustDetails.trustCompanyName
                                ? props.data.trustDetails.trustCompanyName
                                : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {props.data && props.data.trustDetails.isTrustCitizen == true ? (
                    <>
                      <div className="">
                        <Row className="FlagsSelectcss">
                          <Col className="col-md-6 col-sm-6 col-6">
                            <div>
                              <label>Country Of Tax Residence</label>
                            </div>
                          </Col>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <p>
                              {props.data && props.data.trustDetails.trustCountryOfResidence
                                ? props.data.trustDetails.trustCountryOfResidence
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <div>
                              <label>
                                Tax Identification Number <span className="capital">(TIN)</span>
                              </label>
                            </div>
                          </Col>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <p>
                              {props.data && props.data.trustDetails.trustTinNumber
                                ? props.data.trustDetails.trustTinNumber
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <div>
                              <label>
                                Reason if <span className="capital">TIN</span> is Not Available
                              </label>
                            </div>
                          </Col>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <p>
                              {props.data && props.data.trustDetails.trustTinReason
                                ? props.data.trustDetails.trustTinReason
                                : ''}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {props.data
                    ? props.data.trustDetails &&
                    props.data.trustDetails.trustIndividualDetails &&
                    props.data.trustDetails.trustIndividualDetails.map((userDetails, index) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6">
                              <label>
                                {' '}
                                Settlors, Trustees, Authorised people, or anyone else with Effective
                                Control of the trust {index + 1}{' '}
                              </label>
                            </div>

                            <div className="col-md-6 col-sm-6 col-6">
                              <p>{userDetails.fullLegalName}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6">
                              <label>Settlors/Trustee/Authorised{index + 1} Email </label>
                            </div>

                            <div className="col-md-6 col-sm-6 col-6">
                              <p>{userDetails.userEmail}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6">
                              <label>
                                Do you have a corporate trustee or professional trustee{index + 1}{' '}
                              </label>
                            </div>

                            <div className="col-md-6 col-sm-6 col-6">
                              <p>{userDetails.isTrustee == true ? 'Yes' : 'No'}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="ccol-md-6 col-sm-6 col-6">
                              <label> Role Of Settlors/Trustee/Authorised {index + 1} </label>
                            </div>

                            <div className="col-md-6 col-sm-6 col-6">
                              <p>
                                {userDetails.isSettlor == true
                                  ? 'Settlor'
                                  : userDetails.isAuthPerson == 1
                                    ? 'AuthPerson'
                                    : userDetails.isEffectiveControl == 1
                                      ? 'EffectiveControl'
                                      : userDetails.isTrustIndividual == 1
                                        ? 'TrustIndividual'
                                        : ''}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })
                    : ''}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Directors</Accordion.Header>
                <Accordion.Body>
                  {props.data
                    ? props.data.trustDetails &&
                    props.data.trustDetails.trustDirector &&
                    props.data.trustDetails.trustDirector.map((userDetails, index) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6">
                              <label>Trust Director{index + 1} Name </label>
                            </div>

                            <div className="col-md-6 col-sm-6 col-6">
                              <p>{userDetails.fullLegalName}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6">
                              <label>Trust Director{index + 1} Email </label>
                            </div>

                            <div className="col-md-6 col-sm-6 col-6">
                              <p>{userDetails.userEmail}</p>
                            </div>
                          </div>
                        </>
                      );
                    })
                    : ''}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Other Associated People</Accordion.Header>
                <Accordion.Body>

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Source of Funds</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Trust Fund Reason</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.trustDetails.trustFundReason : ''}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Trust Generate Income</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.trustDetails.trustSourceOfIncome : ''}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Trust Settlor Fund</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>
                        {props.data ? props.data.trustDetails.trustsettlorFundsGensettlorFundsGen : ''}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Trust Funds Document</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="document_download">
                        <a
                          href={
                            props.data &&
                              props.data.trustDetails.trustSourceOfFundsProof !== null &&
                              props.data.trustDetails.trustSourceOfFundsProof !== '' &&
                              props.data.trustDetails.trustSourceOfFundsProof !== undefined
                              ? props.data.trustDetails.trustSourceOfFundsProof
                              : ''
                          }
                          className="licedocument-downloade"
                        >
                          {' '}
                          <div className="main-downloade-wrapper">
                            {props.data &&
                              props.data.trustDetails.trustSourceOfFundsProof !== null &&
                              props.data.trustDetails.trustSourceOfFundsProof !== '' &&
                              props.data.trustDetails.trustSourceOfFundsProof !== undefined ? (
                              <p className="downloadeMsg">
                                <div className="downloads">
                                  <i className="fa fa-download downloadeStyle"></i>
                                </div>{' '}
                                VIEW FILE{' '}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </a>

                        <span id="name_tag" class="preview2"></span>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Bank Account</Accordion.Header>
                <Accordion.Body>
                  <BankAccount data={props && props.bank} />

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Wholesale Certification</Accordion.Header>
                <Accordion.Body>
                  <WholesaleCertificate data={props && props.certificate} />

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="view_tabs responsive_hide ">


            <Tab.Container id="left-tabs-example" defaultActiveKey="second">
              <Row>
                <Col sm={5} md={3} className="border-right">
                  <Nav variant="pills" className="flex-column">


                    <Nav.Item>
                      <Nav.Link eventKey="second">Trust Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tax</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="four">Directors</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="five">Other Associated People</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="six">Source of Funds</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="seven">Bank Account</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="eight">Wholesale Certification</Nav.Link>
                    </Nav.Item>


                  </Nav>
                </Col>
                <Col sm={7} md={9} className="formPopUp">
                  <Tab.Content>


                    <Tab.Pane eventKey="second">

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Trust Name </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.trustDetails.trustName : ''}</p>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-md-6">
                          <label>Trust Address Proof</label>
                        </div>
                        <div className="col-md-6">
                          <div className="document_download">
                            <a
                              href={
                                props.data &&
                                  props.data.trustDetails.trustAddressProof !== null &&
                                  props.data.trustDetails.trustAddressProof !== '' &&
                                  props.data.trustDetails.trustAddressProof !== undefined
                                  ? props.data.trustDetails.trustAddressProof
                                  : ''
                              }
                              className="licedocument-downloade"
                            >
                              {' '}
                              <div className="main-downloade-wrapper">
                                {props.data &&
                                  props.data.trustDetails.trustAddressProof !== null &&
                                  props.data.trustDetails.trustAddressProof !== '' &&
                                  props.data.trustDetails.trustAddressProof !== undefined ? (
                                  <p className="downloadeMsg">
                                    <div className="downloads">
                                      <i className="fa fa-download downloadeStyle"></i>
                                    </div>{' '}
                                    VIEW FILE{' '}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </a>

                            <span id="name_tag" class="preview2"></span>
                          </div>
                        </div>
                      </div>
                      <div className="row">



                        {props.data && props.data.trustDetails &&
                          props.data.trustDetails.trustDeedProof.map((url, ind) => {

                            return (
                              <>
                                <div className="col-md-6">
                                  <label>Trust Deed Proof{ind + 1}</label>
                                </div>
                                <div className="col-md-6">
                                  <div className="document_download">
                                    <a
                                      href={
                                        url
                                      }
                                      className="licedocument-downloade"
                                    >
                                      {' '}
                                      <div className="main-downloade-wrapper">
                                        {url ? (
                                          <p className="downloadeMsg">
                                            <div className="downloads">
                                              <i className="fa fa-download downloadeStyle"></i>
                                            </div>{' '}
                                            VIEW FILE{' '}
                                          </p>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </a>

                                    <span id="name_tag" class="preview2"></span>
                                  </div>
                                </div>
                              </>
                            )

                          })

                        }

                        {/* <div className="col-md-6">
                          <div className="document_download">
                            <a
                              href={
                                props.data &&
                                  props.data.trustDetails.trustDeedProof !== null &&
                                  props.data.trustDetails.trustDeedProof !== '' &&
                                  props.data.trustDetails.trustDeedProof &&
                                  'undefined' &&
                                  props.data.trustDetails.trustDeedProof !== undefined
                                  ? props.data.trustDetails.trustDeedProof
                                  : ''
                              }
                              className="licedocument-downloade"
                            >
                              {' '}
                              <div className="main-downloade-wrapper">
                                {props.data &&
                                  props.data.trustDetails.trustDeedProof !== null &&
                                  props.data.trustDetails.trustDeedProof !== '' &&
                                  props.data.trustDetails.trustDeedProof &&
                                  'undefined' &&
                                  props.data.trustDetails.trustDeedProof !== undefined ? (
                                  <p className="downloadeMsg">
                                    <div className="downloads">
                                      <i className="fa fa-download downloadeStyle"></i>
                                    </div>{' '}
                                    VIEW FILE{' '}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </a>

                            <span id="name_tag" class="preview2"></span>
                          </div>
                        </div> */}
                      </div>


                    </Tab.Pane>
                    <Tab.Pane eventKey="third">

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>
                            Trust <span className="capital"> IRD </span>Number
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.trustDetails.trustIrdNumber : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>
                            Trust <span className="capital"> IRD </span>Proof
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="document_download">
                            <a
                              href={
                                props.data &&
                                  props.data.trustDetails.trustIrdNumberProof !== null &&
                                  props.data.trustDetails.trustIrdNumberProof !== '' &&
                                  props.data.trustDetails.trustIrdNumberProof &&
                                  'undefined' &&
                                  props.data.trustDetails.trustIrdNumberProof !== undefined
                                  ? props.data.trustDetails.trustIrdNumberProof
                                  : ''
                              }
                              className="licedocument-downloade"
                            >
                              {' '}
                              <div className="main-downloade-wrapper">
                                {props.data &&
                                  props.data.trustDetails.trustIrdNumberProof !== null &&
                                  props.data.trustDetails.trustIrdNumberProof !== '' &&
                                  props.data.trustDetails.trustIrdNumberProof &&
                                  'undefined' &&
                                  props.data.trustDetails.trustIrdNumberProof !== undefined ? (
                                  <p className="downloadeMsg">
                                    <div className="downloads">
                                      <i className="fa fa-download downloadeStyle"></i>
                                    </div>{' '}
                                    VIEW FILE{' '}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </a>

                            <span id="name_tag" class="preview2"></span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Trust Register Address</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.trustDetails.trustRegisterAddress : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Trust Type</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.trustDetails.trustType : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Country of Incorporation</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.trustDetails.trustcountryOfIncorporation : ''}</p>
                        </div>
                      </div>
                      {props.data && props.data.trustDetails.trustType == 'Other' ? (
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <label>Trust Other Type</label>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <p>{props.data ? props.data.trustDetails.trustTypeOther : ''}</p>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label className=""> Tax Type </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="rwttax">
                            <div className="Main-wrapper-circle">
                              <p className="circle">{props.data && props.data.trustDetails.trustTaxType}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {props.data && props.data.trustDetails.trustTaxType == 'Resident Withholding Tax' ? (
                        <div className="row">
                          <div className="col-md-6  col-sm-6">
                            <label className="">Resident Withholding Tax Rate</label>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div className="rwttax">
                              <div className="Main-wrapper-circle">
                                <p className="circle">
                                  {props.data ? props.data.trustDetails.trustRwtValue : ''}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {props.data && props.data.trustDetails.trustRwtValue == 'Exempt' ? (
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <label>Trust Proof of Exemption</label>
                          </div>

                          <div className="col-md-6 document_download">
                            <a
                              href={
                                props.data &&
                                  props.data.trustDetails.trustExamptProof !== null &&
                                  props.data.trustDetails.trustExamptProof !== '' &&
                                  props.data.trustDetails.trustExamptProof !== undefined
                                  ? props.data.trustDetails.trustExamptProof
                                  : ''
                              }
                              className="licedocument-downloade"
                            >
                              {' '}
                              <div className="main-downloade-wrapper">
                                {props.data &&
                                  props.data.trustDetails.trustExamptProof !== null &&
                                  props.data.trustDetails.trustExamptProof !== '' &&
                                  props.data.trustDetails.trustExamptProof !== undefined ? (
                                  <p className="downloadeMsg">
                                    <div className="downloads">
                                      <i className="fa fa-download downloadeStyle"></i>
                                    </div>{' '}
                                    VIEW FILE{' '}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </a>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {props.data &&
                        props.data.trustDetails.trustTaxType == 'Non Resident Withholding Tax' ? (
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <label>Resident Witholding Tax Exemption</label>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div className="rwttax">
                              <div className="Main-wrapper-circle">
                                <p>
                                  {props.data.trustDetails.trustTaxExemption == 0 ||
                                    props.data.trustDetails.trustTaxExemption == false
                                    ? 'No'
                                    : 'Yes'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>
                            Is the Trust U.S. Citizen, Or U.S. Resident, Or A Tax Resident in Any Other
                            Country
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="">
                            <div class="switch-field nospace">
                              <div className="check_YN_Bank1">
                                <p>
                                  {props.data && props.data.trustDetails.isTrustCitizen == true
                                    ? 'Yes'
                                    : 'No'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Name of corporate trustee company</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="">
                            <div class="switch-field nospace">
                              <div className="check_YN_Bank1">
                                <p>
                                  {props.data && props.data.trustDetails.trustCompanyName
                                    ? props.data.trustDetails.trustCompanyName
                                    : ''}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {props.data && props.data.trustDetails.isTrustCitizen == true ? (
                        <>
                          <div className="">
                            <Row className="FlagsSelectcss">
                              <Col md={6} sm={6}>
                                <div>
                                  <label>Country Of Tax Residence</label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.trustDetails.trustCountryOfResidence
                                    ? props.data.trustDetails.trustCountryOfResidence
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6}>
                                <div>
                                  <label>
                                    Tax Identification Number <span className="capital">(TIN)</span>
                                  </label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.trustDetails.trustTinNumber
                                    ? props.data.trustDetails.trustTinNumber
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6}>
                                <div>
                                  <label>
                                    Reason if <span className="capital">TIN</span> is Not Available
                                  </label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.trustDetails.trustTinReason
                                    ? props.data.trustDetails.trustTinReason
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      {props.data
                        ? props.data.trustDetails &&
                        props.data.trustDetails.trustIndividualDetails &&
                        props.data.trustDetails.trustIndividualDetails.map((userDetails, index) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <label>
                                    {' '}
                                    Settlors, Trustees, Authorised people, or anyone else with Effective
                                    Control of the trust {index + 1}{' '}
                                  </label>
                                </div>

                                <div className="col-md-6 col-sm-6">
                                  <p>{userDetails.fullLegalName}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <label>Settlors/Trustee/Authorised{index + 1} Email </label>
                                </div>

                                <div className="col-md-6 col-sm-6">
                                  <p>{userDetails.userEmail}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <label>
                                    Do you have a corporate trustee or professional trustee{index + 1}{' '}
                                  </label>
                                </div>

                                <div className="col-md-6 col-sm-6">
                                  <p>{userDetails.isTrustee == true ? 'Yes' : 'No'}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <label> Role Of Settlors/Trustee/Authorised {index + 1} </label>
                                </div>

                                <div className="col-md-6 col-sm-6">
                                  <p>
                                    {userDetails.isSettlor == true
                                      ? 'Settlor'
                                      : userDetails.isAuthPerson == 1
                                        ? 'AuthPerson'
                                        : userDetails.isEffectiveControl == 1
                                          ? 'EffectiveControl'
                                          : userDetails.isTrustIndividual == 1
                                            ? 'TrustIndividual'
                                            : ''}
                                  </p>
                                </div>
                              </div>
                            </>
                          );
                        })
                        : ''}


                    </Tab.Pane>
                    <Tab.Pane eventKey="four">

                      {props.data
                        ? props.data.trustDetails &&
                        props.data.trustDetails.trustDirector &&
                        props.data.trustDetails.trustDirector.map((userDetails, index) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <label>Trust Director{index + 1} Name </label>
                                </div>

                                <div className="col-md-6 col-sm-6">
                                  <p>{userDetails.fullLegalName}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <label>Trust Director{index + 1} Email </label>
                                </div>

                                <div className="col-md-6 col-sm-6">
                                  <p>{userDetails.userEmail}</p>
                                </div>
                              </div>
                            </>
                          );
                        })
                        : ''}


                    </Tab.Pane>
                    <Tab.Pane eventKey="five">




                    </Tab.Pane>
                    <Tab.Pane eventKey="six">

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Trust Fund Reason</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.trustDetails.trustFundReason : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Trust Generate Income</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.trustDetails.trustSourceOfIncome : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Trust Settlor Fund</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>
                            {props.data ? props.data.trustDetails.trustsettlorFundsGensettlorFundsGen : ''}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label>Trust Funds Document</label>
                        </div>
                        <div className="col-md-6">
                          <div className="document_download">
                            <a
                              href={
                                props.data &&
                                  props.data.trustDetails.trustSourceOfFundsProof !== null &&
                                  props.data.trustDetails.trustSourceOfFundsProof !== '' &&
                                  props.data.trustDetails.trustSourceOfFundsProof !== undefined
                                  ? props.data.trustDetails.trustSourceOfFundsProof
                                  : ''
                              }
                              className="licedocument-downloade"
                            >
                              {' '}
                              <div className="main-downloade-wrapper">
                                {props.data &&
                                  props.data.trustDetails.trustSourceOfFundsProof !== null &&
                                  props.data.trustDetails.trustSourceOfFundsProof !== '' &&
                                  props.data.trustDetails.trustSourceOfFundsProof !== undefined ? (
                                  <p className="downloadeMsg">
                                    <div className="downloads">
                                      <i className="fa fa-download downloadeStyle"></i>
                                    </div>{' '}
                                    VIEW FILE{' '}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </a>

                            <span id="name_tag" class="preview2"></span>
                          </div>
                        </div>
                      </div>


                    </Tab.Pane>
                    <Tab.Pane eventKey="seven">

                      <BankAccount data={props && props.bank} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="eight">
                      {/* <Wholesale_Certificate data={userr && userr} /> */}
                      <WholesaleCertificate data={props && props.certificate} />
                    </Tab.Pane>





                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>




        </div>
      </div>
    </>
  );
};
export default TrustDetails;
