/*eslint-disable eqeqeq*/

import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { audience, urlapi } from 'src/auth/Configuration';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilValue } from 'recoil';
import { userdataState } from '../Action';
import { Row, Col, Nav, Tab, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import { Form, Input } from 'antd';
import Swal from 'sweetalert2';
import "react-phone-input-2/lib/bootstrap.css";
import FormItem from 'antd/es/form/FormItem';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import $, { now } from 'jquery';
import WholesaleProfile from './WholesaleProfile';


const ProfilePages = () => {
    const {
        getIdTokenClaims,
        getAccessTokenSilently,
        isAuthenticated,
        loginWithRedirect,
        accessToken,
        user,
        logout
    } = useAuth0();
    const [admindata, setAdmindata] = useState('');
    const [verifyloader, setVerifyloader] = useState();
    const [verfimsg, setVerfimsg] = useState();
    const [verfi, setVerfi] = useState();
    const [open, setOpen] = React.useState(false);
    const [modal, setModal] = useState(false);
    const show = () => setModal(!modal);
    const toggle = () => setModal(!modal);
    const [modal1, setModal1] = useState(false);
    const show1 = () => setModal1(!modal1);
    const toggle1 = () => setModal1(!modal1);
    const [modal2, setModal2] = useState(false);
    const show2 = () => setModal2(!modal2);
    const toggle2 = () => {
        setError(false);
        setVerifyTitle("VERIFY MOBILE");


        setModal2(!modal2);

    };
    function setDateformateToValid(datevalue) {
        if (
            datevalue !== null &&
            datevalue !== 'undefined' &&
            typeof datevalue !== 'undefined' &&
            datevalue.includes('/')
        ) {
            let dat = datevalue.split('/');
            dat = dat[2] + '-' + dat[1] + '-' + dat[0];
            return dat;
        }
    }
    const [modal3, setModal3] = useState(false);
    const show3 = () => setModal3(!modal3);
    const toggle3 = () => setModal3(!modal3);
    const [modal4, setModal4] = useState(false);
    const show4 = () => setModal4(!modal4);
    const toggle4 = () => setModal4(!modal4);
    const [modal5, setModal5] = useState(false);
    const show5 = () => setModal5(!modal5);
    const toggle5 = () => setModal5(!modal5);
    const [token, setToken] = useState();
    const [loader, setLoader] = useState(false);
    let navigate = useNavigate();

    const handleOpen = () => {

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    function checkDate() {
        var selectedDate = document.getElementById('documentExpiryDate').value;
        var now = new Date();
        var dt1 = Date.parse(now),
            dt2 = Date.parse(selectedDate);
        if (dt2 < dt1) {
        }
    }
    const getUserMetadata = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: audience,
                scope: 'read:current_user'
            });
        } catch (e) { }
        try {
            const accessidToken = await getIdTokenClaims({
                audience: audience,
                scope: 'read:current_user'
            });
            {
                if (
                    accessidToken.__raw !== 'undefined' ||
                    accessidToken.__raw !== null ||
                    accessidToken.__raw !== undefined
                ) {
                    let token = accessidToken.__raw;
                    sessionStorage.setItem('token', token);
                    setVerfi(token);
                    setToken(token);
                    application(accessidToken.__raw);


                } else {
                }
            }
        } catch (e) { }
    };
    useEffect(() => {
        getUserMetadata();
    }, []);
    const myusers = useRecoilValue(userdataState);
    const investType = myusers ? myusers && myusers[0].investmentType : '';
    //   const investType="Individual";

    const application = async (tokens) => {
        // alert(isAuthenticated)
        if (isAuthenticated) {
            const applicationdata1 = await axios
                .get(`${urlapi}/investor/profile/${investType}`, {
                    headers: { Authorization: `Bearer ${tokens}` }
                })
                .then((res) => {
                    setAdmindata(res.data[0]);
                });
        }
    };
    const resetdata = async () => {
        setVerifyloader(true);
        if (isAuthenticated) {
            const restPassword = await axios
                .put(
                    `${urlapi}/admin/update/password`,
                    {},
                    {
                        headers: { Authorization: `Bearer ${verfi}` }
                    }
                )
                .then((restPassword) => {
                    restPassword.status === 200 ? console.log(restPassword.data) : console.log('fail');
                    setVerifyloader(false);
                    setVerfimsg(restPassword.data);
                });
        }
    };
    const [verifyMobile, setVerifyMobile] = useState(false);
    const updateNumber = (e) => {
        setVerifyMobile(true);
        // alert(e.target.value)
    }
    const EmailUpdateApi = async (value) => {

        const userEmails = {
            "userEmail": value.email,
            "investmentType": value.investType,
        }
        setLoader(true);
        if (isAuthenticated) {
            await axios
                .put(`${urlapi}/investor/email/update`, userEmails, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((res) => {
                    setModal(false);
                    setLoader(false);
                    Swal.fire({
                        text: "success"
                    }).then(() => {
                        application(token);
                    })
                    // navigate('/profilePage');
                    // setAdmindata(res.data[0]);
                }).catch((error) => {
                    setLoader(false);
                    setModal(false);
                    Swal.fire({
                        text: "error"
                    });
                })
        }
    }
    const [code, setCountryCode] = useState();
    const [Numbers, setNumbers] = useState();
    const [otpValue, setOtpValue] = useState();
    const num = (Numbers == "undefined" || Numbers == undefined ? (admindata && admindata.priContactNumber) : Numbers)
    let userNumber = "+" + code + " " + num;
    const [verifyTitile, setVerifyTitle] = useState("VERIFY MOBILE")
    const SendOtp = async () => {

        setVerifyTitle("PLEASE WAIT");

        const val = {
            "to": userNumber,
            "channel": "sms",
            "locale": "en"
        }
        if (isAuthenticated) {
            await axios
                .post(`${urlapi}/investor/start-verify`, val, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((res) => {

                    setLoader(false);
                    setVerifyTitle("UNIQUE VERIFICATION CODE SENT");


                    application(token);

                }).catch((error) => {

                    setLoader(false);
                    setVerifyTitle("VERIFY MOBILE");
                    Swal.fire({
                        text: "error"
                    })
                })
        }
    }
    const [codeLoader, setCodeLoader] = useState(false);
    const [errorloader, setError] = useState(false);
    const VerifyMobileNumber = async () => {
        setCodeLoader(true);

        const vals =
        {
            "to": userNumber,
            "verification_code": otpValue,
            "investmentType": investType
        }

        if (isAuthenticated) {
            await axios
                .post(`${urlapi}/investor/check-verify`, vals, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((res) => {

                    setModal2(false);
                    setCodeLoader(false);
                    Swal.fire({
                        text: "success"
                    }).then(() => {
                        application(token);
                    })
                }).catch((error) => {
                    setCodeLoader(false);
                    // setModal2(false);
                    setError(true);
                })
        }
    }
    const UpdateBankDetails = async (value) => {

        setLoader(true);
        value.investmentType = investType;


        if (isAuthenticated) {
            await axios
                .put(`${urlapi}/investor/update/profile`, value, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((res) => {

                    setModal4(false);
                    setLoader(false);
                    Swal.fire({
                        text: "success"
                    }).then(() => {
                        application(token);
                    })
                }).catch((error) => {
                    setLoader(false);
                    setModal4(false);
                    Swal.fire({
                        text: "error"
                    });
                })
        }
    }
    const UpdateAbout = async (value) => {

        setLoader(true);
        value.investmentType = investType;


        if (isAuthenticated) {
            await axios
                .put(`${urlapi}/investor/update/profile`, value, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((res) => {

                    setModal1(false);
                    setLoader(false);
                    Swal.fire({
                        text: "success"
                    }).then(() => {
                        application(token);
                    })
                }).catch((error) => {
                    setLoader(false);
                    setModal1(false);
                    Swal.fire({
                        text: "error"
                    });
                })
        }
    }
    const UpdateAddress = async (value) => {

        setLoader(true);
        value.investmentType = investType;
        value.userResidentialAddress = `${value.addressLine1}, ${value.suburb}, ${value.city}, ${value.postalCode}, ${value.countryCode}`;


        if (isAuthenticated) {
            await axios
                .put(`${urlapi}/investor/update/address`, value, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((res) => {

                    setModal3(false);
                    setLoader(false);
                    Swal.fire({
                        text: "success"
                    }).then(() => {
                        application(token);
                    })
                }).catch((error) => {
                    setLoader(false);
                    setModal3(false);
                    Swal.fire({
                        text: "error"
                    });
                })
        }
    }
    const [idTypee, setIdTypee] = useState(admindata && admindata.documentIdType);

    const IdUpdate = async (value) => {
        console.log("vallllll", value);
        value.dateOfBirth = $('.ui.icon.input.dateOfBirth>input').val();
        value.documentExpiryDate = $('.ui.icon.input.documentExpiryDate>input').val();
        // value.documentCountryOfIssue = 'NZ';
        // value.verifyFor = 'identity';
        value.documentIdType = idTypee && idTypee;
        value.fullLegalName = value.firstName + " " + value.middleName + " " + value.lastName;
        // value.investmentType = investType;


        console.log("vallllll", value);

        setLoader(true);


        if (isAuthenticated) {
            await axios
                .put(`${urlapi}/investor/update/identity?investType=${investType}`, value, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((res) => {

                    setModal5(false);
                    setLoader(false);
                    Swal.fire({
                        text: "success"
                    }).then(() => {
                        application(token);
                    })
                }).catch((error) => {
                    setLoader(false);
                    setModal5(false);
                    Swal.fire({
                        text: "error"
                    });
                })
        }
    }
    const ResetPass = async () => {
        setLoader(true);

        const emails = admindata && admindata.userEmail;
        const vals =
        {
            "email": emails,
        }

        if (isAuthenticated) {
            await axios
                .post(`${urlapi}/investor/reset/password`, vals, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((res) => {
                    setLoader(false);

                    Swal.fire({
                        text: "success"
                    });

                }).catch((error) => {
                    setLoader(false);

                    Swal.fire({
                        text: "error"
                    });
                })
        }
    }
    const fullNamemm = admindata && admindata.fullLegalName;
    const aa = fullNamemm.split(" ");
    return (
        <>
            {
                loader == true ? <div className="main-loader">
                    <div className="loader"></div>
                </div> :

                    <div className="right_content aurora_layout">
                        <div className="conatiner">
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <div className="heading__top ht_heading">
                                <h2>
                                    <Link to="/Dashboard1">
                                        <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
                                    </Link>
                                    Profile                </h2>
                            </div> */}
                                    <div className=' investor_profile_form_page'>
                                        <div className='investor_profile formPopUp'>
                                            <h4> <Link to="/investorDashboard">
                                                <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
                                            </Link> Investor Profile</h4>

                                            <div className='investor_tabning'>
                                                <Tab.Container defaultActiveKey={"one"}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <Nav variant="pills" className="flex-column">
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="one">About</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="second">Address</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="third">Tax & Bank Details</Nav.Link>
                                                                </Nav.Item>
                                                                {/* <Nav.Item>
                                                                    <Nav.Link eventKey="four">Id Verification</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="five">Wholesale Certificate</Nav.Link>
                                                                </Nav.Item> */}
                                                            </Nav>
                                                        </Col>
                                                        <Col lg={12} className="formPopUp py-3">
                                                            <Tab.Content>
                                                                <Tab.Pane eventKey="one">
                                                                    {admindata ? <div className='row'>
                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <label>Full Name :</label>
                                                                        </div>
                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <span> {admindata.fullLegalName} </span>
                                                                            <i class="fa fa-pencil ms-2" aria-hidden="true" onClick={toggle1}></i>
                                                                        </div>
                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <label>Email Address :</label>
                                                                        </div>
                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <span className='d-flex align-items-center flex-wrap'>{admindata.userEmail} <button className='sent_email_btn mx-3'>send email</button>  <i class="fa fa-pencil" aria-hidden="true" onClick={toggle}></i> </span>
                                                                            <Modal isOpen={modal} show={show} >
                                                                                <ModalHeader toggle={toggle}>
                                                                                    Update Email
                                                                                </ModalHeader>

                                                                                <ModalBody className='email_updated_pro email_updated'>
                                                                                    <Form
                                                                                        className="pop_width-100"
                                                                                        name="basic"
                                                                                        initialValues={{ remember: true }}
                                                                                        onFinish={EmailUpdateApi}
                                                                                    >
                                                                                        <Form.Item
                                                                                            label="Email:"
                                                                                            name="email"
                                                                                            initialValue={admindata.userEmail}
                                                                                            className="quantity"
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Please enter email'
                                                                                                }
                                                                                            ]}
                                                                                        >
                                                                                            <Input
                                                                                                placeholder="Enter Email"
                                                                                                defaultValue={admindata.userEmail}
                                                                                                maxlength="50"
                                                                                                className="mb-2 quantity"
                                                                                                onPaste={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    return false;
                                                                                                }}
                                                                                                required
                                                                                            />
                                                                                        </Form.Item>
                                                                                        <Form.Item>
                                                                                            <Button
                                                                                                className="coffee-color right-side overwrite d-table ml-auto mt-3 "
                                                                                                type="primary"
                                                                                                htmlType="submit"
                                                                                            >
                                                                                                Submit
                                                                                            </Button>
                                                                                        </Form.Item>
                                                                                    </Form>
                                                                                </ModalBody>
                                                                            </Modal>
                                                                        </div>
                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <label>Preferred Name :</label>
                                                                        </div>
                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <span>{admindata.preferredName}</span>
                                                                        </div>
                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <label>Mobile Number :</label>
                                                                        </div>
                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <span>{`+${admindata.priContactCountryCode} ${admindata.priContactNumber}`}</span>
                                                                            <i class="fa fa-pencil ml-2" aria-hidden="true" onClick={toggle2}></i>
                                                                            <Modal isOpen={modal2} show={show2} >
                                                                                <ModalHeader toggle={toggle2}>
                                                                                    Update Mobile Number
                                                                                </ModalHeader>

                                                                                <ModalBody className='email_updated_pro'>
                                                                                    <div className='veryfy_number d-flex align-items-ceter justify-content-between mb-3 flex-wrap'>
                                                                                        <div className='veryfy_input w-100'>
                                                                                            <div className='counmtry_number'>
                                                                                                <PhoneInput
                                                                                                    className="counrtyselext5 digitsOnlynone country_fleg"
                                                                                                    readonly
                                                                                                    country={'nz'}
                                                                                                    preferredCountries={['nz']}

                                                                                                    name="priContactCountryCode"
                                                                                                    defaultValue={code}
                                                                                                    onChange={(phone) => {
                                                                                                        setCountryCode(phone);
                                                                                                    }}
                                                                                                />
                                                                                                <input type="text" placeholder='Enter Number' defaultValue={admindata.priContactNumber ? admindata.priContactNumber : ""} onChange={(e) => setNumbers(e.target.value)} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='veryfy_btn w-100'>
                                                                                            <button onClick={SendOtp}>{verifyTitile}</button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='veryfy_number d-flex align-items-ceter justify-content-between flex-wrap'>
                                                                                        <div className='veryfy_input w-100 verification_inpu'>
                                                                                            <label>Verification code</label>
                                                                                            <input type="text" placeholder='Enter unique verification code' onChange={(e) => {
                                                                                                setOtpValue(e.target.value)
                                                                                                setError(false);
                                                                                            }} />
                                                                                            {errorloader ? <span style={{ color: "red" }} >Please Enter Valid Code</span> : ""}
                                                                                        </div>
                                                                                        <div className='veryfy_btn w-100 mt-3'>
                                                                                            <button onClick={VerifyMobileNumber}>Submit</button>

                                                                                            {codeLoader ? <div className="main-loader position-relative">
                                                                                                <div className="loader"></div>
                                                                                            </div> : ""}

                                                                                        </div>
                                                                                    </div>

                                                                                </ModalBody>
                                                                            </Modal>
                                                                        </div>


                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <label>Reset Password :</label>
                                                                        </div>
                                                                        <div className='col-md-6 col-sm-6 col-12'>
                                                                            <button className='sent_email_btn ' onClick={ResetPass}>send link</button>
                                                                        </div>

                                                                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                                                                            {/* <i class="fa fa-pencil" aria-hidden="true" onClick={toggle1}></i> */}
                                                                            <Modal isOpen={modal1} show={show1} >
                                                                                <ModalHeader toggle={toggle1}>
                                                                                    About
                                                                                </ModalHeader>

                                                                                <ModalBody className='about'>
                                                                                    <Form
                                                                                        className="pop_width-100"
                                                                                        name="basic"
                                                                                        initialValues={{ remember: true }}
                                                                                        onFinish={UpdateAbout}
                                                                                    >
                                                                                        <Form.Item
                                                                                            label="Name:"
                                                                                            name="fullLegalName"
                                                                                            initialValue={admindata.fullLegalName}
                                                                                            className="quantity"
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Please enter name'
                                                                                                }
                                                                                            ]}
                                                                                        >
                                                                                            <Input
                                                                                                placeholder="Enter Name"
                                                                                                defaultValue={admindata.fullLegalName}
                                                                                                maxlength="50"
                                                                                                className="mb-2 quantity"
                                                                                                onPaste={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    return false;
                                                                                                }}
                                                                                                required
                                                                                            />
                                                                                        </Form.Item>



                                                                                        <Form.Item
                                                                                            label="Preferred Name :"
                                                                                            name="preferredName"
                                                                                            initialValue={admindata.preferredName}
                                                                                            className="quantity"
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Please enter preferred name'
                                                                                                }
                                                                                            ]}
                                                                                        >
                                                                                            <Input
                                                                                                placeholder="Enter preferred name"
                                                                                                defaultValue={admindata.preferredName}
                                                                                                maxlength="50"
                                                                                                className="mb-2 quantity"
                                                                                                onPaste={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    return false;
                                                                                                }}
                                                                                                required
                                                                                            />
                                                                                        </Form.Item>
                                                                                        <Form.Item>
                                                                                            <Button
                                                                                                className="coffee-color right-side overwrite d-table ml-auto mt-3 "
                                                                                                type="primary"
                                                                                                htmlType="submit"
                                                                                            >
                                                                                                Submit
                                                                                            </Button>
                                                                                        </Form.Item>
                                                                                    </Form>
                                                                                </ModalBody>
                                                                            </Modal>
                                                                        </div>

                                                                    </div> : ""}
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="third">
                                                                    {admindata ?
                                                                        <div className='row'>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Tax Rate :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{admindata.taxType}</span>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Bank Account Name :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{admindata.primaryBankName}</span>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Bank Account Number :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{admindata.primaryBankAccountNumber}</span>
                                                                            </div>
                                                                            <div className='col-lg-12 text-right'>
                                                                                <i class="fa fa-pencil" aria-hidden="true" onClick={toggle4}></i>
                                                                                <Modal isOpen={modal4} show={show4} >
                                                                                    <ModalHeader toggle={toggle4}>
                                                                                        Update Tax & Bank Details
                                                                                    </ModalHeader>

                                                                                    <ModalBody className='address_update'>
                                                                                        <Form
                                                                                            className="pop_width-100"
                                                                                            name="basic"
                                                                                            initialValues={{ remember: true }}
                                                                                            onFinish={UpdateBankDetails}
                                                                                        >
                                                                                            <Form.Item
                                                                                                label="Bank Account Name :"

                                                                                                name="primaryBankName"
                                                                                                initialValue={admindata.primaryBankName}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter bank account name'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter bank Name"
                                                                                                    defaultValue={admindata.primaryBankName}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="Bank Account Number:"
                                                                                                name="primaryBankAccountNumber"
                                                                                                initialValue={admindata.primaryBankAccountNumber}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter account number '
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter bank account number"
                                                                                                    defaultValue={admindata.primaryBankAccountNumber}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="Tax Rate:"
                                                                                                name="taxType"
                                                                                                initialValue={admindata.taxType}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter tax rate'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter tax rate"
                                                                                                    defaultValue={admindata.taxType}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item>
                                                                                                <Button
                                                                                                    className="coffee-color right-side overwrite d-table ml-auto mt-3 "
                                                                                                    type="primary"
                                                                                                    htmlType="submit"
                                                                                                >
                                                                                                    Submit
                                                                                                </Button>
                                                                                            </Form.Item>
                                                                                        </Form>
                                                                                    </ModalBody>
                                                                                </Modal>
                                                                            </div>
                                                                        </div>
                                                                        : ""}
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="second">
                                                                    {admindata ?
                                                                        <div className='row'>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Address :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{admindata.userResidentialAddress}</span>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Postal Address :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{admindata.userPostalAddress}</span>
                                                                            </div>
                                                                            <div className='col-lg-12 text-right'>
                                                                                <i class="fa fa-pencil" aria-hidden="true" onClick={toggle3}></i>
                                                                                <Modal isOpen={modal3} show={show3} >
                                                                                    <ModalHeader toggle={toggle3}>
                                                                                        Update Address
                                                                                    </ModalHeader>

                                                                                    <ModalBody className='address'>
                                                                                        <Form
                                                                                            className="pop_width-100"
                                                                                            name="basic"
                                                                                            initialValues={{ remember: true }}
                                                                                            onFinish={UpdateAddress}
                                                                                        >
                                                                                            <Form.Item
                                                                                                label="AddressLine1:"
                                                                                                name="addressLine1"
                                                                                                initialValue={admindata.addressLine1}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter addressLine1'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter AddressLine1"
                                                                                                    defaultValue={admindata.addressLine1}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="City:"
                                                                                                name="city"
                                                                                                initialValue={admindata.city}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter city'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter City"
                                                                                                    defaultValue={admindata.city}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="Country:"
                                                                                                name="country"
                                                                                                initialValue={admindata.country}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter country'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter Country"
                                                                                                    defaultValue={admindata.country}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="Country Code:"
                                                                                                name="countryCode"
                                                                                                initialValue={admindata.countryCode}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter countryCode'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter CountryCode"
                                                                                                    defaultValue={admindata.countryCode}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="PostalCode:"
                                                                                                name="postalCode"
                                                                                                initialValue={admindata.postalCode}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter postalCode'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter PostalCode"
                                                                                                    defaultValue={admindata.postalCode}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="Suburb:"
                                                                                                name="suburb"
                                                                                                initialValue={admindata.suburb}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter suburb'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter Suburb"
                                                                                                    defaultValue={admindata.suburb}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item>
                                                                                                <Button
                                                                                                    className="coffee-color right-side overwrite d-table ml-auto mt-3 "
                                                                                                    type="primary"
                                                                                                    htmlType="submit"
                                                                                                >
                                                                                                    Submit
                                                                                                </Button>
                                                                                            </Form.Item>
                                                                                        </Form>
                                                                                    </ModalBody>
                                                                                </Modal>
                                                                            </div>
                                                                        </div>
                                                                        : ""}
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="five">
                                                                    <WholesaleProfile
                                                                        admindata={admindata.certificateDetails}
                                                                    />

                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="four">
                                                                    {admindata ?
                                                                        <div className='row'>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Type of Id :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{admindata.documentIdType}</span>
                                                                            </div>
                                                                            {admindata.documentIdType != null ? (
                                                                                admindata.documentIdType != 'NZ Driver Licence' &&
                                                                                    admindata.documentIdType != 'NZ Passport' ? (
                                                                                    <>

                                                                                        <div className="col-md-6 col-sm-6 col-6">
                                                                                            <label>Other Document Type</label>
                                                                                        </div>

                                                                                        <div className="col-md-6 col-sm-6 col-6">
                                                                                            <p className="paragraph">{admindata.otherDocumentName}</p>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    ''
                                                                                )
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>First Name :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{aa[0]}</span>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Middle Name :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{aa[1]}</span>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Last Name :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{aa[2]}</span>
                                                                            </div>

                                                                            {admindata ? (
                                                                                admindata.documentIdType == 'NZ Driver Licence' ? (
                                                                                    <>
                                                                                        <div className="col-md-6 col-sm-6 col-6">
                                                                                            <label>Licence Number</label>
                                                                                        </div>
                                                                                        <div className="col-md-6 col-sm-6 col-6">
                                                                                            <p className="paragraph">{admindata.documentNumber}</p>
                                                                                        </div>
                                                                                    </>
                                                                                ) : admindata.documentIdType == 'NZ Passport' ? (
                                                                                    <>

                                                                                        <div className="col-md-6 col-sm-6 col-6">
                                                                                            <label>Passport Number</label>
                                                                                        </div>
                                                                                        <div className="col-md-6 col-sm-6 col-6">
                                                                                            <p className="paragraph">{admindata.documentNumber}</p>
                                                                                        </div>

                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className="col-md-6 col-sm-6 col-6">
                                                                                            <label>Document Number</label>
                                                                                        </div>
                                                                                        <div className="col-md-6 col-sm-6 col-6">
                                                                                            <p className="paragraph">{admindata.documentNumber}</p>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            ) : (
                                                                                ''
                                                                            )}

                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Expiry Date :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{admindata.documentExpiryDate}</span>
                                                                            </div>


                                                                            {/* {admindata ? (
                                                                                admindata ? (
                                                                                    admindata.documentIdType == 'NZ Driver Licence' ? (
                                                                                        <>
                                                                                            {' '}
                                                                                            <div className="col-md-6 col-sm-6 col-6">
                                                                                                <label>Version Number</label>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-sm-6 col-6">
                                                                                                <p className="paragraph">{admindata.documentVersionNumber}</p>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : admindata.documentIdType == 'NZ Passport' ? (
                                                                                        <>
                                                                                            <div className="col-md-6 col-sm-6 col-6">
                                                                                                <label>Country Of Issue</label>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-sm-6 col-6">
                                                                                                <p className="paragraph">{admindata.documentCountryOfIssue}</p>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className="col-md-6 col-sm-6 col-6">
                                                                                                <label>Country Of Issue</label>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-sm-6 col-6">
                                                                                                <p className="paragraph">{admindata.documentCountryOfIssue}</p>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                ) : (
                                                                                    ''
                                                                                )
                                                                            ) : (
                                                                                ''
                                                                            )} */}

                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <label>Date of Birth :</label>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-6 col-12'>
                                                                                <span>{admindata.dateOfBirth}</span>
                                                                            </div>
                                                                            <div className='col-lg-12 text-right'>
                                                                                <i class="fa fa-pencil" aria-hidden="true" onClick={toggle5}></i>
                                                                                <Modal isOpen={modal5} show={show5} >
                                                                                    <ModalHeader toggle={toggle5}>
                                                                                        Update Address
                                                                                    </ModalHeader>

                                                                                    <ModalBody className='address'>
                                                                                        <Form
                                                                                            className="pop_width-100"
                                                                                            name="basic"
                                                                                            initialValues={{ remember: true }}
                                                                                            onFinish={IdUpdate}
                                                                                        >


                                                                                            <>
                                                                                                <Row>
                                                                                                    <Col xs={12}>
                                                                                                        <div className="custom-select-btn">
                                                                                                            <label>Please select your id type</label>
                                                                                                            <select
                                                                                                                id="select_option1"
                                                                                                                value={idTypee}
                                                                                                                name="documentIdType"
                                                                                                                onChange={(e) => setIdTypee(e.target.value)}
                                                                                                                className="mb-3"
                                                                                                            >
                                                                                                                <option value="NZ Driver Licence">NZ Driver Licence</option>
                                                                                                                <option value="NZ Passport">NZ Passport</option>
                                                                                                                <option value="other">Other</option>
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </>

                                                                                            {admindata.documentIdType != null ? (
                                                                                                admindata.documentIdType != 'NZ Driver Licence' &&
                                                                                                    admindata.documentIdType != 'NZ Passport' ? (
                                                                                                    <>

                                                                                                        <Form.Item
                                                                                                            label="Other Document Type:"
                                                                                                            name="documentIdType"
                                                                                                            initialValue={admindata.otherDocumentName}
                                                                                                            className="quantity"
                                                                                                            rules={[
                                                                                                                {
                                                                                                                    required: true,
                                                                                                                    message: 'Please enter other document type '
                                                                                                                }
                                                                                                            ]}
                                                                                                        >
                                                                                                            <Input
                                                                                                                placeholder="Enter Other Document Type"
                                                                                                                defaultValue={admindata.otherDocumentName}
                                                                                                                maxlength="50"
                                                                                                                className="mb-2 quantity"
                                                                                                                onPaste={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    return false;
                                                                                                                }}
                                                                                                                required
                                                                                                            />
                                                                                                        </Form.Item>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ''
                                                                                                )
                                                                                            ) : (
                                                                                                ''
                                                                                            )}

                                                                                            <Form.Item
                                                                                                label="First Name:"
                                                                                                name="firstName"
                                                                                                initialValue={aa[0]}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter first name'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter First Name"
                                                                                                    defaultValue={aa[0]}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="Middle Name:"
                                                                                                name="middleName"
                                                                                                initialValue={aa[1]}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter middle name'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter middle Name"
                                                                                                    defaultValue={aa[1]}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="Last Name:"
                                                                                                name="lastName"
                                                                                                initialValue={aa[2]}
                                                                                                className="quantity"
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: 'Please enter last name'
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Enter Last Name"
                                                                                                    defaultValue={aa[2]}
                                                                                                    maxlength="50"
                                                                                                    className="mb-2 quantity"
                                                                                                    onPaste={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        return false;
                                                                                                    }}
                                                                                                    required
                                                                                                />
                                                                                            </Form.Item>




                                                                                            <Form.Item name="documentExpiryDate" className="date_width">
                                                                                                <label className="label">Expiry Date</label>

                                                                                                <div class="custom_datepiker">
                                                                                                    <SemanticDatepicker
                                                                                                        id="documentExpiryDate"
                                                                                                        filterDate={(date) => {
                                                                                                            const now = new Date();
                                                                                                            return date >= now;
                                                                                                        }}
                                                                                                        onPaste={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            return false;
                                                                                                        }}
                                                                                                        onKeyPress={(date) => {
                                                                                                            const now = new Date();
                                                                                                            return date >= now;
                                                                                                        }}
                                                                                                        format="DD/MM/YYYY"
                                                                                                        className="documentExpiryDate mb-2 widthSET"
                                                                                                        allowOnlyNumbers
                                                                                                        required
                                                                                                        minDate={now()}
                                                                                                        onChange={checkDate}
                                                                                                        value={
                                                                                                            admindata.documentExpiryDate
                                                                                                                ? new Date(
                                                                                                                    setDateformateToValid(admindata.documentExpiryDate)
                                                                                                                )
                                                                                                                : ''
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                                <span className="error ExpiryDate"></span>
                                                                                            </Form.Item>


                                                                                            {/* {admindata ? (
                                                                                                admindata ? (
                                                                                                    admindata.documentIdType == 'NZ Driver Licence' ? (
                                                                                                        <>
                                                                                                            {' '}

                                                                                                            <Form.Item
                                                                                                                label="Version Number:"
                                                                                                                name="documentVersionNumber"
                                                                                                                initialValue={admindata.documentVersionNumber}
                                                                                                                className="quantity"
                                                                                                                rules={[
                                                                                                                    {
                                                                                                                        required: true,
                                                                                                                        message: 'Please enter version number'
                                                                                                                    }
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input
                                                                                                                    placeholder="Enter Version Number"
                                                                                                                    defaultValue={admindata.documentVersionNumber}
                                                                                                                    maxlength="50"
                                                                                                                    className="mb-2 quantity"
                                                                                                                    onPaste={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        return false;
                                                                                                                    }}
                                                                                                                    required
                                                                                                                />
                                                                                                            </Form.Item>
                                                                                                        </>
                                                                                                    ) : admindata.documentIdType == 'NZ Passport' ? (
                                                                                                        <>

                                                                                                            <Form.Item
                                                                                                                label="Country Of Issue:"
                                                                                                                name="documentCountryOfIssue"
                                                                                                                initialValue={admindata.documentCountryOfIssue}
                                                                                                                className="quantity"
                                                                                                                rules={[
                                                                                                                    {
                                                                                                                        required: true,
                                                                                                                        message: 'Please enter country of issue'
                                                                                                                    }
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input
                                                                                                                    placeholder="Enter Country Of Issue"
                                                                                                                    defaultValue={admindata.documentCountryOfIssue}
                                                                                                                    maxlength="50"
                                                                                                                    className="mb-2 quantity"
                                                                                                                    onPaste={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        return false;
                                                                                                                    }}
                                                                                                                    required
                                                                                                                />
                                                                                                            </Form.Item>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>

                                                                                                            <Form.Item
                                                                                                                label="Country Of Issue:"
                                                                                                                name="documentCountryOfIssue"
                                                                                                                initialValue={admindata.documentCountryOfIssue}
                                                                                                                className="quantity"
                                                                                                                rules={[
                                                                                                                    {
                                                                                                                        required: true,
                                                                                                                        message: 'Please enter country of issue'
                                                                                                                    }
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input
                                                                                                                    placeholder="Enter Country Of Issue"
                                                                                                                    defaultValue={admindata.documentCountryOfIssue}
                                                                                                                    maxlength="50"
                                                                                                                    className="mb-2 quantity"
                                                                                                                    onPaste={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        return false;
                                                                                                                    }}
                                                                                                                    required
                                                                                                                />
                                                                                                            </Form.Item>
                                                                                                        </>
                                                                                                    )
                                                                                                ) : (
                                                                                                    ''
                                                                                                )
                                                                                            ) : (
                                                                                                ''
                                                                                            )} */}

                                                                                            {idTypee == 'NZ Driver Licence' ? (
                                                                                                <div>
                                                                                                    <Form.Item
                                                                                                        label="Licence Number:"
                                                                                                        name="documentNumber"
                                                                                                        initialValue={admindata ? admindata.documentNumber : ''}
                                                                                                        className="numbersOnly"
                                                                                                        rules={[
                                                                                                            {
                                                                                                                required: true,
                                                                                                                message: 'Please enter licence number'
                                                                                                            }
                                                                                                        ]}
                                                                                                    >
                                                                                                        <Input
                                                                                                            placeholder="Enter licence number"
                                                                                                            defaultValue={admindata ? admindata.documentNumber : ''}
                                                                                                            className="mb-2 digitsOnly"
                                                                                                            maxlength="50"
                                                                                                            required
                                                                                                        />
                                                                                                    </Form.Item>

                                                                                                    <Form.Item
                                                                                                        label="Version Number:"
                                                                                                        name="documentVersionNumber"
                                                                                                        initialValue={admindata ? admindata.documentVersionNumber : ' '}
                                                                                                        className="numbers"
                                                                                                    >
                                                                                                        <Input
                                                                                                            placeholder="Enter version number"
                                                                                                            defaultValue={
                                                                                                                admindata ? admindata.documentVersionNumber : ''
                                                                                                            }
                                                                                                            className="mb-2 numbers"
                                                                                                            maxlength="3"
                                                                                                            minLength={3}
                                                                                                            required
                                                                                                            maxLength={3}
                                                                                                            onPaste={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                return false;
                                                                                                            }}
                                                                                                        />
                                                                                                    </Form.Item>
                                                                                                </div>
                                                                                            ) : idTypee == 'NZ Passport' ? (
                                                                                                <div>
                                                                                                    <Form.Item
                                                                                                        label="Passport Number:"
                                                                                                        name="documentNumber"
                                                                                                        autoComplete="off"
                                                                                                        initialValue={admindata ? admindata.documentNumber : ''}
                                                                                                        className="digitsOnly"
                                                                                                        rules={[
                                                                                                            {
                                                                                                                required: true,
                                                                                                                message: 'Please enter Passport number'
                                                                                                            }
                                                                                                        ]}
                                                                                                    >
                                                                                                        <Input
                                                                                                            autoComplete="off"
                                                                                                            placeholder="Enter passport number"
                                                                                                            maxlength="50"
                                                                                                            defaultValue={admindata ? admindata.documentNumber : ''}
                                                                                                            className="mb-2 digitsOnly"
                                                                                                            onPaste={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                return false;
                                                                                                            }}
                                                                                                        />
                                                                                                    </Form.Item>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <div>
                                                                                                        <Form.Item
                                                                                                            label="Document Number:"
                                                                                                            name="documentNumber"
                                                                                                            autoComplete="off"
                                                                                                            initialValue={admindata ? admindata.documentNumber : ''}
                                                                                                            className="digitsOnly"
                                                                                                        >
                                                                                                            <Input
                                                                                                                autoComplete="off"
                                                                                                                placeholder="Enter document number"
                                                                                                                maxlength="50"
                                                                                                                defaultValue={admindata ? admindata.documentNumber : ''}
                                                                                                                className="mb-2 digitsOnly"
                                                                                                                required
                                                                                                            />
                                                                                                        </Form.Item>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <Form.Item
                                                                                                            label="Document Type:"
                                                                                                            name="otherDocumentName"
                                                                                                            autoComplete="off"
                                                                                                            initialValue={admindata ? admindata.otherDocumentName : ''}
                                                                                                            className="digitsOnly"
                                                                                                        >
                                                                                                            <Input
                                                                                                                autoComplete="off"
                                                                                                                placeholder="Enter document type"
                                                                                                                maxlength="50"
                                                                                                                defaultValue={admindata ? admindata.otherDocumentName : ''}
                                                                                                                className="mb-2 digitsOnly"
                                                                                                                required
                                                                                                            />
                                                                                                        </Form.Item>
                                                                                                    </div>
                                                                                                </>
                                                                                            )}



                                                                                            <Form.Item name="dateOfBirth" className="date_width">
                                                                                                <label className="label">Date of Birth</label>
                                                                                                <div class="custom_datepiker">
                                                                                                    <SemanticDatepicker
                                                                                                        id="dateOfBirth"
                                                                                                        format="DD/MM/YYYY"
                                                                                                        className="dateOfBirth mb-2 widthSET"
                                                                                                        allowOnlyNumbers
                                                                                                        onPaste={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            return false;
                                                                                                        }}
                                                                                                        required
                                                                                                        maxDate={new Date(moment().subtract(18, 'years'))}
                                                                                                        value={
                                                                                                            admindata.dateOfBirth
                                                                                                                ? new Date(setDateformateToValid(admindata.dateOfBirth))
                                                                                                                : ''
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            </Form.Item>
                                                                                            <Form.Item className="pepAml__modalPopup">
                                                                                                <Button
                                                                                                    className="coffee-color right-side overwrite d-table ml-auto mt-3 Edit_button_style"
                                                                                                    type="primary"
                                                                                                    htmlType="submit"
                                                                                                >
                                                                                                    Submit
                                                                                                </Button>
                                                                                            </Form.Item>
                                                                                        </Form>
                                                                                    </ModalBody>
                                                                                </Modal>
                                                                            </div>
                                                                        </div>
                                                                        : ""}
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </Col>

                                                    </Row>
                                                </Tab.Container>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
            }
        </>

    );
};
export default ProfilePages;
