/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
// import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { getName } from 'country-list';
import axios from 'axios';
import { audience, urlapi } from 'src/auth/Configuration';

const PersonalInformation2 = (props) => {
  const { user, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [nloader, setNloader] = useState(false);
  const [token, setToken] = useState();

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  function getCountryName(country) {
    try {
      return getName(country);
    } catch {
      return '';
    }
  }
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;

          //    formData.token =
          // sessionStorage.setItem('token', token);

          //   application(token);
          //   Notessfunc(token);
          getur(token);
        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);
  const NoteClick = (data) => {
    setNloader(true);
    let postadv = {
      downloadUrl: data
    };
    axios
      .post(`${urlapi}/admin/download/image`, postadv, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        window.open(res.data.url);
        setNloader(false);
      });
  };
  const [geturs, setGeturs] = useState();

  const getur = (token) => {
    const NotessData = axios
      .get(`${urlapi}/admin/team/member`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setGeturs(result.data);
        }
      });
  };
  return (
    <div className="row">
      <div className="col-md-11">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Full Legal Name</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>{props.data ? props.data.fullLegalName : ''}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Preferred Name</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>{props.data ? props.data.preferredName : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Date Of Birth</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>{props.data ? props.data.dateOfBirth : ''}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Email </label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            {props.data && props.data.userEmail === props.data.userCode ? (
              <p>{props.data ? props.data.userEmail : ''}</p>
            ) : (
              <p>{props.data ? props.data.userEmail : ''}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Mobile Number</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>
              {props.data ? (
                props.data.priContactNumber !== null &&
                  props.data.priContactCountryCode !== null ? (
                  <p>
                    {props.data && props.data.priContactCountryCode !== ''
                      ? '+' + props.data.priContactCountryCode + ' '
                      : ' '}
                    {props.data ? props.data.priContactNumber : ''}
                  </p>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
        {props.data ? (
          props.data.isAlternateContact === 1 ? (
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6">
                <label> {props.data.secContactType} Additional Number</label>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                {/* <p>
                                            {props.data ? props.data.secContactCountryCode : ''}{' '}
                                            {props.data ? props.data.secContactNumber : ''}
                                          </p> */}

                {props.data ? (
                  props.data.secContactNumber !== null &&
                    props.data.secContactCountryCode !== null ? (
                    <p>
                      {props.data && props.data.secContactCountryCode !== ''
                        ? '+' + props.data.secContactCountryCode + ' '
                        : ' '}
                      {props.data ? props.data.secContactNumber : ''}
                    </p>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : null
        ) : null}

        {/* <>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <label>Intend To Invest With MyFarm</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <p>{props.data ? props.data.intendToInvest : ''}</p>
                                        </div>
                                        {props.data && props.data.intendToInvest == 'Other' ? (
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6 col-6">
                                              <label>Other Intend To Invest With MyFarm </label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6 col-6">
                                              <p className="myfarm_com22">
                                                {props.data ? props.data.otherIntendToInvest : ''}
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <label>Total Intend To Invest With MyFarm</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <p>{props.data ? props.data.totalIintend : ''}</p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <label>Choose To Invest In This Opportunity</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <p>{props.data ? props.data.chooseToInvest : ''}</p>
                                        </div>
                                      </div>
                                      {props.data && props.data.chooseToInvest == 'Other' ? (
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <label>
                                              Other Choose To Invest In This Opportunity
                                            </label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <p>{props.data ? props.data.otherChooseToInvest : ''}</p>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}

                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <label>Occupation</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <p>{props.data ? props.data.occupation : ''}</p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <label> Source of Funds For Your Investment</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6 col-6">
                                          <p>{props.data ? props.data.sourceOfFund : ''}</p>
                                        </div>
                                      </div>
                                    </> */}

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Residential Address</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>{props.data ? props.data.userResidentialAddress : ''}</p>
          </div>
        </div>

        {props.data &&
          props.data.userResidentialAddress != null &&
          props.data.userResidentialAddress != '' ? (
          <></>
        ) : (
          ''
        )}

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Postal Address</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>
              {props.data && props.data.isUserAddressSame === 1
                ? 'Same as home address'
                : props.data && props.data.userPostalAddress
                  ? props.data.userPostalAddress
                  : ''}
            </p>
          </div>
        </div>

        {/* <div className="row">
                                      <div className="col-md-6  col-sm-6">
                                        <label>Occupation</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6">
                                        <p>{props.data ? props.data.occupation : ''}</p>
                                      </div>
                                    </div> */}
        {props.data && props.data.occupation === 'Other' ? (
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <label>Other Occupation</label>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <p>{props.data ? props.data.otherOccupation : ''}</p>
            </div>
          </div>
        ) : (
          ''
        )}

        <>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <label>City/Town Born In</label>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <p>{props.data ? props.data.placeOfBirth : ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <label>Country Born In</label>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <p>{props.data ? getCountryName(props.data.countryOfBirth) : ''}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <label>Country of Residence</label>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <p>{props.data ? getCountryName(props.data.residingCountry) : ''}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <label className="">IRD Number</label>
              {/* <label>IRD NUMBER</label> */}
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <p>{props.data ? props.data.userIrdNumber : ''}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <label className=""> Tax Type </label>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <div className="Main-wrapper-circle">
                <p className="circle">{props.data ? props.data.taxType : ''}</p>
              </div>
            </div>
          </div>

          {props.data && props.data.taxType == 'Resident Withholding Tax' ? (
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6">
                <label className="">Resident Withholding Tax Rate</label>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <div className="rwttax">
                  <div className="Main-wrapper-circle">
                    {' '}
                    <p className="circle">{props.data ? props.data.rwtValue : ''}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {props.data && props.data.rwtValue == 'Exempt' ? (
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6">
                <label>Proof of Exemption</label>
              </div>

              <div className="col-md-6 col-sm-6 col-6 document_download">
                <a
                  href="javascript:void(0)"
                  onClick={() => NoteClick(props.data.examptProof)}
                  className="licedocument-downloade"
                >
                  {' '}
                  <div className="main-downloade-wrapper">
                    {props.data &&
                      props.data.examptProof !== null &&
                      props.data.examptProof !== '' &&
                      props.data.examptProof &&
                      'undefined' &&
                      props.data.examptProof !== undefined ? (
                      <p className="downloadeMsg">
                        <div className="downloads">
                          <i className="fa fa-download downloadeStyle"></i>
                        </div>{' '}
                        View File{' '}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </a>
              </div>
            </div>
          ) : (
            ''
          )}

          {/* {props.data && props.data.taxType == 'AIL 2%' ? (
                                          <div className="checkmystyle">
                                            {' '}
                                            <>
                                              <input
                                                type="checkbox"
                                                id="TickHere"
                                                // value={checkboxx}
                                                name="checkboxx"
                                                // onChange={(e) => setCheckboxx(e.target.value)}
                                              />{' '}
                                              <span class="checkmark"></span>{' '}
                                              <label for="TickHere">
                                                Tick Here If You Hold A Valid Resident Withholding
                                                Tax/Non Resident Withholding Tax Exemption.
                                              </label>
                                            </>
                                          </div>
                                        ) : (
                                          ''
                                        )} */}

          {props.data && props.data.taxType == 'Non Resident Withholding Tax' ? (
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6">
                <label>Resident Withholding Tax Exemption</label>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <div className="rwttax">
                  <div className="Main-wrapper-circle">
                    <p>
                      {props.data.taxExemption == 0 || props.data.taxExemption == false
                        ? 'No'
                        : 'Yes'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {/* <div className="row">
                                          <div className="col-md-6 col-sm-6">
                                            <label>
                                              Is Investor U.S. Citizen, or U.S. Resident, or a Tax
                                              Resident In Any Other Country?
                                            </label>
                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                            <div className="">
                                              <div class="switch-field nospace">
                                                <div className="check_YN_Bank1">
                                                  <p>
                                                    {props.data && props.data.isOtherCitizen === 1
                                                      ? 'Yes'
                                                      : 'No'}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {props.data && props.data.isOtherCitizen === 1 ? (
                                          <>
                                            <div className="main-padding-wrapper">
                                              <Row className="FlagsSelectcss residence">
                                                <Col md={6} sm={6}>
                                                  <div>
                                                    <label>Country of Tax Residence</label>
                                                  </div>
                                                </Col>
                                                <Col md={6} sm={6}>
                                                  <p>
                                                    {props.data && props.data.tinDetails != null
                                                      ? getCountryName(
                                                          props.data.tinDetails[0].countryOfResidence
                                                        )
                                                      : ''}
                                                  </p>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col md={6} sm={6}>
                                                  <div>
                                                    <label>Tax Identification Number (TIN)</label>
                                                  </div>
                                                </Col>
                                                <Col md={6} sm={6}>
                                                  <p>
                                                    {props.data && props.data.tinDetails != null
                                                      ? props.data.tinDetails[0].tinNumber
                                                      : ''}
                                                  </p>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col md={6} sm={6}>
                                                  <div>
                                                    <label>Reason if TIN is Not Available</label>
                                                  </div>
                                                </Col>
                                                <Col md={6} sm={6}>
                                                  <p>
                                                    {props.data && props.data.tinDetails != null
                                                      ? props.data.tinDetails[0].tinReason
                                                      : ''}
                                                  </p>
                                                </Col>
                                              </Row>
                                            </div>
                                          </>
                                        ) : (
                                          ''
                                        )} */}
        </>
      </div>
      <div className="col-md-1 edit_icon">
        {user.profile.includes("UR4") ||
          user.profile.includes("UR2") ||
          (props.data && props.data.applicationStage == 'Active Client') ||
          user.profile.includes("UR3")
          ? ''
          : // <i
          //   onClick={handleOpen}
          //   title="Edit"
          //   class="fa fa-pencil-square-o"
          //   aria-hidden="true"
          // ></i>
          ''}
      </div>
    </div>
  );
};
export default PersonalInformation2;
