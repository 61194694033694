/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';


import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { MHidden } from 'src/components/@material-extend';
import Swal from 'sweetalert2';
import logo from '../../src/pages/replica_logo.png';
import { audience, urlapi } from 'src/auth/Configuration';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userapiState, userdataState } from './Action';
import DashboardNavbar2 from './DashboardNavbar2';


const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;
const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}));
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));
// ----------------------------------------------------------------------

function abc() {
    Swal.fire({
        position: 'top-end',
        html: `Notification & alert messages`,
        showConfirmButton: false,
        customClass: 'blackkk',
        showCloseButton: true
    });
}
export default function NotificationBtn(props) {
    const aaa = props.ttt;
    DashboardNavbar2.propTypes = {
        aaa: PropTypes.func
    };
    const {
        user,

        getIdTokenClaims,
        getAccessTokenSilently,
        isAuthenticated
    } = useAuth0();
    const [token, setToken] = useState();
    const [loader, setLoader] = useState();
    const myuserInvest = useRecoilValue(userdataState);
    // console.log('jjjj', myusers);
    const investments = myuserInvest ? myuserInvest && myuserInvest[0].investmentType : '';
    const getUserMetadata = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: audience,
                scope: 'read:current_user'
            });
        } catch (e) { }
        try {
            const accessidToken = await getIdTokenClaims({
                audience: audience,
                scope: 'read:current_user'
            });
            {
                if (
                    accessidToken.__raw !== 'undefined' ||
                    accessidToken.__raw !== null ||
                    accessidToken.__raw !== undefined
                ) {
                    setToken(accessidToken.__raw);
                    let token = accessidToken.__raw;
                    //    formData.token =
                    //    sessionStorage.setItem("token",formData.token )
                    // applicationss(token);

                    NotificationData(token, "");
                } else {
                }
            }
        } catch (e) { }
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            getUserMetadata();
        }, 900000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        getUserMetadata();
    }, []);
    const [notification, setNotification] = useState(false);

    const [userNotification, setUserNotification] = useState();
    const [notivalue, setNotivalue] = useState();
    const [New, newNoti] = useState(false);

    const NotificationData = async (token, val) => {
        setLoader(true);

        if (isAuthenticated) {
            // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
            // duval/admin/clients
            const applicationdata12 = await axios
                .get(`${urlapi}/investor/notification/${investments}`, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((res) => {
                    if (res.status == 200) {
                        // setLoader(false)
                        setUserNotification(res.data);
                        var a;
                        var count = 0;
                        for (a = 0; a < res.data.length; a++) {
                            if (res.data[a].seen == 0) {
                                count = count + 1;
                                newNoti(true)
                            }
                        }
                        setNotivalue(count);
                        if (val == true) {
                            axios
                                .put(`${urlapi}/investor/update/notification?investType=${investments}`, {}, {
                                    headers: { Authorization: `Bearer ${token}` }
                                }).then(() => {
                                    newNoti(false);
                                })
                        }

                        // alert(userNotification);
                    }
                    else {
                        // setLoader(false);
                    }

                });
            // }
        }
    };
    const [AllNotiValue, setAllNotiValue] = useState(false);
    const nofi_Fuction = () => {
        setNotification(true)
        NotificationData(token, true);

    }
    const cross_notification = () => {
        setNotification(false)
        setAllNotiValue(false)
    }
    const AllNotification = () => {
        setAllNotiValue(true)
    }
    console.log("notivalue", notivalue);
    return (
        <>

            <div className="header_TP justify-content-end">
                {/* <a href="#">
                    <img src={logo} alt="" />
                </a> */}
                <div className="d-inline-block ">
                    <a onClick={() => nofi_Fuction()} className="button bg-white notif_icon position-relative" href="#">
                        <span>Notifications</span>
                        <i class="fa fa-bell" aria-hidden="true"></i>
                        {/* <button className='show_notification'>{notivalue ? notivalue : 0}</button> */}
                        <button className='show_notification'>
                            {New ?
                                //  <i class="fa fa-comment-o d-block position-relative" aria-hidden="true"></i> 
                                <i class="fa fa-comment d-block position-relative" aria-hidden="true"></i>
                                : ""}


                        </button>
                    </a>

                    <MHidden width="lgUp">
                        <IconButton onClick={aaa} sx={{ mr: 1, color: 'text.primary' }}>
                            <Icon icon={menu2Fill} />
                        </IconButton>
                    </MHidden>

                </div>
            </div>
            {
                notification == true ?
                    < div className='notificaion_list'>
                        <h3>Notifications <i class="fa fa-times float-right  cross_icon" onClick={cross_notification} aria-hidden="true"></i></h3>
                        <div className='notificaion_body'>
                            {
                                userNotification && userNotification.map((userInfo, id) => {
                                    return (
                                        <>
                                            <Link>
                                                <div className='notificaion_items d-flex align-items-start'>
                                                    <div className='nofi_icon mr-3'><i class="fa fa-user-circle-o" aria-hidden="true"></i></div>
                                                    <div className='notifi_content' key={id}>
                                                        <h5 className='m-0'>{userInfo.title}</h5>
                                                        <p className='mb-0 mt-1'>{userInfo.description}</p>
                                                        <small className='date'>Date : {userInfo.created_datecreatedAt}</small>
                                                        {/* <small className='text-dark'>Date : {userInfo.updated_date}</small> */}
                                                    </div>
                                                </div>
                                            </Link>
                                        </>
                                    )

                                })
                            }

                        </div>
                    </div> : ""
            }

        </>
    );
}
