import React, { useState, useEffect } from 'react';

import Router from './routes';
import Route2 from './routes2';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import './styles.css';
import './style1.css';
import './main.css';
import 'antd/dist/antd.min.css';
import { RecoilRoot } from 'recoil';

// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { useAuth0 } from '@auth0/auth0-react';
import Router3 from './route3';

// ----------------------------------------------------------------------

export default function App() {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  useEffect(() => {
    // {
    //   isAuthenticated && user.profile == 'User' && logout();
    // }
  });
  if (isLoading) {
    return (
      <div className="main-loader">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <>
      {isAuthenticated && user.profile != 'User' && (
        <RecoilRoot>
          <ThemeConfig>
            <GlobalStyles />
            <BaseOptionChartStyle />
            <ScrollToTop />
            <Router />
          </ThemeConfig>
        </RecoilRoot>

      )}
      {isAuthenticated && user.profile == 'User' && (
        <RecoilRoot>
          <ThemeConfig>
            <GlobalStyles />
            <BaseOptionChartStyle />
            <ScrollToTop />
            <Router3 />
          </ThemeConfig>

        </RecoilRoot>

      )}
      <RecoilRoot>
        {!isAuthenticated && <Route2 />}


      </RecoilRoot>
    </>
  );
}
