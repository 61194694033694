/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { audience, urlapi } from '../../auth/Configuration';

import { Button } from 'react-bootstrap';

import Swal from 'sweetalert2';

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import $ from 'jquery';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Input } from 'antd';

const Newsresearch = () => {
  const [options, setOptions] = useState([]);

  const { user, getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      // duval/admin/clients
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/news/research`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          if (res.status == 200) {
            setLoader(false);

            setOptions(res.data);
            // setUsers(res.data);
          } else {
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
          Swal.fire('', 'Please try after sometimes!', '');
        });
      // }
    }
  };
  // alert(user.profile)

  $.fn.dataTable.ext.errMode = 'none';

  $(document).ready(function () {
    $('#History_table').DataTable({
      scrollX: true,
      sScrollXInner: '100%'
    });
  });
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const show = () => setModal(!modal);

  const onFinish = async (values) => {
    setLoader(true);
    setModal(false);
    await axios
      .post(`${urlapi}/admin/news/research`, values, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        console.log('dddddd', res.status);
        setLoader(false);

        application(token);
        Swal.fire('', 'News & Research created successfully', '');
      })
      .catch((error) => {
        console.log('fffff', error == 'Error: Request failed with status code 400');
        setLoader(false);
        if (error == 'Error: Request failed with status code 400') {
          Swal.fire({
            text: 'Email already existed!',
            confirmButtonColor: '#477894',
            confirmButtonText: 'Ok'
          });
        } else {
          Swal.fire({
            text: 'Please try after sometime',
            confirmButtonColor: '#477894',
            confirmButtonText: 'Ok'
          });
        }
      });
  };

  const deleteuser = async (id) => {
    setLoader(true);
    await axios
      .delete(`${urlapi}/admin/news/research/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        if (res) {
          application(token);

          res.status === 200
            ? Swal.fire({
              html: 'Deleted',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#3085D6'
            }).then((e) => {
              // history.push("/")
              // window.location = '/';
              setLoader(false);
              // window.location.reload(true);
            })
            : Swal.fire(
              '',
              'We are unable to process your request at the moment. Please try again after some time',
              ''
            );
        }
      })
      .catch(function (error) {
        Swal.fire(
          '',
          'Unfortunately an error has occurred while submitting your application. Please try again, or contact us on 0508 287 672 or hello@aurorafinancial.co.nz',
          ''
        );
      });
  };
  const deleteonSubmit = async (id) => {
    Swal.fire({
      allowOutsideClick: false,
      // html: "Please wait a moment while we delete your application",
      html: 'Are you sure you want to delete this News & Research?',
      // showDenyButton: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Yes, Delete News & Research`,
      // denyButtonText:`CANCEL`,
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteuser(id);
      }
    });
  };

  return (
    <>
      <Modal isOpen={modal} show={show}>
        <ModalHeader toggle={toggle}>Add News & Research</ModalHeader>

        <ModalBody>
          <Form
            className="pop_width-100"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Document Name:"
              name="doucmentName"
              // initialValue={user1 ? user1.firstName : ''}
              className="quantity"
              rules={[
                {
                  required: true,
                  message: 'Please enter document name'
                }
              ]}
            >
              <Input
                placeholder="Enter document name(s)"
                // defaultValue={user1 ? user1.firstName : ''}
                maxlength="50"
                className="mb-2 quantity"
                // onPaste={(e) => {
                //   e.preventDefault();
                //   return false;
                // }}
                required
              />
            </Form.Item>

            <Form.Item
              label="Url:"
              name="doucmentUrl"
            // className="letterOnly"
            >
              <Input
                placeholder="Enter url(s)"
                // className="mb-2 letterOnly"
                className="mb-2"
                required
              // onPaste={myhandleChange}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="coffee-color right-side overwrite d-table ml-auto mt-3"
                type="primary"
                htmlType={'submit'}
              //  onClick={handleClick}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </ModalBody>
      </Modal>
      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="right_content">
          <div className="heading__top ht_heading d-flex align-items-center justify-content-between mb-2">
            <h2>
              {/* <Link to="/client">
                <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
              </Link> */}
              News & Research
            </h2>
            {user.profile.includes("UR3") || user.profile.includes("UR1") ? <Button className="news_btn btn-left mr-0" onClick={toggle}>
              +
            </Button>
              :
              ""
            }
          </div>
          <div className=" ht_heading11">
            <div className=" table-responsive">
              <table class="table table-striped new_search_table">
                <tbody>
                  {options &&
                    options.map((deta) => {
                      return (
                        <tr>
                          <td className="text-start  new_search_table_text ">
                            {deta.doucmentName}
                          </td>
                          <td className="new_search_table_buttons text-end">
                            <a
                              className="document_btn d-inline-block"
                              target={'_blank'}
                              // deepcode ignore DOMXSS: <please specify a reason of ignoring this>
                              href={`${deta.doucmentUrl}`} rel="noreferrer"
                            >
                              <button>
                                <i class="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </a>
                            {user.profile.includes("UR3") || user.profile.includes("UR1") ? <button
                              className="m-auto d-inline-block"
                              onClick={() => deleteonSubmit(deta.id)}
                            >
                              <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button> : ""}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Newsresearch;
