import React from 'react'

const WholesaleProfile = (props) => {
    // console.log("props", props);
    return (
        <div className='row'>
            {props ?
                <>
                    <div className='col-md-6 col-sm-6 col-12'>
                        <label>Financial Products:</label>
                    </div>
                    <div className='col-md-6 col-sm-6 col-12'>
                        {props.admindata && props.admindata.acquiringDisposingFinancialProducts ? props.admindata.acquiringDisposingFinancialProducts : ""}
                    </div>
                </> : ""
            }


        </div>
    )
}

export default WholesaleProfile;