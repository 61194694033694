import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { audience, urlapi } from '../../auth/Configuration';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ViewSyndicate from './ViewSyndicate';
import { Link } from 'react-router-dom';


const SyndicateDetails = () => {
  const { getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();
  const { id } = useParams();
  const getUserMetadata = async () => {
    try {
      await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);
  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/syndicate/users/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {

          setLoader(false);


        })
        .catch((error) => {
          setLoader(false);

          Swal.fire('Something went wrong');
        });
      // }
    }
  };
  return (
    <>
      <div className="right_content">
        <div className="heading__top ht_heading">
          <h2>
            <Link to="/investment">
              <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </Link>
            Admin Users
          </h2>
        </div>
        <div className="form director">
          <div className="myfarm_com" key={5}>
            {' '}
            <ViewSyndicate
              apiPath1={`/admin/syndicate/users/${id}`}
              tableTitle={'View User'}
              tableid={'table_id5'}
              AddUserData={'activeAdd'}
            />
          </div>

        </div>
      </div>
    </>
  )
}
export default SyndicateDetails;