/*eslint-disable eqeqeq*/

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Drawer, Typography } from '@mui/material';
import Avatar, { ConfigProvider } from 'react-avatar';

import Scrollbar from './Scrollbar2';
import { MHidden } from 'src/components/@material-extend';
import { sidebarUser } from '../layouts/dashboard/SidebarConfig';
import axios from 'axios';
import { audience, urlapi } from 'src/auth/Configuration';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from '@mui/material/Modal';
import { useIdleTimer } from 'react-idle-timer';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userapiState, userdataState } from './Action';
import NavSection2 from './NavSection2';

// ----------------------------------------------------------------------
const DRAWER_WIDTH = 280;
const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));
const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[200]
}));
// ----------------------------------------------------------------------
DashboardSidebar2.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func
};
export default function DashboardSidebar2({ isOpenSidebar, onCloseSidebar }) {
    const {
        getIdTokenClaims,
        getAccessTokenSilently,
        isAuthenticated,

        user,
        logout
    } = useAuth0();
    const { pathname } = useLocation();
    const [tokens, setTokens] = useState();
    function check(tokens) {
        // user.profile != 'User' ? logout() : application(tokens);
        user.profile != 'User' ? logout() : applications(tokens);
    }
    const applications = () => {

    }
    const [verfi, setVerfi] = useState();
    const [verfimsg, setVerfimsg] = useState();
    const getUserMetadata = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: audience,
                scope: 'read:current_user'
            });
        } catch (e) { }
        try {
            const accessidToken = await getIdTokenClaims({
                audience: audience,
                scope: 'read:current_user'
            });
            {
                if (
                    accessidToken.__raw !== 'undefined' ||
                    accessidToken.__raw !== null ||
                    accessidToken.__raw !== undefined
                ) {
                    let token = accessidToken.__raw;
                    setTokens(accessidToken.__raw);
                    setVerfi(token);
                    check(accessidToken.__raw);
                    InvestmentDetails('undefined', accessidToken.__raw);


                    sessionStorage.setItem('token', token);
                } else {
                }
            }
        } catch (e) { }
    };
    useEffect(() => {
        getUserMetadata();
    }, []);

    const [verifyloader, setVerifyloader] = useState();
    const [admindata, setAdmindata] = useState('');
    const [loaderr, setLoaderr] = useState(false);
    const [userData, setUserData] = useState();
    const [userData1, setUserData1] = useState();
    const [UserName, setUserName] = useState();
    // const application = async (tokens) => {
    //   // alert(isAuthenticated)
    //   if (isAuthenticated) {
    //     const applicationdata1 = await axios
    //       .get(`${urlapi}/investor/user/profile`, {
    //         headers: { Authorization: `Bearer ${tokens}` }
    //       })
    //       .then((res) => {
    //         setAdmindata(res.data);
    //       });
    //   }
    // };
    const [users, setUsers] = useRecoilState(userapiState);
    const [avtarName, setAvtarName] = useState();
    const navigate = useNavigate();

    const InvestmentDetails = async (e, tokenn) => {
        // alert(isAuthenticated)
        navigate("/investorDashboard")
        setUsers(0);
        setLoaderr(true);
        let Invest = e == 'undefined' ? 'undefined' : e.target.value;

        // alert(Invest);
        await axios
            .get(`${urlapi}/investor/user/${Invest}`, {
                headers: { Authorization: `Bearer ${tokenn}` }
            })
            .then((res) => {
                if (res.status == 200) {
                    setLoaderr(false);
                    setUsers(res.data);
                    setUserData(res.data[0]);
                    setUserData1(res.data[1]);
                    setUserName(res.data[0].userName);
                    setAvtarName(res.data[0])
                    // alert(res.data[0].investmentType)
                    InvestType(res.data[0].investmentType, tokenn)
                }
                else {
                    setLoaderr(false);
                    setUsers(res.data);
                    setUserName("");
                    setUserData("");
                    setUserData1("");

                    // console.log(res, "hjgj")
                }

                // setAdmindata(res.data);
            })
            .catch((error) => {

                setLoaderr(false);
            });
    };
    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);
    const llogggout = () => {
        sessionStorage.clear();
        localStorage.clear();
        logout({ returnTo: window.location.origin });
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const resetdata = async () => {
        setVerifyloader(true);
        if (isAuthenticated) {
            const restPassword = await axios
                .put(
                    `${urlapi}/admin/update/password`,
                    {},
                    {
                        headers: { Authorization: `Bearer ${verfi}` }
                    }
                )
                .then((restPassword) => {
                    restPassword.status === 200 ? console.log(restPassword.data) : console.log('fail');
                    setVerifyloader(false);
                    setVerfimsg(restPassword.data);
                });
        }
    };
    // }
    const { id } = useParams();

    const timeout = 900000;
    const [remaining, setRemaining] = useState(timeout);
    const [elapsed, setElapsed] = useState(0);
    const [lastActive, setLastActive] = useState(+new Date());
    const [isIdle, setIsIdle] = useState(false);

    const handleOnActive = () => setIsIdle(false);
    const handleOnIdle = () => setIsIdle(true);
    const { reset, pause, resume, getRemainingTime, getLastActiveTime, getElapsedTime } =
        useIdleTimer({
            timeout,
            onActive: handleOnActive,
            onIdle: handleOnIdle
        });
    // console.log('remaining', remaining);
    const handleReset = () => reset();
    const handlePause = () => pause();
    const handleResume = () => resume();

    useEffect(() => {
        setRemaining(getRemainingTime());
        setLastActive(getLastActiveTime());
        setElapsed(getElapsedTime());
        setInterval(() => {
            setRemaining(getRemainingTime());
            setLastActive(getLastActiveTime());
            setElapsed(getElapsedTime());
        }, 1000);
    }, []);

    if (remaining == 0) {
        sessionStorage.clear();
        localStorage.clear();
        logout();
    }
    const myusers = useRecoilValue(userdataState);
    // console.log('jjjj', myusers);
    const invest = myusers ? myusers && myusers[0].investmentType : '';
    // const invest = "Individual";

    const [Indi, setIndi] = useState();
    const [Joint, setJoint] = useState();
    const [Company, setCompany] = useState();
    const [Trust, setTrust] = useState();
    const [dropShow, setdropShow] = useState(false);
    const InvestType = async (investtt, tokennp) => {
        // alert(isAuthenticated)
        // navigate("/investorDashboard")
        // setUsers(0);
        // setLoaderr(true);
        // let Invest = e == 'undefined' ? 'undefined' : e.target.value;

        // alert(Invest);
        await axios
            .get(`${urlapi}/investor/investmentType`, {
                headers: { Authorization: `Bearer ${tokennp}` }
            })
            .then((res) => {
                // setUsername(res.data);
                if (res.data[1] != undefined && res.data[1] != null && res.data[1] != '') {
                    setdropShow(true);
                }
                var i;
                for (i = 0; i < 4; i++) {
                    if (res.data[i].investmentType == "Company") {
                        setCompany(res.data[i].userName);
                    } else if (res.data[i].investmentType == "Individual") {
                        setIndi(res.data[i].userName);

                    } else if (res.data[i].investmentType == "Joint") {
                        setJoint(res.data[i].userName);

                    } else if (res.data[i].investmentType == "Trust") {
                        setTrust(res.data[i].userName);

                    }
                }
            })

            .catch((error) => {


            });
    };

    const renderContent = (

        <Scrollbar
            sx={{
                height: '100%',
                '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
            }}
        >
            <div className="Nav__Top__B">
                <Box sx={{ mb: 5, mx: 2.5 }}>
                    <div class="nav_bg nav_logo_main">
                        <div class="nav_logo circle-box">
                            <ConfigProvider colors={['#d8d8d8', '#d8d8d8', '#d8d8d8']}>
                                <Avatar
                                    className="avatarname"
                                    name={
                                        // UserName && UserName
                                        (avtarName && avtarName.firstName != null ? avtarName.firstName : '') +
                                        ' ' +
                                        (avtarName && avtarName.lastName != null ? avtarName.lastName : '')
                                    }
                                />
                            </ConfigProvider>{' '}
                        </div>
                        <div className={dropShow == true ? 'select_account' : "one_user"}>
                            {/* <div onClick={handleOpen} className="adminProfile"> */}
                            <Link to="/profilePage" className="adminProfile" >
                                {invest == "Joint" ? <h5 className="text-center mb-1">{userData1 && userData1.entityName}</h5> : <h5 className="text-center mb-1">{UserName && UserName}</h5>}

                                <h5 className="text-center">
                                    {userData && userData.companyDetails && userData.companyDetails.companyName}
                                </h5>
                            </Link>
                            {/* </div> */}
                            {dropShow == true ?
                                <select
                                    className="sel-account"
                                    value={invest}
                                    onChange={(e) => InvestmentDetails(e, tokens)}
                                >
                                    <option value={'Select Account'}>Select Account</option>
                                    {Indi ? <option value={'Individual'}>{Indi}</option> : ""}

                                    {Joint ? <option value={'Joint'}>{Joint}</option> : ""}
                                    {Company ? <option value={'Company'}>{Company}</option> : ""}

                                    {Trust ? <option value={'Trust'}>{Trust}</option> : ""}
                                </select>
                                : ""

                            }

                        </div>

                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box className="formPopUp">
                                <h4>Inveastor Profile</h4>
                                <div className="edit_form">
                                    <div className="form_input row fullinput">
                                        <div className="col-md-6">
                                            <label>Name</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{admindata.fullLegalName}</p>
                                        </div>
                                    </div>
                                    <div className="form_input row fullinput">
                                        <div className="col-md-6">
                                            <label>Email Address</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{admindata.adminEmail}</p>
                                        </div>
                                    </div>
                                    <p>{verfimsg}</p>
                                    <p></p>
                                    <div className="submit_btn submit_btn_admin">
                                        {verifyloader === true ? (
                                            <>
                                                <button>
                                                    {' '}
                                                    Loading ..... <i class="fas fa-circle-notch fa-spin"></i>
                                                </button>
                                            </>
                                        ) : (
                                            <button onClick={resetdata}>Reset Password </button>
                                        )}
                                        {/* {verifyloader === true ? (
                      <div className="main-loader">
                        <div className="loader"></div>
                      </div>
                    ) : null} */}
                                        <button onClick={handleClose}>Cancel</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>

                        <div className="invetor_loader">{loaderr ? <div className="loader"></div> : ''}</div>
                    </div>
                </Box>

                <NavSection2 navConfig={sidebarUser} />
            </div>
            <Box sx={{ mb: 5, mx: 2.5 }}>
                <div className="">
                    <button onClick={llogggout}>Log out</button>
                </div>
            </Box>
        </Scrollbar>
    );



    return (
        <RootStyle>
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default'
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
