/*eslint-disable eqeqeq*/

const Contact = () => {
  return (
    <>
      <div className="right_content aurora_layout">
        <div className="heading__top ht_heading d-flex justify-content-between align-items-center">
          <h2>
            If you have any questions or need assistance, please contact our Investments Team.
          </h2>
        </div>
        <div className="form app_view_form border-0">
          <div class="row">
            <div class="col-md-6 mb-sm-3 ">
              <div class="card custom_card">
                <div class="card-body">
                  <h5 class="card-title">MyFarm Capital Member Services</h5>
                  <p class="card-text">P: 0800 693276</p>
                  <p class="card-text">E: investments@myfarm.co.nz</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 ">
              <div class="card pb-5 custom_card">
                <div class="card-body ">
                  <h5 class="card-title">MyFarm Office Address</h5>
                </div>
              </div>
            </div>
          </div>

        </div>



      </div>
    </>
  );
};
export default Contact;
