/*eslint-disable eqeqeq*/

import React from 'react';
// import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
// import { getCode, getName } from 'country-list';

const EntityDetails = (props) => {
  // const { user } = useAuth0();

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  return (
    <div className="row">
      <div className="col-md-11">
        <div className="row">
          {props.investmentTypes == 'Jointt' ? (
            <div className="col-md-6 col-sm-6">
              <label>
                Full Name of 1<sup>nd</sup> Joint Account Holder
              </label>
            </div>
          ) : (
            <div className="col-md-6 col-sm-6">
              <label>
                Full Name of 2<sup>st</sup>Joint Account Holder
              </label>
            </div>
          )}
          <div className="col-md-6  col-sm-6">
            <p>{props.data ? props.data.fullLegalName : ''}</p>
          </div>
        </div>

        <div className="row">
          {props.investmentTypes == 'Jointt' ? (
            <div className="col-md-6  col-sm-6">
              <label>
                Share of Income Attributed to 1<sup>nd</sup> Joint Account Holder
              </label>
            </div>
          ) : (
            <div className="col-md-6  col-sm-6">
              <label>
                Share of Income Attributed to 2<sup>nd</sup> Joint Account Holder
              </label>
            </div>
          )}

          {props.investmentTypes == 'Jointt' ? (
            <div className="col-md-6  col-sm-6">
              <p>{props.data ? 100 - props.percentage : ''}</p>
            </div>
          ) : (
            <div className="col-md-6  col-sm-6">
              <p>{props.data ? props.data.SecondaryShareOfIncome : ''}</p>
            </div>
          )}
        </div>
      </div>

      {/* <div className="col-md-1 edit_icon">
      {user.profile.includes("UR4") ||
      user.profile.includes("UR2") ||
      (user1 && user1.applicationStage == 'Active Client') ||
      user.profile.includes("UR3") ? (
        ''
      ) : (
        <i
          onClick={handleOpen}
          title="Edit"
          class="fa fa-pencil-square-o"
          aria-hidden="true"
        ></i>
      )}
    </div> */}
    </div>
  );
};
export default EntityDetails;
