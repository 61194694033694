/*eslint-disable eqeqeq*/

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import { getCode, getName } from 'country-list';
import ModalPopup from '../pages/signatures/ModalPopup';

const NameIdAml = (props) => {
  const { user } = useAuth0();

  return (
    <div className="row">
      <div className="col-md-11">
        <>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <label className="aml_id">AML Id</label>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <div className="aml_Details">
                {props.data.idCheck == 1 ? (
                  <div className="aml_pass">ID AML PASS</div>
                ) : (
                  <div className="aml_fail">ID AML FAIL</div>
                )}
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <label>Type Of Id</label>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <p className="paragraph">
                {props.data.documentIdType != null ? props.data.documentIdType : ''}
              </p>
            </div>
          </div>
        </>

        {props.data.documentIdType != null ? (
          props.data.documentIdType != 'NZ Driver Licence' &&
            props.data.documentIdType != 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Other Document Type</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data.otherDocumentName}</p>
                </div>
              </div>
            </>
          ) : (
            ''
          )
        ) : (
          ''
        )}
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>First Name</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p className="paragraph">{props.data.firstName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Middle Name</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p className="paragraph">{props.data.middleName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Last Name</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p className="paragraph">{props.data.lastName}</p>
          </div>
        </div>
        {props.data ? (
          props.data.documentIdType == 'NZ Driver Licence' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Licence Number</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data.documentNumber}</p>
                </div>
              </div>
            </>
          ) : props.data.documentIdType == 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Passport Number</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data.documentNumber}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="row docs">
              <div className="col-md-6 col-sm-6 col-6">
                <label>Document Number</label>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <p className="paragraph">{props.data.documentNumber}</p>
              </div>
            </div>
          )
        ) : (
          ''
        )}
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Expiry Date</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p className="paragraph">{props.data.documentExpiryDate}</p>
          </div>
        </div>

        {props.data ? (
          props.data ? (
            props.data.documentIdType == 'NZ Driver Licence' ? (
              <>
                {' '}
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <label>Version Number</label>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <p className="paragraph">{props.data.documentVersionNumber}</p>
                  </div>
                </div>
              </>
            ) : props.data.documentIdType == 'NZ Passport' ? (
              <>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <label>Country Of Issue</label>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <p className="paragraph">{props.data.documentCountryOfIssue}</p>
                  </div>
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Country Of Issue</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data.documentCountryOfIssue}</p>
                </div>
              </div>
            )
          ) : (
            ''
          )
        ) : (
          ''
        )}

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Date Of Birth</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p className="paragraph">{props.data.dateOfBirth}</p>
          </div>

          {props.data &&
            props.data.identityProof != null &&
            props.data &&
            props.data.identityProof != '' ? (
            <>
              <div className="col-md-6 col-sm-6 col-6">
                <label>Manual Approval</label>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <a href={props.data.identityProof} className="">
                  <div className="main-downloade-wrapper">
                    {props.data &&
                      props.data.identityProof !== null &&
                      props.data.identityProof !== '' &&
                      props.data.identityProof &&
                      'undefined' &&
                      props.data.identityProof !== undefined ? (
                      <p className="downloadeMsg">
                        <div className="downloads">
                          <i className="fa fa-download downloadeStyle"></i>
                        </div>{' '}
                        <p className="View__file">VIEW FILE</p>{' '}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </a>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="col-md-12 edit_icon">
        {(user.profile.includes("UR1") ||
          user.profile.includes("UR2")) &&
          (props.data.applicationStage == 'Submitted' &&
            props.data.idCheck != 1)
          ? (
            <>
              <ModalPopup
                iid={props.data.id}
                userr={props.data}
                getAPI={props.getAPI}
                setLoader={props.setLoader}
              />
            </>
          ) : (
            ""
          )}
      </div>
    </div>
  );
};
export default NameIdAml;
