/*eslint-disable eqeqeq*/

import React from 'react';
// import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
// import { getCode, getName } from 'country-list';

const BankAccount = (props) => {
  const { user } = useAuth0();

  return (
    <div className="row">
      <div className="col-md-11">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>NZ Bank Account</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>{props.data && props.data.isNZBank === 1 ? 'Yes' : 'No'}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Bank Account Name</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>{props.data ? props.data.primaryBankAccountName : ''}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Bank Account Number</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>{props.data ? props.data.primaryBankAccountNumber : ''}</p>
          </div>
        </div>
        {props.data &&
          props.data.bankSwiftCode !== '' &&
          props.data.isNZBank !== 1 &&
          props.data.bankSwiftCode !== null ? (
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <label>Bank Swift Code</label>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <p>{props.data ? props.data.bankSwiftCode : ''}</p>
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Document Proof</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <div className="document_download">
              <a
                href={
                  props.data &&
                    props.data.primaryBankProof !== null &&
                    props.data.primaryBankProof !== '' &&
                    props.data.primaryBankProof &&
                    'undefined' &&
                    props.data.primaryBankProof !== undefined
                    ? props.data.primaryBankProof
                    : ''
                }
                className="licedocument-downloade"
              >
                {' '}
                <div className="main-downloade-wrapper">
                  {props.data &&
                    props.data.primaryBankProof !== null &&
                    props.data.primaryBankProof !== '' &&
                    props.data.primaryBankProof &&
                    'undefined' &&
                    props.data.primaryBankProof !== undefined ? (
                    <p className="downloadeMsg">
                      <div className="downloads">
                        <i className="fa fa-download downloadeStyle"></i>
                      </div>{' '}
                      VIEW FILE{' '}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </a>

              <span id="name_tag" class="preview2"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        {/* <div className="col-md-12 edit_icon PersonalInformationEdit">
          {user.profile.includes("UR4") ||
          user.profile.includes("UR2") ||
          (props.data && props.data.applicationStage == 'Active Client') ||
          user.profile.includes("UR3") ? (
            ''
          ) : (
            <i
              onClick={handleOpen}
              title="Edit"
              class="fa fa-pencil-square-o"
              aria-hidden="true"
            ></i>
          )}
        </div> */}
      </div>
    </div>
  );
};
export default BankAccount;
