import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { getCode, getName } from 'country-list';
import axios from 'axios';
import { Domain, audience, urlapi } from 'src/auth/Configuration';

const IdVerification2 = (props) => {
  const {
    user,
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    logout
  } = useAuth0();
  const [nloader, setNloader] = useState(false);
  const [token, setToken] = useState();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  function getCountryName(country) {
    try {
      return getName(country);
    } catch {
      return '';
    }
  }
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;

          //    formData.token =
          // sessionStorage.setItem('token', token);

          //   application(token);
          //   Notessfunc(token);
          getur(token);
        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);
  const NoteClick = (data) => {
    setNloader(true);
    let postadv = {
      downloadUrl: data
    };
    axios
      .post(`${urlapi}/admin/download/image`, postadv, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        window.open(res.data.url);
        setNloader(false);
      });
  };
  const [geturs, setGeturs] = useState();

  const getur = (token) => {
    const NotessData = axios
      .get(`${urlapi}/admin/team/member`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setGeturs(result.data);
        }
      });
  };
  return (
    <div className="row">
      <div className="col-md-11">
        {/* {props.data && props.data.isMyFarm == 0 ? ( */}
        <>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label>Type of ID </label>
            </div>

            <div className="col-md-6 col-sm-6">
              <p>{props.data ? props.data.documentIdType : ''}</p>
            </div>
          </div>
          {props.data ? (
            props.data.documentIdType != 'NZ Driver Licence' &&
              props.data.documentIdType != 'NZ Passport' ? (
              <>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <label>Other Document Type</label>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <p>{props.data ? props.data.otherDocumentName : ''}</p>
                  </div>
                </div>
              </>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </>
        {/* // )
                                //  : (
                                //   ''
                                // ) */}
        {/* } */}

        <div className="row">
          <div className="col-md-6 col-sm-6">
            <label>First Name</label>
          </div>
          <div className="col-md-6 col-sm-6">
            <p>{props.data ? props.data.firstName : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <label>Middle Name</label>
          </div>
          <div className="col-md-6 col-sm-6">
            <p>{props.data ? props.data.middleName : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <label>Last Name</label>
          </div>
          <div className="col-md-6 col-sm-6">
            <p>{props.data ? props.data.lastName : ''}</p>
          </div>
        </div>

        {props.data ? (
          props.data.documentIdType == 'NZ Driver Licence' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <label>Licence Number</label>
                </div>
                <div className="col-md-6 col-sm-6">
                  <p>{props.data ? props.data.documentNumber : ''}</p>
                </div>
              </div>
            </>
          ) : props.data.documentIdType == 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <label>Passport Number</label>
                </div>
                <div className="col-md-6 col-sm-6">
                  <p>{props.data ? props.data.documentNumber : ''}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <label>Document Number</label>
              </div>
              <div className="col-md-6 col-sm-6">
                <p>{props.data ? props.data.documentNumber : ''}</p>
              </div>
            </div>
          )
        ) : (
          ''
        )}

        {props.data ? (
          props.data.documentIdType == 'NZ Driver Licence' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <label>Expiry Date</label>
                </div>
                <div className="col-md-6 col-sm-6">
                  <p>{props.data ? props.data.documentExpiryDate : ''}</p>
                </div>
              </div>
            </>
          ) : props.data.documentIdType == 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <label>Expiry Date</label>
                </div>
                <div className="col-md-6 col-sm-6">
                  <p>{props.data ? props.data.documentExpiryDate : ''}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <label>Expiry Date</label>
              </div>
              <div className="col-md-6 col-sm-6">
                <p>{props.data ? props.data.documentExpiryDate : ''}</p>
              </div>
            </div>
          )
        ) : (
          ''
        )}

        {props.data ? (
          props.data.documentIdType == 'NZ Driver Licence' ? (
            <>
              {' '}
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <label>Version Number</label>
                </div>
                <div className="col-md-6  col-sm-6">
                  <p>{props.data ? props.data.documentVersionNumber : ''}</p>
                </div>
              </div>
            </>
          ) : props.data.documentIdType == 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6  col-sm-6">
                  <label>Country of Issue</label>
                </div>
                <div className="col-md-6  col-sm-6">
                  <p>{props.data ? props.data.documentCountryOfIssue : ''}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-md-6  col-sm-6">
                <label>Country of Issue</label>
              </div>
              <div className="col-md-6  col-sm-6">
                <p>{props.data ? props.data.documentCountryOfIssue : ''}</p>
              </div>
            </div>
          )
        ) : (
          ''
        )}

        {props.data ? (
          props.data.documentIdType == 'NZ Driver Licence' ? (
            <>
              <div className="row">
                <div className="col-md-6  col-sm-6">
                  <label>Document Proof</label>
                </div>
                <div className="col-md-6  col-sm-6">
                  <div className="document_download">
                    <a
                      href="javascript:void(0)"
                      onClick={() => NoteClick(props.data.documentProof)}
                      className="licedocument-downloade"
                    >
                      {' '}
                      <div className="main-downloade-wrapper">
                        {props.data &&
                          props.data.documentProof !== null &&
                          props.data.documentProof !== '' &&
                          props.data.documentProof &&
                          'undefined' &&
                          props.data.documentProof !== undefined ? (
                          <p className="downloadeMsg">
                            <div className="downloads">
                              <i className="fa fa-download downloadeStyle"></i>
                            </div>{' '}
                            VIEW FILE{' '}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </a>

                    <span id="name_tag" class="preview2"></span>
                  </div>
                </div>
              </div>
            </>
          ) : props.data.documentIdType == 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6  col-sm-6">
                  <label>Document Proof</label>
                </div>
                <div className="col-md-6  col-sm-6">
                  <div className="document_download">
                    <a
                      href="javascript:void(0)"
                      onClick={() => NoteClick(props.data.documentProof)}
                      className="licedocument-downloade"
                    >
                      {' '}
                      <div className="main-downloade-wrapper">
                        {props.data &&
                          props.data.documentProof !== null &&
                          props.data.documentProof !== '' &&
                          props.data.documentProof &&
                          'undefined' &&
                          props.data.documentProof !== undefined ? (
                          <p className="downloadeMsg">
                            <div className="downloads">
                              <i className="fa fa-download downloadeStyle"></i>
                            </div>{' '}
                            VIEW FILE{' '}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </a>

                    <span id="name_tag" class="preview2"></span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6  col-sm-6">
                  <label>Document Proof</label>
                </div>
                <div className="col-md-6  col-sm-6">
                  <div className="document_download">
                    <a
                      href="javascript:void(0)"
                      onClick={() => NoteClick(props.data.documentProof)}
                      className="licedocument-downloade"
                    >
                      {' '}
                      <div className="main-downloade-wrapper">
                        {props.data &&
                          props.data.documentProof !== null &&
                          props.data.documentProof !== '' &&
                          props.data.documentProof &&
                          'undefined' &&
                          props.data.documentProof !== undefined ? (
                          <p className="downloadeMsg">
                            <div className="downloads">
                              <i className="fa fa-download downloadeStyle"></i>
                            </div>{' '}
                            VIEW FILE{' '}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </a>

                    <span id="name_tag" class="preview2"></span>
                  </div>
                </div>
              </div>
            </>
          )
        ) : (
          ''
        )}

        {/* {props.data ? (
                                  props.data.documentIdType == 'NZ Driver Licence' ? (
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Secondary Identity Proof</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <div className="document_download">
                                          <a
                                            href={
                                              props.data &&
                                              props.data.documentIdentificationProof !== null &&
                                              props.data.documentIdentificationProof !== '' &&
                                              props.data.documentIdentificationProof &&
                                              'undefined' &&
                                              props.data.documentIdentificationProof !== undefined
                                                ? props.data.documentIdentificationProof
                                                : ''
                                            }
                                            className="licedocument-downloade"
                                          >
                                            {' '}
                                            <div className="main-downloade-wrapper">
                                              {props.data &&
                                              props.data.documentIdentificationProof !== null &&
                                              props.data.documentIdentificationProof !== '' &&
                                              props.data.documentIdentificationProof &&
                                              'undefined' &&
                                              props.data.documentIdentificationProof !== undefined ? (
                                                <p className="downloadeMsg">
                                                  <div className="downloads">
                                                    <i className="fa fa-download downloadeStyle"></i>
                                                  </div>{' '}
                                                  VIEW FILE{' '}
                                                </p>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </a>

                                          <span id="name_tag" class="preview2"></span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )} */}
      </div>
      {/* <div className="col-md-12 edit_icon PersonalInformationEdit">
                                {user.profile.includes("UR4") ||
                                (props.data && props.data.applicationStage == 'Active Client') ||
                                user.profile.includes("UR2") ||
                                user.profile.includes("UR3") ? (
                                  ''
                                ) : (
                                  <i
                                    onClick={handleOpen}
                                    title="Edit"
                                    class="fa fa-pencil-square-o hover__Edit"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </div> */}
    </div>
  );
};
export default IdVerification2;
