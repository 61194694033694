/*eslint-disable eqeqeq*/

import React from 'react';
// import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
// import { getCode, getName } from 'country-list';

const TermsCondition = (props) => {
  // const { user } = useAuth0();

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  return (
    <div className="row">
      <div className="col-md-11">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Receive Future Emails About MyFarm Investment Opportunities </label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p>{props.data && props.data.futureInvestementOpportunities == 1 ? 'Yes' : 'No'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsCondition;
