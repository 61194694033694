import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { getCode, getName } from 'country-list';
import axios from 'axios';
import { Domain, audience, urlapi } from 'src/auth/Configuration';

const TermsCondition2 = (props) => {
  const {
    user,
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    logout
  } = useAuth0();
  const [nloader, setNloader] = useState(false);
  const [token, setToken] = useState();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const [geturs, setGeturs] = useState();
  console.log('jjj');
  return (
    <div className="row">
      <div className="col-md-11">
        <div className="row">
          <div className="col-md-6 col-sm-auto">
            <label>Receive Future Emails About MyFarm Investment Opportunities </label>
          </div>
          <div className="col-md-6 col-sm-auto">
            <p>{props.data && props.data.futureInvestementOpportunities == 1 ? 'Yes' : 'No'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsCondition2;
