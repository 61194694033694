/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { audience, urlapi } from '../../auth/Configuration';

import Swal from 'sweetalert2';

import $ from 'jquery';
import Collapse from 'react-bootstrap/Collapse';

const ClientsTable = (props) => {
  const [openCo, setopenCo] = useState(false);

  // // dev
  // var urlapi = "https://duvalfunctionapp.azureedge.net/api/duval";

  // stag

  // var urlapi = "https://duvalstag.azureedge.net/api/duval";

  const { getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const getUserMetadata = async () => {
    try {
      await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [users, setUsers] = useState();
  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){

      const applicationdata12 = await axios
        .get(`${urlapi}${props.apiPath1}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);

          setUsers(applicationdata12.data.reverse());
        });
      // }
    }
  };
  $.fn.dataTable.ext.errMode = 'none';

  useEffect(() => {
    $(document).ready(function () {
      $(`#${props.tableid}`).DataTable({
        scrollX: true,
        sScrollXInner: '100%'
      });
    });
  }, [openCo]);

  let tsss = [];
  function arrayRemove(arr, value1) {
    tsss = arr.filter((value) => value !== value1);
  }
  const [loader1, setLoader1] = useState();
  const getCsvData = async () => {
    // setOpen(!open);
    setLoader(true);
    let csvdata = {
      userCode: tsss
    };

    const applicationdata12 = await axios
      .post(`${urlapi}/admin/csv/data`, csvdata, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        if (res.status == 208) {
          setLoader(false);
          Swal.fire({
            text: res.data.message
          });
        } else {
          setopenCo(false);
          window.open(res.data.url);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          text: 'Getting Some error'
        });
      });

    getUserMetadata();

    // setTimeout(() => {
    //   setOpenC(openC);
    // }, 20);
    // window.location.reload();
  };
  return (
    <>
      <h4
        onClick={() => setopenCo(!openCo)}
        aria-controls="example-collapse-text2_compliance_c2"
        aria-expanded={openCo}
        className="drop_icon Completed_apllicatin_icon"
      >
        {props.tableTitle}
      </h4>

      {loader === true ? (
        <div className="note-main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <Collapse in={openCo}>
            <div id="example-collapse-text2_compliance_c2 mb-4">
              {props && props.pass == 'passedApplication' ? (
                <span className="note-footer flex-note Export_btn">
                  {loader1 === true ? (
                    <div className="note-main-loader">
                      <div className="loader Export_loader"></div>
                    </div>
                  ) : (
                    <button className="save CSVexport" onClick={getCsvData}>
                      CSV Export
                    </button>
                  )}
                </span>
              ) : (
                ''
              )}

              <table id={props.tableid} className="responsive nowrap" width="100%">
                <thead>
                  <tr>
                    <th>Action</th>
                    {/* <th>Date Started</th> */}
                    {/* <th>MF Team Member</th> */}
                    <th>Entity Name</th>
                    <th>Investment Type</th>
                    {/* <th>Stage</th> */}
                    <th>Primary Contact Name</th>
                  </tr>
                </thead>
                <tbody>
                  {users !== undefined &&
                    users !== 'undefined' &&
                    users.map((user, i) => {
                      return (
                        <tr>
                          <td>
                            <div className="action">
                              {user.investmentType == 'Company' ? (
                                <span>
                                  <Link
                                    to={{
                                      pathname: `/ApplicationDetails/${user.id}`,
                                      state: user.id
                                    }}
                                  >
                                    <i
                                      title="View application details"
                                      class="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </span>
                              ) : user.investmentType == 'Trust' ? (
                                <span>
                                  <Link
                                    to={{
                                      pathname: `/ApplicationDetails/${user.id}`,
                                      state: user.id
                                    }}
                                  >
                                    <i
                                      title="View application details"
                                      class="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </span>
                              ) : (
                                <span>
                                  <Link
                                    to={{
                                      pathname: `/ApplicationDetailsIndi/${user.id}`,
                                      state: user.id
                                    }}
                                  >
                                    <i
                                      title="View application details"
                                      class="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </span>
                              )}
                              <span>
                                <Link to={{ pathname: `/History/${user.id}` }}>
                                  <i title="History" class="fa fa-history" aria-hidden="true"></i>
                                </Link>
                              </span>
                              <span>
                                <Link
                                  to={{
                                    pathname: `/Transaction/${user.id}`,
                                    state: user.id
                                  }}
                                >

                                  <i class="fa fa-bars" aria-hidden="true" title="Transaction details"></i>
                                  {/* <i class="fa fa-tasks" aria-hidden="true"title="Audit details"></i> */}

                                </Link>
                              </span>
                              {props && props.pass == 'passedApplication' ? <span>
                                <input
                                  type="checkbox"
                                  id={i}
                                  className="chec"
                                  name={user.id}
                                  onChange={(event) => {
                                    // csvchange(event);

                                    if (event.target.checked == true) {
                                      tsss.push(event.target.name);
                                    } else {
                                      arrayRemove(tsss, event.target.name);
                                    }

                                    // setCsvData([...secArry, secArry]);
                                  }}
                                />
                              </span> : ""}

                            </div>
                          </td>
                          {/* <td>{user.createdAt}</td> */}
                          {/* <td>{user.memberName}</td> */}
                          <td>
                            {user.entityName}
                            {/* {user.firstName !== null && user.firstName !== '' ? user.firstName : ''}{' '}
                          {user.lastName !== null && user.lastName !== '' ? user.lastName : ''} */}
                          </td>
                          <td>
                            {/* {userr.totalInvestment} */}
                            {/* {user && user.totalInvestment != null ? <>$</> : ''} */}

                            {/* {user && user.totalInvestment != null
                              ? (Math.round(user.totalInvestment * 1000) / 1000).toLocaleString()
                              : ''} */}

                            {user && user.investmentType != null ? user.investmentType : ''}
                          </td>
                          {/* <td>{user.applicationStage}</td> */}
                          <td>
                            {' '}
                            {user && user.firstName !== null && user.firstName !== ''
                              ? user.firstName
                              : ''}{' '}
                            {user && user.lastName !== null && user.lastName !== ''
                              ? user.lastName
                              : ''}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Collapse>
        </>
      )}
    </>
  );
};

export default ClientsTable;
