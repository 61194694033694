/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { audience, urlapi } from '../../auth/Configuration';

import Swal from 'sweetalert2';

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import $ from 'jquery';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Input, Button } from 'antd';

import Collapse from 'react-bootstrap/Collapse';

const InternalUserTable = (props) => {
  const [openCo, setopenCo] = useState(false);

  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated
  } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )
          // applicationss(token);

          application(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [users, setUsers] = useState();

  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      // duval/admin/clients
      const applicationdata12 = await axios
        .get(`${urlapi}${props.apiPath1}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          if (res.status == 200) {
            setLoader(false);

            setUsers(res.data);
          } else {
            setLoader(false);
          }
        });
      // }
    }
  };

  // alert(user.profile)

  $.fn.dataTable.ext.errMode = 'none';

  useEffect(() => {
    $(document).ready(function () {
      $(`#${props.tableid}`).DataTable({
        scrollX: true,
        sScrollXInner: '100%'
      });
    });
  }, [openCo]);
  const [passwordone, SetPasswordone] = useState();
  const [modal, setModal] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggle = () => setModal(!modal);

  const show = () => setModal(!modal);

  const [passwordcheck, SetPasswordcheck] = useState();

  const roles = []

  const addRole = (e) => {
    if (roles.includes(e)) {
      const index = roles.indexOf(e);
      if (index > -1) { // only splice array when item is found
        roles.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else {
      roles.push(e);

    }
  }
  const onFinish = async (values) => {
    console.log(roles)

    var bodyData = {
      role: roles,
      adminEmail: values.adminEmail,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,


    }
    setLoader(true);
    setModal(false);
    await axios
      .post(`${urlapi}/admin/actions`, bodyData, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        console.log('dddddd', res.status);
        setLoader(false);

        application(token);
        Swal.fire('', 'Admin created successfully', '');
      })
      .catch((error) => {
        console.log('fffff', error == 'Error: Request failed with status code 400');
        setLoader(false);
        if (error == 'Error: Request failed with status code 400') {
          Swal.fire({
            text: 'Email already existed!',
            confirmButtonColor: '#477894',
            confirmButtonText: 'Ok'
          });
        } else {
          Swal.fire({
            text: 'Please try after sometime',
            confirmButtonColor: '#477894',
            confirmButtonText: 'Ok'
          });
        }
      });
  };

  // alert(users);
  return (
    <>
      <h4
        onClick={() => setopenCo(!openCo)}
        aria-controls="example-collapse-text2_compliance_c2"
        aria-expanded={openCo}
        className="drop_icon Completed_apllicatin_icon"
      >
        {props.tableTitle}
      </h4>
      {loader === true ? (
        <div className="note-main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <Collapse in={openCo}>
            <div id="example-collapse-text2_compliance_c2 mb-4">
              {user.profile.includes("UR1") && props.AddUserData == 'activeAdd' ? (
                < Button className="add_user_btn" onClick={toggle} >
                  +
                </Button>
              ) : (
                ''
              )}
              <table id={props.tableid} className="responsive nowrap" width="100%">
                <thead>
                  <tr>
                    <th>Action</th>

                    <th>Full Name</th>

                    <th>Email</th>

                    <th>Admin Role</th>
                  </tr>
                </thead>
                <tbody>
                  {users !== undefined &&
                    users !== 'undefined' &&
                    users.reverse().map((userr) => {
                      return (
                        <tr>
                          <td>
                            <div className="action">
                              <span>
                                <Link
                                  to={{
                                    pathname: `/admins/${userr.id}`,
                                    state: userr.id
                                  }}
                                >
                                  <i
                                    title="View client details"
                                    class="fa fa-eye hover__Edit"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </span>
                            </div>
                          </td>

                          <td>
                            {userr && userr.firstName !== null && userr.firstName !== ''
                              ? userr.firstName
                              : ''}{' '}
                            {userr && userr.lastName !== null && userr.lastName !== ''
                              ? userr.lastName
                              : ''}
                          </td>

                          <td>{userr && userr.adminEmail ? userr.adminEmail : ''}</td>
                          <td>
                            {userr && userr.roleName ?
                              <>
                                {userr.roleName}
                                {/* {(userr.roleName[0] ? userr.roleName[0] : "") + (userr.roleName[0] && userr.roleName[1] ? "," : "") +
                                  (userr.roleName[1] ? userr.roleName[1] : "") + (userr.roleName[1] && userr.roleName[2] ? "," : "") +
                                  (userr.roleName[2] ? userr.roleName[2] : "") + (userr.roleName[2] && userr.roleName[3] ? "," : "") +
                                  (userr.roleName[3] ? userr.roleName[3] : "") + (userr.roleName[3] ? "," : "") +
                                  (userr.roleName[4] ? userr.roleName[4] : "")} */}
                              </>

                              : ''}
                            {/* {userr && userr.role == 'UR1'
                              ? 'Super Admin'
                              : userr && userr.role == 'UR2'
                                ? 'Compliance User'
                                : userr && userr.role == 'UR3'
                                  ? 'Marketing'
                                  : userr && userr.role == 'UR4'
                                    ? 'Approver'
                                    : userr && userr.role == 'UR5'
                                      ? 'Staff'
                                      : ''} */}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Collapse>
        </>
      )
      }

      <Modal isOpen={modal} show={show}>
        <ModalHeader toggle={toggle}>Add User</ModalHeader>

        <ModalBody>
          <Form
            className="pop_width-100"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Enter Email"
              name="adminEmail"
            // className="letterOnly"
            >
              <Input
                placeholder="Enter Email"
                type="email"
                required
              // className="mb-2 letterOnly"
              // onPaste={myhandleChange}
              />
            </Form.Item>
            <Form.Item
              label="First Name:"
              name="firstName"
            // initialValue={user1 ? user1.firstName : ''}
            // className="quantity"
            // className="quantity"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter First Name'
            //   }
            // ]}
            >
              <Input
                placeholder="Enter first name(s)"
                // defaultValue={user1 ? user1.firstName : ''}
                maxlength="50"
                className="mb-2 quantity"
                // onPaste={(e) => {
                //   e.preventDefault();
                //   return false;
                // }}
                required
              />
            </Form.Item>

            <Form.Item
              label="Last Name:"
              name="lastName"
            // className="letterOnly"
            >
              <Input
                placeholder="Enter last name(s)"
                // className="mb-2 letterOnly"
                className="mb-2"
                required
              // onPaste={myhandleChange}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
            // className="myerrorrr"
            >
              <Input
                autocomplete="new-password"
                // type="password"
                placeholder="Enter Password"
                className="mb-2 hideerror myerrorrr"
                type={passwordShown ? 'text' : 'password'}
                // onPaste={handleClick}
                onChange={(e) => SetPasswordcheck(e.target.value)}
                minLength={9}
                required
              />


            </Form.Item>
            <>              <i onClick={togglePasswordVisiblity} className="eye-password seepass">
              {' '}
              <i class="fa fa-eye" aria-hidden="true" />
            </i>
            </>
            <span className="error passworderror" />

            <label>Admin Role:</label>

            <div className='select_url d-flex '>
              <Form.Item
                label="Super Admin"
                name="role"
              >
                <Input
                  placeholder="Enter Email"
                  type="checkbox"
                  defaultValue={"UR1"}
                  onChange={(e) => {

                    addRole(e.target.value);


                  }}

                />
              </Form.Item>
              <Form.Item
                label="Compliance"
                name="role2"

              >
                <Input
                  placeholder="Enter Email"
                  type="checkbox"
                  defaultValue={"UR2"}
                  onChange={(e) => {
                    addRole(e.target.value);


                  }}

                />
              </Form.Item>
              <Form.Item
                label="Marketing"
                name="role3"

              >
                <Input
                  placeholder="Enter Email"
                  type="checkbox"
                  defaultValue={"UR3"}
                  onChange={(e) => {
                    addRole(e.target.value);


                  }}
                />
              </Form.Item>
              <Form.Item
                label="Approver"
                name="role4"

              >
                <Input
                  placeholder="Enter Email"
                  type="checkbox"
                  defaultValue={"UR4"}
                  onChange={(e) => {
                    addRole(e.target.value);


                  }}
                />
              </Form.Item>
              <Form.Item
                label="Staff"
                name="role5"

              >
                <Input
                  placeholder="Enter Email"
                  type="checkbox"
                  defaultValue={"UR5"}
                  onChange={(e) => {
                    addRole(e.target.value);


                  }}
                />
              </Form.Item>
            </div>


            <Form.Item>
              <Button
                className="coffee-color right-side overwrite d-table ml-auto mt-3"
                type="primary"
                htmlType={passwordone ? 'submit' : null}
              //  onClick={handleClick}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InternalUserTable;
