// dev
export const Domain = 'myfarmreplica.au.auth0.com';
export const clientId = 'F7MGhmZP81UfkR7TcO0ucoUv8pKKIxuP';
export const audience = 'urn:auth0-sso-dashboard';
export const CHECK_ENV = 'dev';
export const connection = 'my-farm-replica';
export const singup_auth = 'https://myfarmreplica.au.auth0.com/dbconnections/signup';
export const urlapi = 'https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev';
export const adminapi = 'https://srqq3vdg1e.execute-api.ap-southeast-2.amazonaws.com/dev';
export const mainUrl = 'https://myfarmnewuser-dev.invsta.io/register';
export const signUrl = 'https://myfarmnewuser-dev.invsta.io';


// Stag
// export const Domain = 'wholesale.au.auth0.com';
// export const clientId = 'pvicpCIQpWUTp8U8E5zGhpi1P0ItSjl0';
// export const audience = 'urn:auth0-sso-dashboard';
// export const CHECK_ENV = 'stag';
// export const connection = 'wholesale-db';
// export const singup_auth = 'https://wholesale.au.auth0.com/dbconnections/signup';
// export const urlapi = 'https://d1u0pb8ql4kshw.cloudfront.net';
// export const adminapi = 'https://d1u0pb8ql4kshw.cloudfront.net';
// export const mainUrl = 'https://wholesale-user.invsta.io/register';
// export const signUrl = 'https://wholesale-user.invsta.io';
