/*eslint-disable eqeqeq*/

import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userdataState } from '../Action';
import TableData9 from './TableData9';

const Transactions = (props) => {
  const myusers = useRecoilValue(userdataState);
  // console.log('jjjj', myusers);
  const invest = myusers ? myusers[0].investmentType : '';
  const { id } = useParams();
  let navigate = useNavigate();

  return (
    <>
      <div className="right_content aurora_layout">
        <div className="heading__top ht_heading d-flex justify-content-between align-items-center flex-wrap">
          <h2>
            <span onClick={() => navigate(-1)}><i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </span>TRANSACTIONS DETAILS </h2>
        </div>
        <div className="form transaction_form">
          {id ?
            <>
              <div className="myfarm_com" key={9}>
                {' '}
                <TableData9
                  apiPath1={`/admin/pass/transaction/${id}`}
                  tableTitle={'Passed Transactions'}
                  key={9}
                  tableid={'table_id4'}
                  userId={"user"}
                  apitr={"yes"}

                />
              </div>
              <div className="myfarm_com" key={11}>
                {' '}
                <TableData9
                  apiPath1={`/admin/pending/transaction/${id}`}
                  tableTitle={'Pending Transactions'}
                  key={11}
                  tableid={'table_id5'}
                  userId={"user"}

                />
              </div>
            </>
            :
            <>
              <div className="myfarm_com" key={9}>
                {' '}
                <TableData9
                  apiPath1={`/investor/pending/transaction/${invest}`}
                  tableTitle={'Pending Transactions'}
                  key={9}
                  tableid={'table_id4'}
                  checkInvest={"investor"}
                />
              </div>
              <div className="myfarm_com" key={11}>
                {' '}
                <TableData9
                  apiPath1={`/investor/previous/transaction/${invest}`}
                  tableTitle={'Previous Transactions'}
                  key={11}
                  tableid={'table_id5'}
                  checkInvest={"investor"}

                />
              </div>
            </>
          }

        </div>
      </div>
    </>
  );
};
export default Transactions;
