import React, { useState, useEffect } from 'react';
import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Input, Checkbox } from 'antd';
import $, { now } from 'jquery';
import moment from 'moment';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import ModalPopup from './ModalPopup';
import Box from '@mui/material/Box';

import axios from 'axios';
import { audience, urlapi } from '../../auth/Configuration';
import TableDataHistory from './TableDataHistory';
import AuditTable from './AuditTable';
const InvestAmount = () => {
  const [user1, setUser1] = useState();
  const [user2, setUser2] = useState();
  const [loader, setLoader] = useState(true);
  const [modal12, setModal12] = useState(false);
  const toggle12 = () => setModal12(!modal12);
  const show12 = () => setModal12(!modal12);
  const [amltype12, setAmltype12] = useState('electronics');
  const [verifyFordoc6412, setVerifyFordoc6412] = useState();
  const [userID1, setUserID1] = useState();
  const [documentFile6412, setDocumentFile6412] = useState();
  const [userCompany, SetUserCompany] = useState();
  const [addresCheck1, SetAddresCheck1] = useState(true);
  const [modals1, setModals1] = useState(false);
  const [isLoaderpaymentReceived, setIsLoaderpaymentReceived] = useState(false);
  const [directodata, setDirectodata] = useState();

  const toggles1 = () => setModals1(!modals1);

  const [investType, setInvestType] = useState();

  const [documentFile6422, setDocumentFile6422] = useState();

  const [modals1Ur2, setModals1Ur2] = useState(false);

  const navigate = useNavigate();
  const [modalur2, setModalur2] = useState(false);

  const togglsUr2 = () => setModals1Ur2(!modals1Ur2);

  const showUr2 = () => setModalur2(!modalur2);
  const [assignur, setAssignur] = useState();

  const [userID2, setUserID2] = useState();
  const [isLoaderVerify, setIsLoaderVerify] = useState(false);
  const [isLoaderpaymentReceived1, setIsLoaderpaymentReceived1] = useState(false);


  const [geturs, setGeturs] = useState();






  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated
  } = useAuth0();
  const [token, setToken] = useState();
  const { id } = useParams();

  const getUserMetadata = async () => {
    try {
      await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;

          //    formData.token =
          // sessionStorage.setItem('token', token);
          getur(token);

          application(token);

        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/transaction/details/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {

          setLoader(false);
          setUser1(applicationdata12.data[0]);

          // setInvestType(applicationdata12.data[0].investmentType);

          // if (applicationdata12.data[0].investmentType == "Company") {
          //   SetUserCompany(applicationdata12.data);

          // }
          // else {
          //   setUser1(applicationdata12.data[0]);
          //   setUserID1(applicationdata12.data[0].id);
          // }


          // if (applicationdata12.data.length > 1) {

          //   setUser2(applicationdata12.data[1]);
          //   setUserID2(applicationdata12.data[1].id);

          // }
        })
        .catch((error) => {
          setLoader(false);
          Swal.fire('Something went wrong');
        });
      // }
    }
  };


  const getur = (token) => {
    const NotessData = axios
      .get(`${urlapi}/admin/team/member`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setGeturs(result.data);
          // application(token);
        }
      });
  };


  const applicationTrust = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/transaction/details/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          SetUserCompany(applicationdata12.data);
          setInvestType(applicationdata12.data[0].investmentType)

          if (user.profile == 'UR3') {
            // applicationdiretor(token);
          }
        })
        .catch((error) => {
          setLoader(false);
          Swal.fire('Something went wrong');
        })
        .finally((e) => {
          setLoader(false);
        });
      // }
    }
  };
  const verifyEmails = async (user_id) => {
    setIsLoaderVerify(true);
    const changeStage1 = await axios
      .put(
        `${urlapi}/admin/verify/email/user/${user_id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setIsLoaderVerify(false);
          Swal.fire({
            text: 'Request sent!'
          });
        } else {
          setIsLoaderVerify(false);
          // Swal.fire(result.data.message)
          Swal.fire({
            text: result.data.message
          });
        }
      })
      .catch((error) => {
        setIsLoaderVerify(false);
        Swal.fire({
          text: 'Something went wrong!'
        });
      });
  };
  const verifyEmailss = async (user_id) => {
    setIsLoaderVerify(true);
    const changeStage1 = await axios
      .put(
        `${urlapi}/admin/verify/email/user/${user_id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setIsLoaderVerify(false);
          Swal.fire({
            text: 'Request sent!'
          });
        } else {
          setIsLoaderVerify(false);
          // Swal.fire(result.data.message)
          Swal.fire({
            text: result.data.message
          });
        }
      })
      .catch((error) => {
        setIsLoaderVerify(false);
        Swal.fire({
          text: 'Something went wrong!'
        });
      });
  };
  const updateaccount = async (id) => {
    let databody;

    if (assignur == null || assignur == '' || assignur == undefined) {
      databody = {
        memberName: geturs[0].fullLegalName
      };
    } else {
      databody = {
        memberName: assignur
      };
    }

    setLoader(true);
    await axios
      .put(`${urlapi}/admin/team/member/${id}`, databody, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {

        Swal.fire({
          html: 'Updated',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#3085D6'
        }).then((e) => {
          setLoader(false);
          setModals1Ur2(!modals1Ur2);
          // applicationTrust(token);
        });
        application(token);

      })
      .catch(function (error) {
        setLoader(false);
        Swal.fire('', 'Unfortunately an error has occurred, Please try again.', '');
      });
  };
  let ApplicationStage = '';

  function UpdateApplicationStage(stageValue) {
    if (stageValue === null) {
      const selectOption = document.getElementById('ApplicationStageChange');
      const getSelectText = selectOption.options[selectOption.selectedIndex].value;
      ApplicationStage = getSelectText;
    } else {
      const indexOption = stageValue.target.options.selectedIndex;

      let optionsStage = stageValue.target[indexOption].value;
      ApplicationStage = optionsStage;
    }
  }
  const changeStage = () => {
    UpdateApplicationStage(null);
    setLoader(true);
    if (isAuthenticated) {
      let data = {};
      data.applicationStage = ApplicationStage;

      const changeStage1 = axios
        .put(`${urlapi}/admin/update/stage/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((changeStage1) => {
          setModals1(!modals1);
          setLoader(false);
          if (changeStage1.status == 204) {
            Swal.fire({
              text: 'Application Stage Updated'
            });
          } else {
            Swal.fire({
              text: changeStage1.data.message
            });
            setLoader(false);
          }
          application(token);
        });
      // }
    } else {
      setLoader(false);
    }
  };
  const datechange = async () => {
    const date = await document.querySelector('#date1').value;
    // setDatePaymentReceived(date);
    paymentDateUpadte1(date);
  };
  const paymentDateUpadte1 = async (date) => {
    setIsLoaderpaymentReceived(true);
    if (isAuthenticated) {
      let data = {
        paymentReceived: date
      };
      const dateUpadte = axios
        .put(`${urlapi}/payment/date/update/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((dateUpadte) => {
          setIsLoaderpaymentReceived(false);
          if (dateUpadte.status == 200) {
            Swal.fire({
              text: dateUpadte.data.message
            });
          } else {
            Swal.fire({
              text: dateUpadte.data.message
            });
          }
          // application(token);
        })
        .catch((e) => {
          Swal.fire({
            text: e.message
          });
          setIsLoaderpaymentReceived(false);
        });
      // }
    } else {
      setIsLoaderpaymentReceived(false);
    }
  };
  const paymentDateUpadte2 = async (datevalue) => {
    setIsLoaderpaymentReceived1(true);

    if (isAuthenticated) {
      let data = {
        paymentToDuval: datevalue
      };
      const dateUpadte = axios
        .put(`${urlapi}/payment/date/update/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((dateUpadte) => {
          setIsLoaderpaymentReceived1(false);
          if (dateUpadte.status == 200) {
            Swal.fire({
              text: dateUpadte.data.message
            });
          } else {
            Swal.fire({
              text: dateUpadte.data.message
            });
          }
          // application(token);
        })
        .catch((e) => {
          Swal.fire({
            text: e.message
          });
          setIsLoaderpaymentReceived1(false);
        });
      // }
    } else {
      setIsLoaderpaymentReceived1(false);
    }
  };
  function setDateformateToValid1(date) {
    if (
      date !== null &&
      date !== 'undefined' &&
      typeof date !== 'undefined' &&
      date.includes('/')
    ) {
      let dat = date.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }
  function setDateformateToValid2(datevalue) {
    if (
      datevalue !== null &&
      datevalue !== 'undefined' &&
      typeof datevalue !== 'undefined' &&
      datevalue.includes('/')
    ) {
      let dat = datevalue.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }
  const datechange2 = async () => {
    const datevalue = await document.querySelector('#date2').value;
    // setPaymentDuvalReceived(datevalue);
    paymentDateUpadte2(datevalue);
  };

  return (

    <>
      {
        loader ?
          <div className="main-loader">
            <div className="loader"></div>
          </div>
          :
          <>
            <div className='right_content'>
              <div className='heading__top ht_heading'>

                <h2>
                  <span onClick={() => navigate(-1)}><i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
                  </span> Details </h2>
              </div>
              <div className=' form app_view_form'>
                <div className='app_view'>
                  <div className='app_view_row flex-wrap'>
                    <div className=" w-100 ">

                      {/* // investType == "Individual" || investType == "Joint" ? */}
                      {user1 ? <>
                        <div className='app_view_row '>
                          <div className="app_view_box app_view_box5">
                            <h4>Overview</h4>
                            <div className="row responsive__align">
                              <div className="col-md-6 col-sm-6 col-6">
                                <label>Entity Type:</label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                <p>{user1 ? user1.investmentType : ''}</p>
                              </div>
                            </div>

                            <div className="row responsive__align">
                              <div className="col-md-6  col-sm-6 col-6">
                                <label>Entity Name:</label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                <p>{user1 && user1.entityName !== null ? user1.entityName : ''}</p>
                              </div>
                            </div>
                            <div className="row responsive__align">
                              <div className="col-md-6 col-sm-6 col-6">
                                <label>Investor Type:</label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                <p>{user1 ? user1.investmentType : ''}</p>
                              </div>
                            </div>
                            <div className="row responsive__align">
                              <div className="col-md-6 col-sm-6 col-6">
                                <label>Client Type:</label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                {/* <p>{user1 ? user1.clientType : ''}</p> */}
                                <p>{'Wholesale Investor'}</p>
                              </div>
                            </div>

                            <div className="row responsive__align">
                              <div className="col-md-6  col-sm-6 col-6">
                                <label>Primary Contact Name: </label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                {/* <p>{user1 ? user1.priContactNumber : ''}</p> */}
                                <p>
                                  {' '}
                                  {user1 && user1.fullLegalName !== null && user1.fullLegalName != ''
                                    ? user1.fullLegalName
                                    : ''}
                                </p>
                              </div>
                            </div>
                            <div className="row responsive__align">
                              <div className="col-md-6  col-sm-6 col-6">
                                <label>Email:</label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                <p>{user1 ? user1.userEmail : ''}</p>
                                <span className="status">
                                  {user1 && user1.isEmailVerified ? (
                                    <>
                                      <span className="Success Success_icon">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                        Verified
                                      </span>
                                    </>
                                  ) : (
                                    <span className="danger danger_icon">
                                      <p>
                                        {' '}
                                        <i class="fa fa-times cross_icon" aria-hidden="true"></i> Not
                                        Verified
                                      </p>
                                      {isLoaderVerify == true ? (
                                        <div className="note-main-loader verify_loader">
                                          <div className="loader zip_exp_loader"></div>
                                        </div>
                                      ) : (
                                        <>
                                          <button
                                            className="Send_Email"
                                            onClick={() => verifyEmailss(user1.id)}
                                          >
                                            Send Email
                                          </button>
                                          {/* <button
                                 className=" Manually"
                                 onClick={() => verifyEmail1(user1.id)}
                               >
                                 Update Manually
                               </button> */}
                                        </>
                                      )}
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className="row responsive__align">
                              <div className="col-md-6  col-sm-6 col-6">
                                <label>Phone :</label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                {user1 &&
                                  user1.priContactNumber != null &&
                                  user1 &&
                                  user1.priContactNumber != '' ? (
                                  <>
                                    {user1 ? (
                                      user1.priContactNumber !== null &&
                                        user1.priContactCountryCode !== null ? (
                                        <p>
                                          {user1 && user1.priContactCountryCode !== ''
                                            ? '+' + user1.priContactCountryCode + ' '
                                            : ' '}
                                          {/* {user1 ? user1.priContactCountryCode : ''}{' '} */}
                                          {user1 ? user1.priContactNumber : ''}
                                        </p>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>



                            <div className="row responsive__align">
                              <div className="col-md-6  col-sm-6 col-6">
                                <label>Application Reference Number:</label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                <p>{user1 ? user1.id : ""}</p>
                                {/* <p>{user1 ? user1.paymentReference : ''}</p> */}
                              </div>
                            </div>
                            <div className="row responsive__align align-items-start">
                              <div className="col-md-6">
                                <label>MF Investments Team Member</label>
                              </div>
                              {user1 &&
                                user1.overAllApplicationsStage !== 'Approved' &&
                                user.profile.includes("UR2") || user.profile.includes("UR1") ? (
                                <div className="col-md-6 d-flex justify-content-between align-items-center">
                                  <p>{user1 && user1.memberName}</p>
                                  <div class="ur_edite">
                                    <i
                                      title="Edit"
                                      class="fa fa-pencil-square-o hover__Edit"
                                      aria-hidden="true"
                                      // onClick={() => urPopup()}
                                      onClick={() => togglsUr2()}
                                    ></i>
                                    <span class="sr-only">Edit</span>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              <Modal isOpen={modals1Ur2} show={showUr2} className={""}>
                                {/* <ModalHeader toggle={togglsUr2}>Edit Address</ModalHeader> */}
                                <Box className="formPopUp">
                                  <h4>Change MF Investments Team Member</h4>
                                  <div className="UpdateApplication edit_form">
                                    <Row>
                                      <Col md={6}>
                                        <label>MF Investments Team Member</label>
                                      </Col>

                                      <Col md={6}>
                                        <select
                                          className="w-75 m-0 fw-normal mb-1 pt-0 pb-0 pl-0"
                                          // name="fullLegalName"
                                          onChange={(e) => {
                                            // urChangepopup();
                                            // alert(e.target.value);
                                            setAssignur(e.target.value);
                                          }}
                                          value={assignur}
                                        >
                                          {geturs &&
                                            geturs.map((geturdata) => {
                                              return (
                                                <>
                                                  <option>{geturdata.fullLegalName}</option>
                                                </>
                                              );
                                            })}
                                        </select>
                                      </Col>
                                    </Row>

                                    <Row className="UpdateBtn">
                                      <Col md={6}>
                                        <div onClick={togglsUr2} className="cancelBTN">
                                          Cancel
                                        </div>
                                      </Col>

                                      <Col md={6}>
                                        <button
                                          onClick={() => {
                                            // changeStage2(userr.id, 'main');
                                            updateaccount(user1.id);

                                            // urPopup(user1.id);
                                          }}
                                        >
                                          Yes, Update Now
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Box>
                              </Modal>
                            </div>

                          </div>
                          <div className="app_view_box app_view_box5">
                            <h4>Investment Status</h4>
                            <div className="row responsive__align">
                              <div className="col-md-6  col-sm-6 col-6">
                                <label>Stage: </label>
                              </div>

                              <div className="col-md-6  col-sm-6 col-6">
                                <div className="">
                                  <div className=" ">
                                    {user1 &&
                                      user1.overAllApplicationsStage == 'Submitted' &&
                                      user1.overAllAmlStatus == 1 ? (
                                      <p>Submitted - Passed AML</p>
                                    ) : user1 &&
                                      user1.overAllApplicationsStage == 'Submitted' &&
                                      user1.overAllAmlStatus == 0 ? (
                                      <p>Submitted - Pending AML</p>
                                    ) : (
                                      <p>{user1 ? user1.overAllApplicationsStage : ''}</p>
                                    )}

                                    {/* <p>{user1 ? user1.overAllApplicationsStage : ''}</p> */}
                                  </div>

                                  <div className="col-lg-auto  col-sm-6  col-6  ">
                                    {(user.profile.includes("UR2") || user.profile.includes("UR1")) &&
                                      user1 &&
                                      user1.overAllApplicationsStage == 'Submitted' ? (
                                      // && user1.overAllAmlStatus == 1
                                      <button className="update_stag" onClick={toggles1}>
                                        Update Stage
                                      </button>
                                    ) : (
                                      ''
                                    )}

                                    <Modal isOpen={modals1} show={show12} className={""}>
                                      <Box className="formPopUp">
                                        <h4>Update Application Stage</h4>
                                        <div className="UpdateApplication edit_form">
                                          <Row>
                                            <Col md={6}>
                                              <label>Current Application Stage</label>
                                            </Col>

                                            <Col md={6}>
                                              {user1 &&
                                                user1.overAllApplicationsStage == 'Submitted' &&
                                                user1.overAllAmlStatus == 1 ? (
                                                <p>Submitted - Passed AML</p>
                                              ) : user1 &&
                                                user1.overAllApplicationsStage == 'Submitted' &&
                                                user1.overAllAmlStatus == 0 ? (
                                                <p>Submitted - Pending AML</p>
                                              ) : (
                                                <p>{user1 ? user1.overAllApplicationsStage : ''}</p>
                                              )}
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col md={6}>
                                              <label>Update Stage</label>
                                            </Col>

                                            <Col md={6}>
                                              <select
                                                id="ApplicationStageChange"
                                                onChange={(e) => UpdateApplicationStage(e)}
                                              >
                                                {user1 &&
                                                  user1.overAllApplicationsStage == 'Submitted' &&
                                                  user1.overAllAmlStatus == 0 ? (
                                                  <option class="optionStage" value="In Progress">
                                                    {' '}
                                                    In Progress
                                                  </option>
                                                ) : (
                                                  <>
                                                    <option class="optionStage" value="In Progress">
                                                      {' '}
                                                      In Progress
                                                    </option>
                                                    <option
                                                      class="optionStage"
                                                      value="Approved"
                                                      defaultSelected
                                                      Selected="Approved"
                                                    >
                                                      {' '}
                                                      Submitted - Approved
                                                    </option>
                                                  </>
                                                )}
                                              </select>
                                            </Col>
                                          </Row>

                                          <Row className="UpdateBtn">
                                            <Col md={6}>
                                              <div onClick={toggles1} className="cancelBTN">
                                                Cancel
                                              </div>
                                            </Col>

                                            <Col md={6}>
                                              <button onClick={changeStage}>Save Changes</button>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Box>
                                    </Modal>
                                  </div>
                                </div>
                              </div>

                            </div>

                            <div className="row responsive__align">
                              <div className="col-md-6  col-sm-6 col-6">
                                <label>
                                  <span className="text_upercas">Investment Amount</span>:
                                </label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                <p>{user1 && user1.totalInvestment} </p>
                              </div>
                            </div>
                            <div className="row responsive__align">
                              <div className="col-md-6  col-sm-6 col-6">
                                <label>
                                  <span className="text_upercas">AML</span>:
                                </label>
                              </div>
                              <div className="col-md-6  col-sm-6 col-6">
                                {user1 && user1.investmentType === 'Joint' ? (
                                  <p>
                                    {user1 && user1.overAllAmlStatus === 1 ? (
                                      <p className="aml_pass TextIndent">Pass</p>
                                    ) : (
                                      <p className="aml_fail TextIndent">Fail</p>
                                    )}
                                  </p>
                                ) : (
                                  <>
                                    {user1 && user1.overAllAmlStatus === 1 ? (
                                      <p className="aml_pass TextIndent">Pass</p>
                                    ) : (
                                      <p className="aml_fail TextIndent">Fail</p>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>

                            {user.profile.includes("UR1") ||
                              user.profile == 'UR8' ||
                              user.profile.includes("UR2") ||
                              user.profile == 'UR9' ? (
                              user1 && user1.applicationStage === 'Active Client' ? (
                                <>
                                  <div className="row responsive__align main_wrapper_date">
                                    <div className="col-md-5 col-12">
                                      <label>Date Payment Received:</label>
                                    </div>
                                    <div className="col-md-5 col-12">
                                      <div className="fullinput">
                                        <p>
                                          {user1 != null && user1 != '' && user1 != 'undefined'
                                            ? user1.paymentReceived
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ''
                              )
                            ) : null}
                            {user1 &&
                              (user1.applicationStage === 'Awaiting Payment' ||
                                user1.applicationStage === 'Active Client') ? (
                              <>
                                {user.profile.includes("UR4") ? (
                                  <div className="row responsive__align">
                                    <div className="col-md-5 main_wrapper_daten col-12">
                                      <label>Date Payment Received:</label>
                                    </div>
                                    {(user1 &&
                                      user1.applicationStage === 'Awaiting Payment' &&
                                      user1.paymentReceived == null) ||
                                      user1.paymentReceived == '' ? (
                                      <div className="col-md-5 col-8 date_style">
                                        <div className="fullinput">
                                          <SemanticDatepicker
                                            className="date12 counrtyselext6 numbers input__w50_a"
                                            id="date1"
                                            maxDate={new Date(moment().subtract(0, 'years'))}
                                            format="DD/MM/YYYY"
                                            name="date1"
                                            // value={user1.paymentReceived}
                                            allowOnlyNumbers
                                            onChange={(e) => {
                                              datechange();
                                            }}
                                          // v alue={paymentReceived}
                                          />
                                        </div>

                                        {isLoaderpaymentReceived === true ? (
                                          <div class="col-md-4">
                                            {' '}
                                            <div class="main_date_loader">
                                              <div className="loader">
                                                <p class="loader-inner"></p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    ) : (
                                      <>
                                        <div className="main_date_stage date_style negavtive-text-indent">
                                          <SemanticDatepicker
                                            className="counrtyselext6 numbers input__w50_a resp"
                                            id="date1"
                                            // minDate={now()}
                                            maxDate={new Date(moment().subtract(0, 'years'))}
                                            format="DD/MM/YYYY"
                                            name="date1"
                                            value={
                                              user1 && user1.paymentReceived
                                                ? new Date(setDateformateToValid1(user1.paymentReceived))
                                                : ''
                                            }
                                            allowOnlyNumbers
                                            onChange={(e) => {
                                              datechange();
                                            }}
                                          />

                                          {isLoaderpaymentReceived === true ? (
                                            <div class="col-md-4 col-4">
                                              {' '}
                                              <div class="main_date_loader">
                                                <div className="loader">
                                                  <p class="loader-inner"></p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : null}

                                {user.profile.includes("UR1") ||
                                  user.profile == 'UR8' ||
                                  user.profile.includes("UR2") ||
                                  user.profile == 'UR9' ? (
                                  <>
                                    <div className="row responsive__align main_wrapper_date">
                                      <div className="col-md-5 col-12">
                                        <label>Date Payment Made to Du Val:</label>
                                      </div>
                                      <div className="col-md-5 col-12">
                                        <div className="fullinput">
                                          <p>
                                            {user1 != null && user1 != '' && user1 != 'undefined'
                                              ? user1.paymentToDuval
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}

                                {user.profile.includes("UR4") ? (
                                  <div>
                                    <div className="row responsive__align">
                                      <div className="col-md-5 col-12">
                                        <label>Date Payment Made to Du Val:</label>
                                      </div>

                                      {user1 &&
                                        (user1.applicationStage === 'Awaiting Payment' ||
                                          user1.applicationStage === 'Active Client') ? (
                                        user1 &&
                                          user1.applicationStage === 'Awaiting Payment' &&
                                          user1.paymentReceived == null ? (
                                          <div className="col-md-5 col-8 date_style">
                                            <div className="fullinput">
                                              <SemanticDatepicker
                                                className="counrtyselext6 numbers input__w50_a"
                                                id="date2"
                                                // minDate={now()}
                                                maxDate={new Date(moment().subtract(0, 'years'))}
                                                format="DD/MM/YYYY"
                                                name="date2"
                                                allowOnlyNumbers
                                                onChange={(e) => {
                                                  datechange2();
                                                }}
                                              />
                                            </div>

                                            {isLoaderpaymentReceived1 === true ? (
                                              <div class="col-md-4">
                                                {' '}
                                                <div class="main_date_loader_duval">
                                                  <div className="loader">
                                                    <p class="loader-inner"></p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        ) : (
                                          <div className="main_date_stage date_lader date_style negavtive-text-indent">
                                            <SemanticDatepicker
                                              className="counrtyselext6 numbers input__w50_a"
                                              id="date2"
                                              // minDate={now()}
                                              maxDate={new Date(moment().subtract(0, 'years'))}
                                              format="DD/MM/YYYY"
                                              name="date2"
                                              value={
                                                user1 && user1.paymentToDuval
                                                  ? new Date(setDateformateToValid2(user1.paymentToDuval))
                                                  : ''
                                              }
                                              allowOnlyNumbers
                                              onChange={(e) => {
                                                datechange2();
                                              }}
                                            />

                                            {isLoaderpaymentReceived1 === true ? (
                                              <div class="col-md-4">
                                                {' '}
                                                <div class="main_date_loader_duval">
                                                  <div className="loader">
                                                    <p class="loader-inner"></p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                          // <>{user1.paymentToDuval}</>
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                            {user.profile.includes("UR1") || user.profile.includes("UR2") ? (
                              <>
                                <div className="history_main_wrapper">
                                  <div className="history">
                                    <Link to={{ pathname: `/History/${user1 ? user1.id : ''}` }}>
                                      <i title="History" class="fa fa-history" aria-hidden="true"></i>
                                    </Link>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {user.profile.includes("UR1") || user.profile.includes("UR2") ? (
                              <>
                                <div className="history_main_wrapper view_btn_details">
                                  
                                    <span className='view_detail'>
                                      <Link
                                        to={{
                                          pathname: `/ApplicationDetailsIndi/${user1.id}`,
                                          state: id
                                        }}
                                      >
                                        View Detail
                                      </Link>
                                    </span>
                                  
                                </div>
                              </>
                            ) : (
                              ""
                            )}



                            {/* {user.profile.includes("UR3") ? (
                                <div className="SubscriptionAgree_btn responsive__SubscriptionAgreement">
                                  <Link
                                    to={{ pathname: `/SubscriptionAgreement/${user1 ? user1.id : ''}` }}
                                    className="BITTON__sign_directore"
                                  >
                                     <>
                                      <div className="">
                                        <div className="red_green_text">
                                          {directodata !== undefined &&
                                            directodata !== 'undefined' &&
                                            directodata.map((user1ss) => {
                                              return (
                                                <>
                                                  {user1ss.isLogin === true ? (
                                                    <>
                                                      {user1ss.subscriptionSign != null &&
                                                        user1ss.subscriptionSign != '' ? (
                                                        <>
                                                          <div className="mobile_button d-block d-sm-none">
                                                            <button className="Sign__agreement button__green">
                                                              SIGN SUBSCRIPTION
                                                            </button>
                                                          </div>
                                                          <div className="Sign__agreement_ICON d-none  d-sm-inline-block d-md-inline-block d-lg-inline-block destop__view">
                                                            <p className="text__green">
                                                              <i
                                                                title="Sign all documents"
                                                                class="fa fa-pencil-square-o text__green"
                                                                aria-hidden="true"
                                                              ></i>
                                                            </p>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div className="mobile_button d-block d-sm-none">
                                                            <button className="Sign__agreement button__red">
                                                              SIGN SUBSCRIPTION
                                                            </button>
                                                          </div>

                                                          <div className="Sign__agreement_ICON d-none  d-sm-inline-block d-md-inline-block d-lg-inline-block">
                                                            <div className="row">
                                                              <div className="col-md-12">
                                                                <div className="text_pending">
                                                                  <p className="text__red">
                                                                    <i
                                                                      title="Sign all documents"
                                                                      class="fa fa-pencil-square-o text__red"
                                                                      aria-hidden="true"
                                                                    ></i>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ''
                                                  )}
                                                </>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </> 
                                  </Link>

                                  
                                </div>
                              ) : (
                                ''
                              )} */}
                          </div>
                        </div>
                      </> : ""}




                      <div>

                      </div>
                    </div>





                  </div>
                </div>

              </div>
            </div>
          </>
      }


    </>
  )
}
export default InvestAmount;