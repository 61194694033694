/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { audience, urlapi } from 'src/auth/Configuration';

import { Button } from 'react-bootstrap';

const Syndicates = () => {
  const { user, getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();

  const result = async (token) => {
    setLoader(true);
    if (isAuthenticated) {
      axios
        .get(`${urlapi}/admin/syndicate/actions`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            setOptions(res.data);
          }
        });
    }
  };

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          // setToken(accessidToken.__raw);
          let token = accessidToken.__raw;

          //    formData.token =
          //  sessionStorage.setItem('token', token);

          result(token);
          // Notessfunc(token);
        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [loader, setLoader] = useState();

  const [options, setOptions] = useState([]);

  const roleUser = sessionStorage.getItem('roles');

  return (
    <>
      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="right_content">
          <div className="heading__top ht_heading">
            <h2>
              {/* <Link to="/Newsresearch">
                <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
              </Link> */}
              Syndicates
            </h2>
            <hr />

            <div className="addbutton">
              <h5 className="Lsyndicates">Live Syndicates</h5>
              <div>
                {user.profile.includes("UR3") || user.profile.includes("UR1") ? (
                  <Link to={'/syndicateAdd'}>
                    <Button className="syndicates_btn edite_btn">
                      Add
                      {/* toggle */}
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              {options &&
                options.map((abc) => {
                  return abc.isValidOffer == 1 ? (
                    <>
                      <div className="col-md-4">
                        <div className="col_inner">
                          <Link
                            to="/InfoDetails"
                            onClick={() => {
                              sessionStorage.setItem('id', abc.id);
                            }}
                          >
                            <div className="inner_title">
                              <span>{abc.title}</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  );
                })}
            </div>

            <div className="row">
              <h5 className="mb-5">Close Syndicates</h5>
              {options &&
                options.map((abc) => {
                  return abc.isValidOffer == 0 ? (
                    <>
                      <div className="col-md-4">
                        <div className="col_inner">
                          <Link
                            to="/InfoDetails"
                            onClick={() => {
                              sessionStorage.setItem('id', abc.id);
                            }}
                          >
                            <div className="inner_title">
                              <span>{abc.title}</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
    //   )}
    // </>
  );
};
export default Syndicates;
