import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout2 from './InvestorPages.js/DashboardLayout2';
import DashbordInvesta from './InvestorPages.js/pages/DashbordInvesta';
import InvestmentOpportunities from './InvestorPages.js/pages/InvestmentOpportunities';
import MyDocuments from './InvestorPages.js/pages/MyDocuments';
import Transactions from './InvestorPages.js/pages/Transactions';
import ProfilePage from './InvestorPages.js/pages/ProfilePage';
import InfoDetailsInvestor from './InvestorPages.js/pages/InfoDetailsInvestor';
import Syndicates1 from './InvestorPages.js/pages/Syndicates1';
import Contact from './InvestorPages.js/pages/Contact';
import NewResearch from './InvestorPages.js/pages/NewResearch';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout2 />,
      children: [
        { path: '/', element: <Navigate to="/investorDashboard" /> },

        { path: '/InfoDetailsInvestor/:id', element: <InfoDetailsInvestor /> },


        { path: 'investorDashboard', element: <DashbordInvesta /> },
        { path: 'InvestmentOpportunities', element: <InvestmentOpportunities /> },
        { path: 'Transactions', element: <Transactions /> },
        { path: 'MyDocuments', element: <MyDocuments /> },
        { path: 'NewResearch', element: <NewResearch /> },
        { path: 'Contact', element: <Contact /> },
        { path: 'profilePage', element: <ProfilePage /> },

        { path: '/SyndicatesOnes', element: <Syndicates1 /> },

      ]
    },

  ]);
}
