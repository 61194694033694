/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { audience, urlapi } from 'src/auth/Configuration';

import { Row, Col, Nav, Tab, Accordion } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import moment from 'moment';
import Swal from 'sweetalert2';

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import $ from 'jquery';
import { Modal } from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import ModalPopup from './ModalPopup';
import PersonalInformation from '../PersonalInformation2';
import BankAccount from '../BankAccount';
import IdVerification from '../IdVerification';
import TermsCondition from '../TermsCondition';
import NameIdAml from '../NameIdAml';
import TrustDetails from '../TrustDetails';
import CompanyDetails from '../../pages/CompanyDetails';
import WholesaleCertificate from '../WholesaleCertificate';

const ApplicationDetails = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [imageLoader, setImageLoader] = useState();
  const [documentFileAttach264, setDocumentFileAttach264] = useState();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    function readURL(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        $('#name_tag').text(input.files[0].name);
      }
    }

    $('#imag2').change(function () {
      var imgControlName = '#ImgPreview2';
      readURL(this, imgControlName);
      $('.preview2').addClass('it');
      $('.btn-rmv2').addClass('rmv');
    });

    $('#removeImage2').click(function (e) {
      e.preventDefault();
      $('#imag2').val('');
      $('#ImgPreview2').attr('src', '');
      $('.preview2').text('');
      $('.btn-rmv2').removeClass('rmv');
    });
  });

  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated
  } = useAuth0();
  const [token, setToken] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;

          applicationTrust(token);
          Notessfunc(token);
          getur(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    getUserMetadata();
  }, []);

  const [user2, setUser2] = useState();
  const [loader, setLoader] = useState();
  const [noteText, setNoteText] = useState();
  const [notess, setNotess] = useState();
  // const [documentFileAttach64, setDocumentFileAttach64] = useState();
  const [documentFileAttachNotes64, setDocumentFileAttachNotes64] = useState();
  const [notedocument, setNotedocument] = useState({});

  let applicationStage = '';

  const [noteLoder, setNoteLoder] = useState(false);
  // const [documentFile64, setDocumentFile64] = useState();
  // const [documentFile641, setDocumentFile641] = useState();
  // const [documentFile6412, setDocumentFile6412] = useState();
  const [documentFile6422, setDocumentFile6422] = useState();
  // const [verifyFordoc64, setVerifyFordoc64] = useState();
  // const [verifyFordoc641, setVerifyFordoc641] = useState();
  // const [verifyFordoc6412, setVerifyFordoc6412] = useState();
  // const [verifyFordoc6422, setVerifyFordoc6422] = useState();
  const [userID1, setUserID1] = useState();
  const [userID2, setUserID2] = useState();

  const { className } = props;
  // const [modal, setModal, setForm] = useState(false);
  // const [modal1, setModal1] = useState(false);
  const [modal12, setModal12] = useState(false);
  // const [modal22, setModal22] = useState(false);
  // const [modal23, setModal23] = useState(false);
  // const [modal24, setModal24] = useState(false);
  const [modals1, setModals1] = useState(false);
  const [noteNumber, setnoteNumber] = useState(1);
  // const [modals129, setModals129] = useState(false);

  const toggles1 = () => setModals1(!modals1);

  const show12 = () => setModal12(!modal12);

  const [modals1Ur2, setModals1Ur2] = useState(false);

  const [modalur2, setModalur2] = useState(false);

  const togglsUr2 = () => setModals1Ur2(!modals1Ur2);

  const showUr2 = () => setModalur2(!modalur2);

  function readURL(input) {
    if (input && input) {
      var reader = new FileReader();
      reader.readAsDataURL(input);
      $('.Attch_Note').append(
        "<div class='flex__Note_atttach noteDocument" +
        noteNumber +
        "'>" +
        "<span id='name_tag_note" +
        noteNumber +
        "' class='preview2_note" +
        noteNumber +
        "'></span><input type='button'id='removeImage2_note" +
        noteNumber +
        "' value='x' class='btn-rmv2_note" +
        noteNumber +
        "'/>" +
        '</div>'
      );
      $('#name_tag_note' + noteNumber).text(input.name);
    }
  }

  async function uploadWithJSON_attach__Notess(file) {
    if (Object.keys(notedocument).length >= 5) {
      Swal.fire('You can attach atleast 5 files');
      return;
    }

    readURL(file);
    $('.preview2_note' + noteNumber).addClass('it');
    $('.btn-rmv2_note' + noteNumber).addClass('rmv');

    $('#removeImage2_note' + noteNumber).click(function (e) {
      e.preventDefault();
      $('#imag2_note').val('');
      $('#ImgPreview2_note').attr('src', '');
      $('.noteDocument' + noteNumber).remove();
      delete notedocument['document' + noteNumber];
    });

    // setDocumentFileAttachNotes64(file.name);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });

    const data = {
      img64: await toBase64(file)
    };
    notedocument['document' + noteNumber] = data.img64;
    setNotedocument(notedocument);

    setnoteNumber(noteNumber + 1);
  }

  const handleSaveClick = () => {
    setNoteLoder(true);
    var textarea = document.getElementsByTagName('textarea');
    var result = [];
    for (var i = 0; i < textarea.length; i += 1) {
      result[i] = textarea[i].value;

      if (textarea[i].value === '') {
        setNoteLoder(false);
        Swal.fire('Please enter note first.');
        return; // stop the function
      }
    }

    let postadv = {
      note: noteText
    };
    if (Object.keys(notedocument).length > 0) {
      postadv['noteDocuments'] = notedocument;
    }
    axios
      .post(`${urlapi}/admin/user/notes/${id}`, postadv, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          Notessfunc(token);
          setNoteLoder(false);
          setNoteText('');
          Swal.fire('Note Added');
        } else {
          setNoteLoder(false);
        }
        setNotedocument({});
        setnoteNumber(1);
      })
      .catch((result) => {
        setNotedocument({});
        setNoteLoder(false);
        setnoteNumber(1);
      });
  };

  const Notessfunc = (token) => {
    const NotessData = axios
      .get(`${urlapi}/admin/user/notes/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setNotess(result.data);
        }
      });
  };


  // const agrementpdf = async () => {
  //   if (isAuthenticated) {
  //     setLoader(true);
  //     const applicationdata13 = await axios
  //       .get(`https://duvaltrust-in-backend.azureedge.net/api/duval/user/agreement/${id}`, {
  //         headers: { Authorization: `Bearer ${token}` }
  //       })

  //       .then((applicationdata13) => {
  //         setLoader(false);
  //         if (applicationdata13.status === 200) {
  //           const file = new Blob([applicationdata13.data], { type: 'application/pdf' });
  //           const fileURL = URL.createObjectURL(file);
  //           window.open(fileURL);
  //         } else {
  //           Swal.fire(applicationdata13.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         setLoader(false);

  //         Swal.fire('Something went wrong');
  //       });
  //     // }
  //   }
  // };

  // function wholesale_certifiacte_pdf(userid) {
  //   if (isAuthenticated) {
  //     setLoader(true);
  //     const applicationdata13 = axios

  //       .get(`${urlapi}/user/wholesale/certificate/${userid}`, {
  //         headers: { Authorization: `Bearer ${token}` }
  //       })

  //       .then((applicationdata13) => {
  //         setLoader(false);
  //         if (applicationdata13.status === 200) {
  //           const file = new Blob([applicationdata13.data], { type: 'application/pdf' });
  //           const fileURL = URL.createObjectURL(file);
  //           window.open(fileURL);
  //         } else {
  //           Swal.fire(applicationdata13.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         setLoader(false);

  //         Swal.fire('Something went wrong');
  //       });
  //     // }
  //   }
  // }
  const [directodata, setDirectodata] = useState();

  const applicationdiretor = async (token) => {
    if (isAuthenticated) {
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/director/approvals/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          setDirectodata(applicationdata12.data);
        });
      // }
    }
  };

  const [userCompany, SetUserCompany] = useState();
  const [investType, setInvestType] = useState();

  const applicationTrust = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/user/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          SetUserCompany(applicationdata12.data);
          setInvestType(applicationdata12.data[0].investmentType)

          if (user.profile.includes("UR3")) {
            applicationdiretor(token);
          }
        })
        .catch((error) => {
          setLoader(false);
          Swal.fire('Something went wrong');
        })
        .finally((e) => {
          setLoader(false);
        });
      // }
    }
  };

  // const [datepaymentReceived, setDatePaymentReceived] = useState();
  const [paymentReceived, setPaymentReceived] = useState({});
  // const [paymentToDuval, setPaymentDuvalReceived] = useState();

  function setDateformateToValid1(date) {
    if (
      date !== null &&
      date !== 'undefined' &&
      typeof date !== 'undefined' &&
      date.includes('/')
    ) {
      let dat = date.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }

  function setDateformateToValid2(datevalue) {
    if (
      datevalue !== null &&
      datevalue !== 'undefined' &&
      typeof datevalue !== 'undefined' &&
      datevalue.includes('/')
    ) {
      let dat = datevalue.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }
  const datechange = async () => {
    const date = await document.querySelector('#date1').value;
    paymentDateUpadte1(date);
  };

  const datechange2 = async () => {
    const datevalue = await document.querySelector('#date2').value;
    paymentDateUpadte2(datevalue);
  };

  const [isLoaderpaymentReceived, setIsLoaderpaymentReceived] = useState(false);
  const [isLoaderpaymentReceived1, setIsLoaderpaymentReceived1] = useState(false);

  const paymentDateUpadte1 = async (date) => {
    setIsLoaderpaymentReceived(true);
    if (isAuthenticated) {
      let data = {
        payment_received: date
      };
      const dateUpadte = axios
        .put(`${urlapi}/payment/date/update/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((dateUpadte) => {
          setIsLoaderpaymentReceived(false);
          if (dateUpadte.status == 200) {
            Swal.fire({
              text: dateUpadte.data.message
            });
          } else {
            Swal.fire({
              text: dateUpadte.data.message
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            text: e.message
          });
          setIsLoaderpaymentReceived(false);
        });
      // }
    } else {
      setIsLoaderpaymentReceived(false);
    }
  };

  const paymentDateUpadte2 = async (datevalue) => {
    setIsLoaderpaymentReceived1(true);

    if (isAuthenticated) {
      let data = {
        payment_to_duval: datevalue
      };
      const dateUpadte = axios
        .put(`${urlapi}/payment/date/update/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((dateUpadte) => {
          setIsLoaderpaymentReceived1(false);
          if (dateUpadte.status == 200) {
            Swal.fire({
              text: dateUpadte.data.message
            });
          } else {
            Swal.fire({
              text: dateUpadte.data.message
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            text: e.message
          });
          setIsLoaderpaymentReceived1(false);
        });
      // }
    } else {
      setIsLoaderpaymentReceived1(false);
    }
  };

  function UpdateApplicationStage(stageValue, id) {
    if (stageValue === null) {
      const selectOption = document.getElementById(`StageChange${id}`);
      // const selectOption = updateStage;
      const getSelectText = selectOption.options[selectOption.selectedIndex].value;
      applicationStage = getSelectText;

    } else {
      const indexOption = stageValue.target.value;

      applicationStage = indexOption;

    }
  }


  const changeStage = (userid, modp) => {




    if (applicationStage == null || applicationStage == '' || applicationStage == undefined) {
      const selectOption = document.getElementById(`StageChange${userid}`);
      // const selectOption = updateStage;
      const getSelectText = selectOption.options[selectOption.selectedIndex].value;
      applicationStage = getSelectText;
    }
    console.log("sss", applicationStage);

    setLoader(true);
    if (isAuthenticated) {
      let data = {};
      if (Object.keys(paymentReceived).length > 0) {
        data = paymentReceived;
      }
      data.applicationStage = applicationStage;

      const changeStage1 = axios
        .put(`${urlapi}/admin/update/stage/${userid}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((changeStage1) => {
          setModals1(!modals1);

          if (modp == 'main') {
            setModals1(!modals1);
          }

          // setModals129(!modals129);

          setLoader(false);
          if (changeStage1.status == 204) {
            Swal.fire({
              text: 'Application Stage Updated'
            });
          } else {
            Swal.fire({
              text: changeStage1.data.message
            });

            setPaymentReceived({});
          }
          applicationTrust(token);
        });
    } else {
      setLoader(false);
    }
  };



  const [addresCheck1, SetAddresCheck1] = useState(true);

  const [pepAml, setPepAml] = useState(true);



  const [user1, setUser1] = useState();
  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/user/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          setUser1(applicationdata12.data[0]);
          setUserID1(applicationdata12.data[0].id);
          // alert(applicationdata12.data[0].fullLegalName);
          if (user.profile.includes("UR3")) {
            applicationdiretor(token);
          }

          if (applicationdata12.data.length > 1) {
            setUserID2(applicationdata12.data[1].id);

            setUser2(applicationdata12.data[1]);
          }
        })
        .catch((error) => {
          setLoader(false);

          Swal.fire('Something went wrong');
        });
      // }
    }
  };
  // const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  const deleteaccount = async (id) => {
    setLoader(true);
    await axios
      .delete(`${urlapi}/admin/user/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        if (res) {
          application(token);

          res.status === 200
            ? Swal.fire({
              html: 'Deleted',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#3085D6'
            }).then((e) => {
              navigate('/application');
              // window.location = '/';
              setLoader(false);
              // window.location.reload(true);
            })
            : Swal.fire(
              '',
              'We are unable to process your request at the moment. Please try again after some time',
              ''
            );
        }
      })
      .catch(function (error) {
        Swal.fire('', 'Unfortunately an error has occurred, Please try again.', '');
      });
  };
  const deleteonSubmit = async (id) => {
    Swal.fire({
      allowOutsideClick: false,
      // html: "Please wait a moment while we delete your application",
      html: 'Are you sure you want to delete this application and all records from the system?',
      // showDenyButton: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Yes, Delete Now`,
      // denyButtonText:`CANCEL`,
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteaccount(id);
      }
    });
  };

  const [assignur, setAssignur] = useState();

  const updateaccount = async (id) => {
    let databody;

    if (assignur == null || assignur == '' || assignur == undefined) {
      databody = {
        memberName: geturs[0].fullLegalName
      };
    } else {
      databody = {
        memberName: assignur
      };
    }

    setLoader(true);
    await axios
      .put(`${urlapi}/admin/team/member/${id}`, databody, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        Swal.fire({
          html: 'Updated',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#3085D6'
        }).then((e) => {
          setLoader(false);
          setModals1Ur2(!modals1Ur2);
          applicationTrust(token);
        });
      })
      .catch(function (error) {
        setLoader(false);
        Swal.fire('', 'Unfortunately an error has occurred, Please try again.', '');
      });
  };
  const certifiacte_pdf = (pdff) => {
    window.open(pdff);
  };
  const [geturs, setGeturs] = useState();

  const getur = (token) => {
    const NotessData = axios
      .get(`${urlapi}/admin/team/member`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setGeturs(result.data);
        }
      });
  };


  async function uploadWithJSON_attach2(file, userIdf) {

    setDocumentFileAttach264(file.name);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };
    let datadocument = {
      columnName: 'confirmationDocument',
      fileName: documentFileAttach264,
      document: data.img64
    };
    setImageLoader(true);
    axios
      .post(`${urlapi}/admin/confirmation/document/${userIdf}`, datadocument, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        let urlFile = (datadocument.columnName = result.data.url);
        if (result.status === 200) {
          setImageLoader(false);
          applicationTrust(token);
        } else {
          Swal.fire('Something went wrong');
        }
      });
  }
  const [ziploader, setZiploader] = useState(false);
  const getZipData = async (id, zipUrl) => {
    let Zipdata = {
      downloadUrl: zipUrl
    };
    setZiploader(true);
    const zip = await axios
      .put(`${urlapi}/admin/zip/download/${id}`, Zipdata, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        if (res.status == 208) {
          setZiploader(false);
          Swal.fire({
            text: res.data.message
          });
        } else {
          setZiploader(false);
          window.open(res.data.url);
        }
      });

  };
  const [isLoaderVerify, setIsLoaderVerify] = useState(false);

  const verifyEmails = async (user_id) => {
    setIsLoaderVerify(true);
    const changeStage1 = await axios
      .put(
        `${urlapi}/admin/verify/email/user/${user_id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setIsLoaderVerify(false);
          Swal.fire({
            text: 'Request sent!'
          });
        } else {
          setIsLoaderVerify(false);
          // Swal.fire(result.data.message)
          Swal.fire({
            text: result.data.message
          });
        }
      })
      .catch((error) => {
        setIsLoaderVerify(false);
        Swal.fire({
          text: 'Something went wrong!'
        });
      });
  };
  return (
    <>
      <>
        {loader === true ? (
          <div className="main-loader">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="right_content">
            <div className="conatiner">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="heading__top ht_heading">
                    {/* <h2>
                      <Link to="/application">
                        <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
                      </Link>
                      APPLICATIONS: View & Edit
                    </h2> */}
                    <h2>
                      <span onClick={() => navigate(-1)}><i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
                      </span>APPLICATIONS: View & Edit </h2>
                  </div>
                </div>
                <div className="col-md-6 text-right">
                  {userCompany &&
                    userCompany[0].personal[0].applicationStage != 'Approved' &&
                    (user.profile.includes("UR2") || user.profile.includes("UR1")) ? (
                    <button
                      className="m-auto d-inline-block delete_app_btn"
                      onClick={() => deleteonSubmit(id)}
                    >
                      {/* <i class="fa fa-trash-o" aria-hidden="true"></i> */}Delete Application
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            <div className="form app_view_form">
              <div className="app_view">
                <div className="app_view">
                  <div className="app_view_row ">
                    <div className="app_view_box app_view_box5">
                      <h4>Overview</h4>
                      {userCompany &&
                        userCompany.map((usert) => {
                          return (
                            usert.personal &&
                            usert.personal
                              .filter((usert) => usert.isPrimary === 1 && usert.isPrimary !== null)
                              .map((userr, key) => {
                                return (
                                  <>
                                    <div className="row responsive__align">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Entity type:</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p key={key}>
                                          {userr.investmentType != null ? userr.investmentType : ''}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row responsive__align">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Entity Name:</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        {investType && investType == "Trust" ?
                                          <p>
                                            {userr.entityName != null ? userr.entityName : ''}
                                          </p> :
                                          <p >
                                            {usert.companyDetails != null && usert.companyDetails.companyName != undefined ? usert.companyDetails.companyName : ""}
                                          </p>
                                        }

                                      </div>
                                    </div>
                                    <div className="row responsive__align">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Investor Type:</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p key={key}>
                                          {userr.investmentType != null ? userr.investmentType : ''}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row responsive__align">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Client Type:</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        {/* <p key={key}>
                                          {userr.clientType != null
                                            ? userr.clientType
                                            : ''}
                                        </p> */}
                                        <p key={key}>{'Wholesale Investor'}</p>
                                      </div>
                                    </div>
                                    <div className="row responsive__align">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Primary Contact Name: </label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>
                                          {/* MF Investments Team Member{' '} */}
                                          {userr &&
                                            userr.fullLegalName !== null &&
                                            userr.fullLegalName != ''
                                            ? userr.fullLegalName
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row responsive__align">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Email:</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>{userr.userEmail != null ? userr.userEmail : ''}</p>
                                        <span className="status">
                                          {userr && userr.isEmailVerified ? (
                                            <>
                                              <span className="Success Success_icon">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                                Verified
                                              </span>
                                            </>
                                          ) : (
                                            <span className="danger danger_icon">
                                              <p>
                                                {' '}
                                                <i class="fa fa-times cross_icon" aria-hidden="true"></i> Not
                                                Verified
                                              </p>
                                              {isLoaderVerify == true ? (
                                                <div className="note-main-loader verify_loader">
                                                  <div className="loader zip_exp_loader"></div>
                                                </div>
                                              ) : (
                                                <>
                                                  <button
                                                    className="Send_Email"
                                                    onClick={() => verifyEmails(userr.id)}
                                                  >
                                                    Send Email
                                                  </button>
                                                  {/* <button
                                    className=" Manually"
                                    onClick={() => verifyEmail1(user1.id)}
                                  >
                                    Update Manually
                                  </button> */}
                                                </>
                                              )}
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="row responsive__align">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Phone :</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        {userr &&
                                          userr.priContactNumber != null &&
                                          userr.priContactNumber != '' ? (
                                          <>
                                            {userr ? (
                                              userr.priContactNumber !== null &&
                                                userr.priContactNumber !== null ? (
                                                <p>
                                                  {userr && userr.priContactNumber !== ''
                                                    ? '+' +
                                                    userr.priContactCountryCode +
                                                    ' ' +
                                                    userr.priContactNumber
                                                    : ' '}
                                                </p>
                                              ) : (
                                                ''
                                              )
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>

                                    {/* <div className="row responsive__align">
                                      <div className="col-md-6">
                                        <label>Fund: </label>
                                      </div>
                                      <div className="col-md-6">
                                        <p>{userr.fundCode != null ? userr.fundCode : ''}</p>
                                      </div>
                                    </div> */}
                                    {/* 
                                    <div className="row responsive__align">
                                      <div className="col-md-6">
                                        <label>Investment:</label>
                                      </div>
                                      <div className="col-md-6">
                                        <p>
                                          {userr && userr.totalInvestment != null ? <>$</> : ''}
                                          {userr ? userr.totalInvestment : ''}
                                        </p>
                                      </div>
                                    </div> */}

                                    <div className="row responsive__align">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Application Reference Number:</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>{userr ? userr.id : ''}</p>
                                      </div>
                                    </div>
                                    {/* <div className="row responsive__align">
                                      <div className="col-md-6">
                                        <label>Investor Relations Associate :</label>
                                      </div>
                                      <div className="col-md-6">
                                        <p>{userr ? userr.investorRelation : ''}</p>
                                      </div>
                                    </div> */}
                                    <div className="row responsive__align align-items-start">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>MF Investments Team Member</label>
                                      </div>
                                      {
                                        // user1 &&
                                        // user1.overAllApplicationsStage !== 'Approved' &&
                                        (user.profile.includes("UR2") || user.profile.includes("UR1")) && (userr.applicationStage != "In Progress") ? (
                                          < div className="col-md-6 d-flex justify-content-between align-items-center" >
                                            <p>{userr ? userr.memberName : ''}</p>
                                            <div class="ur_edite">
                                              <i
                                                title="Edit"
                                                class="fa fa-pencil-square-o hover__Edit"
                                                aria-hidden="true"
                                                // onClick={() => urPopup()}
                                                onClick={() => togglsUr2()}
                                              ></i>
                                              <span class="sr-only">Edit</span>
                                            </div>
                                          </div>
                                        ) : (
                                          ''
                                        )
                                      }

                                      <Modal
                                        isOpen={modals1Ur2}
                                        show={showUr2}
                                        className={className}
                                      >
                                        {/* <ModalHeader toggle={togglsUr2}>Edit Address</ModalHeader> */}
                                        <Box className="formPopUp">
                                          <h4>Change MF Investments Team Member</h4>
                                          <div className="UpdateApplication edit_form">
                                            <Row>
                                              <Col md={6}>
                                                <label>MF Investments Team Member</label>
                                              </Col>

                                              <Col md={6}>
                                                <select
                                                  className="w-75 m-0 fw-normal mb-1 pt-0 pb-0 pl-0"
                                                  // name="fullLegalName"
                                                  onChange={(e) => {
                                                    // urChangepopup();
                                                    // alert(e.target.value);
                                                    setAssignur(e.target.value);
                                                  }}
                                                  value={assignur}
                                                >
                                                  {geturs &&
                                                    geturs.map((geturdata) => {
                                                      return (
                                                        <>
                                                          <option>{geturdata.fullLegalName}</option>
                                                        </>
                                                      );
                                                    })}
                                                </select>
                                              </Col>
                                            </Row>

                                            <Row className="UpdateBtn">
                                              <Col md={6}>
                                                <div onClick={togglsUr2} className="cancelBTN">
                                                  Cancel
                                                </div>
                                              </Col>

                                              <Col md={6}>
                                                <button
                                                  onClick={() => {
                                                    // changeStage2(userr.id, 'main');
                                                    updateaccount(userr.id);
                                                    // urPopup(userr.id);
                                                  }}
                                                >
                                                  Yes, Update Now
                                                </button>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Box>
                                      </Modal>
                                    </div>
                                  </>
                                );
                              })
                          );
                        })}
                    </div>

                    <div className="app_view_box app_view_box5">
                      <h4>Application Status</h4>
                      <>
                        {userCompany &&
                          userCompany.map((usert) => {
                            return (
                              usert.personal &&
                              usert.personal
                                .filter(
                                  (userr) => userr.isPrimary === 1 && userr.isPrimary !== null
                                )

                                .map((userr) => {
                                  return (
                                    <>
                                      <div className="row responsive__align">
                                        <div className="col-md-3 col-sm-3 col-3 ">
                                          <label>Stage: </label>
                                        </div>

                                        <div className="col-md-9 col-sm-9 col-9">
                                          <div className="row align-items-center justify-content-between">
                                            {/* <div className="col-lg-auto col-sm-6 col-7 pl-sm-3">
               
                                              <p>{userr ? userr.overAllApplicationsStage : ''}</p>
                                            </div> */}
                                            <div className="col-lg-auto col-sm-6 col-6 pl-sm-3">
                                              {userr &&
                                                userr.overAllApplicationsStage == 'Submitted' &&
                                                userr.overAllAmlStatus == 1 ? (
                                                <p>Submitted - Passed AML</p>
                                              ) : userr &&
                                                userr.overAllApplicationsStage == 'Submitted' &&
                                                userr.overAllAmlStatus == 0 ? (
                                                <p>Submitted - Pending AML</p>
                                              ) : (
                                                <p>{userr ? userr.overAllApplicationsStage : ''}</p>
                                              )}

                                              {/* <p>{user1 ? user1.overAllApplicationsStage : ''}</p> */}
                                            </div>

                                            <div className="col-lg-auto  col-sm-6  col-6 ">
                                              {/* {user.profile.includes("UR2") ||
                                              user.profile.includes("UR4") ||
                                              user.profile.includes("UR5") ||
                                              (userr &&
                                                userr.overAllApplicationStage == 'Completed') ||
                                              (userr &&
                                                userr.overAllApplicationStage == 'Approved') ||
                                              (userr &&
                                                userr.overAllApplicationStage == 'Active Client') ||
                                              userr.applicationStage == 'In Progress' ? (
                                                ''
                                              ) : (
                                                <button className="update_stag" onClick={toggles1}>
                                                  Update Stage
                                                </button>
                                              )} */}
                                              {(user.profile.includes("UR2") || user.profile.includes("UR1")) &&
                                                userr &&
                                                userr.overAllApplicationsStage == 'Submitted' ? (
                                                // && user1.overAllAmlStatus == 1
                                                <button className="update_stag" onClick={toggles1}>
                                                  Update Stage
                                                </button>
                                              ) : (
                                                ''
                                              )}
                                              <Modal
                                                isOpen={modals1}
                                                show={show12}
                                                className={className}
                                              >
                                                <Box className="formPopUp">
                                                  <h4>Update Application Stage</h4>
                                                  <div className="UpdateApplication edit_form">
                                                    <Row>
                                                      <Col md={6}>
                                                        <label>Current Application Stage</label>
                                                      </Col>

                                                      <Col md={6}>
                                                        {userr &&
                                                          userr.overAllApplicationsStage ==
                                                          'Submitted' &&
                                                          userr.overAllAmlStatus == 1 ? (
                                                          <p>Submitted - Passed AML</p>
                                                        ) : userr &&
                                                          userr.overAllApplicationsStage ==
                                                          'Submitted' &&
                                                          userr.overAllAmlStatus == 0 ? (
                                                          <p>Submitted - Pending AML</p>
                                                        ) : (
                                                          <p>
                                                            {userr
                                                              ? userr.overAllApplicationsStage
                                                              : ''}
                                                          </p>
                                                        )}
                                                      </Col>
                                                    </Row>

                                                    <Row>
                                                      <Col md={6}>
                                                        <label>Update Stage</label>
                                                      </Col>

                                                      <Col md={6}>
                                                        <select
                                                          id={`StageChange${userr.id}`}
                                                          onChange={(e) =>
                                                            UpdateApplicationStage(e, userr.id)
                                                          }

                                                        >
                                                          {userr &&
                                                            userr.overAllApplicationsStage ==
                                                            'Submitted' &&
                                                            userr.overAllAmlStatus == 0 ? (
                                                            <option
                                                              class="optionStage"
                                                              value="In Progress"
                                                            >
                                                              {' '}
                                                              In Progress
                                                            </option>
                                                          ) : (
                                                            <>
                                                              <option
                                                                class="optionStage"
                                                                value="In Progress"
                                                              >
                                                                {' '}
                                                                In Progress
                                                              </option>
                                                              <option
                                                                class="optionStage"
                                                                value="Approved"
                                                              // defaultSelected
                                                              // Selected="Approved"
                                                              >
                                                                {' '}
                                                                Submitted - Approved
                                                              </option>
                                                            </>
                                                          )}
                                                        </select>
                                                      </Col>
                                                    </Row>

                                                    <Row className="UpdateBtn">
                                                      <Col md={6}>
                                                        <div
                                                          onClick={toggles1}
                                                          className="cancelBTN"
                                                        >
                                                          Cancel
                                                        </div>
                                                      </Col>

                                                      <Col md={6}>
                                                        <button onClick={() => changeStage(userr.id, "first")}>
                                                          Save Changes
                                                        </button>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Box>
                                              </Modal>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row responsive__align">
                                        <div className="col-md-3 col-sm-3 col-3">
                                          <label>
                                            <span className="text_upercas">AML</span>:
                                          </label>
                                        </div>
                                        <div className="col-md-9 col-sm-9 col-9">
                                          <p>
                                            {userr && userr.overAllAmlStatus === 1 ? (
                                              <p className="aml_pass TextIndent">Pass</p>
                                            ) : (
                                              <p className="aml_fail TextIndent">Fail</p>
                                            )}
                                          </p>
                                        </div>
                                      </div>

                                      {user.profile.includes("UR1") ||
                                        user.profile == 'UR8' ||
                                        user.profile.includes("UR2") ||
                                        user.profile == 'UR9' ? (
                                        userr && userr.applicationStage === 'Active Client' ? (
                                          <>
                                            <div className="row responsive__align main_wrapper_date">
                                              <div className="col-md-5 col-12">
                                                <label>Date Payment Received:</label>
                                              </div>
                                              <div className="col-md-5 col-12">
                                                <div className="fullinput">
                                                  <p>
                                                    {userr != null &&
                                                      userr != '' &&
                                                      userr != 'undefined'
                                                      ? userr.paymentReceived
                                                      : ''}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          ''
                                        )
                                      ) : null}
                                      {userr &&
                                        (userr.applicationStage === 'Awaiting Payment' ||
                                          userr.applicationStage === 'Active Client') ? (
                                        <>
                                          {user.profile.includes("UR4") ? (
                                            < div className="row responsive__align" >
                                              <div className="col-md-5 main_wrapper_daten col-12">
                                                <label>Date Payment Received:</label>
                                              </div>
                                              {(userr &&
                                                userr.applicationStage === 'Awaiting Payment' &&
                                                userr.paymentReceived == null) ||
                                                userr.paymentReceived == '' ? (
                                                <div className="col-md-5 col-8 date_style">
                                                  <div className="fullinput">
                                                    <SemanticDatepicker
                                                      className="date12 counrtyselext6 numbers input__w50_a"
                                                      id="date1"
                                                      maxDate={
                                                        new Date(moment().subtract(0, 'years'))
                                                      }
                                                      format="DD/MM/YYYY"
                                                      name="date1"
                                                      // value={userr.paymentReceived}
                                                      allowOnlyNumbers
                                                      onChange={(e) => {
                                                        datechange();
                                                      }}
                                                    // v alue={paymentReceived}
                                                    />
                                                  </div>

                                                  {isLoaderpaymentReceived === true ? (
                                                    <div class="col-md-4">
                                                      {' '}
                                                      <div class="main_date_loader">
                                                        <div className="loader">
                                                          <p class="loader-inner"></p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : (
                                                <>
                                                  <div className="main_date_stage date_style negavtive-text-indent">
                                                    <SemanticDatepicker
                                                      className="counrtyselext6 numbers input__w50_a resp"
                                                      id="date1"
                                                      // minDate={now()}
                                                      maxDate={
                                                        new Date(moment().subtract(0, 'years'))
                                                      }
                                                      format="DD/MM/YYYY"
                                                      name="date1"
                                                      value={
                                                        userr && userr.paymentReceived
                                                          ? new Date(
                                                            setDateformateToValid1(
                                                              userr.paymentReceived
                                                            )
                                                          )
                                                          : ''
                                                      }
                                                      allowOnlyNumbers
                                                      onChange={(e) => {
                                                        datechange();
                                                      }}
                                                    />

                                                    {isLoaderpaymentReceived === true ? (
                                                      <div class="col-md-4 col-4">
                                                        {' '}
                                                        <div class="main_date_loader">
                                                          <div className="loader">
                                                            <p class="loader-inner"></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          ) : null}

                                          {user.profile.includes("UR1") ||
                                            user.profile == 'UR8' ||
                                            user.profile.includes("UR2") ||
                                            user.profile == 'UR9' ? (
                                            <>
                                              <div className="row responsive__align main_wrapper_date">
                                                <div className="col-md-5 col-12">
                                                  <label>Date Payment Made to Du Val:</label>
                                                </div>
                                                <div className="col-md-5 col-12">
                                                  <div className="fullinput">
                                                    <p>
                                                      {userr != null &&
                                                        userr != '' &&
                                                        userr != 'undefined'
                                                        ? userr.paymentToDuval
                                                        : ''}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : null}

                                          {user.profile.includes("UR4") ? (
                                            < div >
                                              <div className="row responsive__align">
                                                <div className="col-md-5 col-12">
                                                  <label>Date Payment Made to Du Val:</label>
                                                </div>

                                                {userr &&
                                                  (userr.applicationStage === 'Awaiting Payment' ||
                                                    userr.applicationStage === 'Active Client') ? (
                                                  userr &&
                                                    userr.applicationStage === 'Awaiting Payment' &&
                                                    userr.paymentReceived == null ? (
                                                    <div className="col-md-5 col-8 date_style">
                                                      <div className="fullinput">
                                                        <SemanticDatepicker
                                                          className="counrtyselext6 numbers input__w50_a"
                                                          id="date2"
                                                          // minDate={now()}
                                                          maxDate={
                                                            new Date(moment().subtract(0, 'years'))
                                                          }
                                                          format="DD/MM/YYYY"
                                                          name="date2"
                                                          allowOnlyNumbers
                                                          onChange={(e) => {
                                                            datechange2();
                                                          }}
                                                        />
                                                      </div>

                                                      {isLoaderpaymentReceived1 === true ? (
                                                        <div class="col-md-4">
                                                          {' '}
                                                          <div class="main_date_loader_duval">
                                                            <div className="loader">
                                                              <p class="loader-inner"></p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  ) : (
                                                    <div className="main_date_stage date_lader date_style negavtive-text-indent">
                                                      <SemanticDatepicker
                                                        className="counrtyselext6 numbers input__w50_a"
                                                        id="date2"
                                                        // minDate={now()}
                                                        maxDate={
                                                          new Date(moment().subtract(0, 'years'))
                                                        }
                                                        format="DD/MM/YYYY"
                                                        name="date2"
                                                        value={
                                                          userr && userr.paymentToDuval
                                                            ? new Date(
                                                              setDateformateToValid2(
                                                                userr.paymentToDuval
                                                              )
                                                            )
                                                            : ''
                                                        }
                                                        allowOnlyNumbers
                                                        onChange={(e) => {
                                                          datechange2();
                                                        }}
                                                      />

                                                      {isLoaderpaymentReceived1 === true ? (
                                                        <div class="col-md-4">
                                                          {' '}
                                                          <div class="main_date_loader_duval">
                                                            <div className="loader">
                                                              <p class="loader-inner"></p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                    // <>{userr.paymentToDuval}</>
                                                  )
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}

                                      <div className="history_main_wrapper">
                                        <div className="history">
                                          <Link
                                            to={{
                                              pathname: `/History/${userr ? userr.id : ''}`
                                            }}
                                          >
                                            <i
                                              title="History"
                                              class="fa fa-history"
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                        </div>
                                      </div>
                                      {/* {(user.profile.includes("UR1") || user.profile.includes("UR2") || user.profile.includes("UR3") || user.profile.includes("UR4") ) ? (
                                        <>
                                          <div className="history_main_wrapper">
                                            <div className="history">
                                              <Link
                                                to={{
                                                  pathname: `/History/${userr ? userr.id : ''}`
                                                }}
                                              >
                                                <i
                                                  title="History"
                                                  class="fa fa-history"
                                                  aria-hidden="true"
                                                ></i>
                                              </Link>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )} */}
                                      {user.profile.includes("UR3") ? (
                                        < div className="SubscriptionAgree_btn responsive__SubscriptionAgreement" >
                                          <Link
                                            to={{
                                              pathname: `/SubscriptionAgreement/${userr ? userr.id : ''
                                                }`
                                            }}
                                            className="BITTON__sign_directore"
                                          >
                                            <>
                                              <div className="">
                                                <div className="red_green_text">
                                                  {directodata !== undefined &&
                                                    directodata !== 'undefined' &&
                                                    directodata.map((userrss) => {
                                                      return (
                                                        <>
                                                          {userrss.isLogin === true ? (
                                                            <>
                                                              {userrss.subscriptionSign != null &&
                                                                userrss.subscriptionSign != '' ? (
                                                                <>
                                                                  <div className="mobile_button d-block d-sm-none">
                                                                    <button className="Sign__agreement button__green">
                                                                      SIGN SUBSCRIPTION
                                                                    </button>
                                                                  </div>
                                                                  <div className="Sign__agreement_ICON d-none  d-sm-inline-block d-md-inline-block d-lg-inline-block destop__view">
                                                                    <p className="text__green">
                                                                      <i
                                                                        title="Sign all documents"
                                                                        class="fa fa-pencil-square-o text__green"
                                                                        aria-hidden="true"
                                                                      ></i>
                                                                    </p>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className="mobile_button d-block d-sm-none">
                                                                    <button className="Sign__agreement button__red">
                                                                      SIGN SUBSCRIPTION
                                                                    </button>
                                                                  </div>

                                                                  <div className="Sign__agreement_ICON d-none  d-sm-inline-block d-md-inline-block d-lg-inline-block">
                                                                    <div className="row">
                                                                      <div className="col-md-12">
                                                                        <div className="text_pending">
                                                                          <p className="text__red">
                                                                            <i
                                                                              title="Sign all documents"
                                                                              class="fa fa-pencil-square-o text__red"
                                                                              aria-hidden="true"
                                                                            ></i>
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      );
                                                    })}
                                                </div>
                                              </div>
                                            </>
                                          </Link>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                            );
                          })}
                      </>
                    </div>
                  </div>
                </div>
              </div>
              {/* Company Details */}

              {
                userCompany &&
                userCompany.map((usert) => {
                  return (
                    usert.personal &&
                    usert.personal.map((userr, index) => {
                      return (
                        <>
                          <>
                            {usert.companyDetails != null && index == 0 ? (
                              <>
                                <CompanyDetails data={usert} bank={userr && userr} certificate={userr && userr} />
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        </>
                      )
                    })

                  );
                })
              }
              {
                userCompany &&
                userCompany.map((usert) => {
                  return (
                    usert.personal &&
                    usert.personal.map((userr, ind) => {

                      return (
                        <>
                          {usert.trustDetails != null && ind == 0 ? (
                            <>
                              <TrustDetails data={usert} bank={userr && userr} certificate={userr && userr} />
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      )
                    })

                  );
                })
              }
              {/* Application Details */}
              <div className="app_view_box">
                <div className="app_view">
                  <h4 className="">Application Details</h4>

                  {userCompany &&
                    userCompany.map((usert, i) => {
                      return (
                        usert.personal &&
                        usert.personal.map((userr, indexx) => {
                          // usert.personal[i].applicationStage

                          function addClass() {
                            var element = document.getElementById(`modals${userr.id}`);
                            element.classList.add('modalsClass');
                          }
                          return (
                            <div className="view_tabs ">
                              <div className="primary_text flex-wrap">
                                <div className="col-12 col-sm-12 col-md-4">{userr.fullLegalName}</div>

                                {/* <div className="col-12 col-sm-12 col-md-8 ">
                                  <div className='d-flex align-items-center flex-wrap justify-content-between'>
                                    {indexx == 0 ? "" : <span className="stage">
                                      {userr &&
                                        userr.applicationStage == 'Submitted' &&
                                        userr.amlStatus == 1 ? (
                                        <p className="mb-0">Submitted - Passed AML</p>
                                      ) : userr &&
                                        userr.applicationStage == 'Submitted' &&
                                        userr.amlStatus == 0 ? (
                                        <p className="mb-0">Submitted - Pending AML</p>
                                      ) : (
                                        <p>{userr ? userr.applicationStage : ''}</p>
                                      )}
                                    </span>}

                                    {(user.profile.includes("UR2") || user.profile.includes("UR1")) &&
                                      userr && indexx != 0 &&
                                      userr.applicationStage == 'Submitted' ? (
                                      <button
                                        className="update_stag update_status mr-2"
                                        onClick={() => addClass(userr.id)}
                                      >
                                        Update Status
                                      </button>
                                    ) : (
                                      ''
                                    )}
                                   
                                    <div className="note-footer flex-note overAllAmlStatus m-0">
                                      {ziploader === true ? (
                                        <div className="note-main-loader">
                                          <div className="loader zip_exp_loader"></div>
                                        </div>
                                      ) : userr && userr.overAllAmlStatus === 1 && userr.applicationStage == "Submitted" ? (
                                        <button
                                          className="save zipexport"
                                          onClick={() => getZipData(userr.id, userr.zipUrl)}
                                        >
                                          Zip Export
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>

                                  <div className="custom_modal">
                                    <Box className="formPopUp1 modall popup" id={`modals${userr.id}`}>
                                      <h4>Update Application Status</h4>
                                      <div className="UpdateApplication edit_form">
                                        <Row>
                                          <Col md={6}>
                                            <label>Current Application Status</label>
                                          </Col>

                                          <Col md={6}>
                                            <p>
                                              {' '}
                                              {userr &&
                                                userr.applicationStage == 'Submitted' &&
                                                userr.overAllAmlStatus == 1 ? (
                                                <p>Submitted - Passed AML</p>
                                              ) : userr &&
                                                userr.applicationStage == 'Submitted' &&
                                                userr.overAllAmlStatus == 0 ? (
                                                <p>Submitted - Pending AML</p>
                                              ) : (
                                                <p>{userr ? userr.applicationStage : ''}</p>
                                              )}
                                            </p>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col md={6}>
                                            <label>Update Status</label>
                                          </Col>

                                          <Col md={6}>
                                            <select
                                              id={`StageChange${userr.id}`}
                                              onChange={(e) => {
                                                UpdateApplicationStage(e, userr.id);
                                                // setUpdateStage(e);
                                              }}
                                              defaultValue={
                                                user.profile == 'UR8'
                                                  ? 'Completed'
                                                  : (user.profile.includes("UR2")) == 'Approved'
                                              }
                                            >
                                              {userr &&
                                                userr.applicationStage == 'Submitted' &&
                                                userr.overAllAmlStatus == 1 ? (
                                                <option class="optionStage" value="In Progress">
                                                  {' '}
                                                  In Progress
                                                </option>
                                              ) : (
                                                <>
                                                  <option class="optionStage" value="In Progress">
                                                    {' '}
                                                    In Progress
                                                  </option>
                                                  <option
                                                    class="optionStage"
                                                    value="Approved"
                                                    defaultSelected
                                                    Selected="Approved"
                                                  >
                                                    {' '}
                                                    Submitted - Approved
                                                  </option>
                                                </>
                                              )}
                                            </select>
                                          </Col>
                                        </Row>

                                        <Row className="UpdateBtn">
                                          <Col md={6}>
                                            <div
                                              onClick={() => {
                                                var element = document.getElementById(
                                                  `modals${userr.id}`
                                                );
                                                element.classList.remove('modalsClass');
                                              }}
                                              className="cancelBTN"
                                            >
                                              Cancel
                                            </div>
                                          </Col>

                                          <Col md={6}>
                                            <button
                                              onClick={() => {
                                                changeStage(userr.id, 'second');
                                                var element = document.getElementById(
                                                  `modals${userr.id}`
                                                );
                                                element.classList.remove('modalsClass');
                                              }}
                                            >
                                              Save Changes
                                            </button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Box>
                                  </div>
                                </div> */}
                              </div>
                              <div className='show_responsive'>
                                <Accordion defaultActiveKey="9">
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>Personal Information </Accordion.Header>
                                    <Accordion.Body>
                                      <PersonalInformation data={userr && userr} />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>ID Verification </Accordion.Header>
                                    <Accordion.Body>
                                      <IdVerification data={userr && userr} />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                    <Accordion.Header>Terms & Conditions</Accordion.Header>
                                    <Accordion.Body>
                                      <TermsCondition data={userr && userr} />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                              <div className='view_tabs responsive_hide'>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                                  <Row>
                                    <Col sm={5} md={3} className="border-right">
                                      <Nav variant="pills" className="flex-column">
                                        {/* <Nav.Item>
                                        <Nav.Link eventKey="first">Investment Details</Nav.Link>
                                      </Nav.Item> */}

                                        <Nav.Item>
                                          <Nav.Link eventKey="second">Personal Information</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                          <Nav.Link eventKey="forth">ID Verification</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                        <Nav.Link eventKey="five">Bank Account</Nav.Link>
                                      </Nav.Item> */}
                                        {/* <Nav.Item>
                                        <Nav.Link eventKey="six">Wholesale Certification</Nav.Link>
                                      </Nav.Item> */}
                                        <Nav.Item>
                                          <Nav.Link eventKey="seven">Terms & Conditions</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Col sm={7} md={9} className="formPopUp">
                                      <Tab.Content>
                                        <Tab.Pane eventKey="second">
                                          <PersonalInformation data={userr && userr} />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="third">
                                          <div className="row">
                                            <div className="col-md-11">
                                              <div className="row">
                                                <div className="col-md-6 col-sm-6">
                                                  {userr && userr.investmentType == 'Joint' ? (
                                                    <label>
                                                      Full Name of 1st Joint Account Holder
                                                    </label>
                                                  ) : (
                                                    <label>full legal name</label>
                                                  )}
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                  <p>{userr ? userr.fullLegalName : ''}</p>
                                                </div>
                                              </div>
                                              {userr && userr.investmentType == 'Joint' ? (
                                                <div className="row">
                                                  <div className="col-md-6 col-sm-6">
                                                    <label>
                                                      Share of Income Attributed to 1<sup>st</sup>{' '}
                                                      Joint Account Holder
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6">
                                                    <p>{userr ? userr.primaryShareOfIncome : ''}</p>
                                                  </div>
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </div>

                                            <div className="col-md-12 edit_icon PersonalInformationEdit">
                                              {
                                                user.profile.includes("UR2") ||
                                                  (userr && userr.applicationStage == 'Active Client') ||
                                                  user.profile.includes("UR3") || user.profile.includes("UR4") ? (
                                                  ''
                                                ) : (
                                                  <i
                                                    onClick={handleOpen}
                                                    title="Edit"
                                                    class="fa fa-pencil-square-o hover__Edit"
                                                    aria-hidden="true"
                                                  ></i>
                                                )}
                                            </div>
                                          </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="forth">
                                          <IdVerification data={userr && userr} />
                                        </Tab.Pane>

                                        {/* <Tab.Pane eventKey="five">
                                        <BankAccount data={userr && userr} />
                                      </Tab.Pane> */}

                                        {/* <Tab.Pane eventKey="six">
                                        <WholesaleCertificate data={userr && userr} />
                                      </Tab.Pane> */}

                                        <Tab.Pane eventKey="seven">
                                          <TermsCondition data={userr && userr} />

                                          {/* <div className="row">
                                          <div className="col-md-11">
                                            <div className="row">
                                              <div className="col-md-6 col-sm-auto">
                                                <label>
                                                  Receive Future Emails About MyFarm Investment
                                                  Opportunities{' '}
                                                </label>
                                              </div>
                                              <div className="col-md-6 col-sm-auto">
                                                <p>{userr ? userr.fullLegalName : ''}</p>
                                              </div>
                                            </div>

                                            <div className="row">
                                             
                                              <div className="col-md-6 col-sm-auto">
                                                <p>
                                                  {userr &&
                                                  userr.certificateDetails !== null &&
                                                  userr.certificateDetails.subscriptionOfficeHeld
                                                    ? userr.certificateDetails
                                                        .subscriptionOfficeHeld
                                                    : ''}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="row signature_img">
                                              <div className="col-md-6 col-sm-auto">
                                                <label>ADHERENCE AGREEMENT Signature</label>
                                              </div>
                                              <div className="col-md-6">
                                                <p>
                                                  <img
                                                    src={
                                                      userr &&
                                                      userr.certificateDetails !== null &&
                                                      userr.certificateDetails.adherenceSign
                                                        ? userr.certificateDetails.adherenceSign
                                                        : ''
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>

                                            <div className="row">
                                              
                                              <div className="col-md-6 col-sm-auto">
                                                <p>
                                                  {userr &&
                                                  userr.certificateDetails !== null &&
                                                  userr.certificateDetails.adherenceOfficeHeld
                                                    ? userr.certificateDetails.adherenceOfficeHeld
                                                    : ''}
                                                </p>
                                              </div>
                                            </div>

                                            {(user.profile.includes("UR1") ||
                                              user.profile.includes("UR2") ||
                                              user.profile.includes("UR4") ||
                                              user.profile == 'UR8') &&
                                            userr &&
                                            (userr.applicationStage == 'Awaiting Payment' ||
                                              userr.applicationStage == 'Active Client' ||
                                              userr.applicationStage ==
                                                'Awaiting Final Approval') ? (
                                              <div className="main_wrappr_agrementspdf">
                                                <div className="agrementspdf__wrapper history__pdfr">
                                                  <span
                                                    className="agrementspdf"
                                                    onClick={agrementpdf}
                                                  >
                                                    <button class="mb-bottom Subscription_button">
                                                      Generate Subscription/Adherence PDF Agreements
                                                    </button>
                                                  </span>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>

                                          <div className="col-md-1 edit_icon">
                                            <Modal
                                              open={open}
                                              onClose={handleClose}
                                              aria-labelledby="parent-modal-title"
                                              aria-describedby="parent-modal-description"
                                            >
                                              <Box className="formPopUp">
                                                <h4>Terms & Agreements Edit</h4>

                                                <div className="edit_form">
                                                  <div className="form_input fullinput">
                                                    <label>full name of authorised person</label>
                                                    <input
                                                      type="text"
                                                      placeholder="Enter full name of authorised person"
                                                    />
                                                   
                                                  </div>

                                                  <div className="form_input fullinput">
                                                    <label>Office held</label>
                                                    <input
                                                      type="text"
                                                      placeholder="Enter Office held"
                                                    />
                                                  </div>

                                                  <div className="form_input fullinput">
                                                    <label>Adherence agreement proof</label>
                                                    <input
                                                      type="text"
                                                      placeholder="Enter Adherence agreement proof"
                                                    />
                                                  </div>

                                                  <div className="form_input fullinput">
                                                    <label>Subscription agreement proof</label>
                                                    <input
                                                      type="text"
                                                      placeholder="Enter Subscription agreement proof"
                                                    />
                                                  </div>

                                                  <div className="submit_btn">
                                                    <button>Submit</button>
                                                  </div>
                                                </div>
                                              </Box>
                                            </Modal>
                                          </div>
                                        </div> */}
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>

                            </div>
                          );
                        })
                      );
                    })}
                </div>
              </div>

              {/* AML DETAILS */}

              <div className="app_view_box  responsive_for_destop">
                <div className="app_view">
                  <h4>
                    <span className="text_upercas">AML</span> Details
                  </h4>
                </div>

                {userCompany &&
                  userCompany.map((usert) => {
                    return (
                      usert.personal &&
                      usert.personal.map((userr) => {
                        return (
                          <>
                            <div>
                              {' '}
                              <div className="primary_text">
                                <div>{userr.fullLegalName}</div>

                                <div className="aml_Details">
                                  {userr.amlStatus == 1 ? (
                                    <div className="aml_pass">AML PASS</div>
                                  ) : (
                                    <div className="aml_fail">AML FAIL</div>
                                  )}
                                </div>
                              </div>

                              <div className='show_responsive'>
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>Verification Type </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="row">
                                        <div className="col-md-11">
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>Verification Type</label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <p>
                                                {userr
                                                  ? userr.identificationType == 'Biometric'
                                                    ? 'Biometrics'
                                                    : 'Certified Copy of ID'
                                                  : ''}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>Name & ID </Accordion.Header>
                                    <Accordion.Body>
                                      <NameIdAml
                                        data={userr && userr}
                                        getAPI={applicationTrust}
                                        setLoader={setLoader}
                                      />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>Address </Accordion.Header>
                                    <Accordion.Body>
                                      {userr ? (

                                        <div className="row">
                                          <div className="col-md-11">
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>
                                                  <span className="text_upercas">AML</span>{' '}
                                                  Address{' '}
                                                </label>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-6">
                                                {userr.addressCheck == 1 ? (
                                                  <>
                                                    <div className="aml_pass">
                                                      ADDRESS AML PASS
                                                    </div>
                                                  </>
                                                ) : (
                                                  <div className="aml_fail">
                                                    ADDRESS AML FAIL
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>Address </label>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <p>{userr.userResidentialAddress}</p>
                                              </div>

                                              {userr &&
                                                userr.addressDetails != null &&
                                                userr.addressProof != null &&
                                                userr &&
                                                userr.addressProof != '' ? (
                                                <>
                                                  {' '}
                                                  <div className="col-md-6 col-sm-6 col-6">
                                                    <label> Manual Approval</label>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-6">
                                                    <a href={userr.addressProof} className="">
                                                      <div className="main-downloade-wrapper">
                                                        {userr &&
                                                          userr.addressDetails != null &&
                                                          userr.addressProof !== null &&
                                                          userr.addressProof !== '' &&
                                                          userr.addressProof &&
                                                          'undefined' &&
                                                          userr.addressProof !== undefined ? (
                                                          <p className="downloadeMsg">
                                                            <div className="downloads">
                                                              <i className="fa fa-download downloadeStyle"></i>
                                                            </div>{' '}
                                                            <p className="View__file">
                                                              VIEW FILE
                                                            </p>{' '}
                                                          </p>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </div>
                                                    </a>

                                                    <a href={documentFile6422}>
                                                      <img src={documentFile6422} />
                                                    </a>
                                                  </div>
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-12 edit_icon iconn">
                                            {
                                              (userr.applicationStage == 'Submitted' &&
                                                userr.addressCheck != 1) &&

                                                (user.profile.includes("UR1") ||
                                                  user.profile.includes("UR2")) ? (
                                                <>
                                                  <ModalPopup
                                                    iid={userr.id}
                                                    userr={userr}
                                                    addresss={addresCheck1}
                                                    getAPI={applicationTrust}
                                                    setLoader={setLoader}
                                                  />
                                                </>
                                              ) : (
                                                ""
                                              )}
                                          </div>
                                        </div>

                                      ) : (
                                        ''
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                    <Accordion.Header>PEP </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="row">
                                        <div className="col-md-11">
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>
                                                <span className="text_upercas">PEP</span>{' '}
                                              </label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              {userr.pepCheck == 1 ? (
                                                <div className="aml_pass">PEP PASS</div>
                                              ) : (
                                                <div className="aml_fail">PEP FAIL</div>
                                              )}
                                            </div>

                                            {userr &&
                                              userr.isManualPep != 0 &&
                                              userr &&
                                              userr.isManualPep != '' ? (
                                              <>
                                                <div className="col-md-6 col-sm-6 col-6">
                                                  <label>Manual Approval</label>
                                                </div>
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-12 edit_icon iconn">
                                          {
                                            (user.profile.includes("UR1") || user.profile.includes("UR2")) &&
                                              (userr.applicationstage == 'Submitted' &&
                                                userr.pepCheck != 1)
                                              ? (
                                                <>
                                                  <ModalPopup
                                                    iid={userr.id}
                                                    userr={userr}
                                                    pepAml={pepAml}
                                                    getAPI={applicationTrust}
                                                    setLoader={setLoader}
                                                  />
                                                </>
                                              ) : (
                                                ""
                                              )}
                                        </div>
                                      </div>
                                    </Accordion.Body>

                                  </Accordion.Item>
                                  {userr &&
                                    userr.identificationType != null &&
                                    userr &&
                                    userr.identificationType != undefined ? (
                                    <>
                                      {userr && userr.identificationType == 'document' ? (
                                        <Accordion.Item eventKey="4">
                                          <Accordion.Header>Certified Copy of ID </Accordion.Header>
                                          <Accordion.Body>

                                            <div className="row">
                                              <div className="col-md-11">
                                                <div className="row">
                                                  <div className="col-md-6 col-sm-6 col-6">
                                                    <label>
                                                      <span className="text_upercas">AML</span>{' '}
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-6">
                                                    <p>
                                                      {userr.idCheck === 1 ? (
                                                        <div className="aml_pass">ID AML PASS</div>
                                                      ) : (
                                                        <div className="aml_fail">ID AML FAIL</div>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                          </Accordion.Body>
                                        </Accordion.Item>
                                      ) : (
                                        <Accordion.Item eventKey="4">
                                          <Accordion.Header>Biometric </Accordion.Header>
                                          <Accordion.Body>

                                            <div className="row">
                                              <div className="col-md-11">
                                                <div className="row">
                                                  <div className="col-md-6 col-sm-6 col-6">
                                                    <label>
                                                      <span className="text_upercas">AML</span>{' '}
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-6">
                                                    <p>
                                                      {userr.liveCheck === 1 ? (
                                                        <div className="aml_pass">
                                                          AML Biometrics Pass
                                                        </div>
                                                      ) : (
                                                        <div className="aml_fail">
                                                          AML Biometrics Fail
                                                        </div>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                          </Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    </>
                                  ) : (
                                    ''
                                  )}

                                  <Accordion.Item eventKey="5">
                                    <Accordion.Header>AML Result Documents </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="row">
                                        <div className="col-md-11">
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>
                                                <span className="text_upercas">Identity</span>{' '}
                                                Result Document{' '}
                                              </label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <div className="document_download">
                                                <a
                                                  href={
                                                    userr &&
                                                      userr.identityResult !== null &&
                                                      userr.identityResult !== '' &&
                                                      userr.identityResult &&
                                                      'undefined' &&
                                                      userr.identityResult !== undefined
                                                      ? userr.identityResult
                                                      : ''
                                                  }
                                                  className="licedocument-downloade"
                                                >
                                                  {' '}
                                                  <div className="main-downloade-wrapper">
                                                    {userr &&
                                                      userr.identityResult !== null &&
                                                      userr.identityResult !== '' &&
                                                      userr.identityResult &&
                                                      'undefined' &&
                                                      userr.identityResult !== undefined ? (
                                                      <p className="downloadeMsg">
                                                        <div className="downloads">
                                                          <i className="fa fa-download downloadeStyle"></i>
                                                        </div>{' '}
                                                        VIEW FILE{' '}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                </a>

                                                <span id="name_tag" class="preview2"></span>
                                              </div>
                                            </div>
                                          </div>

                                          {userr.identificationType == 'Biometric' ||
                                            (userr &&
                                              userr.liveResult !== null &&
                                              userr.liveResult !== '' &&
                                              userr.liveResult &&
                                              'undefined' &&
                                              userr.liveResult !== undefined) ? (
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>
                                                  <span className="text_upercas">
                                                    Biometrics
                                                  </span>{' '}
                                                  Result Document{' '}
                                                </label>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <div className="document_download">
                                                  <a
                                                    href={
                                                      userr &&
                                                        userr.liveResult !== null &&
                                                        userr.liveResult !== '' &&
                                                        userr.liveResult &&
                                                        'undefined' &&
                                                        userr.liveResult !== undefined
                                                        ? userr.liveResult
                                                        : ''
                                                    }
                                                    className="licedocument-downloade"
                                                  >
                                                    {' '}
                                                    <div className="main-downloade-wrapper">
                                                      {userr &&
                                                        userr.liveResult !== null &&
                                                        userr.liveResult !== '' &&
                                                        userr.liveResult &&
                                                        'undefined' &&
                                                        userr.liveResult !== undefined ? (
                                                        <p className="downloadeMsg">
                                                          <div className="downloads">
                                                            <i className="fa fa-download downloadeStyle"></i>
                                                          </div>{' '}
                                                          VIEW FILE{' '}
                                                        </p>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </div>
                                                  </a>

                                                  <span id="name_tag" class="preview2"></span>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            ''
                                          )}

                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>Address result document </label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6 document_download">
                                              <a
                                                href={
                                                  userr &&
                                                    userr.addressResult !== null &&
                                                    userr.addressResult !== '' &&
                                                    userr.addressResult &&
                                                    'undefined' &&
                                                    userr.addressResult !== undefined
                                                    ? userr.addressResult
                                                    : ''
                                                }
                                                className="licedocument-downloade"
                                              >
                                                {' '}
                                                <div className="main-downloade-wrapper">
                                                  {userr &&
                                                    userr.addressResult !== null &&
                                                    userr.addressResult !== '' &&
                                                    userr.addressResult &&
                                                    'undefined' &&
                                                    userr.addressResult !== undefined ? (
                                                    <p className="downloadeMsg">
                                                      <div className="downloads">
                                                        <i className="fa fa-download downloadeStyle"></i>
                                                      </div>{' '}
                                                      VIEW FILE{' '}
                                                    </p>
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                              <div className="view_tabs responsive_hide">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                  <Row>
                                    <Col sm={3} className="border-right">
                                      <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                          <Nav.Link eventKey="first">Verification Type</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="second">Name & ID</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="third">Address</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="forth">PEP</Nav.Link>
                                        </Nav.Item>
                                        {userr &&
                                          userr.identificationType != null &&
                                          userr &&
                                          userr.identificationType != undefined ? (
                                          <>
                                            {userr && userr.identificationType == 'document' ? (
                                              <Nav.Item>
                                                <Nav.Link eventKey="five">
                                                  Certified Copy of ID
                                                </Nav.Link>
                                              </Nav.Item>
                                            ) : (
                                              <Nav.Item>
                                                <Nav.Link eventKey="five">Biometrics</Nav.Link>
                                              </Nav.Item>
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}

                                        <Nav.Item>
                                          <Nav.Link eventKey="six">AML Result Documents</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>

                                    <Col sm={9} className="formPopUp">
                                      <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                          <div className="row">
                                            <div className="col-md-11">
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <label>Verification Type</label>
                                                </div>
                                                <div className="col-md-6">
                                                  <p>
                                                    {userr
                                                      ? userr.identificationType == 'Biometric'
                                                        ? 'Biometrics'
                                                        : 'Certified Copy of ID'
                                                      : ''}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="second">
                                          <NameIdAml
                                            data={userr && userr}
                                            getAPI={applicationTrust}
                                            setLoader={setLoader}
                                          />

                                        </Tab.Pane>
                                        {userr ? (
                                          <Tab.Pane eventKey="third">
                                            <div className="row">
                                              <div className="col-md-11">
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <label>
                                                      <span className="text_upercas">AML</span>{' '}
                                                      Address{' '}
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6">
                                                    {userr.addressCheck == 1 ? (
                                                      <>
                                                        <div className="aml_pass">
                                                          ADDRESS AML PASS
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <div className="aml_fail">
                                                        ADDRESS AML FAIL
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <label>Address </label>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <p>{userr.userResidentialAddress}</p>
                                                  </div>

                                                  {userr &&
                                                    userr.addressDetails != null &&
                                                    userr.addressProof != null &&
                                                    userr &&
                                                    userr.addressProof != '' ? (
                                                    <>
                                                      {' '}
                                                      <div className="col-md-6">
                                                        <label> Manual Approval</label>
                                                      </div>
                                                      <div className="col-md-6">
                                                        <a href={userr.addressProof} className="">
                                                          <div className="main-downloade-wrapper">
                                                            {userr &&
                                                              userr.addressDetails != null &&
                                                              userr.addressProof !== null &&
                                                              userr.addressProof !== '' &&
                                                              userr.addressProof &&
                                                              'undefined' &&
                                                              userr.addressProof !== undefined ? (
                                                              <p className="downloadeMsg">
                                                                <div className="downloads">
                                                                  <i className="fa fa-download downloadeStyle"></i>
                                                                </div>{' '}
                                                                <p className="View__file">
                                                                  VIEW FILE
                                                                </p>{' '}
                                                              </p>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </div>
                                                        </a>

                                                        <a href={documentFile6422}>
                                                          <img src={documentFile6422} />
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              </div>
                                              <div className="col-md-12 edit_icon iconn">
                                                {
                                                  (userr.applicationStage == 'Submitted' &&
                                                    userr.addressCheck != 1) &&
                                                    (user.profile.includes("UR1") ||
                                                      user.profile.includes("UR2")) ? (
                                                    <>
                                                      <ModalPopup
                                                        iid={userr.id}
                                                        userr={userr}
                                                        addresss={addresCheck1}
                                                        getAPI={applicationTrust}
                                                        setLoader={setLoader}
                                                      />
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                              </div>
                                            </div>
                                          </Tab.Pane>
                                        ) : (
                                          ''
                                        )}

                                        <Tab.Pane eventKey="forth">
                                          <div className="row">
                                            <div className="col-md-11">
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <label>
                                                    <span className="text_upercas">PEP</span>{' '}
                                                  </label>
                                                </div>
                                                <div className="col-md-6">
                                                  {userr.pepCheck == 1 ? (
                                                    <div className="aml_pass">PEP PASS</div>
                                                  ) : (
                                                    <div className="aml_fail">PEP FAIL</div>
                                                  )}
                                                </div>

                                                {userr &&
                                                  userr.isManualPep != 0 &&
                                                  userr &&
                                                  userr.isManualPep != '' ? (
                                                  <>
                                                    <div className="col-md-6">
                                                      <label>Manual Approval</label>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </div>
                                            <div className="col-md-12 edit_icon iconn">
                                              {(user.profile.includes("UR1") ||

                                                user.profile.includes("UR2")) &&
                                                (userr.applicationStage == 'Submitted' &&
                                                  userr.pepCheck != 1)
                                                ? (
                                                  <>
                                                    <ModalPopup
                                                      iid={userr.id}
                                                      userr={userr}
                                                      pepAml={pepAml}
                                                      getAPI={applicationTrust}
                                                      setLoader={setLoader}
                                                    />
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                            </div>
                                          </div>
                                        </Tab.Pane>

                                        {userr && userr.identificationType == 'document' ? (
                                          <Tab.Pane eventKey="five">
                                            <div className="row">
                                              <div className="col-md-11">
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <label>
                                                      <span className="text_upercas">AML</span>{' '}
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <p>
                                                      {userr.idCheck === 1 ? (
                                                        <div className="aml_pass">ID AML PASS</div>
                                                      ) : (
                                                        <div className="aml_fail">ID AML FAIL</div>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Tab.Pane>
                                        ) : (
                                          <Tab.Pane eventKey="five">
                                            <div className="row">
                                              <div className="col-md-11">
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <label>
                                                      <span className="text_upercas">AML</span>{' '}
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <p>
                                                      {userr.liveCheck === 1 ? (
                                                        <div className="aml_pass">
                                                          AML Biometrics Pass
                                                        </div>
                                                      ) : (
                                                        <div className="aml_fail">
                                                          AML Biometrics Fail
                                                        </div>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Tab.Pane>
                                        )}

                                        <Tab.Pane eventKey="six">
                                          <div className="row">
                                            <div className="col-md-11">
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <label>
                                                    <span className="text_upercas">Identity</span>{' '}
                                                    Result Document{' '}
                                                  </label>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="document_download">
                                                    <a
                                                      href={
                                                        userr &&
                                                          userr.identityResult !== null &&
                                                          userr.identityResult !== '' &&
                                                          userr.identityResult &&
                                                          'undefined' &&
                                                          userr.identityResult !== undefined
                                                          ? userr.identityResult
                                                          : ''
                                                      }
                                                      className="licedocument-downloade"
                                                    >
                                                      {' '}
                                                      <div className="main-downloade-wrapper">
                                                        {userr &&
                                                          userr.identityResult !== null &&
                                                          userr.identityResult !== '' &&
                                                          userr.identityResult &&
                                                          'undefined' &&
                                                          userr.identityResult !== undefined ? (
                                                          <p className="downloadeMsg">
                                                            <div className="downloads">
                                                              <i className="fa fa-download downloadeStyle"></i>
                                                            </div>{' '}
                                                            VIEW FILE{' '}
                                                          </p>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </div>
                                                    </a>

                                                    <span id="name_tag" class="preview2"></span>
                                                  </div>
                                                </div>
                                              </div>

                                              {userr.identificationType == 'Biometric' ||
                                                (userr &&
                                                  userr.liveResult !== null &&
                                                  userr.liveResult !== '' &&
                                                  userr.liveResult &&
                                                  'undefined' &&
                                                  userr.liveResult !== undefined) ? (
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <label>
                                                      <span className="text_upercas">
                                                        Biometrics
                                                      </span>{' '}
                                                      Result Document{' '}
                                                    </label>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="document_download">
                                                      <a
                                                        href={
                                                          userr &&
                                                            userr.liveResult !== null &&
                                                            userr.liveResult !== '' &&
                                                            userr.liveResult &&
                                                            'undefined' &&
                                                            userr.liveResult !== undefined
                                                            ? userr.liveResult
                                                            : ''
                                                        }
                                                        className="licedocument-downloade"
                                                      >
                                                        {' '}
                                                        <div className="main-downloade-wrapper">
                                                          {userr &&
                                                            userr.liveResult !== null &&
                                                            userr.liveResult !== '' &&
                                                            userr.liveResult &&
                                                            'undefined' &&
                                                            userr.liveResult !== undefined ? (
                                                            <p className="downloadeMsg">
                                                              <div className="downloads">
                                                                <i className="fa fa-download downloadeStyle"></i>
                                                              </div>{' '}
                                                              VIEW FILE{' '}
                                                            </p>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </div>
                                                      </a>

                                                      <span id="name_tag" class="preview2"></span>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                ''
                                              )}

                                              <div className="row">
                                                <div className="col-md-6">
                                                  <label>Address result document </label>
                                                </div>
                                                <div className="col-md-6 document_download">
                                                  <a
                                                    href={
                                                      userr &&
                                                        userr.addressResult !== null &&
                                                        userr.addressResult !== '' &&
                                                        userr.addressResult &&
                                                        'undefined' &&
                                                        userr.addressResult !== undefined
                                                        ? userr.addressResult
                                                        : ''
                                                    }
                                                    className="licedocument-downloade"
                                                  >
                                                    {' '}
                                                    <div className="main-downloade-wrapper">
                                                      {userr &&
                                                        userr.addressResult !== null &&
                                                        userr.addressResult !== '' &&
                                                        userr.addressResult &&
                                                        'undefined' &&
                                                        userr.addressResult !== undefined ? (
                                                        <p className="downloadeMsg">
                                                          <div className="downloads">
                                                            <i className="fa fa-download downloadeStyle"></i>
                                                          </div>{' '}
                                                          VIEW FILE{' '}
                                                        </p>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </div>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>
                            </div>
                          </>
                        );
                      })
                    );
                  })}
              </div>

              {/* NOTES */}

              <div className="app_view_box">
                <div className="app_view">
                  <h4>Notes</h4>

                  <div className="note new">
                    {noteLoder === true ? (
                      <div className="note-main-loader note-load">
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <div className="notes">
                        <textarea
                          className="overflow"
                          rows="8"
                          placeholder="Type to add a note..."
                          value={noteText}
                          onChange={(e) => {
                            setNoteText(e.target.value);
                          }}
                        ></textarea>

                        <div className="row justify__align">
                          <div className="col-md-6 col-sm-6 col-6">
                            <div class="yes1_attach__notes">
                              <button>
                                <a className="click-hover Edit_button_style_note">
                                  {' '}
                                  <span class="btn_upload_note">
                                    <input
                                      type="file"
                                      id="imag2_note"
                                      title=""
                                      name="image_note"
                                      onChange={(e) => {
                                        uploadWithJSON_attach__Notess(e.target.files[0]);
                                      }}
                                    />
                                  </span>
                                  Attach
                                  {/* Attach */}
                                </a>
                              </button>
                              <p>{documentFileAttachNotes64}</p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 col-6">
                            <div className="note-footer flex-note">
                              {/* <small>{characterLimit - noteText.length} Remaining</small> */}
                              <button className="save" onClick={handleSaveClick}>
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="Attch_Note"></div>
                      </div>
                    )}

                    {notess !== undefined &&
                      notess !== 'undefined' &&
                      notess
                        .slice(0)
                        .reverse()
                        .map((userr) => {
                          return (
                            <div className="notes">
                              <div className="note_date">
                                <p className="note_date_right">{userr.createdAt}</p>
                                <p>{userr.note}</p>
                              </div>
                              <div className="posta">
                                <p>Notes By </p>
                                <p>{userr.createdBy}</p>
                              </div>
                              <div className="main_attach">
                                {userr.noteDocuments && userr.noteDocuments.length > 0
                                  ? userr.noteDocuments.map((notesDoc, key) => {
                                    return (
                                      <div className="flex__msg">
                                        {/* deepcode ignore DOMXSS: <please specify a reason of ignoring this> */}
                                        <a href={notesDoc.documentUrl}>
                                          <div className="downloadeMsg">
                                            <div className="downloads">
                                              <i className="fa fa-download downloadeStyle"></i>
                                            </div>{' '}
                                          </div>
                                        </a>
                                      </div>
                                    );
                                  })
                                  : ''}
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div >
          </div >
        )}
      </>
    </>
  );
};

export default ApplicationDetails;
