/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { audience, urlapi } from '../../auth/Configuration';

import { useParams } from 'react-router-dom';
import { Form, Input, Button } from 'antd';

import Swal from 'sweetalert2';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import $ from 'jquery';

const AdminDeta = (props) => {
  // const [idTypee, setIdTypee] = useState();
  // const [idTypee2, setIdTypee2] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const show = () => setModal(!modal);

  const navigate = useNavigate();

  useEffect(() => {
    function readURL(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        $('#name_tag').text(input.files[0].name);
      }
    }

    $('#imag2').change(function () {
      var imgControlName = '#ImgPreview2';
      readURL(this, imgControlName);
      $('.preview2').addClass('it');
      $('.btn-rmv2').addClass('rmv');
    });

    $('#removeImage2').click(function (e) {
      e.preventDefault();
      $('#imag2').val('');
      $('#ImgPreview2').attr('src', '');
      $('.preview2').text('');
      $('.btn-rmv2').removeClass('rmv');
    });
  });

  const { id } = useParams();
  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated
  } = useAuth0();
  const [token, setToken] = useState();
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          let token = accessidToken.__raw;
          setToken(accessidToken.__raw);

          application(token);
        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [user1, setUser1] = useState();
  const [loader, setLoader] = useState();

  const [directodata, setDirectodata] = useState();

  const applicationdiretor = async (token) => {
    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){

      const applicationdata12 = await axios
        .get(`${urlapi}/admin/director/approvals/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          setDirectodata(applicationdata12.data);
        });
      // }
    }
  };

  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/action/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          console.log('applicationdata12applicationdata12applicationdata12', applicationdata12);
          setUser1(applicationdata12.data[0]);
          // setUserID1(applicationdata12.data.id);
          if (user.profile.includes("UR3")) {
            applicationdiretor(token);
          }

          // setIdTypee(applicationdata12.data[0].documentIdType);
          // if (applicationdata12.data.length > 1) {
          //   setUserID2(applicationdata12.data[1].id);

          //   setIdTypee2(applicationdata12.data[1].documentIdType);
          //   setUser2(applicationdata12.data[1]);
          // }
        });
      // }
    }
  };

  const [isLoaderVerify, setIsLoaderVerify] = useState(false);

  const verifyEmail = async (user_id) => {
    setIsLoaderVerify(true);
    const changeStage1 = await axios
      .put(
        `${urlapi}/admin/verify/email/${user_id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setIsLoaderVerify(false);
          Swal.fire({
            text: 'Request sent!'
          });
        } else {
          setIsLoaderVerify(false);
          // Swal.fire(result.data.message)
          Swal.fire({
            text: result.data.message
          });
        }
      })
      .catch((error) => {
        setIsLoaderVerify(false);
        Swal.fire({
          text: 'Something went wrong!'
        });
      });
  };
  const verifyEmail1 = async (user_id) => {
    setIsLoaderVerify(true);
    const changeStage1 = await axios
      .put(
        `${urlapi}/admin/verify/user/email/${user_id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((result) => {
        if (result.status === 200) {
          Swal.fire({
            text: 'Updated!'
          });
          application(token);

          setIsLoaderVerify(false);
        } else {
          setIsLoaderVerify(false);
          // Swal.fire(result.data.message)
          Swal.fire({
            text: result.data.message
          });
        }
      })
      .catch((error) => {
        setIsLoaderVerify(false);

        Swal.fire({
          text: 'Something went wrong!'
        });
      });
  };

  const [isLoader, setIsLoader] = useState();
  const ActivDeactiveAdmin = async (user_id) => {
    // setIsLoader(true);

    setTimeout(async () => {
      // .then((res) => {

      user1.isActive == 1
        ? Swal.fire({
          html: 'Are you sure you want to Deactivate this User so they can no longer login to the platform?',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Yes, Deactivate User Now `,
          // denyButtonText: `Don't save`
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            // let token = sessionStorage.getItem('token');

            axios
              .put(
                `${urlapi}/admin/action/${user_id}`,
                { isActive: false },
                {
                  headers: { Authorization: `Bearer ${token}` }
                }
              )
              .then((result) => {
                if (result.status === 200) {
                  application(token);
                }
              });
          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }
        })
        : user1.isActive == 0
          ? Swal.fire({
            html: 'Are you sure you want to Activate this User so they can now login to the platform?',
            // showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `Yes, Activate User Now`,
            // denyButtonText: `Don't save`
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed) {
              // let token = sessionStorage.getItem('token');

              axios
                .put(
                  `${urlapi}/admin/action/${user_id}`,
                  { isActive: true },
                  {
                    headers: { Authorization: `Bearer ${token}` }
                  }
                )
                .then((result) => {
                  if (result.status === 200) {
                    application(token);
                  }
                });
            } else if (result.isDenied) {
              // Swal.fire('Changes are not saved', '', 'info')
            }
          })
          : Swal.fire(
            '',
            'We are unable to process your request at the moment. Please try again after some time',
            ''
          );
      // });
    });
  };

  const deleteuser = async (user_id) => {
    setIsLoader(true);
    axios
      .delete(`${urlapi}/admin/action/${user_id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        setIsLoader(false);

        if (res) {
          res.status === 200
            ? Swal.fire({
              html: 'Deleted',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#3085D6'
            }).then((e) => {
              navigate('/Internalusers');

              // window.location = '/';
            })
            : Swal.fire(
              '',
              'We are unable to process your request at the moment. Please try again after some time',
              ''
            );
        }
      })
      .catch(function (error) {
        Swal.fire(
          '',
          'Unfortunately an error has occurred while submitting your application. Please try again, or contact us on 0508 287 672 or hello@aurorafinancial.co.nz',
          ''
        );
      });
  };
  const deleteonSubmit = async (user_id) => {
    Swal.fire({
      allowOutsideClick: false,
      // html: "Please wait a moment while we delete your application",
      html: 'Are you sure you want to delete this Admin User and all records from the platform? (Alternatively you can de-activate this user).',
      // showDenyButton: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Yes, Delete Admin User Now`,
      // denyButtonText:`CANCEL`,
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteuser(user_id);
      }
    });
  };
  const Adminrole = []

  const addAdminRole = (e) => {
    if (Adminrole.includes(e)) {
      const index = Adminrole.indexOf(e);
      if (index > -1) { // only splice array when item is found
        Adminrole.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else {
      Adminrole.push(e);

    }
  }

  const onFinished = async (values) => {
    setLoader(true);
    setModal(false);

    var postData = {
      role: Adminrole
    }
    await axios
      .put(`${urlapi}/admin/update/role/${user1.id}`, postData, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        console.log('dddddd', res.status);
        setLoader(false);

        application(token);
        Swal.fire('', 'Admin Roles Successfully Updated', '');
      })
      .catch((error) => {
        console.log('fffff', error == 'Error: Request failed with status code 400');
        setLoader(false);
        if (error == 'Error: Request failed with status code 400') {
          Swal.fire({
            text: 'Email already existed!',
            confirmButtonColor: '#477894',
            confirmButtonText: 'Ok'
          });
        } else {
          Swal.fire({
            text: 'Please try after sometime',
            confirmButtonColor: '#477894',
            confirmButtonText: 'Ok'
          });
        }
      });
  };
  // const [ediditUser, setEdiditUser] = useState ()
  return (
    <>
      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="right_content">
          <div className="conatiner">
            <div className="row">
              <div className="col-md-12">
                <div className="heading__top ht_heading">
                  <h2>
                    <Link to="/Internalusers">
                      <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
                    </Link>
                    Admin User Details
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="form app_view_form">
            <div className="app_view">
              <div className="app_view">
                <div className="app_view_row ">
                  <div className="app_view_box app_view_box5">
                    <h4>Overview</h4>
                    <div className="row responsive__align align-items-start">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>User Name :</label>
                      </div>
                      <div className='col-md-6  col-sm-6 col-6'>
                        <div className='d-flex align-items-start justify-content-between flex-wrap'>
                          <div className="admin_user_name">
                            <p>{user1 ? user1.fullLegalName : ''}</p>
                          </div>
                          <div className=" delete_admin_user">
                            {user.profile == 'UR1' ? (
                              <button
                                className="deleteAdmin"
                                onClick={() => deleteonSubmit(user1 && user1.id)}
                              >
                                Delete Admin User
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row responsive__align align-items-start">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Email Address:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? user1.adminEmail : ''}</p>
                        {/* //+++ */}
                        <span className="status">
                          {user1 && user1.isEmailVerified ? (
                            <>
                              <span className="Success Success_icon">
                                <i class="fa fa-check" aria-hidden="true"></i>
                                Verified
                              </span>
                            </>
                          ) : (
                            <span className="danger danger_icon">
                              <p>
                                {' '}
                                <i class="fa fa-times cross_icon" aria-hidden="true"></i> Not
                                Verified
                              </p>
                              {isLoaderVerify == true ? (
                                <div className="note-main-loader verify_loader">
                                  <div className="loader zip_exp_loader"></div>
                                </div>
                              ) : (
                                <>
                                  <button
                                    className="Send_Email"
                                    onClick={() => verifyEmail(user1.id)}
                                  >
                                    Send Email
                                  </button>
                                  {/* <button
                                    className=" Manually"
                                    onClick={() => verifyEmail1(user1.id)}
                                  >
                                    Update Manually
                                  </button> */}
                                </>
                              )}
                            </span>
                          )}
                        </span>
                        {/* //+++ */}
                      </div>
                    </div>
                    <Modal isOpen={modal} show={show}>
                      <ModalHeader toggle={toggle}>Update Admin Roles</ModalHeader>

                      <ModalBody className='email_updated_pro'>
                        <Form
                          className="pop_width-100"
                          name="basic"
                          initialValues={{ remember: true }}
                          onFinish={onFinished}
                        >


                          <label className='fs-5'>Admin Role:</label>

                          <div className='select_url d-flex '>
                            <Form.Item
                              label="Super Admin"
                              name="role"
                            >
                              <Input
                                placeholder="Enter Email"
                                type="checkbox"
                                // checked={user1 && user1.role.includes("UR1") ? true : false}
                                defaultValue={"UR1"}
                                onChange={(e) => {


                                  addAdminRole(e.target.value);


                                }}

                              />
                            </Form.Item>
                            <Form.Item
                              label="Compliance"
                              name="role2"

                            >
                              <Input
                                placeholder="Enter Email"
                                type="checkbox"
                                defaultValue={"UR2"}
                                onChange={(e) => {
                                  addAdminRole(e.target.value);


                                }}

                              />
                            </Form.Item>
                            <Form.Item
                              label="Marketing"
                              name="role3"

                            >
                              <Input
                                placeholder="Enter Email"
                                type="checkbox"
                                defaultValue={"UR3"}
                                onChange={(e) => {
                                  addAdminRole(e.target.value);


                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Approver"
                              name="role4"

                            >
                              <Input
                                placeholder="Enter Email"
                                type="checkbox"
                                defaultValue={"UR4"}
                                onChange={(e) => {
                                  addAdminRole(e.target.value);


                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Staff"
                              name="role5"

                            >
                              <Input
                                placeholder="Enter Email"
                                type="checkbox"
                                defaultValue={"UR5"}
                                onChange={(e) => {
                                  addAdminRole(e.target.value);


                                }}
                              />
                            </Form.Item>
                          </div>


                          <Form.Item>
                            <Button
                              className="coffee-color right-side overwrite d-table ml-auto mt-3"
                              type="primary"
                              htmlType='submit'
                            //  onClick={handleClick}
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Form>
                      </ModalBody>
                    </Modal>
                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Admin Role Level :</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6 mb-2">
                        <div className='d-flex align-items-center flex-wrap'>
                          <p className='mr-3'>
                            {/* {user1 && user1.roleName ? user1.roleName : ""} */}
                            {user1 && user1.roleName ?
                              <>
                                {(user1.roleName[0] ? user1.roleName[0] : "") + (user1.roleName[0] && user1.roleName[1] ? "," : "") +
                                  (user1.roleName[1] ? user1.roleName[1] : "") + (user1.roleName[1] && user1.roleName[2] ? "," : "") +
                                  (user1.roleName[2] ? user1.roleName[2] : "") + (user1.roleName[2] && user1.roleName[3] ? "," : "") +
                                  (user1.roleName[3] ? user1.roleName[3] : "") + (user1.roleName[3] ? "," : "") +
                                  (user1.roleName[4] ? user1.roleName[4] : "")}
                              </>

                              : ''}
                          </p>
                          {user.profile.includes("UR1") ? <i class="fa fa-pencil role_edit" onClick={toggle} aria-hidden="true"></i>
                            : ''}

                        </div>

                        {/* <p>{user1 && user1.role == 'UR1'
                          ? 'Super Admin'
                          : user1 && user1.role == 'UR2'
                            ? 'Compliance User'
                            : user1 && user1.role == 'UR3'
                              ? 'Marketing'
                              : user1 && user1.role == 'UR4'
                                ? 'Approver'
                                : user1 && user1.role == 'UR5'
                                  ? 'Staff'
                                  : ''}</p> */}
                      </div>
                    </div>

                    <div className="row responsive__align align-items-center">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Admin User Activate/Deactivate:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? (user1.isPrimary == 1 ? user1.id : '') : ''}</p>
                        {user && user.profile.includes("UR1") ? (
                          < button
                            className=" Manually d_activae "
                            onClick={() =>
                              ActivDeactiveAdmin(user1 && user1.id ? user1.id : user1.id)
                            }
                          >
                            {user1 && user1.isActive == 1 ? 'De-Activate ' : 'Activate'}
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default AdminDeta;
