/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { audience, urlapi } from '../../auth/Configuration';
import $ from 'jquery';
import Collapse from 'react-bootstrap/Collapse';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Input, Button } from 'antd';
import Swal from 'sweetalert2';
import { useRecoilState, useRecoilValue } from 'recoil';
import { syndicatevalue, removeUsers } from 'src/InvestorPages.js/Action';
// import { userapiState, userdataState, syndicatevalue } from './';

const NotificationTable = (props) => {
  const [openCo, setopenCo] = useState(false);
  const [modal, setModal] = useState(false);

  const { getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();
  const [syncValue, setSyncValue] = useRecoilState(syndicatevalue);
  const [rrId, setrrId] = useRecoilState(removeUsers);
  console.log("removeUsers", rrId)
  console.log("synId", syncValue)
  const getUserMetadata = async () => {
    try {
      await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [users, setUsers] = useState();
  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {

      const applicationdata12 = await axios
        .get(`${urlapi}${props.apiPath1}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          // console.log(applicationdata12.data[0].createdAt)
          setUsers(applicationdata12.data.reverse());
        });
      // }
    }
  };
  $.fn.dataTable.ext.errMode = 'none';

  useEffect(() => {
    $(document).ready(function () {
      $(`#${props.tableid}`).DataTable({
        scrollX: true,
        sScrollXInner: '100%'
      });
    });
  }, [openCo]);

  const toggle = () => setModal(!modal);
  const show = () => setModal(!modal);

  const [userid, setUserid] = useState([]);
  const [removeId, setRemoveId] = useState([]);
  let syncId = [];
  let [SyndicateIDkk, setSyndicateId] = useState([]);

  async function updateId(checked, id) {
    let list = userid;
    let reid = removeId;

    if (checked == true) {
      await list.push(id);
      setUserid(list);
      RemoveId(reid, id);
    } else {
      reid.push(id);
      setRemoveId(reid);
      arrayRemove(list, id)
    }
  }



  function arrayRemove(arr, value1) {
    let list;
    list = arr.filter((value) => value !== value1);
    setUserid(list);
  }
  function RemoveId(arry, value2) {
    let reid;
    reid = arry.filter((value) => value !== value2);
    setRemoveId(reid);
  }
  const sendNotification = async (title, des) => {
    // console.log(userid,'userid')
    // alert(userid);
    // setOpen(!open);
    props.setLoader(true);
    let notificationData = {
      user_id: userid.includes("All") ? "All" : userid,
      "remove_id": removeId,
      description: des,
      title: title

    };

    const applicationdata12 = await axios
      .post(`${urlapi}/admin/notification/${props.syndicateID}`, notificationData, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        if (res.status == 200) {

          setrrId(0);

          props.setLoader(false);
          Swal.fire({
            text: "Notification sent"
          });
          setModal(false)
          setopenCo(!openCo)
        }
        setUserid([]);
        setRemoveId([]);
      })
      .catch((err) => {
        setModal(false)
        setopenCo(!openCo)
        props.setLoader(false);
        setRemoveId([]);
        setUserid([]);
        setrrId(0);

        Swal.fire({
          text: 'Getting Some error'
        });

      }).finally(() => {
        props.setActiveClient(false)
        props.setOpensyndicate(false)
        props.setClosesyndicate(false)
        props.setndividualSyndi(false)
        props.StepDescription('')
        props.setTitle('')
      })

    getUserMetadata();

    // setTimeout(() => {
    //   setOpenC(openC);
    // }, 20);
    // window.location.reload();
  };

  const fun = (idDFata) => {
    // alert(idDFata)
    // Get the "Select All" checkbox and all checkboxes with class "select"
    const selectAll = document.getElementById(`select-all${idDFata}`);
    const checkboxes = document.querySelectorAll(`.select${idDFata}`);
    // Add event listener to "Select All" checkbox
    selectAll && selectAll.addEventListener('change', function () {

      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = this.checked;
      }
    });
    // Add event listener to each "select" checkbox
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].addEventListener('change', function () {
        // If any "select" checkbox is unchecked, uncheck "Select All" checkbox
        if (!this.checked) {
          selectAll.checked = false;
        }
        // If all "select" checkboxes are checked, check "Select All" checkbox
        else if ([...checkboxes].every(checkbox => checkbox.checked)) {
          selectAll.checked = true;
        }
      });
    }
    return (
      <>
      </>
    )
  }
  const val = useRecoilValue(syndicatevalue);
  // console.log("syndicatevalue", val)
  const blank = [];
  return (
    <>
      <h4
        onClick={() => setopenCo(!openCo)}
        aria-controls="example-collapse-text2_compliance_c2"
        aria-expanded={openCo}
        className="drop_icon Completed_apllicatin_icon"
      >
        {props.tableTitle}
      </h4>
      <div className='all_select d-flex justify-content-end'>
        <input type="checkbox" value="selecAllButtons" name={`${props.syndicateID}`} className='mr-2 select-alls' id={`select-alls${props.dataid}`} onChange={(event) => {
          // csvchange(event);
          const { name, checked } = event.target;
          setSyncValue((prevCheckboxes) => ({ ...prevCheckboxes, [name]: checked }));


        }} />

        <label>Select Syndicate</label>

      </div>
      {loader === true ? (
        <div className="note-main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <Collapse in={openCo}>
            <div id="  mb-4">
              <div className='all_select d-flex justify-content-end'>
                <input type="checkbox" value="selecAllButton" className='mr-2 select-all' id={`select-all${props.dataid}`} onChange={(event) => {
                  // csvchange(event);
                  fun(props.dataid);
                  if (event.target.checked == true) {
                    updateId(true, "All");
                    setrrId(true);
                  } else {
                    updateId(false, "All");
                    setrrId(false);
                  }
                }} />

                <label>Select All Users</label>

              </div>
              <table id={props.tableid} className="responsive nowrap" width="100%">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Date Started</th>
                    <th>Entity Name</th>
                    <th>MF Team Member</th>

                    <th>Primary Contact Name</th>
                    <th>Amount</th>
                    <th>Investment Type</th>
                    <th>Stage</th>
                  </tr>
                </thead>
                <tbody>
                  {users !== undefined &&
                    users !== 'undefined' &&
                    users.map((user, i) => {
                      return (
                        <>
                          <tr>
                            <td className='notification_checkbox_parent'>
                              <span>
                                <Link
                                  to={{
                                    pathname: `/investPageAmount/${user.id}`,
                                    state: user.id
                                  }}
                                >
                                  <i
                                    title="View application details"
                                    class="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </span>
                              <span className='notification_checkbox'>
                                <input
                                  type="checkbox"
                                  className={`select${props.dataid}`}
                                  name={user.user_id}

                                  onChange={(event) => {
                                    const { name, checked } = event.target;
                                    // csvchange(event);
                                    fun(props.dataid);
                                    if (event.target.checked == true) {
                                      updateId(true, event.target.name);


                                    } else {
                                      updateId(false, event.target.name);

                                    }

                                  }}
                                />



                              </span>
                            </td>

                            <td>
                              {user.createdAt}

                            </td>
                            <td>
                              {user.entityName}

                            </td>
                            <td>{user.memberName}</td>

                            <td>
                              {user.firstName + " " + user.lastName}

                            </td>
                            <td>
                              {/* {user.totalInvestment} */}
                              {user && user.totalInvestment != null ? <>$</> : ''}

                              {user && user.totalInvestment != null
                                ? (Math.round(user.totalInvestment * 1000) / 1000).toLocaleString()
                                : ''}

                            </td>
                            <td>
                              {user.investmentType}

                            </td>
                            <td>
                              {user.applicationStage}

                            </td>


                          </tr>


                        </>

                      );
                    })}
                </tbody>
              </table>
              <button class="btn send_notification" onClick={() => { sendNotification(props.titles, props.descriptions) }}>Send  Notification</button>
            </div>
          </Collapse>
        </>
      )}


      <Modal isOpen={modal} show={show}>
        <ModalHeader toggle={toggle}>Add User</ModalHeader>

        <ModalBody>
          <Form
            className="pop_width-100"
            name="basic"
            onFinish={sendNotification}
          >
            <Form.Item
              label="Title"
              name="title"
            >
              <Input
                placeholder="Title"
                type="text"
                required
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
            >
              <Input
                placeholder="Description"
                type="text"
                required
              />
            </Form.Item>
            <Form.Item>

              <Button
                className="coffee-color right-side overwrite d-table ml-auto mt-3"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default NotificationTable;
