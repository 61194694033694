/*eslint-disable eqeqeq*/

import React from 'react';
// import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
// import { getCode, getName } from 'country-list';

const IdVerification = (props) => {
  // const { user } = useAuth0();

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  return (
    <div className="row">
      <div className="col-md-11">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Type Of ID</label>
          </div>

          <div className="col-md-6 col-sm-6 col-6">
            <p className="paragraph">{props.data ? props.data.documentIdType : ''}</p>
          </div>
        </div>
        {props.data ? (
          props.data.documentIdType != 'NZ Driver Licence' &&
            props.data.documentIdType != 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Other Document Type</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data ? props.data.otherDocumentName : ''}</p>
                </div>
              </div>
            </>
          ) : (
            ''
          )
        ) : (
          ''
        )}

        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>First Name</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p className="paragraph">{props.data ? props.data.firstName : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Middle Name</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p className="paragraph">{props.data ? props.data.middleName : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-6">
            <label>Last Name</label>
          </div>
          <div className="col-md-6 col-sm-6 col-6">
            <p className="paragraph">{props.data ? props.data.lastName : ''}</p>
          </div>
        </div>
        {props.data ? (
          props.data.documentIdType == 'NZ Driver Licence' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Licence Number</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data ? props.data.documentNumber : ''}</p>
                </div>
              </div>
            </>
          ) : props.data.documentIdType == 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Passport Number</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data ? props.data.documentNumber : ''}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6">
                <label>Document Number</label>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <p className="paragraph">{props.data ? props.data.documentNumber : ''}</p>
              </div>
            </div>
          )
        ) : (
          ''
        )}

        {props.data ? (
          props.data.documentIdType == 'NZ Driver Licence' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Expiry Date</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data ? props.data.documentExpiryDate : ''}</p>
                </div>
              </div>
            </>
          ) : props.data.documentIdType == 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Expiry Date </label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data ? props.data.documentExpiryDate : ''}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6">
                <label>Expiry Date</label>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <p className="paragraph">{props.data ? props.data.documentExpiryDate : ''}</p>
              </div>
            </div>
          )
        ) : (
          ''
        )}

        {props.data ? (
          props.data.documentIdType == 'NZ Driver Licence' ? (
            <>
              {' '}
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Version Number</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p className="paragraph">{props.data ? props.data.documentVersionNumber : ''}</p>
                </div>
              </div>
            </>
          ) : props.data.documentIdType == 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Country Of Issue</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <p>{props.data ? props.data.documentCountryOfIssue : ''}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6">
                <label>Country Of Issue</label>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <p>{props.data ? props.data.documentCountryOfIssue : ''}</p>
              </div>
            </div>
          )
        ) : (
          ''
        )}

        {props.data ? (
          props.data.documentIdType == 'NZ Driver Licence' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Document Proof</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="document_download">
                    <a
                      href={
                        props.data &&
                          props.data.documentProof !== null &&
                          props.data.documentProof !== '' &&
                          props.data.documentProof &&
                          'undefined' &&
                          props.data.documentProof !== undefined
                          ? props.data.documentProof
                          : ''
                      }
                      className="licedocument-downloade"
                    >
                      {' '}
                      <div className="main-downloade-wrapper">
                        {props.data &&
                          props.data.documentProof !== null &&
                          props.data.documentProof !== '' &&
                          props.data.documentProof &&
                          'undefined' &&
                          props.data.documentProof !== undefined ? (
                          <p className="downloadeMsg">
                            <div className="downloads">
                              <i className="fa fa-download downloadeStyle"></i>
                            </div>{' '}
                            VIEW FILE{' '}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </a>

                    <span id="name_tag" class="preview2"></span>
                  </div>
                </div>
              </div>
            </>
          ) : props.data.documentIdType == 'NZ Passport' ? (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Document Proof</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="document_download">
                    <a
                      href={
                        props.data &&
                          props.data.documentProof !== null &&
                          props.data.documentProof !== '' &&
                          props.data.documentProof &&
                          'undefined' &&
                          props.data.documentProof !== undefined
                          ? props.data.documentProof
                          : ''
                      }
                      className="licedocument-downloade"
                    >
                      {' '}
                      <div className="main-downloade-wrapper">
                        {props.data &&
                          props.data.documentProof !== null &&
                          props.data.documentProof !== '' &&
                          props.data.documentProof &&
                          'undefined' &&
                          props.data.documentProof !== undefined ? (
                          <p className="downloadeMsg">
                            <div className="downloads">
                              <i className="fa fa-download downloadeStyle"></i>
                            </div>{' '}
                            VIEW FILE{' '}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </a>

                    <span id="name_tag" class="preview2"></span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6">
                  <label>Document Proof</label>
                </div>
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="document_download">
                    <a
                      href={
                        props.data &&
                          props.data.documentProof !== null &&
                          props.data.documentProof !== '' &&
                          props.data.documentProof &&
                          'undefined' &&
                          props.data.documentProof !== undefined
                          ? props.data.documentProof
                          : ''
                      }
                      className="licedocument-downloade"
                    >
                      {' '}
                      <div className="main-downloade-wrapper">
                        {props.data &&
                          props.data.documentProof !== null &&
                          props.data.documentProof !== '' &&
                          props.data.documentProof &&
                          'undefined' &&
                          props.data.documentProof !== undefined ? (
                          <p className="downloadeMsg">
                            <div className="downloads">
                              <i className="fa fa-download downloadeStyle"></i>
                            </div>{' '}
                            VIEW FILE{' '}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </a>

                    <span id="name_tag" class="preview2"></span>
                  </div>
                </div>
              </div>
            </>
          )
        ) : (
          ''
        )}
      </div>
      {/* <div className="col-md-12 edit_icon PersonalInformationEdit">
        {user.profile.includes("UR4") ||
        (props.data && props.data.applicationStage == 'Active Client') ||
        user.profile.includes("UR2") ||
        user.profile.includes("UR3") ? (
          ''
        ) : (
          <i
            onClick={handleOpen}
            title="Edit"
            class="fa fa-pencil-square-o hover__Edit"
            aria-hidden="true"
          ></i>
        )}
      </div> */}
    </div>
  );
};
export default IdVerification;
