import React, { useState, useEffect } from 'react';


import { Link } from 'react-router-dom';
import LiveSyndicateTable from './LiveSyndicateTable';
import { audience, urlapi } from '../../auth/Configuration';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import TableData2 from './TableData2';

const Investments = () => {
  const { getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const getUserMetadata = async () => {
    try {
      await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [users, setUsers] = useState();
  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {

      const applicationdata12 = await axios
        .get(`${urlapi}/admin/syndicate/actions`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);

          setUsers(applicationdata12.data.reverse());
        });
      // }
    }
  };

  return (
    <>
      <div className="right_content">
        <div className="heading__top ht_heading">
          <h2>
            {/* <Link to="/Dashboard1">
              <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </Link> */}
            INVESTMENTS
          </h2>
        </div>
        {/* <div className="form director">
          <div className="myfarm_com myfarm_Active" key={7}>
            {' '}
            <TableData2
              apiPath1={'/admin/investment'}
              tableTitle={'Syndicate'}
              key={1}
              tableid={'table_id4'}
            />
          </div>

        </div> */}
        {users && users.map((user, index) => {
          return (
            <>
              {user.isValidOffer ? <div className="form director p-3">
                <div className="myfarm_com myfarm_Active" key={index}>
                  {' '}
                  <LiveSyndicateTable
                    apiPath1={`/admin/syndicate/users/${user.id}`}
                    tableTitle={`${user.title}`}
                    key={index}
                    tableid={`table_id44${index}`}
                  />
                </div>
              </div> : ""}
            </>
          )
        })

        }
      </div>
    </>
  );
};

export default Investments;
