/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Input, Checkbox } from 'antd';
import { audience, urlapi } from 'src/auth/Configuration';
import moment from 'moment';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import axios from 'axios';
// import EditIcon from "@material-ui/icons/Edit";
import $, { now } from 'jquery';
import { Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const ModalPopup = (props) => {
  // alert(props.userr.dateOfBirth);
  const { getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState();
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          checkToken(token);
        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    setIdTypee(props.userr.documentIdType);
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [userTrust, SetUserTrust] = useState();
  const applicationTrust = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/user/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          SetUserTrust(applicationdata12.data);
        });
    }
  };

  function checkToken(token) {
    return token;
  }
  const { id } = useParams();
  const [idTypee, setIdTypee] = useState();
  const [amltype, setAmltype] = useState('electronics');
  const [modal, setModal, setForm] = useState(false);
  const [loader, setLoader] = useState();
  const [datevalue1, setDatevalue1] = useState(moment().format('DD/MM/YYYY'));
  const [verifyFordoc64, setVerifyFordoc64] = useState();
  const [documentFile64, setDocumentFile64] = useState();

  const toggle = () => setModal(!modal);
  const show = () => setModal(!modal);
  const { className } = props;
  function checkDate() {
    var selectedDate = document.getElementById('documentExpiryDate').value;
    var now = new Date();
    var dt1 = Date.parse(now),
      dt2 = Date.parse(selectedDate);
    if (dt2 < dt1) {
    }
  }
  useEffect(() => {
    function readURL(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        $('#name_tag').text(input.files[0].name);
      }
    }
    $('#imag2').change(function () {
      var imgControlName = '#ImgPreview2';
      readURL(this, imgControlName);
      $('.preview2').addClass('it');
      $('.btn-rmv2').addClass('rmv');
    });
    $('#removeImage2').click(function (e) {
      e.preventDefault();
      $('#imag2').val('');
      $('#ImgPreview2').attr('src', '');
      $('.preview2').text('');
      $('.btn-rmv2').removeClass('rmv');
    });
  });

  async function uploadWithJSON(file, e) {
    var str = file.type;

    const fileType = str.slice(-3);


    if (file.size > 2202009) {
      Swal.fire({
        text: "Please attach ID proof (maximum file size is 2MB)."
      });
    }
    else if (fileType && (fileType == "pdf" || fileType == "jpg" || fileType == "png")) {
      setDocumentFile64(file.name);
    }
    else {
      Swal.fire({
        text: "Only pdf jpg png file attached."
      });
    }

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };

    setVerifyFordoc64(data.img64);
  }

  const [address, setAddress] = useState('address');
  const onFinish = async (values) => {
    values.dateOfBirth = $('.ui.icon.input.dateOfBirth>input').val();
    values.documentExpiryDate = $('.ui.icon.input.documentExpiryDate>input').val();
    values.documentCountryOfIssue = 'NZ';
    values.verifyFor = 'identity';
    values.verificationType = amltype;
    values.documentIdType = idTypee;

    let add = {
      addressDetails: {
        addressLine1: values.addressLine1,
        city: values.city,
        countryCode: 'NZ',
        postalCode: values.postalCode,
        suburb: values.suburb,
        country: values.country
      },

      verificationType: amltype,

      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,

      verifyFor: address
    };

    let data = {
      verifyFor: 'pep'
    };
    if (props.addresss == true) {
      if (
        verifyFordoc64 !== null &&
        verifyFordoc64 !== undefined &&
        verifyFordoc64 !== 'undefined' &&
        amltype === 'viaDoc'
      ) {
        props.setLoader(true);
        add['doc64'] = verifyFordoc64;
        const changeStage1 = await axios
          .put(`${urlapi}/admin/verify/aml/${props.userr.id}`, add, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then((result) => {
            if (result.status === 200) {
              // window.location.reload();
              props.getAPI(token);
              checkToken(token);
              props.setLoader(false);
              Swal.fire({
                text: 'Successfully'
              });

              setModal(!modal);
            } else {
              props.setLoader(false);
              Swal.fire({
                text: result.data.message
              });
            }
          })
          .catch((result) => {
            setModal(!modal);
          });
      } else if (amltype !== 'viaDoc') {
        props.setLoader(true);

        const changeStage1 = await axios
          .put(`${urlapi}/admin/verify/aml/${props.userr.id}`, add, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then((result) => {
            if (result.status === 200) {
              // window.location.reload();
              props.getAPI(token);
              Swal.fire({
                text: 'Successfully'
              });

              checkToken(token);

              setModal(!modal);
              props.setLoader(false);
            } else {
              props.setLoader(false);
              Swal.fire({
                text: result.data.message
              });
            }
          })
          .catch((result) => {
            setModal(!modal);
          });
      } else {
        Swal.fire({
          text: 'Please attach document'
        });
      }
    } else if (props.pepAml == true) {
      props.setLoader(true);
      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${props.userr.id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            // window.location.reload();
            checkToken(token);
            props.setLoader(false);
            props.getAPI(token);
            Swal.fire({
              text: 'Successfully'
            });

            setModal(!setModal);
            // checkToken(token);
          } else {
            props.setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        })
        .catch((result) => {
          setModal(!modal);
        });
    } else if (props.bioAml == true) {
      let dataBio = {
        verifyFor: 'biometric'
      };

      props.setLoader(true);
      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${props.userr.id}`, dataBio, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            // window.location.reload();
            props.getAPI(token);
            Swal.fire({
              text: 'Successfully'
            });

            checkToken(token);
            props.setLoader(false);

            setModal(!modal);
          } else {
            props.setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else {
      if (
        verifyFordoc64 !== null &&
        verifyFordoc64 !== undefined &&
        verifyFordoc64 !== 'undefined' &&
        amltype === 'viaDoc'
      ) {
        props.setLoader(true);
        values.doc64 = verifyFordoc64;
        const changeStage1 = await axios
          .put(`${urlapi}/admin/verify/aml/${props.userr.id}`, values, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then((result) => {
            if (result.status === 200) {
              // window.location.reload();
              props.getAPI(token);
              Swal.fire({
                text: 'Successfully'
              });

              setModal(!modal);
              checkToken(token);
              props.setLoader(false);

              // Swal.fire({
              //   text: result.data.message
              // });
            } else {
              props.setLoader(false);
              Swal.fire({
                text: result.data.message
              });
            }
          });
      } else if (amltype !== 'viaDoc') {
        props.setLoader(true);

        const changeStage1 = await axios
          .put(`${urlapi}/admin/verify/aml/${props.userr.id}`, values, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then((result) => {
            if (result.status === 200) {
              // window.location.reload();
              props.getAPI(token);

              setModal(!modal);
              checkToken(token);
              props.setLoader(false);
              Swal.fire({
                text: 'Successfully'
              });
            } else {
              props.setLoader(false);
              Swal.fire({
                text: result.data.message
              });
            }
          });
      } else {
        Swal.fire({
          text: 'Please attach document'
        });
      }
    }
  };

  function setDateformateToValid(datevalue) {
    if (
      datevalue !== null &&
      datevalue !== 'undefined' &&
      typeof datevalue !== 'undefined' &&
      datevalue.includes('/')
    ) {
      let dat = datevalue.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }

  return (
    <>
      <>
        {loader == true ? (
          <div className="main-loader">
            <div className="loader myloader"></div>
          </div>
        ) : (
          <div>
            <div className="right-side right-side-email">
              {' '}
              <i
                title="Edit"
                class="fa fa-pencil-square-o hover__Edit"
                aria-hidden="true"
                onClick={toggle}
              ></i>
            </div>

            <Modal isOpen={modal} show={show} className={className}>
              <ModalHeader toggle={toggle}>
                {props.pepAml == true ? (
                  '  PEP - Manually Approve'
                ) : (
                  <>
                    {props.addresss == true ? (
                      `Edit Adress`
                    ) : (
                      <>
                        {props.bioAml == true
                          ? 'BIOMETRICS - Manually Approve'
                          : ' Update Name & ID Details'}
                      </>
                    )}
                  </>
                )}
              </ModalHeader>

              <ModalBody>
                {props.pepAml != true && props.bioAml != true ? (
                  <>
                    <label>Please select AML verification type </label>

                    <select
                      id="select_option1"
                      name="amltype"
                      className="mb-3"
                      onChange={(e) => setAmltype(e.target.value)}
                    >
                      <option value="electronics">Re-Check Electronic Verification</option>
                      <option value="viaDoc"> Manual Approval</option>
                    </select>
                  </>
                ) : (
                  ''
                )}

                <Form
                  className="pop_width-100"
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  {props.addresss != true && props.pepAml != true && props.bioAml != true ? (
                    <>
                      <Row>
                        <Col xs={12}>
                          <div className="custom-select-btn">
                            <label>Please select your id type</label>
                            <select
                              id="select_option1"
                              value={idTypee}
                              name="identificationType"
                              onChange={(e) => setIdTypee(e.target.value)}
                              className="mb-3"
                            >
                              <option value="NZ Driver Licence">NZ Driver Licence</option>
                              <option value="NZ Passport">NZ Passport</option>
                              <option value="other">Other</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ''
                  )}
                  {props.pepAml != true && props.bioAml != true ? (
                    <>
                      <Form.Item
                        label="First name:"
                        name="firstName"
                        initialValue={props.userr ? props.userr.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter first name'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter first name(s)"
                          defaultValue={props.userr ? props.userr.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>
                      <Form.Item
                        label="Middle Name:"
                        name="middleName"
                        initialValue={props.userr ? props.userr.middleName : ''}
                      >
                        <Input
                          placeholder="Enter Middle name(s)"
                          defaultValue={props.userr ? props.userr.middleName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Last Name:"
                        name="lastName"
                        initialValue={props.userr ? props.userr.lastName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter last name'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter Last name(s)"
                          defaultValue={props.userr ? props.userr.lastName : ''}
                          maxlength="50"
                          required
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    ''
                  )}

                  {props.addresss == true ? (
                    <>
                      <Form.Item
                        className="formtext"
                        label="Address:"
                        name="addressLine1"
                        initialValue={
                          props.userr
                            ? props.userr.addressDetails !== null &&
                              props.userr.addressDetails[0].addressLine1 !== null &&
                              props.userr.addressDetails[0].addressLine1 != ' '
                              ? props.userr.addressDetails[0].addressLine1
                              : ''
                            : ''
                        }
                        rules={[{ required: true, message: 'Please enter first name' }]}
                      >
                        <Input
                          placeholder="Enter  addressLine"
                          maxlength="200"
                          defaultValue={
                            props.userr
                              ? props.userr.addressDetails !== null &&
                                props.userr.addressDetails[0].addressLine1 !== null &&
                                props.userr.addressDetails[0].addressLine1 != ' '
                                ? props.userr.addressDetails[0].addressLine1
                                : ''
                              : ''
                          }
                          required
                        />
                      </Form.Item>
                      <Form.Item
                        label="Suburb:"
                        name="suburb"
                        initialValue={
                          props.userr
                            ? props.userr.addressDetails !== null &&
                              props.userr.addressDetails[0].suburb !== null
                              ? props.userr.addressDetails[0].suburb
                              : ''
                            : ''
                        }
                      >
                        <Input
                          placeholder="Enter  suburb"
                          defaultValue={
                            props.userr
                              ? props.userr.addressDetails !== null &&
                                props.userr.addressDetails[0].suburb !== null
                                ? props.userr.addressDetails[0].suburb
                                : ''
                              : ''
                          }
                          maxlength="20"
                          className="mb-2 numbers"
                        // className="numbers"
                        />
                      </Form.Item>
                      <Form.Item
                        className="formtext"
                        label="City"
                        name="city"
                        initialValue={
                          props.userr
                            ? props.userr.addressDetails !== null &&
                              props.userr.addressDetails[0].city !== null
                              ? props.userr.addressDetails[0].city
                              : ''
                            : ''
                        }
                        rules={[{ required: true, message: 'Please enter last name' }]}
                      >
                        <Input
                          // className="mb-3"
                          placeholder="Enter  city"
                          maxlength="50"
                          defaultValue={
                            props.userr
                              ? props.userr.addressDetails !== null &&
                                props.userr.addressDetails[0].city !== null
                                ? props.userr.addressDetails[0].city
                                : ''
                              : ''
                          }
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        className="formtext"
                        label="Postal Code "
                        name="postalCode"
                        initialValue={
                          props.userr
                            ? props.userr.addressDetails !== null &&
                              props.userr.addressDetails[0].postalCode !== null
                              ? props.userr.addressDetails[0].postalCode
                              : ''
                            : ''
                        }
                        // className="mb-3"
                        rules={[{ required: true, message: 'Please enter last name' }]}
                      >
                        <Input
                          // className="mb-3"
                          placeholder="Enter  postalCode"
                          maxlength="10"
                          defaultValue={
                            props.userr
                              ? props.userr.addressDetails !== null &&
                                props.userr.addressDetails[0].postalCode !== null
                                ? props.userr.addressDetails[0].postalCode
                                : ''
                              : ''
                          }
                          className="numbers"
                          // maxlength="4"
                          required
                        />
                      </Form.Item>
                      <Form.Item
                        label="Country:"
                        name="country"
                        initialValue={
                          props.userr
                            ? props.userr.addressDetails !== null &&
                              props.userr.addressDetails[0].country !== null
                              ? props.userr.addressDetails[0].country
                              : ''
                            : ''
                        }
                      >
                        <Input
                          type="text"
                          placeholder="Enter  country"
                          maxlength="20"
                          defaultValue={
                            props.userr
                              ? props.userr.addressDetails !== null &&
                                props.userr.addressDetails[0].country !== null
                                ? props.userr.addressDetails[0].country
                                : ''
                              : ''
                          }
                          required
                          className="mb-2"
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      {props.pepAml != true && props.bioAml != true ? (
                        <>
                          <Row>
                            <Col sm={12}>
                              <label className="label">Date of Birth</label>

                              <Form.Item name="dateOfBirth" className="date_width">
                                <div class="custom_datepiker">
                                  <SemanticDatepicker
                                    id="dateOfBirth"
                                    format="DD/MM/YYYY"
                                    className="dateOfBirth mb-2 widthSET"
                                    allowOnlyNumbers
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    required
                                    maxDate={new Date(moment().subtract(18, 'years'))}
                                    value={
                                      props.userr && props.userr.dateOfBirth
                                        ? new Date(setDateformateToValid(props.userr.dateOfBirth))
                                        : ''
                                    }
                                  />
                                </div>
                              </Form.Item>
                            </Col>
                          </Row>
                          {idTypee == 'NZ Driver Licence' ? (
                            <div>
                              <Form.Item
                                label="Licence Number:"
                                name="documentNumber"
                                initialValue={props.userr ? props.userr.documentNumber : ''}
                                className="numbersOnly"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter licence number'
                                  }
                                ]}
                              >
                                <Input
                                  placeholder="Enter licence number"
                                  defaultValue={props.userr ? props.userr.documentNumber : ''}
                                  className="mb-2 digitsOnly"
                                  maxlength="50"
                                  required
                                />
                              </Form.Item>

                              <Form.Item
                                label="Version Number:"
                                name="documentVersionNumber"
                                initialValue={props.userr ? props.userr.documentVersionNumber : ' '}
                                className="numbers"
                              >
                                <Input
                                  placeholder="Enter version number"
                                  defaultValue={
                                    props.userr ? props.userr.documentVersionNumber : ''
                                  }
                                  className="mb-2 numbers"
                                  maxlength="3"
                                  minLength={3}
                                  required
                                  maxLength={3}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                />
                              </Form.Item>
                            </div>
                          ) : idTypee == 'NZ Passport' ? (
                            <div>
                              <Form.Item
                                label="Passport Number:"
                                name="documentNumber"
                                autoComplete="off"
                                initialValue={props.userr ? props.userr.documentNumber : ''}
                                className="digitsOnly"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter Passport number'
                                  }
                                ]}
                              >
                                <Input
                                  autoComplete="off"
                                  placeholder="Enter passport number"
                                  maxlength="50"
                                  defaultValue={props.userr ? props.userr.documentNumber : ''}
                                  className="mb-2 digitsOnly"
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                />
                              </Form.Item>
                            </div>
                          ) : (
                            <>
                              <div>
                                <Form.Item
                                  label="Document Number:"
                                  name="documentNumber"
                                  autoComplete="off"
                                  initialValue={props.userr ? props.userr.documentNumber : ''}
                                  className="digitsOnly"
                                >
                                  <Input
                                    autoComplete="off"
                                    placeholder="Enter document number"
                                    maxlength="50"
                                    defaultValue={props.userr ? props.userr.documentNumber : ''}
                                    className="mb-2 digitsOnly"
                                    required
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  label="Document Type:"
                                  name="otherDocumentName"
                                  autoComplete="off"
                                  initialValue={props.userr ? props.userr.otherDocumentName : ''}
                                  className="digitsOnly"
                                >
                                  <Input
                                    autoComplete="off"
                                    placeholder="Enter document type"
                                    maxlength="50"
                                    defaultValue={props.userr ? props.userr.otherDocumentName : ''}
                                    className="mb-2 digitsOnly"
                                    required
                                  />
                                </Form.Item>
                              </div>
                            </>
                          )}
                          <Row>
                            <Col sm={12}>
                              <label className="label">Expiry Date</label>

                              <Form.Item name="documentExpiryDate" className="date_width">
                                <div class="custom_datepiker">
                                  <SemanticDatepicker
                                    id="documentExpiryDate"
                                    filterDate={(date) => {
                                      const now = new Date();
                                      return date >= now;
                                    }}
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    onKeyPress={(date) => {
                                      const now = new Date();
                                      return date >= now;
                                    }}
                                    format="DD/MM/YYYY"
                                    className="documentExpiryDate mb-2 widthSET"
                                    allowOnlyNumbers
                                    required
                                    minDate={now()}
                                    onChange={checkDate}
                                    value={
                                      props.userr && props.userr.documentExpiryDate
                                        ? new Date(
                                          setDateformateToValid(props.userr.documentExpiryDate)
                                        )
                                        : ''
                                    }
                                  />
                                </div>
                                <span className="error ExpiryDate"></span>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )}

                  {props.pepAml != true && props.bioAml != true ? (
                    <>
                      {amltype === 'viaDoc' ? (
                        <>
                          <Row className="fullinput mt-2 mb-2">
                            <Col>
                              <Col xs={12}>
                                <div class="yes1">
                                  <a className="click-hover Edit_button_style">
                                    {' '}
                                    <span class="btn_upload">
                                      <input
                                        type="file"
                                        id="imag2"
                                        title=""
                                        name="image"
                                        // onClick={removeError}
                                        onChange={(e) => {
                                          uploadWithJSON(e.target.files[0]);
                                          // removeError();
                                        }}
                                      />
                                      {/* <img src={attch} alt="fileuploade" /> */}
                                    </span>
                                    Attach ID Proof
                                  </a>
                                  <p>{documentFile64}</p>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </>
                  ) : (
                    ''
                  )}

                  {props.pepAml == true ? (
                    <>
                      <Form.Item
                        name="pep"
                        className="input_check"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(new Error('Please Confirm Manual PEP Pass'))
                          }
                        ]}
                      >
                        <Checkbox>
                          I confirm I have manually reviewed the PEP match results, and confirm that
                          I am satisfied that none of these match results relate to this investor.
                        </Checkbox>
                      </Form.Item>
                    </>
                  ) : (
                    ''
                  )}

                  {props.bioAml == true ? (
                    <>
                      <Form.Item
                        name="pep"
                        className="input_check"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(new Error('Please Confirm Manual PEP Pass'))
                          }
                        ]}
                      >
                        <Checkbox>
                          <>
                            I CONFIRM THAT I HAVE MANUALLY REVIEWED THE BIOMETRICS IMAGES AND
                            RESULTS, AND CONFIRM THAT I AM SATISFIED THAT THE BIOMETRICS HAS
                            ACHIEVED A PASS RESULT.
                          </>
                        </Checkbox>
                      </Form.Item>
                    </>
                  ) : (
                    ''
                  )}

                  {props.pepAml != true ? (
                    <>
                      <Form.Item className="pepAml__modalPopup">
                        <Button
                          className="coffee-color right-side overwrite d-table ml-auto mt-3 Edit_button_style"
                          type="primary"
                          htmlType="submit"
                        >
                          {amltype === 'viaDoc'
                            ? 'Submit & Manually Approve Id'
                            : 'Re-Check Electronic AML'}
                        </Button>
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      {props.pepAml == true ? (
                        <>
                          <Form.Item className="pepAml__modalPopup">
                            <Button
                              className="coffee-color right-side overwrite d-table ml-auto mt-3 Edit_button_style"
                              type="primary"
                              htmlType="submit"
                            >
                              Confirm Manual PEP Pass
                            </Button>
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          {props.bioAml == true ? (
                            <>
                              <Form.Item className="pepAml__modalPopup">
                                <Button
                                  className="coffee-color right-side overwrite d-table ml-auto mt-3 Edit_button_style"
                                  type="primary"
                                  htmlType="submit"
                                >
                                  CONFIRM MANUAL BIOMETRIC PASS
                                </Button>
                              </Form.Item>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </>
                  )}
                </Form>
              </ModalBody>
            </Modal>
          </div>
        )}
      </>
    </>
  );
};

export default ModalPopup;
