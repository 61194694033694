/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Domain, audience, urlapi } from 'src/auth/Configuration';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, FormText } from 'reactstrap';
import { Form, Input, Checkbox, message, Radio, Select, DatePicker, Space } from 'antd';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

const CloseInfoDetails = () => {
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();
  const roleUser = sessionStorage.getItem('roles');

  const [tokenn2, setTokenn2] = useState();
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) {}
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setTokenn2(accessidToken.__raw);
          let token = accessidToken.__raw;
          result(token);
        }
      }
    } catch (e) {}
  };

  // console.log("ddd",tokenn2)
  useEffect(() => {
    getUserMetadata();
  }, []);

  const [info, setInfo] = useState([]);
  let userid = sessionStorage.getItem('id');
  // console.log("tokentokentokentoken", token)

  const result = async (token) => {
    if (isAuthenticated) {
      axios
        .get(`${urlapi}/admin/syndicate/action/${userid}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          if (res.status === 200) {
            setInfo(res.data);
          }

          console.log('options1111', res.data);
        });
    }
  };

  const DateDelete = async () => {
    let data;
    axios.delete(`${urlapi}/admin/syndicate/action/${userid}`, {
      headers: { Authorization: `Bearer ${tokenn2}` }
    });
  };

  const [loader, setLoader] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const show = () => setModal(!modal);

  const onFinish = async (values) => {
    setLoader(true);
    window.location.reload(true);
    values['isValidOffer'] = 1;

    await axios
      .put(`${urlapi}/admin/syndicate/action/${info.id}`, values, {
        headers: { Authorization: `Bearer ${tokenn2}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setLoader(false);
          setModal(!modal);
        } else {
          setLoader(false);
        }
      });
  };

  return (
    <>
      <div className="right_content">
        {' '}
        <h2>
          <Link to="/Syndicates">
            <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
          </Link>
          Info Details:
        </h2>
        <div className="heading__top ht_heading">
          <div className="row">
            <div className="col-lg-12">
              {/* <button >
                                <i class="fa fa-pencil-square-o infologo" aria-hidden="true"></i>
                            </button> */}
              <div>
                {roleUser == 'UR5' || roleUser == 'UR4' ? (
                  ''
                ) : (
                  <Button className="syndicates_btn" onClick={toggle}>
                    <i class="fa fa-pencil-square-o infologo" aria-hidden="true"></i>
                  </Button>
                )}

                <Modal isOpen={modal} show={show}>
                  <ModalHeader toggle={toggle}>Syndicates Details:</ModalHeader>

                  <ModalBody>
                    <Form
                      className="pop_width-100"
                      name="basic"
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                    >
                      <Form.Item
                        label="Syndicate Name:"
                        name="syndicateName"
                        initialValue={info.syndicateName}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter First Name'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter syndicate name"
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Description/Address:"
                        name="address"
                        initialValue={info.address}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Description/Address'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter first name(s)"
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Units and deposit blurb:"
                        name="blurb"
                        initialValue={info.blurb}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter units and deposit blurb'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter full sentence(s)  with unit price, minimum units, and deposit payment information including deposit amount and date"
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Further Payments Blurb:"
                        name="furtherPaymentsBlurb"
                        initialValue={info.furtherPaymentsBlurb}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter further payments blurb'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter full sentence(s)  with all information regarding further payments/calls table info"
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="IM instructions:"
                        name="hyperLinkText1"
                        initialValue={info.hyperLinkText1}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                      >
                        <Input
                          placeholder="Enter IM instructions, eg: Please review the information memorandum (IM)."
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Link to IM:"
                        name="hyperLink1"
                        initialValue={info.hyperLink1}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                      >
                        <Input
                          placeholder="Provide hyperlink to IM."
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Supplementary IM instructions (if relevant):"
                        name="hyperLinkText2"
                        initialValue={info.hyperLinkText2}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter IM instructions'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter supplementary IM instructions, eg: Please review the  supplementary information memorandum."
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Link to supplementary IM (if relevant):"
                        name="hyperLink2"
                        initialValue={info.hyperLink2}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please provide hyperlink to IM'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Provide hyperlink to  Supplement IM."
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Syndicate Code:"
                        name="code"
                        initialValue={info.code}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter syndicate code'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter Syndicate Code."
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Value per unit:"
                        name="valuePerUnit"
                        initialValue={info.valuePerUnit}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter value per unit'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter value per unit."
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Deposit % amount:"
                        name="percentageValue"
                        initialValue={info.percentageValue}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter deposit % amount'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter deposit % amount."
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label="Syndicate payment reference:"
                        name="bankReferance"
                        initialValue={info.bankReferance}
                        // initialValue={user1 ? user1.firstName : ''}
                        className="quantity"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter payment reference'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter payment reference."
                          // defaultValue={user1 ? user1.firstName : ''}
                          maxlength="50"
                          className="mb-2 quantity"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          required
                        />
                      </Form.Item>

                      <Row>
                        <Col sm={12}>
                          <label className="label">Deposit due date</label>

                          <Form.Item
                            name="depositDueDate"
                            initialValue={info.depositDueDate}
                            className="date_width"
                          >
                            <div class="custom_datepiker">
                              <SemanticDatepicker
                                id="dateOfBirth"
                                format="DD/MM/YYYY"
                                className="dateOfBirth mb-2 widthSET"
                                allowOnlyNumbers
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                required
                                maxDate={new Date(moment().subtract(18, 'years'))}
                              />
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item>
                        <Button
                          className="coffee-color right-side overwrite d-table ml-auto mt-3 "
                          type="primary"
                          htmlType="submit"
                          // onClick={refreshPage}
                        >
                          submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <div className=" ht_heading11">
          <div className="row">
            <div className="col-md-6">Syndicate Name:</div>
            <div className="col-md-6">{info.syndicateName}</div>

            <div className="col-md-6">Description/Address:</div>
            <div className="col-md-6">{info.address}</div>

            <div className="col-md-6">Units and deposit blurb:</div>
            <div className="col-md-6">{info.blurb}</div>

            <div className="col-md-6">Further Payments Blurb:</div>
            <div className="col-md-6">{info.furtherPaymentsBlurb}</div>

            <div className="col-md-6">IM instructions:</div>
            <div className="col-md-6">{info.hyperLinkText1}</div>

            <div className="col-md-6">Link to IM:</div>
            <div className="col-md-6 linkunderline">
              <Link onClick={() => window.open('/LinkToIm')}>{info.hyperLink1} </Link>
            </div>

            <div className="col-md-6">Supplementary IM instructions (if relevant):</div>
            <div className="col-md-6">{info.hyperLinkText2}</div>

            <div className="col-md-6">Link to supplementary IM (if relevant):</div>
            <div className="col-md-6 linkunderline">
              <Link onClick={() => window.open('/LinksuplyIM')}>{info.hyperLink2}</Link>{' '}
            </div>

            <div className="col-md-6">Syndicate Code:</div>
            <div className="col-md-6">{info.code}</div>

            <div className="col-md-6">Value per unit:</div>
            <div className="col-md-6">{info.valuePerUnit}</div>

            <div className="col-md-6">Deposit % amount:</div>
            <div className="col-md-6">{info.percentageValue}</div>

            <div className="col-md-6">Syndicate payment reference:</div>
            <div className="col-md-6">{info.bankReferance}</div>

            <div className="col-md-6">Deposit due date:</div>
            <div className="col-md-6">{info.depositDueDate}</div>
          </div>
        </div>
        {roleUser == 'UR5' || roleUser == 'UR4' ? (
          ''
        ) : (
          <button
            onClick={(e) => {
              DateDelete(e);
            }}
          >
            Delete Offer
          </button>
        )}
      </div>
    </>
  );
};
export default CloseInfoDetails;
