import { disableCache, Icon } from '@iconify/react';
import { useAuth0 } from '@auth0/auth0-react';
import React, { Component } from 'react';
import chevronRightFill from '@iconify/icons-eva/chevron-right-fill';
// ----------------------------------------------------------------------
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
let rolesss = sessionStorage.getItem('roles');
// alert(rolesss);
export const sidebarConfigUR5 = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Clients',
    path: '/client',
    icon: getIcon(chevronRightFill)
  },

  {
    title: 'Syndicates',
    path: '/Syndicates',
    icon: getIcon(chevronRightFill)
  }
];

export const sidebarConfigUR1UR2 = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon(chevronRightFill)
  },

  {
    title: 'Applications',
    path: '/application',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Clients',
    path: '/client',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Investments',
    path: '/investment',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'News & Research',
    path: '/Newsresearch',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Syndicates',
    path: '/Syndicates',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Internal Users',
    path: '/Internalusers',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Notification',
    path: '/Notification',
    icon: getIcon(chevronRightFill)
  },
  // {
  //   title: ' Client Maintenance',
  //   path: '/clientMaintanance',
  //   icon: getIcon(chevronRightFill)
  // }
];
export const sidebarConfigu4 = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Applications',
    path: '/application',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Clients',
    path: '/client',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'News & Research',
    path: '/Newsresearch',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Syndicates',
    path: '/Syndicates',
    icon: getIcon(chevronRightFill)
  }
];

export const sidebarUser = [
  {
    title: 'Dashboard',
    path: '/investorDashboard',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Investment Opportunities',
    path: '/InvestmentOpportunities',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Transactions',
    path: '/Transactions',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'My Documents',
    path: '/Mydocuments',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'News & Research',
    path: '/Newresearch',
    icon: getIcon(chevronRightFill)
  },
  {
    title: 'Contact',
    path: '/Contact',
    icon: getIcon(chevronRightFill)
  }
]
