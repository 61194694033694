/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { Link, } from 'react-router-dom';
import axios from 'axios';

import { useAuth0 } from '@auth0/auth0-react';
import { audience, urlapi } from 'src/auth/Configuration';
import { useRecoilState, useRecoilValue } from 'recoil';
import { allusersdata, userdataState } from '../Action';


const Syndicates1 = () => {
  const [linkValue, setLinkValue] = useState(true);
  const [CheckboxValue, setCheckboxValue] = useState(true);
  const [cValue, SetCvalue] = useState(false);


  const validateCheck = () => {
    setLinkValue(false);
  };
  const hhhh = () => {
    const jj = document.getElementById('ddd');
    if (jj.checked) {
      setCheckboxValue(false);
    } else {
      setCheckboxValue(true);
    }
  };



  const [form, setContactInfo] = useRecoilState(allusersdata);
  const newComponent = () => {
    SetCvalue(true);
  };

  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();
  const [tokenn, setTokenn] = useState({});

  const [checkboxValues, setCheckboxValues] = useState(true);
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setTokenn(accessidToken.__raw);
          let token = accessidToken.__raw;
          // result(token);
        }
      }
    } catch (e) { }
  };
  const amountt = useRecoilValue(allusersdata);

  useEffect(() => {

    getUserMetadata();
  }, []);
  const myusers = useRecoilValue(userdataState);
  console.log('jjjj', myusers);
  let id = sessionStorage.getItem('id');
  const [syndicate33, setSyndicate33] = useState(false);
  const postApi = (e) => {
    // alert('dfcdd');
    setSyndicate33(true);
    // alert(isAuthenticated)

    // setLoaderr(true);
    let Invest = e == 'undefined' ? 'undefined' : e.target.value;
    const value = {
      syndicateId: id,
      totalInvestment: amountt.email,
      sourceOfFund: amountt.phone,
      investmentType: myusers ? myusers[0].investmentType : ''
    };
    // alert(Invest);
    axios
      .put(`${urlapi}/investor/investment/amount`, value, {
        headers: { Authorization: `Bearer ${tokenn}` }
      })
      .then((res) => {
        //
        // setAdmindata(res.data);
      })
      .catch((error) => {
        // setLoaderr(false);
      });
  };
  const CheckValues = () => {
    const jj = document.getElementById('kkk');
    if (jj.checked) {
      setCheckboxValues(false);
    } else {
      setCheckboxValues(true);
    }
  };


  const stateChange = () => {
    setContactInfo({ ...form, email: null, phone: null });

    // setContactInfo({ ...form, phone: null });
    SetCvalue(false);
  };

  return (
    <>
      {syndicate33 == true ? (
        <div className="right_content aurora_layout">
          {/* <div className="heading__top ht_heading d-flex justify-content-between align-items-center flex-wrap">
          <h2>See our below range of MyFarm investment strategies</h2>
          <Link to="/Forms" className="btn btnlights ">
            MAKE CONTRIBUTION <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </Link>
        </div> */}
          <div className="form snydicates snydicates_ok_pop ">
            <div className="form_inner text-center ">
              <p className="text-center m-0">
                Thank you for submitting your investment application!
              </p>
              <p className="text-center">
                We are processing this now, and will confirm once this has been allocated
              </p>
              <Link to={'/investorDashboard'}>
                <button className="btn btnlights">OK</button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          {cValue == true ? (
            <div className="right_content aurora_layout">
              {/* <div className="heading__top ht_heading d-flex justify-content-between align-items-center flex-wrap">
 <h2>See our below range of MyFarm investment strategies</h2>
 <Link to="/Forms" className="btn btnlights ">
   MAKE CONTRIBUTION <i class="fa fa-arrow-right" aria-hidden="true"></i>
 </Link>
</div> */}
              <div className="form snydicates">
                <h4>Payment details</h4>
                <div className="form_inner d-flex align-items-center mb-3">
                  <input type="checkbox" name="checkbox" id="kkk" onClick={CheckValues} />
                  <label className="ps-3">
                    I confirm I have read and agree to the Terms and Conditions{' '}
                  </label>
                </div>
                <div className="form_inner mt-4 d-flex align-items-center justify-content-between">
                  {/* <Link to="/SyndicatesOnes"> */}
                  <button className="previous_btn btn btnlights" onClick={() => stateChange()}>
                    PREVIOUS
                  </button>
                  {/* </Link> */}
                  {/* <Link to="/Syndicatesthree"> */}
                  <button
                    onClick={(e) => postApi(e)}
                    disabled={checkboxValues == false ? false : true}
                    className="apply_now btn btnlights"
                  >
                    APPLY NOW
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="right_content aurora_layout">
              {/* <div className="heading__top ht_heading d-flex justify-content-between align-items-center flex-wrap">
      <h2>See our below range of MyFarm investment strategies</h2>
      <Link to="/Forms" className="btn btnlights ">
        MAKE CONTRIBUTION <i class="fa fa-arrow-right" aria-hidden="true"></i>
      </Link>
    </div> */}
              <div className="form snydicates">
                <div className="form_inner d-flex align-items-center mb-3">
                  <input
                    type="checkbox"
                    id="ddd"
                    name="checkboxx"
                    onClick={hhhh}
                    disabled={linkValue == false ? false : true}
                  />
                  <label className="ps-3">
                    I have downloaded and read the Information Memorandum{' '}
                    <a
                      className="text-decoration-underline"
                      href="#"
                      onClick={validateCheck}
                      target={'_blank'}
                    >
                      here
                    </a>
                  </label>
                </div>
                <div className="form_inner mb-3">
                  <label>How much do you wish to invest in this Syndicate?</label>
                  <input
                    type="number"
                    name="text"
                    placeholder="Enter $ investment amount"
                    disabled={CheckboxValue == false ? false : true}
                    value={form.email == null || form.email == undefined || form.email == "" ? null : form.email}
                    // onChange={(e) => setAmount(e.target.value)}
                    onChange={(ev) => setContactInfo({ ...form, email: ev.target.value })}
                  />
                </div>
                <div className="form_inner mb-3">
                  <label>Your investment amount will equal $XXX units in this offer</label>
                </div>
                <div className="form_inner mb-3">
                  <label>What is your source of funds for this investment?</label>
                  <input
                    type="text"
                    name="sources"
                    placeholder="Enter your source of funds"
                    value={form.phone == null || form.phone == undefined || form.phone == "" ? null : form.phone}
                    // onChange={(e) => SetSourceFund(e.target.value)}
                    onChange={(ev) => setContactInfo({ ...form, phone: ev.target.value })}
                  />
                </div>

                <div className="form_inner mb-3 text-right">
                  {/* <Link to="/Syndicatestwo"> */}
                  <button
                    className="continue_btn btn btnlights"
                    onClick={newComponent}
                    disabled={form.email ? false : true}
                  >
                    CONTINUE
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Syndicates1;
