import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const LinksuplyIM = () => {
  const [options, setOptions] = useState([]);
  const result = () => {
    axios.get(`https://620dcdba20ac3a4eedca69ab.mockapi.io/duval/admin/documents`).then((res) => {
      setOptions(res.data);
      console.log('options1111', res.data);
    });
    console.log('options', options);
  };
  useEffect(() => {
    result();
  }, []);
  return (
    <>
      <div className="right_content">
        <div className="heading__top ht_heading">
          <h2>
            <Link>
              <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </Link>
            Link to supplementary IM (if relevant):
          </h2>
        </div>
        <div className=" ht_heading11">
          <table class="table table-striped ">
            <tbody>
              {options &&
                options.map((deta) => {
                  return (
                    <tr>
                      <td>{deta.document_name}</td>
                      <td>
                        <a className="document_btn" href={`${deta.decument_url}`}>
                          <button>Download</button>
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default LinksuplyIM;
