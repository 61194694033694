/*eslint-disable eqeqeq*/

import axios from 'axios';
import { useEffect, useState } from 'react';

const MyDocuments = () => {
  const [options, setOptions] = useState([])
  const result = () => {
    axios.get(`https://620dcdba20ac3a4eedca69ab.mockapi.io/duval/admin/documents`).then((res) => {
      setOptions(res.data);
      console.log("options1111", res.data)

    })
    console.log("options", options)
  }

  useEffect(() => {
    result();
  }, [])




  return (
    <>
      <div className="right_content  ">
        <div className="heading__top ht_heading d-flex justify-content-between align-items-center flex-wrap ">
          <h2>
            Important documents, information and forms relating to MyFarm Investments.
          </h2>
          {/* <Link to="/Forms" className='btn btnlights '>
              MAKE CONTRIBUTION <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </Link> */}
        </div>
        <div className="form p-0 m-0 border">
          <div className='main_table'>
            <table class="table table-striped ">
              <tbody>
                {
                  options && options.map((deta) => {
                    return (
                      <tr>
                        <td>{deta.document_name}</td>

                        <td><a className='document_btn' target={"_blank"} href={`${deta.decument_url} `}><button>Download</button></a></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
};
export default MyDocuments;
