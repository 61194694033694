/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link as useLocation } from 'react-router-dom';

import axios from 'axios';
import { audience, urlapi } from '../../auth/Configuration';

const Dashboard1 = () => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  const [token, setToken] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          result(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState();

  const result = async (token) => {
    setLoader(true);
    const applicationdata12 = await axios
      .get(`${urlapi}/admin/deshboard`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        setData(res.data);
        setLoader(false);
      });
  };

  return (
    <>
      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="right_content">
            <div className="heading__top ht_heading">
              <h2>
                {/* Dashboard */}
                DASHBOARD
              </h2>
            </div>
            <div className="form ">
              <div className="buttond_dashboard myfarm_form">



                <>

                  <div className="col-lg-3  col-sm-12 col-12 my-farm-ad">
                    {' '}
                    <button>
                      <h5>
                        {data.applicationStage !== undefined
                          ? data.applicationStage
                            .toString()
                            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                          : data.applicationStage}{' '}
                      </h5>
                      <p>Total Applications Submitted</p>
                    </button>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-12 my-farm-ad">
                    <button>
                      <h5>
                        $
                        {data.totalAmount !== undefined
                          ? data.totalAmount
                            .toString()
                            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                          : data.totalAmount}
                      </h5>

                      <p>Total Applications $ Submitted</p>
                    </button>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-12 my-farm-ad">
                    <button>
                      <h5>
                        $
                        {data.AMLfail !== undefined
                          ? data.AMLfail.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                          : data.AMLfail}{' '}
                      </h5>
                      <p>Total Applications $ Pending AML</p>
                    </button>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-12 my-farm-ad">
                    <button>
                      <h5>
                        $
                        {data.amlPass !== undefined
                          ? data.amlPass.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                          : data.amlPass}{' '}
                      </h5>
                      <p>Total Applications $ Passed</p>
                    </button>
                  </div>
                </>

                {/* {user.profile.includes("UR3") ? (
                  <div className=" col-lg-6  col-sm-7 col-12">
                    <Link to="/application">
                      <button>APPLICATIONS</button>
                    </Link>
                  </div>
                ) : null} */}

                {/* {user.profile.includes("UR4") ? (
                  <div className=" col-lg-6  col-sm-12 col-12">
                    {' '}
                    <Link to="/application">
                      <button>Applications – Approval & Payments</button>
                    </Link>
                  </div>
                ) : null} */}

                {/* {user.profile.includes("UR4") ? (
                  <div className="col-lg-6 col-sm-12 col-12">
                    <Link to="/client">
                      <button>CLIENTS</button>
                    </Link>
                  </div>
                ) : (
                  ''
                )} */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard1;
