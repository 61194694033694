import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { useParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Stack } from '@mui/material';
import Avatar, { ConfigProvider } from 'react-avatar';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';

import { sidebarConfigu4, sidebarConfigUR1UR2, sidebarConfigUR5, sidebarUser } from './SidebarConfig';
import account from '../../_mocks_/account';
import axios from 'axios';
import { Domain, audience, urlapi, clientId } from '../../auth/Configuration';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from '@mui/material/Modal';
import { ControlOutlined } from '@ant-design/icons';
import { useIdleTimer } from 'react-idle-timer';
// ----------------------------------------------------------------------
const DRAWER_WIDTH = 280;
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));
const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));
// ----------------------------------------------------------------------
DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const {
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    user,
    logout
  } = useAuth0();
  const { pathname } = useLocation();
  function check(token) {
    // user.profile == 'User' ? logout() : application(token);
    application(token);
  }
  // alert(user.profile)
  // alert(user.profile.includes("UR4"))
  const [verfi, setVerfi] = useState();
  const [verfimsg, setVerfimsg] = useState();
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          let token = accessidToken.__raw;
          setVerfi(token);
          check(token);
          // sessionStorage.setItem('token', token);
        } else {
        }
      }
    } catch (e) { }
  };
  const getUserMetadataRef = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          let token = accessidToken.__raw;
          setVerfi(token);
          // sessionStorage.setItem('token', token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadataRef();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);
  // let firstName = sessionStorage.getItem('firstName');
  // let LastName= sessionStorage.getItem('LastName')
  const [verifyloader, setVerifyloader] = useState();
  const [admindata, setAdmindata] = useState('');

  const application = async (token) => {
    if (isAuthenticated) {
      const applicationdata1 = await axios
        .get(`${urlapi}/admin/profile`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          // console.log('resresresres', res);
          setAdmindata(res.data);
          sessionStorage.setItem('roles', res.data.role);
          // setFirstName(
          //   applicationdata1.data != 'undefined' && applicationdata1.data !== null
          //     ? applicationdata1.data.firstName
          //     : ''
          // );
          // setLastName(
          //   applicationdata1.data != 'undefined' && applicationdata1.data !== null
          //     ? applicationdata1.data.lastName
          //     : ''
          // );
          // setfullLegalName(
          //   applicationdata1.data != 'undefined' && applicationdata1.data !== null
          //     ? applicationdata1.data.fullLegalName
          //     : ''
          // );
          // setRoleName(
          //   applicationdata1.data != 'undefined' && applicationdata1.data !== null
          //     ? applicationdata1.data.roleName
          //     : ''
          // );
          // setfullLegalName(
          //   applicationdata1.data != 'undefined' && applicationdata1.data !== null
          //   ? applicationdata1.data.adminEmail
          //   : ''
          // )
        });
    }
  };
  // useEffect(()=>{
  //   application();
  // },[])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const llogggout = () => {
    sessionStorage.clear();
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //  const resetdata = ()=>{
  //   alert("hello");

  const resetdata = async () => {
    setVerifyloader(true);
    if (isAuthenticated) {
      const restPassword = await axios
        .put(
          `${urlapi}/admin/update/password`,
          {},
          {
            headers: { Authorization: `Bearer ${verfi}` }
          }
        )
        .then((restPassword) => {
          setVerifyloader(false);
          setVerfimsg(restPassword.data);
        });
    }
  };
  // }
  const { id } = useParams();

  const timeout = 900000;
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [isIdle, setIsIdle] = useState(false);

  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);
  const { reset, pause, resume, getRemainingTime, getLastActiveTime, getElapsedTime } =
    useIdleTimer({
      timeout,
      onActive: handleOnActive,
      onIdle: handleOnIdle
    });
  // console.log('remaining', remaining);
  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();
  useEffect(() => {
    setRemaining(getRemainingTime());
    setLastActive(getLastActiveTime());
    setElapsed(getElapsedTime());
    setInterval(() => {
      setRemaining(getRemainingTime());
      setLastActive(getLastActiveTime());
      setElapsed(getElapsedTime());
    }, 1000);
  }, []);

  if (remaining == 0) {
    sessionStorage.clear();
    localStorage.clear();
    logout();
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box> */}
      <div className="Nav__Top__B">
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <div class="nav_bg nav_logo_main">
            <div class="nav_logo circle-box">
              <ConfigProvider colors={['#dcd4cc', '#dcd4cc', '#dcd4cc']}>
                <Avatar
                  className="avatarname"
                  name={
                    (admindata.firstName != null ? admindata.firstName : '') +
                    ' ' +
                    (admindata.lastName != null ? admindata.lastName : '')
                  }
                />
              </ConfigProvider>{' '}
            </div>
            <div onClick={handleOpen} className="adminProfile">
              <a href="#">{admindata.fullLegalName}</a>
              <h5 className="text-center mt-2">
                {' '}
                {admindata.role == 'UR1'
                  ? 'Super Admin'
                  : admindata.role == 'UR2'
                    ? 'Compliance User'
                    : admindata.role == 'UR3'
                      ? 'Marketing'
                      : admindata.role == 'UR4'
                        ? 'Approver'
                        : admindata.role == 'UR5'
                          ? 'Staff'
                          : ''}
              </h5>
            </div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box className="formPopUp">
                <h4>Admin Profile</h4>
                <div className="edit_form">
                  <div className="form_input row fullinput">
                    <div className="col-md-6">
                      <label>Name</label>
                    </div>
                    <div className="col-md-6">
                      <p>{admindata.fullLegalName}</p>
                    </div>
                  </div>
                  <div className="form_input row fullinput">
                    <div className="col-md-6">
                      <label>Email Address</label>
                    </div>
                    <div className="col-md-6">
                      <p>{admindata.adminEmail}</p>
                    </div>
                  </div>
                  <p>{verfimsg}</p>
                  <p></p>
                  <div className="submit_btn submit_btn_admin">
                    {verifyloader === true ? (
                      <>
                        <button>
                          {' '}
                          Loading ..... <i class="fas fa-circle-notch fa-spin"></i>
                        </button>
                      </>
                    ) : (
                      <button onClick={resetdata}>Reset Password </button>
                    )}
                    {/* {verifyloader === true ? (
                      <div className="main-loader">
                        <div className="loader"></div>
                      </div>
                    ) : null} */}
                    <button onClick={handleClose}>Cancel</button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </Box>
        {
          user.profile == 'User' ?
            <NavSection navConfig={sidebarUser} /> :
            user.profile.includes("UR1") || user.profile.includes("UR2") ? (
              < NavSection navConfig={sidebarConfigUR1UR2} />
            ) : user.profile.includes("UR5") ? (
              < NavSection navConfig={sidebarConfigUR5} />
            ) : (
              <NavSection navConfig={sidebarConfigu4} />
            )}
      </div>
      <Box sx={{ mb: 5, mx: 2.5 }}>
        <div className="mt-2">
          <button onClick={llogggout}>Log out</button>
        </div>
      </Box>
      {/* <Box sx={{ flexGrow: 1 }} /> */}
    </Scrollbar>
  );
  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
