/*eslint-disable eqeqeq*/

import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { useRecoilValue } from 'recoil';
import { userdataState } from '../Action';
import ExpirePopup from './ExpirePopup';
import ExpirePopupId from './ExpirePopupId';
import Swal from 'sweetalert2';
import { signUrl } from 'src/auth/Configuration';

const DashbordInvesta = () => {
  const myusers = useRecoilValue(userdataState);
  const IdExpire = myusers && myusers[0].documentExpiryDate;
  const invest = myusers ? myusers && myusers[0].investmentType : '';
  const updateDate = invest != "Joint" ? (myusers && myusers[0].certificateDetails ? myusers[0].certificateDetails.updatedAt : "") : (myusers && myusers[1].certificateDetails ? myusers[1].certificateDetails.updatedAt : "");

  const [dataaa, setVal] = useState(false);
  const [isPop, setIPop] = useState("true");



  useEffect(() => {
    // setVal(true)
    setInterval(() => {
      setVal(true)
    }, 4000);
  }, []);
  function myFunction() {
    // window.open(`https://myfarmnewuser-dev.invsta.io`);

    Swal.fire({
      html: `<p class="swal_peragraph">You will be taken to another browser window and asked to either Start New Application, or Continue Exiting application. Please click on the option to Continue Existing application, you will then be able to login to the onboarding site, and enter the details of the new investment entity.</p>`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Create New Entity Now',
      cancelButtonText: "Cancel"
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(`${signUrl}`)
      }

    })
  }
  // {dataaa && <Api2 />}
  useEffect(() => {
    var test = sessionStorage.getItem("isIdPop");

    if (test == "false" || test == "true"
    ) {
      setIPop(test);

    } else {

      setIPop("true");

    }
  }, [])
  return (
    <>
      <div className="right_content aurora_layout">
        <div className="heading__top ht_heading d-flex justify-content-between align-items-center flex-wrap">
          <h2>DASHBOARD</h2>

          {/* <ExpirePopup expirationDates={updateDate} /> */}
          <button
            // to="/Loginpage"
            onClick={myFunction}
            className="btn btnlights btnlights22 "
          >
            Add Account <i class="fa fa-arrow-right" aria-hidden="false"></i>
          </button>
        </div>
        <div className="form">
          {myusers == 0 ? <div className="main-loader">
            <div className="loader"></div>
          </div> :
            <>
              {myusers == "Data not found" ?
                "Data not found"
                : "Data Found"}
            </>
          }
          {isPop}
          {
            isPop == "true" && (invest) && <ExpirePopup expirationDates={updateDate} invest={invest} />
          }


          {(isPop == "true" && dataaa && invest) && <ExpirePopupId expirationDatesId={IdExpire} invest={invest} />}

        </div>
      </div>
    </>
  );
};
export default DashbordInvesta;
