import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import React from 'react';
//
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import ApplicationDetails from './pages/signatures/ApplicationDetails';
// import ApplicationDetails2 from './pages/signatures/ApplicationDetails2';
import ApplicationDetailsIndi from './pages/signatures/ApplicationDetailsIndi';
import SubscriptionAgreement from './pages/signatures/SubscriptionAgreement';
import AllApplication from './pages/signatures/AllApplication';
import Client from './pages/signatures/Client';
import Dashboard1 from './pages/signatures/Dashboard1';
import Example from './pages/signatures/SyndicatesPopup';

import Newsresearch from './pages/signatures/Newsresearch';
import Syndicates from './pages/signatures/Syndicates';
import Internalusers from './pages/signatures/Internalusers';
import Notification from './pages/signatures/Notification';

import History from './pages/signatures/History';
import InfoDetails from './pages/signatures/Syndicates/InfoDetails';
import CloseInfoDetails from './pages/signatures/Syndicates/CloseInfoDetails';

import AdminUser from './pages/signatures/AdminUser';

import LinkToIm from './pages/signatures/Syndicates/LinkToIm';
import LinksuplyIM from './pages/signatures/Syndicates/LinksuplyIM';
import SyndicateAdd from './pages/signatures/SynducateAdd';
import Investments from './pages/signatures/Investments';
import ClientMantanance from './pages/signatures/ClientMantanance';
import FailAddressIndi from './pages/signatures/FailAddressIndi';
import LiveSyndicateTable from './pages/signatures/LiveSyndicateTable';
import SyndicateDetails from './pages/signatures/SyndicateDetails';
import Transactions from './InvestorPages.js/pages/Transactions';
import InvestAmount from './pages/signatures/InvestAmount';

// import ClinetDetails from './pages/signatures/ClinetDetails';

// ----------------------------------------------------------------------
//SubscriptionAgreementCom
export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: 'applicationdetails/:id', element: <ApplicationDetails /> },
        // { path: 'applicationdetails2/:id', element: <ApplicationDetails2 /> },
        { path: 'applicationdetailsindi/:id', element: <ApplicationDetailsIndi /> },
        { path: 'addressFailClient/:id', element: <FailAddressIndi /> },
        { path: 'liveSyndicate/:id', element: <SyndicateDetails /> },
        { path: 'investPageAmount/:id', element: <InvestAmount /> },
        { path: 'SubscriptionAgreement/:id', element: <SubscriptionAgreement /> },
        { path: 'application', element: <AllApplication /> },
        { path: 'Newsresearch', element: <Newsresearch /> },
        { path: 'Syndicates', element: <Syndicates /> },
        { path: 'syndicateAdd', element: <SyndicateAdd /> },
        { path: 'syndicateAdds/:id', element: <SyndicateAdd /> },
        { path: 'Internalusers', element: <Internalusers /> },
        { path: 'Notification', element: <Notification /> },
        { path: 'Example', element: <Example /> },
        { path: 'InfoDetails', element: <InfoDetails /> },
        { path: 'CloseInfoDetails', element: <CloseInfoDetails /> },
        { path: 'admins/:id', element: <AdminUser /> },
        { path: 'LinkToIm', element: <LinkToIm /> },
        { path: 'LinksuplyIM', element: <LinksuplyIM /> },
        { path: 'clientMaintanance', element: <ClientMantanance /> },

        // { path: 'ClinetDetails/:id', element: <ClinetDetails /> },

        { path: 'client', element: <Client /> },
        { path: 'investment', element: <Investments /> },
        { path: 'Dashboard1', element: <Dashboard1 /> },
        { path: 'History/:id', element: <History /> },
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'Transaction/:id', element: <Transactions /> }

      ]
    },

    { path: '*', element: <Navigate to="/dashboard" /> }
  ]);
}
