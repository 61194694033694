import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Domain, audience, urlapi } from 'src/auth/Configuration';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, Button } from 'antd';
import axios from 'axios';
import Swal from 'sweetalert2';

const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix]);
  return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>;
};
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};
const SyndicateAdd = () => {
  const getInitialData1 = {
    heading: '',
    vedioThumb: '',
    subHeading: '',
    paragraph: '',
    imageUrl: '',
    vedioUrl: ''
  };
  const navigate = useNavigate();
  const { id } = useParams();
  // alert(id);
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();
  const [token, setToken] = useState();
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          if (id) {
            result(accessidToken.__raw);
          }
          //    formData.token =
          //  sessionStorage.setItem('token', token);

          //   result(token);
          // Notessfunc(token);
        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    getUserMetadata();
  }, []);
  const [inputDataList, setinputDataList] = useState([]);
  const handleAddClick = () => {
    setinputDataList([...inputDataList, getInitialData1]);
  };
  const handleRemoveClick = (index, e) => {
    const newList = [...inputDataList];
    newList.splice(index, 1);
    setinputDataList(newList);
  };
  const [loader, setLoader] = useState();
  const [data, setData] = useState();
  const onFinish = async (value) => {
    console.log("hhh", inputDataList);
    const hhh = {
      title: value.name.title,
      subtitle: value.name.subtitle,
      sector: value.name.sector,
      percentageValue: value.name.percentageValue,
      minInvestment: value.name.minInvestment,
      syndicate_docs: inputDataList.length == 0 ? [getInitialData1] : inputDataList
    };
    setLoader(true);
    // value.name.syndicate_docs = inputDataList;

    // alert(value.name.title);
    console.log(hhh);
    // window.location.reload(true);
    if (id) {
      await axios
        .put(`${urlapi}/admin/syndicate/action/${id}`, hhh, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((results) => {
          if (results.status == 200) {
            setLoader(false);
            navigate('/Syndicates');
            //   setModal(!modal);
            //   result(token);
          } else {
            setLoader(false);
            Swal.fire({
              text: results.data.message
            });
          }
        });
    } else {
      await axios
        .post(`${urlapi}/admin/syndicate/actions`, hhh, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((results) => {
          if (results.status == 200) {
            setLoader(false);
            navigate('/Syndicates');
            //   setModal(!modal);
            //   result(token);
          } else {
            setLoader(false);
            Swal.fire({
              text: results.data.message
            });
          }
        });
    }
  };
  let userid = sessionStorage.getItem('id');
  const result = async (token) => {
    setLoader(true);

    axios
      .get(`${urlapi}/admin/syndicate/action/${userid}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          // setLoader(false);
        }

        console.log('options1111', res.data);
        setinputDataList(res.data.syndicate_docs);
      });
  };

  console.log('ggggg', inputDataList);
  console.log('eeee', getInitialData1);
  // alert(data && data.subtitle);
  // if (id && data) {
  //   inputDataList = data.syndicate_docs;
  // }
  return (
    <>
      <div className="right_content">
        <div className="heading__top ht_heading">
          <h2>
            <Link to="/Syndicates">
              <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </Link>
            Syndicates
          </h2>
        </div>

        <div className="form add_syndecate">
          <Form className="anttd_form" name="form_item_path" layout="vertical" onFinish={onFinish}>
            {data ? (
              <MyFormItemGroup prefix={['name']}>
                <MyFormItem name="title" label="Title">
                  <Input defaultValue={data && data.title} />
                </MyFormItem>
                <MyFormItem name="subtitle" label="Sub Title">
                  <Input defaultValue={data && data.subtitle} />
                </MyFormItem>
                <MyFormItem name="sector" label="Sector">
                  <Input defaultValue={data && data.sector} />
                </MyFormItem>
                <MyFormItem name="percentageValue" label="Return">
                  <Input defaultValue={data && data.percentageValue} />
                </MyFormItem>
                <MyFormItem name="minInvestment" label="Minimum Investment">
                  <Input defaultValue={data && data.minInvestment} />
                </MyFormItem>

                {inputDataList &&
                  inputDataList.map((x, index) => {
                    return (
                      <>
                        <div className="addSection_title">
                          <h4>Section Detail</h4>
                        </div>
                        <div className={'bdetail addsection' + index + 1 + '' + 'box'}>
                          <Row>
                            <Col lg={12}>
                              <label>Heading </label>
                              <input
                                type="text"
                                placeholder="Heading"
                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                className="validation_name w-100"
                                onChange={(event) => {
                                  //   removeError(event);
                                  //   setForm(event);
                                  const secArray = inputDataList;
                                  secArray[index].heading = event.target.value;
                                  setinputDataList([...secArray]);
                                  console.log(inputDataList);
                                }}
                                name="heading"
                                value={inputDataList[index].heading}
                              />
                              <span className={`full_legal_name${index} error`}></span>
                            </Col>
                            <Col lg={12}>
                              <label>Sub Heading</label>
                              <input
                                type="text"
                                placeholder="Sub Heading"
                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                className="validation_name w-100"
                                onChange={(event) => {
                                  //   removeError(event);
                                  //   setForm(event);
                                  const secArray = inputDataList;
                                  secArray[index].subHeading = event.target.value;
                                  setinputDataList([...secArray]);
                                  console.log(inputDataList);
                                }}
                                name="subHeading"
                                value={inputDataList[index].subHeading}
                              />
                              <span className={`full_legal_name${index} error`}></span>
                            </Col>
                            <Col lg={12}>
                              <label>Paragraph</label>
                              <textarea
                                type="text"
                                placeholder="Paragraph"
                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                className="validation_name w-100"
                                onChange={(event) => {
                                  //   removeError(event);
                                  //   setForm(event);
                                  const secArray = inputDataList;
                                  secArray[index].paragraph = event.target.value;
                                  setinputDataList([...secArray]);
                                  console.log(inputDataList);
                                }}
                                name="paragraph"
                                value={inputDataList[index].paragraph}
                              />
                              <span className={`full_legal_name${index} error`}></span>
                            </Col>
                            <Col lg={12}>
                              <label>Image URL</label>
                              <input
                                type="text"
                                placeholder="Image URL"
                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                className="validation_name w-100"
                                onChange={(event) => {
                                  //   removeError(event);
                                  //   setForm(event);
                                  const secArray = inputDataList;
                                  secArray[index].imageUrl = event.target.value;
                                  setinputDataList([...secArray]);
                                  console.log(inputDataList);
                                }}
                                name="imageUrl"
                                value={inputDataList[index].imageUrl}
                              />
                              <span className={`full_legal_name${index} error`}></span>
                            </Col>
                            <Col lg={12}>
                              <label>Vedio URL</label>
                              <input
                                type="text"
                                placeholder="Vedio URL"
                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                className="validation_name w-100"
                                onChange={(event) => {
                                  //   removeError(event);
                                  //   setForm(event);
                                  const secArray = inputDataList;
                                  secArray[index].vedioUrl = event.target.value;
                                  setinputDataList([...secArray]);
                                  console.log(inputDataList);
                                }}
                                name="vedioUrl"
                                value={inputDataList[index].vedioUrl}
                              />
                              <span className={`full_legal_name${index} error`}></span>
                            </Col>
                            <Col lg={12}>
                              <label>Vedio Thumb Nail</label>
                              <input
                                type="text"
                                placeholder="Vedio Thumb Nail"
                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                className="validation_name w-100"
                                onChange={(event) => {
                                  //   removeError(event);
                                  //   setForm(event);
                                  const secArray = inputDataList;
                                  secArray[index].vedioThumb = event.target.value;
                                  setinputDataList([...secArray]);
                                  console.log(inputDataList);
                                }}
                                name="vedioThumb"
                                value={inputDataList[index].vedioThumb}
                              />
                              <span className={`full_legal_name${index} error`}></span>
                            </Col>
                          </Row>

                          <div className="indu_remove_btn row">
                            <Col className="revove_btn mb-3" lg={12}>
                              <div className="removebtn2">
                                {inputDataList.length > 1 && (
                                  <a
                                    className="removebtn syndicates_btn edite_btn "
                                    onClick={(e) => handleRemoveClick(index, e)}
                                    name={x.fullLegalName}
                                    value={x.userEmail}
                                  // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                  >
                                    Remove
                                  </a>
                                )}
                              </div>
                            </Col>
                          </div>
                        </div>
                      </>
                    );
                  })}
                <div className="fullinput indiviual_inp row">
                  <Col sm={12}>
                    <div className="Trust_ind_btn">
                      {inputDataList.length <= 3 ? (
                        <a
                          className="syndicates_btn edite_btn Individual "
                          onClick={handleAddClick}
                        // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                        >
                          Add Section
                        </a>
                      ) : null}
                    </div>
                  </Col>
                </div>
              </MyFormItemGroup>
            ) : id ? (
              ''
            ) : (
              <MyFormItemGroup prefix={['name']}>
                <MyFormItem name="title" label="Title">
                  <Input defaultValue={data && data.title} />
                </MyFormItem>
                <MyFormItem name="subtitle" label="Sub Title">
                  <Input defaultValue={data && data.subtitle} />
                </MyFormItem>
                <MyFormItem name="sector" label="Sector">
                  <Input />
                </MyFormItem>
                <MyFormItem name="percentageValue" label="Return">
                  <Input type='number' />
                </MyFormItem>
                <MyFormItem name="minInvestment" label="Minimum Investment">
                  <Input type='number' />
                </MyFormItem>

                {inputDataList.map((x, index) => {
                  return (
                    <>
                      <div className="addSection_title">
                        <h4>Section Detail</h4>
                      </div>
                      <div className={'bdetail addsection' + index + 1 + '' + 'box'}>
                        <Row>
                          <Col lg={12}>
                            <label>Heading </label>
                            <input
                              type="text"
                              placeholder="Heading"
                              // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                              className="validation_name w-100"
                              onChange={(event) => {
                                //   removeError(event);
                                //   setForm(event);
                                const secArray = inputDataList;
                                secArray[index].heading = event.target.value;
                                setinputDataList([...secArray]);
                                console.log(inputDataList);
                              }}
                              name="heading"
                              value={inputDataList[index].heading}
                            />
                            <span className={`full_legal_name${index} error`}></span>
                          </Col>
                          <Col lg={12}>
                            <label>Sub Heading</label>
                            <input
                              type="text"
                              placeholder="Sub Heading"
                              // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                              className="validation_name w-100"
                              onChange={(event) => {
                                //   removeError(event);
                                //   setForm(event);
                                const secArray = inputDataList;
                                secArray[index].subHeading = event.target.value;
                                setinputDataList([...secArray]);
                                console.log(inputDataList);
                              }}
                              name="subHeading"
                              value={inputDataList[index].subHeading}
                            />
                            <span className={`full_legal_name${index} error`}></span>
                          </Col>
                          <Col lg={12}>
                            <label>Paragraph</label>
                            <textarea
                              type="text"
                              placeholder="Paragraph"
                              // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                              className="validation_name w-100"
                              onChange={(event) => {
                                //   removeError(event);
                                //   setForm(event);
                                const secArray = inputDataList;
                                secArray[index].paragraph = event.target.value;
                                setinputDataList([...secArray]);
                                console.log(inputDataList);
                              }}
                              name="paragraph"
                              value={inputDataList[index].paragraph}
                            />
                            <span className={`full_legal_name${index} error`}></span>
                          </Col>
                          <Col lg={12}>
                            <label>Image URL</label>
                            <input
                              type="text"
                              placeholder="Image URL"
                              // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                              className="validation_name w-100"
                              onChange={(event) => {
                                //   removeError(event);
                                //   setForm(event);
                                const secArray = inputDataList;
                                secArray[index].imageUrl = event.target.value;
                                setinputDataList([...secArray]);
                                console.log(inputDataList);
                              }}
                              name="imageUrl"
                              value={inputDataList[index].imageUrl}
                            />
                            <span className={`full_legal_name${index} error`}></span>
                          </Col>
                          <Col lg={12}>
                            <label>Vedio URL</label>
                            <input
                              type="text"
                              placeholder="Vedio URL"
                              // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                              className="validation_name w-100"
                              onChange={(event) => {
                                //   removeError(event);
                                //   setForm(event);
                                const secArray = inputDataList;
                                secArray[index].vedioUrl = event.target.value;
                                setinputDataList([...secArray]);
                                console.log(inputDataList);
                              }}
                              name="vedioUrl"
                              value={inputDataList[index].vedioUrl}
                            />
                            <span className={`full_legal_name${index} error`}></span>
                          </Col>
                          <Col lg={12}>
                            <label>Vedio Thumb Nail</label>
                            <input
                              type="text"
                              placeholder="Vedio Thumb Nail"
                              // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                              className="validation_name w-100"
                              onChange={(event) => {
                                //   removeError(event);
                                //   setForm(event);
                                const secArray = inputDataList;
                                secArray[index].vedioThumb = event.target.value;
                                setinputDataList([...secArray]);
                                console.log(inputDataList);
                              }}
                              name="vedioThumb"
                              value={inputDataList[index].vedioThumb}
                            />
                            <span className={`full_legal_name${index} error`}></span>
                          </Col>
                        </Row>

                        <div className="indu_remove_btn row">
                          <Col className="revove_btn mb-3" lg={12}>
                            <div className="removebtn2">
                              {inputDataList.length > 1 && (
                                <a
                                  className="removebtn syndicates_btn edite_btn "
                                  onClick={(e) => handleRemoveClick(index, e)}
                                  name={x.fullLegalName}
                                  value={x.userEmail}
                                // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                                >
                                  Remove
                                </a>
                              )}
                            </div>
                          </Col>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="fullinput indiviual_inp row">
                  <Col sm={12}>
                    <div className="Trust_ind_btn">
                      {inputDataList.length <= 3 ? (
                        <a
                          className="syndicates_btn edite_btn Individual "
                          onClick={handleAddClick}
                        // disabled={formData.is_in_relation_with_main == 1 ? true : false}
                        >
                          Add Section
                        </a>
                      ) : null}
                    </div>
                  </Col>
                </div>
              </MyFormItemGroup>
            )}
            <div className="row">
              <Col lg={12}>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="syndicates_btn edite_btn submit "
                >
                  Submit
                </Button>
              </Col>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default SyndicateAdd;
