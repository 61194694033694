import React, { useState, useEffect } from 'react';
import { audience, urlapi } from '../../auth/Configuration';
import NotificationTable from './NotificationTable';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import CreateNotification from './CreateNewNotification';
import ViewNotification from './ViewNotification';
import Collapse from 'react-bootstrap/Collapse';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { removeUsers, syndicatevalue } from 'src/InvestorPages.js/Action';
import { useRecoilState, useRecoilValue } from 'recoil';


const Notification = () => {
    const { getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [token, setToken] = useState();
    const [loader, setLoader] = useState();
    const [openCo, setopenCo] = useState(false);
    const [modal, setModal] = useState(false);
    const getUserMetadata = async () => {
        try {
            await getAccessTokenSilently({
                audience: audience,
                scope: 'read:current_user'
            });
        } catch (e) { }
        try {
            const accessidToken = await getIdTokenClaims({
                audience: audience,
                scope: 'read:current_user'
            });
            {
                if (
                    accessidToken.__raw !== 'undefined' ||
                    accessidToken.__raw !== null ||
                    accessidToken.__raw !== undefined
                ) {
                    setToken(accessidToken.__raw);
                    let token = accessidToken.__raw;
                    //    formData.token =
                    //    sessionStorage.setItem("token",formData.token )

                    application(token);
                } else {
                }
            }
        } catch (e) { }
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            getUserMetadata();
        }, 900000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        getUserMetadata();
    }, []);

    const [users, setUsers] = useState();
    const application = async (token) => {
        setLoader(true);

        if (isAuthenticated) {

            const applicationdata12 = await axios
                .get(`${urlapi}/admin/syndicate/actions`, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((applicationdata12) => {
                    setLoader(false);

                    setUsers(applicationdata12.data.reverse());

                });
            // }
        }
    };
    const toggle = () => setModal(!modal);
    const show = () => setModal(!modal);
    const [hide, setHide] = useState(false)
    const [nextPage, sendNotifi] = useState(false)
    const [activeClient, setActiveClient] = useState(false)
    const [opensyndicate, setOpensyndicate] = useState(false)
    const [closesyndicate, setClosesyndicate] = useState(false)
    const [individualSyndi, setndividualSyndi] = useState(false)
    $('input.checkat').on('change', function () {
        $('input.checkat').not(this).prop('checked', false);
    });
    const [description, StepDescription] = useState();
    const [titleNotifi, setTitle] = useState();
    const [list, setList] = useState([]);
    const val = useRecoilValue(syndicatevalue);
    const [syncValue, setSyncValue] = useRecoilState(syndicatevalue);
    const [selectoption, setError] = useState();
    const [titleError, setTitleError] = useState();
    const [desError, setDesError] = useState();
    const Valid = () => {
        if (activeClient != true && opensyndicate != true && closesyndicate != true && individualSyndi != true) {
            setError(true)
        } else if (titleNotifi == undefined || titleNotifi == null || titleNotifi == '') {
            setTitleError(true);
        }
        else if (description == undefined || description == null || description == '') {
            setDesError(true);
        }
        else {
            setHide(true)

        }
    }
    const sendNotification = (allsyndicate) => {

        if (activeClient != true && opensyndicate != true && closesyndicate != true && individualSyndi != true) {
            setError(true)
        } else if (titleNotifi == undefined || titleNotifi == null || titleNotifi == '') {
            setTitleError(true);
        }
        else if (description == undefined || description == null || description == '') {
            setDesError(true);
        }
        else {
            setLoader(true);

            var ids = [];
            list && list.map((val) => {
                ids.push(val.id);
            })
            if (allsyndicate == "all") {
                var data =
                {
                    "syndicate_id": val,
                    "type": "All",
                    "title": titleNotifi,
                    "description": description

                }

            } else {
                var data = activeClient ? {
                    "type": "All Active Client",

                    "title": titleNotifi,
                    "description": description
                } : {
                    "type": "Individual client",
                    "user_id": ids,
                    "title": titleNotifi,
                    "description": description
                }
            }


            axios
                .post(`${urlapi}/admin/user/insert/notification`, [data], {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then(async (applicationdata12) => {
                    setSyncValue(0);
                    setSearData('');
                    setList('')
                    console.log("after", val)
                    setError('')
                    setTitleError('')
                    setDesError('')
                    setLoader(false);
                    setActiveClient(false)
                    setOpensyndicate(false)
                    setClosesyndicate(false)
                    setndividualSyndi(false)
                    StepDescription('')
                    setTitle('')


                    Swal.fire({
                        text: "Notification sent"
                    });
                    // setUsers(applicationdata12.data.reverse());


                }).catch(() => {
                    Swal.fire({
                        text: "error"
                    });
                    setLoader(false);
                }).finally(() => {
                    setSyncValue(0);
                    setSearData('');
                    setList('')
                    console.log("after", val)
                    setError('')
                    setTitleError('')
                    setDesError('')

                })
        }




    }

    const [searchValue, setSearchValue] = useState();
    const [searData, setSearData] = useState();
    const [dataShow, setDataShow] = useState(false);




    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //         if (searData != null && searData != '' && searData != 'undefined') {
    //             // setRunning(true);
    //         }
    //         var data = { "userName": searData }
    //         setSearchValue("");

    //         axios
    //             .put(`${urlapi}/admin/user/search/notification`, data, {
    //                 headers: { Authorization: `Bearer ${token}` }
    //             })
    //             .then((result) => {
    //                 // setRunning(false);
    //                 setSearchValue(result.data);
    //                 setDataShow(true)

    //             }).catch(() => {
    //                 setDataShow(false);
    //             })
    //     }, 1000);
    //     return () => clearTimeout(delayDebounceFn);
    // }, [searData]);
    const showed = () => {
        setDataShow(false)

    }
    const [miniLoader, setMiniLoader] = useState(false);
    const CallApi = (event) => {
        const delayDebounceFn = setTimeout(() => {
            if (searData != null && searData != '' && searData != 'undefined') {
                setMiniLoader(true);
            }
            var data = { "userName": searData }
            setSearchValue("");
            setMiniLoader(true)
            axios
                .put(`${urlapi}/admin/user/search/notification`, data, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((result) => {
                    // setRunning(false);
                    setSearchValue(result.data);
                    setDataShow(true)
                    setMiniLoader(false);


                }).catch(() => {
                    setDataShow(false);
                    setMiniLoader(false);

                })
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }
    var lists;
    // console.log("jjj", lists)
    console.log("22", list)
    const CrossBtn = (id, index) => {
        // const removeItem = (itemId) => {
        const updatedArray = list.filter((item) => item.id !== id);
        setList(updatedArray);
        //   };
    }
    const [rrId, setrrId] = useRecoilState(removeUsers);
    console.log(users, "kkk")

    return (
        <>

            {loader == true ? (
                <div className="main-loader">
                    <div className="loader"></div>
                </div>
            ) : (<>
                <div className="right_content">
                    <div className="heading__top ht_heading" >
                        <h2>
                            <span onClick={() => {
                                setHide(false)
                                sendNotifi(false)

                            }}><i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
                            </span>
                            Create Notification
                        </h2>
                    </div>
                    <div className="form director">
                        <div className="myfarm_com">
                            {/* <CreateNotification /> */}

                            {hide == false ? <>

                                <div className=' mb-3'>
                                    <div className='create_new_notif'>
                                        <h4
                                            onClick={() => setopenCo(!openCo)}
                                            aria-controls="example-collapse-text2_compliance_c2"
                                            aria-expanded={openCo}
                                            className="drop_icon Completed_apllicatin_icon"
                                        >
                                            Create New Notification
                                        </h4>
                                    </div>
                                    {loader === true ? (
                                        <div className="note-main-loader">
                                            <div className="loader"></div>
                                        </div>
                                    ) : (
                                        <>
                                            <Collapse in={openCo}>
                                                <div className="new_notification">
                                                    <div className="myfarm_com">
                                                        <div id="create_details">
                                                            <div className='select_notif'>
                                                                <p className='fw-bold fs-6'>Select who to send notification to</p>
                                                                <div className="noti_items">
                                                                    <input type="checkbox" name='all_active' id='active_client' className='checkat' onChange={(e) => {
                                                                        setError('');
                                                                        if (e.target.checked == true) {
                                                                            setActiveClient(true);
                                                                            setOpensyndicate(false);
                                                                            setClosesyndicate(false);
                                                                            setndividualSyndi(false);
                                                                            sendNotifi(false);





                                                                        } else {
                                                                            setActiveClient(false);

                                                                        }
                                                                    }} />
                                                                    <label>All Active Clients</label>
                                                                </div>
                                                                <div className="noti_items">
                                                                    <input type="checkbox" id='opensy' className='checkat' onChange={(e) => {
                                                                        setError('');

                                                                        if (e.target.checked == true) {
                                                                            sendNotifi(true)
                                                                            setOpensyndicate(true);
                                                                            setActiveClient(false);
                                                                            setClosesyndicate(false);
                                                                            setndividualSyndi(false);




                                                                        }
                                                                        else {
                                                                            sendNotifi(false)
                                                                            setOpensyndicate(false);


                                                                        }
                                                                    }
                                                                    } />
                                                                    <label>Select from Open Syndicates</label>
                                                                </div>
                                                                <div className="noti_items">
                                                                    <input type="checkbox" id='closesy' className='checkat' onChange={(e) => {
                                                                        setError('');

                                                                        if (e.target.checked == true) {
                                                                            sendNotifi(true);
                                                                            setClosesyndicate(true);
                                                                            setOpensyndicate(false);
                                                                            setActiveClient(false);
                                                                            setndividualSyndi(false);
                                                                        }
                                                                        else {
                                                                            sendNotifi(false);
                                                                            setClosesyndicate(false);






                                                                        }
                                                                    }
                                                                    } />
                                                                    <label>Select from Closed Syndicates</label>
                                                                </div>
                                                                <div className="noti_items">
                                                                    <input type="checkbox" id='indi_client' className='checkat' onClick={(e) => {
                                                                        setError('');

                                                                        if (e.target.checked == true) {
                                                                            setndividualSyndi(true);
                                                                            setOpensyndicate(false);
                                                                            setActiveClient(false);
                                                                            setClosesyndicate(false);
                                                                            sendNotifi(false);


                                                                        } else {
                                                                            setndividualSyndi(false);


                                                                        }

                                                                    }} />
                                                                    <label>Select individual Clients</label>
                                                                </div>

                                                                {individualSyndi == true ?
                                                                    <>
                                                                        <div className="search_indi">
                                                                            <label>Search Individual</label>
                                                                            <input type="search" placeholder='Search' value={searData} onChange={(e) => {
                                                                                setError('');

                                                                                setSearData(e.target.value, token);
                                                                                CallApi(e.target.value)
                                                                            }} />

                                                                        </div>
                                                                        {/* {searchValue && searchValue.map((val) => {
                                                                            return <p>
                                                                                {val.fullLegalName}
                                                                            </p>
                                                                        })} */}

                                                                    </>
                                                                    : ""}
                                                            </div>
                                                            {selectoption == true ? <div className='noti_error'>Please select any box</div> : ""}
                                                            {/* {
                                                                miniLoader == true ? "jitu" :
                                                                    <>

                                                                        {dataShow == true && searData
                                                                            ? searchValue &&
                                                                            searchValue.map((ttt) => {
                                                                                // console.log("kkkk", ttt);
                                                                                return (
                                                                                    <button
                                                                                        className="indiSearch"
                                                                                        onClick={(e) => {
                                                                                            // formData.companyName = ttt.entityName
                                                                                            // formData.companyNumber = ttt.nzbn
                                                                                            // Companysearch(ttt.nzbn);
                                                                                            // setcomNumber(ttt.nzbn)
                                                                                            setSearData(ttt.fullLegalName)
                                                                                            showed();
                                                                                            var map = {
                                                                                                "id": ttt.id,
                                                                                                "userName": ttt.fullLegalName
                                                                                            }
                                                                                            setList([...list, map])
                                                                                        }}
                                                                                    >
                                                                                        {ttt.fullLegalName}
                                                                                    </button>
                                                                                );
                                                                            })
                                                                            : ""}
                                                                    </>
                                                            } */}
                                                            {miniLoader == true ? <div class="spinner-border" role="status">
                                                                <span class="sr-only" >Loading...</span>
                                                            </div> :
                                                                <>
                                                                    {dataShow == true && searData
                                                                        ? searchValue &&
                                                                        searchValue.map((ttt) => {
                                                                            // console.log("kkkk", ttt);
                                                                            return (
                                                                                <button
                                                                                    className="indiSearch"
                                                                                    onClick={(e) => {
                                                                                        // formData.companyName = ttt.entityName
                                                                                        // formData.companyNumber = ttt.nzbn
                                                                                        // Companysearch(ttt.nzbn);
                                                                                        // setcomNumber(ttt.nzbn)
                                                                                        setSearData(ttt.fullLegalName)
                                                                                        showed();
                                                                                        var map = {
                                                                                            "id": ttt.id,
                                                                                            "userName": ttt.fullLegalName
                                                                                        }
                                                                                        setList([...list, map])
                                                                                    }}
                                                                                >
                                                                                    {ttt.fullLegalName}
                                                                                </button>
                                                                            );
                                                                        })
                                                                        : ""}
                                                                </>
                                                            }










                                                            {/* <div className="notif_sent_items">
                                                                {individualSyndi == true ?
                                                                    <>
                                                                        <div className="noti_fiel notification_search input">
                                                                            <label className='mb-3'>Notification will be sent to:</label>
                                                                            <input type="text" />
                                                                        </div>


                                                                        {
                                                                            list && list.map((val, ind) => {
                                                                                return <>

                                                                                    <div className='add_noti position-relative'>
                                                                                        <li>{val.userName}</li>
                                                                                        <button onClick={() => CrossBtn(val.id, ind)}>X</button>
                                                                                    </div> : ""


                                                                                </>
                                                                            })
                                                                        }
                                                                    </> : ""}



                                                                <div className="noti_fiel">
                                                                    <label className='mb-0'>Notification title:</label>
                                                                    <input type="text" className='ml-2' onChange={(e) => {
                                                                        setTitle(e.target.value);
                                                                    }} />
                                                                </div>
                                                                <div className="noti_fiel" >
                                                                    <label className='mb-0'>Notification body text:</label>
                                                                    <input type="text" className='ml-2' onChange={(e) => {
                                                                        StepDescription(e.target.value);
                                                                    }} />
                                                                </div>
                                                                {nextPage == true ? <div className="send_btn d-flex justify-content-end">
                                                                    <button className='text-right' onClick={() => setHide(true)}>Send Notification</button>
                                                                </div> :
                                                                    <div className="send_btn d-flex justify-content-end">
                                                                        <button className='text-right' onClick={() => sendNotification()}>Send Notification</button>
                                                                    </div>}

                                                            </div> */}
                                                            <div className="notif_sent_items">
                                                                {individualSyndi == true ?
                                                                    <>
                                                                        <div className="noti_fiel notification_search input">
                                                                            {list && list[0] != undefined && list[0] != null && list[0] != '' ? <label className='mb-3'>Notification will be sent to:</label> : ""}
                                                                            {
                                                                                list && list.map((val, ind) => {
                                                                                    return <>

                                                                                        <div className='notification_add_item col-12 col-md-6 '>
                                                                                            <div className='add_noti position-relative'>
                                                                                                <li>{val.userName}</li>
                                                                                                <button onClick={() => CrossBtn(val.id, ind)}><i class="fa fa-times" aria-hidden="true"></i></button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </> :



                                                                    activeClient == true ? <div className="noti_fiel notification_search  input input_value">
                                                                        <label className='mb-3'>Notification will be sent to:</label>
                                                                        <p className='notification_add_item py-2 px-2'>All Active Client</p>
                                                                    </div>

                                                                        : opensyndicate == true ?

                                                                            <div className="noti_fiel notification_search input_value">
                                                                                <label className='mb-3'>Notification will be sent to:</label>
                                                                                <p className='notification_add_item py-2 px-2 '>Open Syndicate</p>
                                                                            </div>


                                                                            : closesyndicate == true ?

                                                                                <div className="noti_fiel notification_search input input_value">
                                                                                    <label className='mb-3'>Notification will be sent to:</label>
                                                                                    <p className='notification_add_item py-2 px-2 '>Close syndicate</p>
                                                                                </div>
                                                                                : ""
                                                                }
                                                                <div className="noti_fiel mt-3">
                                                                    <label className='mb-0'>Notification title:</label>
                                                                    <input type="text" className='mb-2' placeholder='Enter notification title' onChange={(e) => {
                                                                        setTitleError('');
                                                                        setTitle(e.target.value);
                                                                    }} />
                                                                </div>
                                                                {titleError == true ? <div className='noti_error'>Please enter title</div> : ""}
                                                                <div className="noti_fiel" >
                                                                    <label className='mb-0'>Notification body text:</label>
                                                                    <input type="text" placeholder='Enter notification body text' className='mb-2' onChange={(e) => {
                                                                        setDesError('');
                                                                        StepDescription(e.target.value);
                                                                    }} />
                                                                </div>
                                                                {desError == true ? <div className='noti_error'>Please enter description</div> : ""}
                                                                {nextPage == true ? <div className="send_btn d-flex justify-content-end">
                                                                    <button className='text-right' onClick={() => Valid()
                                                                    }>Send Notification</button>
                                                                </div> :
                                                                    <div className="send_btn d-flex justify-content-end">
                                                                        <button className='text-right' onClick={() => sendNotification()}>Send Notification</button>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </>
                                    )}
                                </div>

                                <div className="myfarm_com myfarm_Active" key={7}>
                                    {' '}
                                    <ViewNotification
                                        apiPath1={'/admin/get/notification'}
                                        tableTitle={'View Previous Notification'}
                                        key={1}
                                        tableid={'table_id4'}
                                    />
                                </div>
                            </> :
                                opensyndicate == true ?
                                    <>
                                        {users && users.map((user, index) => {
                                            if (user.isValidOffer == 1) {
                                                return (

                                                    <>
                                                        <div className="form director p-3">
                                                            <div className="myfarm_com myfarm_Active" key={index}>
                                                                {' '}
                                                                <NotificationTable
                                                                    apiPath1={`/admin/syndicate/users/${user.id}`}
                                                                    tableTitle={`${user.title}`}
                                                                    key={index}
                                                                    tableid={`table_id44${index}`}
                                                                    syndicateID={user.id}
                                                                    dataid={index}
                                                                    setLoader={setLoader}
                                                                    titles={titleNotifi}
                                                                    descriptions={description}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }



                                        })

                                        }
                                        <>{rrId == true ? "" :
                                            <button onClick={() => {
                                                sendNotification("all")
                                            }}>Send Notification</button>
                                        }</>


                                    </>
                                    :
                                    <>
                                        {users && users.map((user, index) => {
                                            if (user.isValidOffer == 0) {
                                                return (

                                                    <>
                                                        <div className="form director p-3">
                                                            <div className="myfarm_com myfarm_Active" key={index}>
                                                                {' '}
                                                                <NotificationTable
                                                                    apiPath1={`/admin/syndicate/users/${user.id}`}
                                                                    tableTitle={`${user.title}`}
                                                                    key={index}
                                                                    tableid={`table_id44${index}`}
                                                                    syndicateID={user.id}
                                                                    dataid={index}
                                                                    setLoader={setLoader}
                                                                    titles={titleNotifi}
                                                                    descriptions={description}
                                                                    setActiveClient={setActiveClient}
                                                                    setOpensyndicate={setOpensyndicate}
                                                                    setClosesyndicate={setClosesyndicate}
                                                                    setndividualSyndi={setndividualSyndi}
                                                                    StepDescription={StepDescription}
                                                                    setTitle={setTitle}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }



                                        })

                                        }
                                        <>{rrId == true ? "" :
                                            <button onClick={() => {
                                                sendNotification("all")
                                            }}>Send Notification</button>
                                        }</>


                                    </>
                            }

                        </div>

                    </div>
                </div>
            </>)}
        </>


    );
};

export default Notification;
