/*eslint-disable eqeqeq*/

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { audience, urlapi } from 'src/auth/Configuration';
import { useParams, Link } from 'react-router-dom';

const InvestmentOpportunities = () => {
  const {
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,

  } = useAuth0();
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          let token = accessidToken.__raw;
          // setTokens(accessidToken.__raw);

          applicationt(accessidToken.__raw);
          sessionStorage.setItem('token', token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    getUserMetadata();
  }, []);
  const { id } = useParams();
  const [loader, setLoader] = useState();

  const [apiData, setApiData] = useState();
  const applicationt = async (tokens) => {
    // alert(isAuthenticated)
    setLoader(true);

    if (isAuthenticated) {
      const applicationdata1 = await axios
        .get(`${urlapi}/investor/syndicate  `, {
          headers: { Authorization: `Bearer ${tokens}` }
        })
        .then((res) => {
          setLoader(false);

          setApiData(res.data);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  //

  //

  return (
    <>
      {loader == true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="right_content aurora_layout">
          <div className="heading__top ht_heading d-flex justify-content-between align-items-center flex-wrap">
            <h2>See our below range of MyFarm investment strategies</h2>
            {/* <Link to="/Forms" className="btn btnlights ">
              MAKE CONTRIBUTION <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </Link> */}
          </div>
          <div className="ht_heading11">
            <div className="row ">
              <h5 className="mb-4 mt-3">Syndicates</h5>
              {apiData &&
                apiData.map((abc) => {
                  console.log('dccdd', abc);

                  return (
                    <>
                      {abc.isValidOffer ? <div className="col-md-4">
                        <div className="col_inner">

                          <Link
                            // to=`/InfoDetails`
                            to={{
                              pathname: `/InfoDetailsInvestor/${abc ? abc.id : ''}`
                            }}
                            onClick={() => {
                              sessionStorage.setItem('id', abc.id);
                            }}
                          >
                            <div className="inner_title">
                              <span>{abc.title}</span>
                            </div>
                          </Link>
                        </div>
                      </div> : ""}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default InvestmentOpportunities;
