/*eslint-disable eqeqeq*/

import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Nav, Tab, Accordion } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { getCode, getName } from 'country-list';
import ModalPopup from './signatures/ModalPopup';
import BankAccount from './BankAccount';
import WholesaleCertificate from './WholesaleCertificate';


const CompanyDetails = (props, bank) => {
  const { user } = useAuth0();

  return (
    <>



      <div className="app_view_box">
        <div className="app_view">
          <h4 className="">Company Details</h4>


          <div className='show_responsive'>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Company Overview </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-11">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <label>Company Name </label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <p>{props.data ? props.data.companyDetails.companyName : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <label>Company Number </label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <p>{props.data ? props.data.companyDetails.companyNumber : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <label>Company Trading Name</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <p>{props.data ? props.data.companyDetails.companyTradingName : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <label>Company registered Address</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <p>{props.data ? props.data.companyDetails.companyRegisterAddress : ''}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <label>Company Address Proof</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="document_download">
                            <a
                              href={
                                props.data &&
                                  props.data.companyDetails.companyAddressProof !== null &&
                                  props.data.companyDetails.companyAddressProof !== '' &&
                                  props.data.companyDetails.companyAddressProof !== undefined
                                  ? props.data.companyDetails.companyAddressProof
                                  : ''
                              }
                              className="licedocument-downloade"
                            >
                              {' '}
                              <div className="main-downloade-wrapper">
                                {props.data &&
                                  props.data.companyDetails.companyAddressProof !== null &&
                                  props.data.companyDetails.companyAddressProof !== '' &&
                                  props.data.companyDetails.companyAddressProof &&
                                  'undefined' &&
                                  props.data.companyDetails.companyAddressProof !== undefined ? (
                                  <p className="downloadeMsg">
                                    <div className="downloads">
                                      <i className="fa fa-download downloadeStyle"></i>
                                    </div>{' '}
                                    VIEW FILE{' '}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </a>

                            <span id="name_tag" class="preview2"></span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Tax</Accordion.Header>
                <Accordion.Body className='text_accordion'>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>
                        Company <span className="capital"> IRD </span>number
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.companyDetails.companyIrdNumber : ''}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label className=""> Tax Type </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="rwttax">
                        <div className="Main-wrapper-circle">
                          <p className="circle">
                            {props.data && props.data.companyDetails.companyTaxType}
                          </p>
                        </div>
                      </div>

                      {/* <p>{props.data ? props.data.userIrdNumber : ''}</p> */}
                    </div>
                  </div>
                  {props.data &&
                    props.data.companyDetails.companyTaxType == 'Resident Withholding Tax' ? (
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label className="">Resident Withholding Tax Rate</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-6">
                        <div className="rwttax">
                          <div className="Main-wrapper-circle">
                            <p className="circle">
                              {props.data ? props.data.companyDetails.companyRwtValue : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {props.data && props.data.companyDetails.companyRwtValue == 'Exempt' ? (
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Proof of Exemption</label>
                      </div>

                      <div className="col-md-6 col-sm-6 col-6 document_download">
                        <a
                          href={
                            props.data &&
                              props.data.companyDetails.companyExamptProof !== null &&
                              props.data.companyDetails.companyExamptProof !== '' &&
                              props.data.companyDetails.companyExamptProof !== undefined
                              ? props.data.companyDetails.companyExamptProof
                              : ''
                          }
                          className="licedocument-downloade"
                        >
                          {' '}
                          <div className="main-downloade-wrapper">
                            {props.data &&
                              props.data.companyDetails.companyExamptProof !== null &&
                              props.data.companyDetails.companyExamptProof !== '' &&
                              props.data.companyDetails.companyExamptProof &&
                              'undefined' &&
                              props.data.companyDetails.companyExamptProof !== undefined ? (
                              <p className="downloadeMsg">
                                <div className="downloads">
                                  <i className="fa fa-download downloadeStyle"></i>
                                </div>{' '}
                                VIEW FILE{' '}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {props.data &&
                    props.data.companyDetails.companyTaxType == 'Non Resident Withholding Tax' ? (
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Resident Witholding Tax Exemption</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-6">
                        <div className="rwttax">
                          <div className="Main-wrapper-circle">
                            <p>
                              {props.data.companyDetails.companyTaxExemption == 0 ||
                                props.data.companyDetails.taxExemption == false
                                ? 'No'
                                : 'Yes'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label>
                        Is the Company U.S. Citizen, Or U.S. Resident, Or A Tax Resident in Any Other
                        Country
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="">
                        <div class="switch-field nospace">
                          <div className="check_YN_Bank1">
                            <p>
                              {props.data && props.data.companyDetails.isCompanyOhterCountryCitizen === 1
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {props.data && props.data.companyDetails.isCompanyOhterCountryCitizen === 1 ? (
                    <>
                      <div className="">
                        <Row className="FlagsSelectcss">
                          <Col md={6} sm={6}>
                            <div>
                              <label>Country Of Tax Residence</label>
                            </div>
                          </Col>
                          <Col md={6} sm={6}>
                            <p>
                              {props.data && props.data.companyDetails.companyCountryOfResidence
                                ? props.data.companyDetails.companyCountryOfResidence
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} sm={6}>
                            <div>
                              <label>
                                Tax Identification Number <span className="capital">(TIN)</span>
                              </label>
                            </div>
                          </Col>
                          <Col md={6} sm={6}>
                            <p>
                              {props.data && props.data.companyDetails.companyTinNumber
                                ? props.data.companyDetails.companyTinNumber
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} sm={6}>
                            <div>
                              <label>
                                Reason if <span className="capital">TIN</span> is Not Available
                              </label>
                            </div>
                          </Col>
                          <Col md={6} sm={6}>
                            <p>
                              {props.data && props.data.companyDetails.companyTinReason
                                ? props.data.companyDetails.companyTinReason
                                : ''}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Is Company Registered In Other Country </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>
                        {props.data ? (
                          <>{props.data.companyDetails.entityCompanyOne == 1 ? <p>Yes</p> : <p>No</p>}</>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>


                  </div>
                  {props.data.companyDetails.entityCompanyOne == 1 ? (
                    <div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          {props.data && props.data.companyDetails.otherCountryCompanyRegistered ? (
                            <label>Company Registered in other Country</label>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <p>
                            {props.data ? props.data.companyDetails.otherCountryCompanyRegistered : ''}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          {props.data && props.data.companyDetails.fullLegalCompanyName ? (
                            <label>Company Full Company Name</label>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <p>{props.data ? props.data.companyDetails.fullLegalCompanyName : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          {props.data && props.data.companyDetails.companySecCountryOfResidence ? (
                            <label>Company Registered in other Country</label>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <p>
                            {props.data ? props.data.companyDetails.companySecCountryOfResidence : ''}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          {props.data && props.data.companyDetails.secFullLegalCompanyName ? (
                            <label>Company Full Company Name</label>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <p>{props.data ? props.data.companyDetails.secFullLegalCompanyName : ''}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Directors</Accordion.Header>
                <Accordion.Body>
                  {props.data
                    ? props.data.companyDetails &&
                    props.data.companyDetails.companyDirector &&
                    props.data.companyDetails.companyDirector.map((comp_Details, index) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6">
                              <label>Company Director{index + 1} Name </label>
                            </div>

                            <div className="col-md-6 col-sm-6 col-6">
                              <p>{comp_Details.fullLegalName}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6">
                              <label>Company Director{index + 1} Email </label>
                            </div>

                            <div className="col-md-6 col-sm-6 col-6">
                              <p>{comp_Details.userEmail}</p>
                            </div>
                          </div>

                        </>
                      );
                    })
                    : ''}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Other Associated People</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Holding Personal Assets</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="">
                        <div class="switch-field nospace">
                          <div className="check_YN_Bank1">
                            <p>
                              {props.data && props.data.companyDetails.isHoldingPersonalAssets === 1
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Company Other Individual Operating</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="">
                        <div class="switch-field nospace">
                          <div className="check_YN_Bank1">
                            <p>
                              {props.data && props.data.companyDetails.isCompanyIndividualOperating === 1
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {props.data && props.data.companyDetails.isCompanyIndividualOperating === 1 ? (
                    <>
                      <div className="">
                        <Row className="FlagsSelectcssc">
                          <Col className="col-md-6 col-sm-6 col-6">
                            <div>
                              <label>Company Other Individual FullName</label>
                            </div>
                          </Col>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <p>
                              {props.data && props.data.companyDetails.individualFullName
                                ? props.data.companyDetails.individualFullName
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <div>
                              <label>Company Other Individual Role </label>
                            </div>
                          </Col>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <p>
                              {props.data && props.data.companyDetails.IndividualRole
                                ? props.data.companyDetails.IndividualRole
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <div>
                              <label>Company Other Individual Email</label>
                            </div>
                          </Col>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <p>
                              {props.data && props.data.companyDetails.IndividualEmail
                                ? props.data.companyDetails.IndividualEmail
                                : ''}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label> Other Company Individual Operating</label>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="">
                        <div class="switch-field nospace">
                          <div className="check_YN_Bank1">
                            <p>
                              {props.data && props.data.companyDetails.isOtherCompanyIndividual === 1
                                ? 'Yes'
                                : 'No'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {props.data && props.data.companyDetails.isOtherCompanyIndividual === 1 ? (
                    <>
                      <div className="">
                        <Row className="FlagsSelectcssc">
                          <Col className="col-md-6 col-sm-6 col-6">
                            <div>
                              <label>Company Second Individual FullName</label>
                            </div>
                          </Col>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <p>
                              {props.data && props.data.companyDetails.secondaryIndividualFullName
                                ? props.data.companyDetails.secondaryIndividualFullName
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <div>
                              <label>Company Second Individual Role </label>
                            </div>
                          </Col>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <p>
                              {props.data && props.data.companyDetails.secondaryIndividualRole
                                ? props.data.companyDetails.secondaryIndividualRole
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <div>
                              <label>Company Other Individual Email</label>
                            </div>
                          </Col>
                          <Col className="col-md-6 col-sm-6 col-6">
                            <p>
                              {props.data && props.data.companyDetails.SecondaryIndividualEmail
                                ? props.data.companyDetails.SecondaryIndividualEmail
                                : ''}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Source of Funds </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Company Fund Reason</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.companyDetails.companyFundReason : ''}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Company Generate Income</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <p>{props.data ? props.data.companyDetails.companyGenerateIncome : ''}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <label>Company Funds Document</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <div className="document_download">
                        <a
                          href={
                            props.data &&
                              props.data.companyDetails.companyFundsDocument !== null &&
                              props.data.companyDetails.companyFundsDocument !== '' &&
                              props.data.companyDetails.companyFundsDocument &&
                              'undefined' &&
                              props.data.companyDetails.companyFundsDocument !== undefined
                              ? props.data.companyDetails.companyFundsDocument
                              : ''
                          }
                          className="licedocument-downloade"
                        >
                          {' '}
                          <div className="main-downloade-wrapper">
                            {props.data &&
                              props.data.companyDetails.companyFundsDocument !== null &&
                              props.data.companyDetails.companyFundsDocument !== '' &&
                              props.data.companyDetails.companyFundsDocument &&
                              'undefined' &&
                              props.data.companyDetails.companyFundsDocument !== undefined ? (
                              <p className="downloadeMsg">
                                <div className="downloads">
                                  <i className="fa fa-download downloadeStyle"></i>
                                </div>{' '}
                                VIEW FILE{' '}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </a>

                        <span id="name_tag" class="preview2"></span>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Bank Account</Accordion.Header>
                <Accordion.Body>
                  <BankAccount data={props && props.bank} />

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Wholesale Certification</Accordion.Header>
                <Accordion.Body>
                  <WholesaleCertificate data={props && props.certificate} />

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="view_tabs responsive_hide ">


            <Tab.Container id="left-tabs-example" defaultActiveKey="second">
              <Row>
                <Col sm={5} md={3} className="border-right">
                  <Nav variant="pills" className="flex-column">
                    {/* <Nav.Item>
                                        <Nav.Link eventKey="first">Investment Details</Nav.Link>
                                      </Nav.Item> */}

                    <Nav.Item>
                      <Nav.Link eventKey="second">Company Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tax</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="four">Directors</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="five">Other Associated People</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="six">Source of Funds</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="seven">Bank Account</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="eight">Wholesale Certification</Nav.Link>
                    </Nav.Item>

                  </Nav>
                </Col>
                <Col sm={7} md={9} className="formPopUp">
                  <Tab.Content>

                    <Tab.Pane eventKey="second">

                      <div className="row">
                        <div className="col-md-11">
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label>Company Name </label>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p>{props.data ? props.data.companyDetails.companyName : ''}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label>Company Number </label>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p>{props.data ? props.data.companyDetails.companyNumber : ''}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label>Company Trading Name</label>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p>{props.data ? props.data.companyDetails.companyTradingName : ''}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <label>Company registered Address</label>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p>{props.data ? props.data.companyDetails.companyRegisterAddress : ''}</p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <label>Company Address Proof</label>
                            </div>
                            <div className="col-md-6">
                              <div className="document_download">
                                <a
                                  href={
                                    props.data &&
                                      props.data.companyDetails.companyAddressProof !== null &&
                                      props.data.companyDetails.companyAddressProof !== '' &&
                                      props.data.companyDetails.companyAddressProof !== undefined
                                      ? props.data.companyDetails.companyAddressProof
                                      : ''
                                  }
                                  className="licedocument-downloade"
                                >
                                  {' '}
                                  <div className="main-downloade-wrapper">
                                    {props.data &&
                                      props.data.companyDetails.companyAddressProof !== null &&
                                      props.data.companyDetails.companyAddressProof !== '' &&
                                      props.data.companyDetails.companyAddressProof &&
                                      'undefined' &&
                                      props.data.companyDetails.companyAddressProof !== undefined ? (
                                      <p className="downloadeMsg">
                                        <div className="downloads">
                                          <i className="fa fa-download downloadeStyle"></i>
                                        </div>{' '}
                                        VIEW FILE{' '}
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </a>

                                <span id="name_tag" class="preview2"></span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>
                            Company <span className="capital"> IRD </span>number
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.companyDetails.companyIrdNumber : ''}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label className=""> Tax Type </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="rwttax">
                            <div className="Main-wrapper-circle">
                              <p className="circle">
                                {props.data && props.data.companyDetails.companyTaxType}
                              </p>
                            </div>
                          </div>

                          {/* <p>{props.data ? props.data.userIrdNumber : ''}</p> */}
                        </div>
                      </div>
                      {props.data &&
                        props.data.companyDetails.companyTaxType == 'Resident Withholding Tax' ? (
                        <div className="row">
                          <div className="col-md-6  col-sm-6">
                            <label className="">Resident Withholding Tax Rate</label>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div className="rwttax">
                              <div className="Main-wrapper-circle">
                                <p className="circle">
                                  {props.data ? props.data.companyDetails.companyRwtValue : ''}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {props.data && props.data.companyDetails.companyRwtValue == 'Exempt' ? (
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <label>Proof of Exemption</label>
                          </div>

                          <div className="col-md-6 document_download">
                            <a
                              href={
                                props.data &&
                                  props.data.companyDetails.companyExamptProof !== null &&
                                  props.data.companyDetails.companyExamptProof !== '' &&
                                  props.data.companyDetails.companyExamptProof !== undefined
                                  ? props.data.companyDetails.companyExamptProof
                                  : ''
                              }
                              className="licedocument-downloade"
                            >
                              {' '}
                              <div className="main-downloade-wrapper">
                                {props.data &&
                                  props.data.companyDetails.companyExamptProof !== null &&
                                  props.data.companyDetails.companyExamptProof !== '' &&
                                  props.data.companyDetails.companyExamptProof &&
                                  'undefined' &&
                                  props.data.companyDetails.companyExamptProof !== undefined ? (
                                  <p className="downloadeMsg">
                                    <div className="downloads">
                                      <i className="fa fa-download downloadeStyle"></i>
                                    </div>{' '}
                                    VIEW FILE{' '}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </a>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {props.data &&
                        props.data.companyDetails.companyTaxType == 'Non Resident Withholding Tax' ? (
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <label>Resident Witholding Tax Exemption</label>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div className="rwttax">
                              <div className="Main-wrapper-circle">
                                <p>
                                  {props.data.companyDetails.companyTaxExemption == 0 ||
                                    props.data.companyDetails.taxExemption == false
                                    ? 'No'
                                    : 'Yes'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>
                            Is the Company U.S. Citizen, Or U.S. Resident, Or A Tax Resident in Any Other
                            Country
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="">
                            <div class="switch-field nospace">
                              <div className="check_YN_Bank1">
                                <p>
                                  {props.data && props.data.companyDetails.isCompanyOhterCountryCitizen === 1
                                    ? 'Yes'
                                    : 'No'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {props.data && props.data.companyDetails.isCompanyOhterCountryCitizen === 1 ? (
                        <>
                          <div className="">
                            <Row className="FlagsSelectcss">
                              <Col md={6} sm={6}>
                                <div>
                                  <label>Country Of Tax Residence</label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.companyDetails.companyCountryOfResidence
                                    ? props.data.companyDetails.companyCountryOfResidence
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6}>
                                <div>
                                  <label>
                                    Tax Identification Number <span className="capital">(TIN)</span>
                                  </label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.companyDetails.companyTinNumber
                                    ? props.data.companyDetails.companyTinNumber
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6}>
                                <div>
                                  <label>
                                    Reason if <span className="capital">TIN</span> is Not Available
                                  </label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.companyDetails.companyTinReason
                                    ? props.data.companyDetails.companyTinReason
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        ''
                      )}

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Is Company Registered In Other Country </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>
                            {props.data ? (
                              <>{props.data.companyDetails.entityCompanyOne == 1 ? <p>Yes</p> : <p>No</p>}</>
                            ) : (
                              ''
                            )}
                          </p>
                        </div>


                      </div>
                      {props.data.companyDetails.entityCompanyOne == 1 ? (
                        <div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              {props.data && props.data.companyDetails.otherCountryCompanyRegistered ? (
                                <label>Company Registered in other Country</label>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p>
                                {props.data ? props.data.companyDetails.otherCountryCompanyRegistered : ''}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              {props.data && props.data.companyDetails.fullLegalCompanyName ? (
                                <label>Company Full Company Name</label>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p>{props.data ? props.data.companyDetails.fullLegalCompanyName : ''}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              {props.data && props.data.companyDetails.companySecCountryOfResidence ? (
                                <label>Company Registered in other Country</label>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p>
                                {props.data ? props.data.companyDetails.companySecCountryOfResidence : ''}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              {props.data && props.data.companyDetails.secFullLegalCompanyName ? (
                                <label>Company Full Company Name</label>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p>{props.data ? props.data.companyDetails.secFullLegalCompanyName : ''}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">

                      {props.data
                        ? props.data.companyDetails &&
                        props.data.companyDetails.companyDirector &&
                        props.data.companyDetails.companyDirector.map((comp_Details, index) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <label>Company Director{index + 1} Name </label>
                                </div>

                                <div className="col-md-6 col-sm-6">
                                  <p>{comp_Details.fullLegalName}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <label>Company Director{index + 1} Email </label>
                                </div>

                                <div className="col-md-6 col-sm-6">
                                  <p>{comp_Details.userEmail}</p>
                                </div>
                              </div>

                            </>
                          );
                        })
                        : ''}
                    </Tab.Pane>
                    <Tab.Pane eventKey="five">

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Holding Personal Assets</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="">
                            <div class="switch-field nospace">
                              <div className="check_YN_Bank1">
                                <p>
                                  {props.data && props.data.companyDetails.isHoldingPersonalAssets === 1
                                    ? 'Yes'
                                    : 'No'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Company Other Individual Operating</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="">
                            <div class="switch-field nospace">
                              <div className="check_YN_Bank1">
                                <p>
                                  {props.data && props.data.companyDetails.isCompanyIndividualOperating === 1
                                    ? 'Yes'
                                    : 'No'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {props.data && props.data.companyDetails.isCompanyIndividualOperating === 1 ? (
                        <>
                          <div className="">
                            <Row className="FlagsSelectcssc">
                              <Col md={6} sm={6}>
                                <div>
                                  <label>Company Other Individual FullName</label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.companyDetails.individualFullName
                                    ? props.data.companyDetails.individualFullName
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6}>
                                <div>
                                  <label>Company Other Individual Role </label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.companyDetails.IndividualRole
                                    ? props.data.companyDetails.IndividualRole
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6}>
                                <div>
                                  <label>Company Other Individual Email</label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.companyDetails.IndividualEmail
                                    ? props.data.companyDetails.IndividualEmail
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        ''
                      )}

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label> Other Company Individual Operating</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="">
                            <div class="switch-field nospace">
                              <div className="check_YN_Bank1">
                                <p>
                                  {props.data && props.data.companyDetails.isOtherCompanyIndividual === 1
                                    ? 'Yes'
                                    : 'No'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {props.data && props.data.companyDetails.isOtherCompanyIndividual === 1 ? (
                        <>
                          <div className="">
                            <Row className="FlagsSelectcssc">
                              <Col md={6} sm={6}>
                                <div>
                                  <label>Company Second Individual FullName</label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.companyDetails.secondaryIndividualFullName
                                    ? props.data.companyDetails.secondaryIndividualFullName
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6}>
                                <div>
                                  <label>Company Second Individual Role </label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.companyDetails.secondaryIndividualRole
                                    ? props.data.companyDetails.secondaryIndividualRole
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6}>
                                <div>
                                  <label>Company Other Individual Email</label>
                                </div>
                              </Col>
                              <Col md={6} sm={6}>
                                <p>
                                  {props.data && props.data.companyDetails.SecondaryIndividualEmail
                                    ? props.data.companyDetails.SecondaryIndividualEmail
                                    : ''}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="six">

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Company Fund Reason</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.companyDetails.companyFundReason : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Company Generate Income</label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <p>{props.data ? props.data.companyDetails.companyGenerateIncome : ''}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Funds Document</label>
                        </div>
                        <div className="col-md-6">
                          <div className="document_download">
                            <a
                              href={
                                props.data &&
                                  props.data.companyDetails.companyFundsDocument !== null &&
                                  props.data.companyDetails.companyFundsDocument !== '' &&
                                  props.data.companyDetails.companyFundsDocument &&
                                  'undefined' &&
                                  props.data.companyDetails.companyFundsDocument !== undefined
                                  ? props.data.companyDetails.companyFundsDocument
                                  : ''
                              }
                              className="licedocument-downloade"
                            >
                              {' '}
                              <div className="main-downloade-wrapper">
                                {props.data &&
                                  props.data.companyDetails.companyFundsDocument !== null &&
                                  props.data.companyDetails.companyFundsDocument !== '' &&
                                  props.data.companyDetails.companyFundsDocument &&
                                  'undefined' &&
                                  props.data.companyDetails.companyFundsDocument !== undefined ? (
                                  <p className="downloadeMsg">
                                    <div className="downloads">
                                      <i className="fa fa-download downloadeStyle"></i>
                                    </div>{' '}
                                    VIEW FILE{' '}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </a>

                            <span id="name_tag" class="preview2"></span>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seven">

                      <BankAccount data={props && props.bank} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="eight">
                      {/* <Wholesale_Certificate data={userr && userr} /> */}
                      <WholesaleCertificate data={props && props.certificate} />
                    </Tab.Pane>




                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>




        </div>
      </div>

    </>
  );
};
export default CompanyDetails;
