/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
// import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { getName } from 'country-list';
import axios from 'axios';
import { audience, urlapi } from 'src/auth/Configuration';

const BankAccount2 = (props) => {
  const { user, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [nloader, setNloader] = useState(false);
  const [token, setToken] = useState();

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  function getCountryName(country) {
    try {
      return getName(country);
    } catch {
      return '';
    }
  }
  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;

          //    formData.token =
          // sessionStorage.setItem('token', token);

          //   application(token);
          //   Notessfunc(token);
          getur(token);
        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);
  const NoteClick = (data) => {
    setNloader(true);
    let postadv = {
      downloadUrl: data
    };
    axios
      .post(`${urlapi}/admin/download/image`, postadv, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        window.open(res.data.url);
        setNloader(false);
      });
  };
  const [geturs, setGeturs] = useState();

  const getur = (token) => {
    const NotessData = axios
      .get(`${urlapi}/admin/team/member`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setGeturs(result.data);
        }
      });
  };
  return (
    <div className="row">
      <div className="col-md-11">
        <div className="row">
          <div className="col-md- col-sm-6">
            <label>
              {' '}
              <span className="capitals">NZ</span> Bank Account
            </label>
          </div>
          <div className="col-md- col-sm-6">
            <p>{props.data && props.data.isNZBank === 1 ? 'Yes' : 'No'}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md- col-sm-6">
            <label>Bank Account Name</label>
          </div>
          <div className="col-md-6 col-sm-6">
            <p>{props.data ? props.data.primaryBankAccountName : ''}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-6">
            <label>Bank Account Number</label>
          </div>
          <div className="col-md-6 col-sm-6">
            <p>{props.data ? props.data.primaryBankAccountNumber : ''}</p>
          </div>
        </div>
        {props.data &&
          props.data.bankSwiftCode !== '' &&
          props.data.isNZBank !== 1 &&
          props.data.bankSwiftCode !== null ? (
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label>Bank Swift Code</label>
            </div>
            <div className="col-md-6 col-sm-6">
              <p>{props.data ? props.data.bankSwiftCode : ''}</p>
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-md-6   col-sm-6">
            <label>Document Proof</label>
          </div>
          <div className="col-md-6  col-sm-6">
            <div className="document_download">
              <a
                href="javascript:void(0)"
                onClick={() => NoteClick(props.data.primaryBankProof)}
                className="licedocument-downloade"
              >
                {' '}
                <div className="main-downloade-wrapper">
                  {props.data &&
                    props.data.primaryBankProof !== null &&
                    props.data.primaryBankProof !== '' &&
                    props.data.primaryBankProof &&
                    'undefined' &&
                    props.data.primaryBankProof !== undefined ? (
                    <p className="downloadeMsg">
                      <div className="downloads">
                        <i className="fa fa-download downloadeStyle"></i>
                      </div>{' '}
                      VIEW FILE{' '}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </a>

              <span id="name_tag" class="preview2"></span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="">
                                <div className="col-md-12 edit_icon PersonalInformationEdit">
                                  {user.profile.includes("UR4") ||
                                  user.profile.includes("UR2") ||
                                  (props.data && props.data.applicationStage == 'Active Client') ||
                                  user.profile.includes("UR3") ? (
                                    ''
                                  ) : (
                                    <i
                                      onClick={handleOpen}
                                      title="Edit"
                                      class="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </div>
                              </div> */}
    </div>
  );
};
export default BankAccount2;
