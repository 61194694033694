/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import { Link } from 'react-router-dom';
// import leftarrowback from '../leftarrowback.png';
import { Link, useNavigate } from 'react-router-dom';
// import leftarrowback from './back-arrow.png';

import axios from 'axios';
import { audience, urlapi } from '../../auth/Configuration';

import { Row, Col, Nav, Tab, Table, Button } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import moment from 'moment';
import Swal from 'sweetalert2';

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import $, { now } from 'jquery';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Input, Checkbox } from 'antd';

import { getName } from 'country-list';

import PersonalInformation2 from '../PersonalInformation2';
import IdVerification2 from '../IdVerification2';
import BankAccount2 from '../BankAccount2';
import TermsCondition2 from '../TermsCondition2';
import EntityDetails from '../EntityDetails';

const ApplicationDetailsIndi = (props) => {
  // const countryList = require('country-list');
  function getCountryName(country) {
    try {
      return getName(country);
    } catch {
      return '';
    }
  }

  const [idTypee, setIdTypee] = useState();
  const [idTypee2, setIdTypee2] = useState();
  const [imageLoader, setImageLoader] = useState();

  const [amltype, setAmltype] = useState('electronics');
  const [amltype1, setAmltype1] = useState('electronics');
  const [amltype12, setAmltype12] = useState('electronics');
  const [amltype22, setAmltype22] = useState('electronics');

  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const [open1, setOpen1] = React.useState(false);

  // const [datevalue, setDatevalue] = useState(moment().format('DD/MM/YYYY'));
  const [datevalue1, setDatevalue1] = useState(moment().format('DD/MM/YYYY'));

  useEffect(() => {
    function readURL(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        $('#name_tag').text(input.files[0].name);
      }
    }

    $('#imag2').change(function () {
      var imgControlName = '#ImgPreview2';
      readURL(this, imgControlName);
      $('.preview2').addClass('it');
      $('.btn-rmv2').addClass('rmv');
    });

    $('#removeImage2').click(function (e) {
      e.preventDefault();
      $('#imag2').val('');
      $('#ImgPreview2').attr('src', '');
      $('.preview2').text('');
      $('.btn-rmv2').removeClass('rmv');
    });
  });

  function setDateformateToValid1(date) {
    if (
      date !== null &&
      date !== 'undefined' &&
      typeof date !== 'undefined' &&
      date.includes('/')
    ) {
      let dat = date.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }

  function setDateformateToValid2(datevalue) {
    if (
      datevalue !== null &&
      datevalue !== 'undefined' &&
      typeof datevalue !== 'undefined' &&
      datevalue.includes('/')
    ) {
      let dat = datevalue.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }
  function setDateformateToValid(datevalue) {
    if (
      datevalue !== null &&
      datevalue !== 'undefined' &&
      typeof datevalue !== 'undefined' &&
      datevalue.includes('/')
    ) {
      let dat = datevalue1.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }
  function setDateformateToValid(datevalue1) {
    if (
      datevalue1 !== null &&
      datevalue1 !== 'undefined' &&
      typeof datevalue1 !== 'undefined' &&
      datevalue1.includes('/')
    ) {
      let dat = datevalue1.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }

  const { id } = useParams();
  // const location = useLocation();
  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated
  } = useAuth0();
  const [token, setToken] = useState();
  const getUserMetadata = async () => {
    try {
      await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;

          //    formData.token =
          // sessionStorage.setItem('token', token);

          application(token);
          Notessfunc(token);
          getur(token);
        } else {
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const [user1, setUser1] = useState();
  const [user2, setUser2] = useState();
  const [loader, setLoader] = useState();
  const [noteText, setNoteText] = useState();
  const [notess, setNotess] = useState();
  // const [documentFileAttach64, setDocumentFileAttach64] = useState();
  const [documentFileAttach264, setDocumentFileAttach264] = useState();
  const [documentFileAttachNotes64, setDocumentFileAttachNotes64] = useState();
  const [notedocument, setNotedocument] = useState({});
  // const [verifyFordocAttachNotes64, setVerifyFordocAttachNotes64] = useState();
  // const [ApplicationStage, setApplicationStage] = useState('');

  let ApplicationStage = '';

  const [noteLoder, setNoteLoder] = useState(false);
  const [documentFile64, setDocumentFile64] = useState();
  const [documentFile641, setDocumentFile641] = useState();
  const [documentFile6412, setDocumentFile6412] = useState();
  const [UploadfileData, setDocumentFile6422] = useState();
  const [verifyFordoc64, setVerifyFordoc64] = useState();
  const [verifyFordoc641, setVerifyFordoc641] = useState();
  const [verifyFordoc6412, setVerifyFordoc6412] = useState();
  const [verifyFordoc6422, setVerifyFordoc6422] = useState();
  const [userID1, setUserID1] = useState();
  const [userID2, setUserID2] = useState();

  const { className } = props;
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal12, setModal12] = useState(false);
  const [modal22, setModal22] = useState(false);
  const [modal23, setModal23] = useState(false);
  const [modalBio, setModalBio] = useState(false);
  const [modalBioSec, setModalBioSec] = useState(false);
  const [modal24, setModal24] = useState(false);
  const [modals1, setModals1] = useState(false);
  const [noteNumber, setnoteNumber] = useState(1);
  const [Imodel, setImodel] = useState(false);
  const [Imodel2, setImodel2] = useState(false);

  const Itoggle = () => setImodel(!Imodel);
  // const Itoggle2 = () => setImodel2(!Imodel2);
  const toggles1 = () => setModals1(!modals1);
  const toggle = () => setModal(!modal);
  const show = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const show1 = () => setModal1(!modal1);
  const toggle12 = () => setModal12(!modal12);
  const toggle22 = () => setModal22(!modal22);
  const toggleBio = () => setModalBio(!modalBio);
  const toggleBioSec = () => setModalBioSec(!modalBioSec);

  const show12 = () => setModal12(!modal12);
  // const Ishow = () => setModal12(!Imodel);
  // const Ishow2 = () => setModal12(!Imodel);
  const show22 = () => setModal22(!modal22);
  const show23 = () => setModal23(!modal23);
  // const showBio = () => setModalBio(!modalBio);
  const showBioSec = () => setModalBioSec(!modalBioSec);
  const toggle23 = () => setModal23(!modal23);
  const show24 = () => setModal24(!modal24);
  const toggle24 = () => setModal24(!modal24);

  function readURL(input) {
    if (input && input) {
      var reader = new FileReader();
      reader.readAsDataURL(input);

      if (input.size > 2202009) {
        $('#name_tag_note' + noteNumber).text('');
      } else {
        $('.Attch_Note').append(
          "<div class='flex__Note_atttach noteDocument" +
          noteNumber +
          "'>" +
          "<span id='name_tag_note" +
          noteNumber +
          "' class='preview2_note" +
          noteNumber +
          "'></span><input type='button'id='removeImage2_note" +
          noteNumber +
          "' value='x' class='btn-rmv2_note" +
          noteNumber +
          "'/>" +
          '</div>'
        );
        $('#name_tag_note' + noteNumber).text(input.name);
      }
    }
  }

  async function uploadWithJSON_attach__Notess(file) {
    if (Object.keys(notedocument).length >= 5) {
      Swal.fire('You can attach atleast 5 files');
      return;
    }

    readURL(file);
    $('.preview2_note' + noteNumber).addClass('it');
    $('.btn-rmv2_note' + noteNumber).addClass('rmv');

    $('#removeImage2_note' + noteNumber).click(function (e) {
      e.preventDefault();
      $('#imag2_note').val('');
      $('#ImgPreview2_note').attr('src', '');
      $('.noteDocument' + noteNumber).remove();
      delete notedocument['document' + noteNumber];
    });

    // setDocumentFileAttachNotes64(file.name);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });

    const data = {
      img64: await toBase64(file)
    };

    if (file.size > 2202009) {
      Swal.fire('Please attach notes proof (maximum file size is 2MB).');
      setNotedocument('');
      notedocument['document' + noteNumber] = '';
    } else {
      notedocument['document' + noteNumber] = data.img64;

      setNotedocument(notedocument);
    }

    setnoteNumber(noteNumber + 1);
  }

  // const [idata, setIdata] = useState();
  // const [nloader, setNloader] = useState(false);

  const NoteClick = (data) => {
    // setNloader(true);
    let postadv = {
      downloadUrl: data
    };
    axios
      .post(`${urlapi}/admin/download/image`, postadv, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        window.open(res.data.url);
        // setNloader(false);
      });
  };

  const handleSaveClick = () => {
    setNoteLoder(true);
    var textarea = document.getElementsByTagName('textarea');
    var result = [];
    for (var i = 0; i < textarea.length; i += 1) {
      result[i] = textarea[i].value;

      if (textarea[i].value === '') {
        setNoteLoder(false);
        // check if value is empty
        Swal.fire('Please enter note first.');
        return; // stop the function
      }
    }

    let postadv = {
      note: noteText
    };
    if (Object.keys(notedocument).length > 0) {
      postadv['noteDocuments'] = notedocument;
    }
    axios
      .post(`${urlapi}/admin/user/notes/${id}`, postadv, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          Notessfunc(token);
          setNoteLoder(false);
          setNoteText('');
          Swal.fire('Note Added');
        } else {
          setNoteLoder(false);
        }
        setNotedocument({});
        setnoteNumber(1);
      })
      .catch((result) => {
        setNotedocument({});
        setNoteLoder(false);
        setnoteNumber(1);
      });
  };
  const Notessfunc = (token) => {
    const NotessData = axios
      .get(`${urlapi}/admin/user/notes/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setNotess(result.data);
        }
      });
  };



  const agrementpdf = async () => {
    if (isAuthenticated) {
      setLoader(true);
      const applicationdata13 = await axios
        .get(`${urlapi}/user/agreement/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })

        .then((applicationdata13) => {
          setLoader(false);
          if (applicationdata13.status === 200) {
            const file = new Blob([applicationdata13.data], { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          } else {
            Swal.fire(applicationdata13.data.message);
          }
        })
        .catch((error) => {
          setLoader(false);

          Swal.fire('Something went wrong');
        });
      // }
    }
  };
  // const { userId } = useParams();

  function wholesale_certifiacte_pdf(userid) {
    if (isAuthenticated) {
      setLoader(true);
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      const applicationdata13 = axios

        .get(`${urlapi}/user/wholesale/certificate/${userid}`, {
          headers: { Authorization: `Bearer ${token}` }
        })

        .then((applicationdata13) => {
          setLoader(false);
          if (applicationdata13.status === 200) {
            const file = new Blob([applicationdata13.data], { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          } else {
            Swal.fire(applicationdata13.data.message);
          }
        })
        .catch((error) => {
          setLoader(false);

          Swal.fire('Something went wrong');
        });
      // }
    }
  }
  const [directodata, setDirectodata] = useState();

  const applicationdiretor = async (token) => {
    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){

      const applicationdata12 = await axios
        .get(`${urlapi}/admin/director/approvals/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          setDirectodata(applicationdata12.data);
        });
      // }
    }
  };

  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      const applicationdata12 = await axios
        .get(`${urlapi}/admin/user/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((applicationdata12) => {
          setLoader(false);
          setUser1(applicationdata12.data[0]);
          setAssignur(applicationdata12.data[0].memberName);
          setUserID1(applicationdata12.data[0].id);
          // alert(applicationdata12.data[0].fullLegalName);
          if (user.profile.includes("UR3")) {
            applicationdiretor(token);
          }

          setIdTypee(applicationdata12.data[0].documentIdType);

          if (applicationdata12.data.length > 1) {
            setUserID2(applicationdata12.data[1].id);

            setIdTypee2(applicationdata12.data[1].documentIdType);
            setUser2(applicationdata12.data[1]);
          }
        })
        .catch((error) => {
          setLoader(false);

          Swal.fire('Something went wrong');
        });
      // }
    }
  };

  // const [datepaymentReceived, setDatePaymentReceived] = useState();
  // const [paymentReceived, setPaymentReceived] = useState({});
  // const [paymentToDuval, setPaymentDuvalReceived] = useState();

  const datechange = async () => {
    const date = await document.querySelector('#date1').value;
    // setDatePaymentReceived(date);
    paymentDateUpadte1(date);
  };

  const datechange2 = async () => {
    const datevalue = await document.querySelector('#date2').value;
    // setPaymentDuvalReceived(datevalue);
    paymentDateUpadte2(datevalue);
  };

  const [isLoaderpaymentReceived, setIsLoaderpaymentReceived] = useState(false);
  const [isLoaderpaymentReceived1, setIsLoaderpaymentReceived1] = useState(false);

  const paymentDateUpadte1 = async (date) => {
    setIsLoaderpaymentReceived(true);
    if (isAuthenticated) {
      let data = {
        paymentReceived: date
      };
      const dateUpadte = axios
        .put(`${urlapi}/payment/date/update/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((dateUpadte) => {
          setIsLoaderpaymentReceived(false);
          if (dateUpadte.status == 200) {
            Swal.fire({
              text: dateUpadte.data.message
            });
          } else {
            Swal.fire({
              text: dateUpadte.data.message
            });
          }
          // application(token);
        })
        .catch((e) => {
          Swal.fire({
            text: e.message
          });
          setIsLoaderpaymentReceived(false);
        });
      // }
    } else {
      setIsLoaderpaymentReceived(false);
    }
  };
  const paymentDateUpadte2 = async (datevalue) => {
    setIsLoaderpaymentReceived1(true);

    if (isAuthenticated) {
      let data = {
        paymentToDuval: datevalue
      };
      const dateUpadte = axios
        .put(`${urlapi}/payment/date/update/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((dateUpadte) => {
          setIsLoaderpaymentReceived1(false);
          if (dateUpadte.status == 200) {
            Swal.fire({
              text: dateUpadte.data.message
            });
          } else {
            Swal.fire({
              text: dateUpadte.data.message
            });
          }
          // application(token);
        })
        .catch((e) => {
          Swal.fire({
            text: e.message
          });
          setIsLoaderpaymentReceived1(false);
        });
      // }
    } else {
      setIsLoaderpaymentReceived1(false);
    }
  };
  function UpdateApplicationStage(stageValue) {
    if (stageValue === null) {
      const selectOption = document.getElementById('ApplicationStageChange');
      const getSelectText = selectOption.options[selectOption.selectedIndex].value;
      ApplicationStage = getSelectText;
    } else {
      const indexOption = stageValue.target.options.selectedIndex;

      let optionsStage = stageValue.target[indexOption].value;
      ApplicationStage = optionsStage;
    }
  }
  const changeStage = () => {
    UpdateApplicationStage(null);
    setLoader(true);
    if (isAuthenticated) {
      let data = {};
      data.applicationStage = ApplicationStage;

      const changeStage1 = axios
        .put(`${urlapi}/admin/update/stage/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((changeStage1) => {
          setModals1(!modals1);
          setLoader(false);
          if (changeStage1.status == 204) {
            Swal.fire({
              text: 'Application Stage Updated'
            });
          } else {
            Swal.fire({
              text: changeStage1.data.message
            });
            setLoader(false);
          }
          application(token);
        });
      // }
    } else {
      setLoader(false);
    }
  };

  function setDateformateToValid(date1) {
    if (
      date1 !== null &&
      date1 !== 'undefined' &&
      typeof date1 !== 'undefined' &&
      date1.includes('/')
    ) {
      let dat = date1.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }

  // async function uploadWithJSON_attach(file, e, userid) {
  //   setDocumentFileAttach64(file.name);
  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);

  //       reader.onerror = (error) => reject(error);
  //     });
  //   const data = {
  //     img64: await toBase64(file)
  //   };
  //   let datadocument = {
  //     columnName: 'confirmationDocument',
  //     fileName: documentFileAttach64,
  //     document: data.img64
  //   };
  //   setImageLoader(true);
  //   axios
  //     .post(`${urlapi}/admin/confirmation/document/${id}`, datadocument, {
  //       headers: { Authorization: `Bearer ${token}` }
  //     })
  //     .then((result) => {
  //       let urlFile = (datadocument.columnName = result.data.url);
  //       if (result.status === 200) {
  //         setImageLoader(false);
  //         application(token);
  //       } else {
  //         Swal.fire('Something went wrong');
  //       }
  //     });
  // }
  async function uploadWithJSON_attach2(file, userIdf) {
    // alert('vgbb');
    // alert(userIdf);
    var strs4 = file.type;

    const fileTypes4 = strs4.slice(-3);
    if (file.size > 2202009) {
      Swal.fire('Please attach ID proof (maximum file size is 2MB).');

    }
    else if (fileTypes4 && (fileTypes4 == "pdf" || fileTypes4 == "jpg" || fileTypes4 == "png")) {
      setDocumentFileAttach264(file.name);
    } else {
      Swal.fire({
        text: "Only pdf jpg png file attached."
      });
    }

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };
    let datadocument = {
      columnName: 'confirmationDocument',
      fileName: documentFileAttach264,
      document: data.img64
    };
    setImageLoader(true);
    axios
      .post(`${urlapi}/admin/confirmation/document/${userIdf}`, datadocument, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        let urlFile = (datadocument.columnName = result.data.url);
        if (result.status === 200) {
          setImageLoader(false);
          application(token);
        } else {
          Swal.fire('Something went wrong');
        }
      });
  }

  async function uploadWithJSON(file, e) {
    var strs = file.type;

    const fileTypes = strs.slice(-3);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };

    if (file.size > 2202009) {
      Swal.fire('Please attach ID proof (maximum file size is 2MB).');
      setDocumentFile64('');
      setVerifyFordoc64('');
    }
    else if (fileTypes && (fileTypes == "pdf" || fileTypes == "jpg" || fileTypes == "png")) {
      setDocumentFile64(file.name);

      setVerifyFordoc64(data.img64);
    } else {
      Swal.fire({
        text: "Only pdf jpg png file attached."
      });
    }
  }

  async function uploadWithJSON1(file, e) {
    var str = file.type;

    const fileType = str.slice(-3);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };
    // next();
    if (file.size > 2202009) {
      Swal.fire('Please attach ID proof (maximum file size is 2MB).');
      setDocumentFile641('');
      setVerifyFordoc641('');
    }
    else if (fileType && (fileType == "pdf" || fileType == "jpg" || fileType == "png")) {
      setDocumentFile641(file.name);

      setVerifyFordoc641(data.img64);
    } else {

      Swal.fire({
        text: "Only pdf jpg png file attached."
      });
    }

    // setErrror('');
  }
  async function uploadWithJSON12(file, e) {
    var stra5 = file.type;

    const fileTypew5 = stra5.slice(-3);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };
    // next();
    if (file.size > 2202009) {
      Swal.fire('Please attach address proof (maximum file size is 2MB).');
      setVerifyFordoc6412('');
      setDocumentFile6412('');
    } else if (fileTypew5 && (fileTypew5 == "pdf" || fileTypew5 == "jpg" || fileTypew5 == "png")) {
      setDocumentFile6412(file.name);

      setVerifyFordoc6412(data.img64);
    } else {
      Swal.fire({
        text: "Only pdf jpg png file attached."
      });
    }

    // setErrror('');
  }

  async function uploadWithJSON22(file, e) {
    var stra = file.type;

    const fileTypew = stra.slice(-3);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
    const data = {
      img64: await toBase64(file)
    };
    // next();

    if (file.size > 2202009) {
      Swal.fire('Please attach address proof (maximum file size is 2MB).');
      setDocumentFile6422('');
      setVerifyFordoc6422('');
    }
    else if (fileTypew && (fileTypew == "pdf" || fileTypew == "jpg" || fileTypew == "png")) {
      setDocumentFile6422(file.name);

      setVerifyFordoc6422(data.img64);
    } else {
      Swal.fire({
        text: "Only pdf jpg png file attached."
      });
    }

    // setErrror('');
  }

  // function checkDate() {
  //   var selectedDate = document.getElementById('documentExpiryDate').value;
  //   var now = new Date();
  //   var dt1 = Date.parse(now),
  //     dt2 = Date.parse(selectedDate);
  //   if (dt2 < dt1) {
  //   }
  // }
  // const onFinishpep = async (values) => {
  //   const changeStage1 = await axios
  //     .put(`${urlapi}/admin/verify/aml/${userID1}`, values, {
  //       headers: { Authorization: `Bearer ${token}` }
  //     })
  //     .then((result) => {
  //       if (result.status === 200) {
  //         application(token);
  //         setOpen(false);
  //       }
  //     });
  // };
  const [invest, setInvest] = useState();
  const [invest2, setInvest2] = useState();

  // const onFinishInvest2 = async () => {
  //   if (
  //     typeof invest === 'undefined' ||
  //     invest2 === 0 ||
  //     invest2 === '' ||
  //     invest2 === null ||
  //     invest2 < 20000
  //   ) {
  //     $('.unitserror').html('Miminmum investment should be $20,000');
  //   } else if (
  //     typeof invest2 === 'undefined' ||
  //     invest2 === 0 ||
  //     invest2 === '' ||
  //     invest2 === null ||
  //     invest2 % 10000 !== 0
  //   ) {
  //     $('.unitserror').html('increase by multiples of $10,000');
  //   } else {
  //     let value = {
  //       totalInvestment: invest2
  //     };

  //     setLoader(true);

  //     const changeStage1 = await axios
  //       .put(`${urlapi}/admin/investment/amount/${userID2}`, value, {
  //         headers: { Authorization: `Bearer ${token}` }
  //       })
  //       .then((result) => {
  //         if (result.status === 200) {
  //           // window.location.reload();

  //           application(token);
  //           setImodel2(!Imodel2);
  //         } else {
  //           setLoader(false);
  //           // Swal.fire(result.data.message)
  //           Swal.fire({
  //             text: result.data.message
  //           });
  //         }
  //       });
  //   }
  // };

  // const onFinishInvest = async () => {
  //   // alert(invest);
  //   if (
  //     typeof invest === 'undefined' ||
  //     invest === 0 ||
  //     invest === '' ||
  //     invest === null ||
  //     invest === user1.totalInvestment
  //   ) {
  //     $('.unitserror').html('Please update investment amount');
  //   } else if (invest.replace(/,/g, '') < 20000) {
  //     $('.unitserror').html('Miminmum investment should be $20,000');
  //   } else if (
  //     // typeof invest === 'undefined' ||
  //     // invest === 0 ||
  //     // invest === '' ||
  //     // invest === null ||
  //     invest.replace(/,/g, '') % 10000 !==
  //     0
  //   ) {
  //     $('.unitserror').html('increase by multiples of $10,000');
  //   } else {
  //     let value = {
  //       totalInvestment: invest
  //     };

  //     setLoader(true);

  //     const changeStage1 = await axios
  //       .put(`${urlapi}/admin/investment/amount/${userID1}`, value, {
  //         headers: { Authorization: `Bearer ${token}` }
  //       })
  //       .then((result) => {
  //         if (result.status === 200) {
  //           // window.location.reload();

  //           application(token);
  //           setImodel(!Imodel);
  //         } else {
  //           setLoader(false);
  //           // Swal.fire(result.data.message)
  //           Swal.fire({
  //             text: 'Something went wrong'
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         setImodel(Imodel);

  //         // Swal.fire(result.data.message)
  //         Swal.fire({
  //           text: 'Something went wrong'
  //         });
  //       });
  //   }
  // };

  const onFinish = async (values) => {
    values.dateOfBirth = $('.ui.icon.input.dateOfBirth>input').val();

    values.documentExpiryDate = $('.ui.icon.input.documentExpiryDate>input').val();
    values.documentCountryOfIssue = 'NZ';

    values.verifyFor = 'identity';
    values.verificationType = amltype;
    values.documentIdType = idTypee;
    // values.fileName = 'IdentityVerification';

    if (
      verifyFordoc64 !== null &&
      verifyFordoc64 !== undefined &&
      verifyFordoc64 !== 'undefined' &&
      amltype === 'viaDoc'
    ) {
      setLoader(true);
      values.doc64 = verifyFordoc64;
      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID1}`, values, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            // window.location.reload();

            application(token);
            setModal(!modal);
          } else {
            setLoader(false);
            // Swal.fire(result.data.message)
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else if (amltype !== 'viaDoc') {
      setLoader(true);

      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID1}`, values, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            application(token);
            setModal(!modal);
          } else {
            setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else {
      Swal.fire({
        text: 'Please Attach Document'
      });
    }
  };

  const onFinish12 = async (values) => {
    var dateOfBirth = $('.ui.icon.input.addateOfBirth>input').val();
    // alert(dateOfBirth);
    let add = {
      addressDetails: {
        addressLine1: values.addressLine1,
        suburb: values.suburb,
        city: values.city,
        postalCode: values.postalCode,
        countryCode: 'NZ',
        country: values.country
      },
      // fileName: 'VerifyAmlAddress',

      userResidentialAddress:
        values.addressLine1 +
        ', ' +
        values.suburb +
        ', ' +
        values.city +
        ', ' +
        values.postalCode +
        ', ' +
        values.country,
      verificationType: amltype12,

      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dateOfBirth: dateOfBirth,
      verifyFor: 'address'
    };
    if (
      verifyFordoc6412 !== null &&
      verifyFordoc6412 !== undefined &&
      verifyFordoc6412 !== 'undefined' &&
      amltype12 === 'viaDoc'
    ) {
      setLoader(true);
      add['doc64'] = verifyFordoc6412;

      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID1}`, add, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            // window.location.reload();

            application(token);
            setModal12(!modal12);
          } else {
            setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else if (amltype12 !== 'viaDoc') {
      setLoader(true);

      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID1}`, add, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            // window.location.reload();

            application(token);
            setModal12(!modal12);
          } else {
            setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else {
      Swal.fire({
        text: 'Please Attach Document'
      });
    }
  };
  const onFinish22 = async (values) => {
    var dateOfBirth = $('.ui.icon.input.addateOfBirth1>input').val();
    // alert(dateOfBirth);

    let add = {
      addressDetails: {
        addressLine1: values.addressLine1,
        suburb: values.suburb,
        postalCode: values.postalCode,
        city: values.city,
        countryCode: 'NZ',
        country: values.country
      },
      // fileName: 'VerifyAmlAddressSec',
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dateOfBirth: dateOfBirth,
      verificationType: amltype22,

      verifyFor: 'address'
    };
    if (
      verifyFordoc6422 !== null &&
      verifyFordoc6422 !== undefined &&
      verifyFordoc6422 !== 'undefined' &&
      amltype22 === 'viaDoc'
    ) {
      setLoader(true);
      add['doc64'] = verifyFordoc6422;
      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID2}`, add, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            // window.location.reload();
            application(token);
            setModal22(!modal22);
          } else {
            setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else if (amltype22 !== 'viaDoc') {
      setLoader(true);

      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID2}`, add, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            application(token);
            setModal22(!modal22);
          } else {
            setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else {
      Swal.fire({
        text: 'Please Attach Document'
      });
    }
  };

  // const [peppass, setPeppass] = useState(0);
  const onFinish23 = async (values) => {
    let data = {
      verifyFor: 'pep'
    };
    setLoader(true);
    const changeStage1 = await axios
      .put(`${urlapi}/admin/verify/aml/${userID1}`, data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          // window.location.reload();

          application(token);
          setModal23(!setModal23);
        } else {
          setLoader(false);
          Swal.fire({
            text: result.data.message
          });
        }
      });
  };
  const certifiacte_pdfs = (urls) => {
    window.open(urls);
  };
  const onFinishBio = async (values) => {
    let data = {
      verifyFor: 'biometric'
    };
    setLoader(true);
    const changeStage1 = await axios
      .put(`${urlapi}/admin/verify/aml/${userID1}`, data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          // window.location.reload();

          application(token);
          setModalBio(!modalBio);
        } else {
          setLoader(false);
          Swal.fire({
            text: result.data.message
          });
        }
      });
  };
  const onFinishBioSec = async (values) => {
    let data = {
      verifyFor: 'biometric'
    };
    setLoader(true);
    const changeStage1 = await axios
      .put(`${urlapi}/admin/verify/aml/${userID2}`, data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          // window.location.reload();

          application(token);
          setModalBioSec(!modalBioSec);
        } else {
          setLoader(false);
          Swal.fire({
            text: result.data.message
          });
        }
      });
  };
  const onFinish24 = async (values) => {
    let data = {
      verifyFor: 'pep'
    };
    setLoader(true);
    const changeStage1 = await axios
      .put(`${urlapi}/admin/verify/aml/${userID2}`, data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          // window.location.reload();

          application(token);
          setModal24(!setModal24);
        } else {
          setLoader(false);
          Swal.fire({
            text: result.data.message
          });
        }
      });
  };
  const onFinish1 = async (values) => {
    values.dateOfBirth = $('.ui.icon.input.dateOfBirth1>input').val();
    values.documentExpiryDate = $('.ui.icon.input.documentExpiryDate1>input').val();
    values.documentCountryOfIssue = 'NZ';

    values.verifyFor = 'identity';
    values.verificationType = amltype1;
    values.documentIdType = idTypee2;
    // values.fileName = 'IdentityVerificationSec';
    if (
      verifyFordoc641 !== null &&
      verifyFordoc641 !== undefined &&
      verifyFordoc641 !== 'undefined' &&
      amltype1 === 'viaDoc'
    ) {
      setLoader(true);
      values.doc64 = verifyFordoc641;
      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID2}`, values, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            // window.location.reload();

            application(token);
            setModal1(!modal1);
          } else {
            setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else if (amltype1 !== 'viaDoc') {
      setLoader(true);

      const changeStage1 = await axios
        .put(`${urlapi}/admin/verify/aml/${userID2}`, values, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          if (result.status === 200) {
            application(token);
            setModal1(!modal1);
          } else {
            setLoader(false);
            Swal.fire({
              text: result.data.message
            });
          }
        });
    } else {
      Swal.fire({
        text: 'Please Attach Document'
      });
    }
  };

  function matchesYearAndMonth(input) {
    return /([12]\d{3}-(0[1-9]|1[0-2]))/.test(input);
  }
  const [ziploader, setZiploader] = useState(false);
  const [ziploader1, setZiploader1] = useState(false);

  const getZipData = async (id, zipUrl) => {
    let Zipdata = {
      downloadUrl: zipUrl
    };
    setZiploader(true);
    const zip = await axios
      .put(`${urlapi}/admin/zip/download/${id}`, Zipdata, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        if (res.status == 208) {
          setZiploader(false);
          Swal.fire({
            text: res.data.message
          });
        } else {
          setZiploader(false);
          window.open(res.data.url);
        }
      });
  };

  const getZipData2 = async (id, zipUrl) => {
    let Zipdata = {
      downloadUrl: zipUrl
    };
    setZiploader1(true);
    const zip = await axios
      .put(`${urlapi}/admin/zip/download/${id}`, Zipdata, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        if (res.status == 208) {
          setZiploader1(false);
          Swal.fire({
            text: res.data.message
          });
        } else {
          setZiploader1(false);
          window.open(res.data.url);
        }
      });
  };
  const deleteaccount = async (id) => {
    setLoader(true);
    await axios
      .delete(`${urlapi}/admin/user/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        if (res) {
          // application(token);

          res.status === 200
            ? Swal.fire({
              html: 'Deleted',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#3085D6'
            }).then((e) => {
              navigate('/application');
              // history.push("/")
              // window.location = '/application';
              setLoader(false);
              // window.location.reload(true);
            })
            : Swal.fire(
              '',
              'We are unable to process your request at the moment. Please try again after some time',
              ''
            );
        }
      })
      .catch(function (error) {
        Swal.fire('', 'Unfortunately an error has occurred, Please try again.', '');
      });
  };

  const deleteonSubmit = async (id) => {
    Swal.fire({
      allowOutsideClick: false,
      // html: "Please wait a moment while we delete your application",
      html: 'Are you sure you want to delete this application and all records from the system?',
      // showDenyButton: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Yes, Delete Now`,
      // denyButtonText:`CANCEL`,
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteaccount(id);
      }
    });
  };

  const [geturs, setGeturs] = useState();

  const getur = (token) => {
    const NotessData = axios
      .get(`${urlapi}/admin/team/member`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        if (result.status === 200) {
          setGeturs(result.data);
        }
      });
  };

  const [modals1Ur2, setModals1Ur2] = useState(false);

  const [modalur2, setModalur2] = useState(false);

  const togglsUr2 = () => setModals1Ur2(!modals1Ur2);

  const showUr2 = () => setModalur2(!modalur2);

  // const urPopup = async (id) => {
  //   Swal.fire({
  //     allowOutsideClick: false,
  //     // html: "Please wait a moment while we delete your application",
  //     html: 'Change MF Investments Team Member',
  //     // showDenyButton: false,
  //     showConfirmButton: true,
  //     showCancelButton: true,
  //     confirmButtonText: `Yes`,
  //     // denyButtonText:`CANCEL`,
  //     cancelButtonText: 'No'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // deleteaccount(id);
  //       updateaccount(id);
  //     }
  //   });
  // };

  const [assignur, setAssignur] = useState();
  const updateaccount = async (id) => {
    let databody;

    if (assignur == null || assignur == '' || assignur == undefined) {
      databody = {
        memberName: geturs[0].fullLegalName
      };
    } else {
      databody = {
        memberName: assignur
      };
    }
    setLoader(true);
    await axios
      .put(`${urlapi}/admin/team/member/${id}`, databody, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        Swal.fire({
          html: 'Updated',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#3085D6'
        }).then((e) => {
          setLoader(false);
          setModals1Ur2(!modals1Ur2);
          application(token);
        });
      })
      .catch(function (error) {
        setLoader(false);
        Swal.fire('', 'Unfortunately an error has occurred, Please try again.', '');
      });
  };
  const [isLoaderVerify, setIsLoaderVerify] = useState(false);

  const verifyEmailss = async (user_id) => {
    setIsLoaderVerify(true);
    const changeStage1 = await axios
      .put(
        `${urlapi}/admin/verify/email/user/${user_id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setIsLoaderVerify(false);
          Swal.fire({
            text: 'Request sent!'
          });
        } else {
          setIsLoaderVerify(false);
          // Swal.fire(result.data.message)
          Swal.fire({
            text: result.data.message
          });
        }
      })
      .catch((error) => {
        setIsLoaderVerify(false);
        Swal.fire({
          text: 'Something went wrong!'
        });
      });
  };

  return (
    <>
      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="right_content">
          <div className="conatiner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="heading__top ht_heading">
                  <h2>
                    <span onClick={() => navigate(-1)}><i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
                    </span>APPLICATIONS: View & Edit </h2>
                </div>
              </div>
              <div className="col-md-6 text-right">
                {user1 &&
                  user1.applicationStage != 'Approved' &&
                  (user.profile.includes("UR1") || user.profile.includes("UR2")) ? (
                  <button
                    className="m-auto d-inline-block delete_app_btn"
                    onClick={() => deleteonSubmit(id)}
                  >
                    {/* <i class="fa fa-trash-o" aria-hidden="true"></i> */}Delete Application
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className="form app_view_form">
            <div className="app_view">
              <div className="app_view">
                <div className="app_view_row ">
                  <div className="app_view_box app_view_box5">
                    <h4>Overview</h4>
                    <div className="row responsive__align">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Entity Type:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? user1.investmentType : ''}</p>
                      </div>
                    </div>

                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Entity Name:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 && user1.entityName !== null ? user1.entityName : ''}</p>
                      </div>
                    </div>
                    <div className="row responsive__align">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Investor Type:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? user1.investmentType : ''}</p>
                      </div>
                    </div>
                    <div className="row responsive__align">
                      <div className="col-md-6 col-sm-6 col-6">
                        <label>Client Type:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        {/* <p>{user1 ? user1.clientType : ''}</p> */}
                        <p>{'Wholesale Investor'}</p>
                      </div>
                    </div>

                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Primary Contact Name: </label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        {/* <p>{user1 ? user1.priContactNumber : ''}</p> */}
                        <p>
                          {' '}
                          {user1 && user1.fullLegalName !== null && user1.fullLegalName != ''
                            ? user1.fullLegalName
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Email:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? user1.userEmail : ''}</p>
                        <span className="status">
                          {user1 && user1.isEmailVerified ? (
                            <>
                              <span className="Success Success_icon">
                                <i class="fa fa-check" aria-hidden="true"></i>
                                Verified
                              </span>
                            </>
                          ) : (
                            <span className="danger danger_icon">
                              <p>
                                {' '}
                                <i class="fa fa-times cross_icon" aria-hidden="true"></i> Not
                                Verified
                              </p>
                              {isLoaderVerify == true ? (
                                <div className="note-main-loader verify_loader">
                                  <div className="loader zip_exp_loader"></div>
                                </div>
                              ) : (
                                <>
                                  <button
                                    className="Send_Email"
                                    onClick={() => verifyEmailss(user1.id)}
                                  >
                                    Send Email
                                  </button>
                                  {/* <button
                                    className=" Manually"
                                    onClick={() => verifyEmail1(user1.id)}
                                  >
                                    Update Manually
                                  </button> */}
                                </>
                              )}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Phone :</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        {user1 &&
                          user1.priContactNumber != null &&
                          user1 &&
                          user1.priContactNumber != '' ? (
                          <>
                            {user1 ? (
                              user1.priContactNumber !== null &&
                                user1.priContactCountryCode !== null ? (
                                <p>
                                  {user1 && user1.priContactCountryCode !== ''
                                    ? '+' + user1.priContactCountryCode + ' '
                                    : ' '}
                                  {/* {user1 ? user1.priContactCountryCode : ''}{' '} */}
                                  {user1 ? user1.priContactNumber : ''}
                                </p>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    {/* <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Fund: </label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? user1.fundCode : ''}</p>
                      </div>
                    </div> */}
                    {/* 
                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Investment:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>
                          {user1 && user1.totalInvestment != null ? <>$</> : ''}
                        

                          {user1 && user1.totalInvestment != null ? user1.totalInvestment : ''}
                        </p>
                      </div>
                    </div> */}

                    <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Application Reference Number:</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? (user1.isPrimary == 1 ? user1.id : '') : ''}</p>
                        {/* <p>{user1 ? user1.paymentReference : ''}</p> */}
                      </div>
                    </div>
                    <div className="row responsive__align align-items-start">
                      <div className="col-md-6">
                        <label>MF Investments Team Member</label>
                      </div>

                      {
                        // user1 &&
                        //   user1.overAllApplicationsStage !== 'Approved' && (user1.applicationStage != "In Progress") &&
                        (user.profile.includes("UR2") || user.profile.includes("UR1")) ? (
                          <div className="col-md-6 d-flex justify-content-between align-items-center">
                            <p>{user1 && user1.memberName}</p>
                            <div class="ur_edite">
                              <i
                                title="Edit"
                                class="fa fa-pencil-square-o hover__Edit"
                                aria-hidden="true"
                                // onClick={() => urPopup()}
                                onClick={() => togglsUr2()}
                              ></i>
                              <span class="sr-only">Edit</span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      <Modal isOpen={modals1Ur2} show={showUr2} className={className}>
                        {/* <ModalHeader toggle={togglsUr2}>Edit Address</ModalHeader> */}
                        <Box className="formPopUp">
                          <h4>Change MF Investments Team Member</h4>
                          <div className="UpdateApplication edit_form">
                            <Row>
                              <Col md={6}>
                                <label>MF Investments Team Member</label>
                              </Col>
                              <Col md={6}>
                                <select
                                  className="w-75 m-0 fw-normal mb-1 pt-0 pb-0 pl-0"
                                  // name="fullLegalName"
                                  onChange={(e) => {
                                    // urChangepopup();
                                    // alert(e.target.value);
                                    setAssignur(e.target.value);
                                  }}
                                  value={assignur}
                                >
                                  {geturs &&
                                    geturs.map((geturdata) => {
                                      return (
                                        <>
                                          <option>{geturdata.fullLegalName}</option>
                                        </>
                                      );
                                    })}
                                </select>
                              </Col>
                            </Row>

                            <Row className="UpdateBtn">
                              <Col md={6}>
                                <div onClick={togglsUr2} className="cancelBTN">
                                  Cancel
                                </div>
                              </Col>

                              <Col md={6}>
                                <button
                                  onClick={() => {
                                    // changeStage2(userr.id, 'main');
                                    updateaccount(user1.id);

                                    // urPopup(user1.id);
                                  }}
                                >
                                  Yes, Update Now
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                    {/* <div className="row responsive__align">
                      <div className="col-md-6">
                        <label>MF Investments Team Member</label>
                      </div>
                      {user1 &&
                      user1.overAllApplicationsStage !== 'Approved' &&
                      user.profile.includes("UR2") ? (
                        <div className="col-md-6 d-flex justify-content-between align-items-center">
                          <select className="w-75 m-0 fw-normal mb-1 pt-0 pb-0 pl-0">
                            {geturs &&
                              geturs.map((geturdata) => {
                                return (
                                  <>
                                    <option>{geturdata.fullLegalName}</option>
                                  </>
                                );
                              })}
                          </select>
                          <div class="ur_edite">
                            <i
                              title="Edit"
                              class="fa fa-pencil-square-o hover__Edit"
                              aria-hidden="true"
                            ></i>
                            <span class="sr-only">Edit</span>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div> */}
                    {/* <div className="row responsive__align">
                      <div className="col-md-6  col-sm-6 col-6">
                        <label>Investor Relations Associate :</label>
                      </div>
                      <div className="col-md-6  col-sm-6 col-6">
                        <p>{user1 ? user1.investorRelation : ''}</p>
                      </div>
                    </div> */}
                  </div>

                  <div className="app_view_box app_view_box5">
                    <h4>Application Status</h4>
                    <div className="row responsive__align">
                      <div className="col-md-3 col-sm-3 col-3 ">
                        <label>Stage: </label>
                      </div>

                      <div className="col-md-9 col-sm-9 col-9">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-lg-auto col-sm-6 col-6 pl-sm-3">
                            {user1 &&
                              user1.overAllApplicationsStage == 'Submitted' &&
                              user1.overAllAmlStatus == 1 ? (
                              <p>Submitted - Passed AML</p>
                            ) : user1 &&
                              user1.overAllApplicationsStage == 'Submitted' &&
                              user1.overAllAmlStatus == 0 ? (
                              <p>Submitted - Pending AML</p>
                            ) : (
                              <p>{user1 ? user1.overAllApplicationsStage : ''}</p>
                            )}

                            {/* <p>{user1 ? user1.overAllApplicationsStage : ''}</p> */}
                          </div>

                          <div className="col-lg-auto  col-sm-6  col-6  ">
                            {(user.profile.includes("UR2") || user.profile.includes("UR1")) &&
                              user1 &&
                              user1.overAllApplicationsStage == 'Submitted' ? (
                              // && user1.overAllAmlStatus == 1
                              <button className="update_stag" onClick={toggles1}>
                                Update Stage
                              </button>
                            ) : (
                              ''
                            )}

                            <Modal isOpen={modals1} show={show12} className={className}>
                              <Box className="formPopUp">
                                <h4>Update Application Stage</h4>
                                <div className="UpdateApplication edit_form">
                                  <Row>
                                    <Col md={6}>
                                      <label>Current Application Stage</label>
                                    </Col>

                                    <Col md={6}>
                                      {user1 &&
                                        user1.overAllApplicationsStage == 'Submitted' &&
                                        user1.overAllAmlStatus == 1 ? (
                                        <p>Submitted - Passed AML</p>
                                      ) : user1 &&
                                        user1.overAllApplicationsStage == 'Submitted' &&
                                        user1.overAllAmlStatus == 0 ? (
                                        <p>Submitted - Pending AML</p>
                                      ) : (
                                        <p>{user1 ? user1.overAllApplicationsStage : ''}</p>
                                      )}
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={6}>
                                      <label>Update Stage</label>
                                    </Col>

                                    <Col md={6}>
                                      <select
                                        id="ApplicationStageChange"
                                        onChange={(e) => UpdateApplicationStage(e)}
                                      >
                                        {user1 &&
                                          user1.overAllApplicationsStage == 'Submitted' &&
                                          user1.overAllAmlStatus == 0 ? (
                                          <option class="optionStage" value="In Progress">
                                            {' '}
                                            In Progress
                                          </option>
                                        ) : (
                                          <>
                                            <option class="optionStage" value="In Progress">
                                              {' '}
                                              In Progress
                                            </option>
                                            <option
                                              class="optionStage"
                                              value="Approved"
                                              defaultSelected
                                              Selected="Approved"
                                            >
                                              {' '}
                                              Submitted - Approved
                                            </option>
                                          </>
                                        )}
                                      </select>
                                    </Col>
                                  </Row>

                                  <Row className="UpdateBtn">
                                    <Col md={6}>
                                      <div onClick={toggles1} className="cancelBTN">
                                        Cancel
                                      </div>
                                    </Col>

                                    <Col md={6}>
                                      <button onClick={changeStage}>Save Changes</button>
                                    </Col>
                                  </Row>
                                </div>
                              </Box>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row responsive__align">
                      <div className="col-md-3 col-sm-3 col-3">
                        <label>
                          <span className="text_upercas">AML</span>:
                        </label>
                      </div>
                      <div className="col-md-9 col-sm-9 col-9">
                        {user1 && user1.investmentType === 'Joint' ? (
                          <p>
                            {user1 && user1.overAllAmlStatus === 1 ? (
                              <p className="aml_pass TextIndent">Pass</p>
                            ) : (
                              <p className="aml_fail TextIndent">Fail</p>
                            )}
                          </p>
                        ) : (
                          <>
                            {user1 && user1.overAllAmlStatus === 1 ? (
                              <p className="aml_pass TextIndent">Pass</p>
                            ) : (
                              <p className="aml_fail TextIndent">Fail</p>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {user.profile.includes("UR1") ||
                      user.profile == 'UR8' ||
                      user.profile.includes("UR2") ||
                      user.profile == 'UR9' ? (
                      user1 && user1.applicationStage === 'Active Client' ? (
                        <>
                          <div className="row responsive__align main_wrapper_date">
                            <div className="col-md-5 col-12">
                              <label>Date Payment Received:</label>
                            </div>
                            <div className="col-md-5 col-12">
                              <div className="fullinput">
                                <p>
                                  {user1 != null && user1 != '' && user1 != 'undefined'
                                    ? user1.paymentReceived
                                    : ''}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )
                    ) : null}
                    {user1 &&
                      (user1.applicationStage === 'Awaiting Payment' ||
                        user1.applicationStage === 'Active Client') ? (
                      <>
                        {user.profile.includes("UR4") ? (
                          <div className="row responsive__align">
                            <div className="col-md-5 main_wrapper_daten col-12">
                              <label>Date Payment Received:</label>
                            </div>
                            {(user1 &&
                              user1.applicationStage === 'Awaiting Payment' &&
                              user1.paymentReceived == null) ||
                              user1.paymentReceived == '' ? (
                              <div className="col-md-5 col-8 date_style">
                                <div className="fullinput">
                                  <SemanticDatepicker
                                    className="date12 counrtyselext6 numbers input__w50_a"
                                    id="date1"
                                    maxDate={new Date(moment().subtract(0, 'years'))}
                                    format="DD/MM/YYYY"
                                    name="date1"
                                    // value={user1.paymentReceived}
                                    allowOnlyNumbers
                                    onChange={(e) => {
                                      datechange();
                                    }}
                                  // v alue={paymentReceived}
                                  />
                                </div>

                                {isLoaderpaymentReceived === true ? (
                                  <div class="col-md-4">
                                    {' '}
                                    <div class="main_date_loader">
                                      <div className="loader">
                                        <p class="loader-inner"></p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : (
                              <>
                                <div className="main_date_stage date_style negavtive-text-indent">
                                  <SemanticDatepicker
                                    className="counrtyselext6 numbers input__w50_a resp"
                                    id="date1"
                                    // minDate={now()}
                                    maxDate={new Date(moment().subtract(0, 'years'))}
                                    format="DD/MM/YYYY"
                                    name="date1"
                                    value={
                                      user1 && user1.paymentReceived
                                        ? new Date(setDateformateToValid1(user1.paymentReceived))
                                        : ''
                                    }
                                    allowOnlyNumbers
                                    onChange={(e) => {
                                      datechange();
                                    }}
                                  />

                                  {isLoaderpaymentReceived === true ? (
                                    <div class="col-md-4 col-4">
                                      {' '}
                                      <div class="main_date_loader">
                                        <div className="loader">
                                          <p class="loader-inner"></p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            )}
                          </div>
                        ) : null}

                        {user.profile.includes("UR1") ||
                          user.profile == 'UR8' ||
                          user.profile.includes("UR2") ||
                          user.profile == 'UR9' ? (
                          <>
                            <div className="row responsive__align main_wrapper_date">
                              <div className="col-md-5 col-12">
                                <label>Date Payment Made to Du Val:</label>
                              </div>
                              <div className="col-md-5 col-12">
                                <div className="fullinput">
                                  <p>
                                    {user1 != null && user1 != '' && user1 != 'undefined'
                                      ? user1.paymentToDuval
                                      : ''}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        {user.profile.includes("UR4") ? (
                          <div>
                            <div className="row responsive__align">
                              <div className="col-md-5 col-12">
                                <label>Date Payment Made to Du Val:</label>
                              </div>

                              {user1 &&
                                (user1.applicationStage === 'Awaiting Payment' ||
                                  user1.applicationStage === 'Active Client') ? (
                                user1 &&
                                  user1.applicationStage === 'Awaiting Payment' &&
                                  user1.paymentReceived == null ? (
                                  <div className="col-md-5 col-8 date_style">
                                    <div className="fullinput">
                                      <SemanticDatepicker
                                        className="counrtyselext6 numbers input__w50_a"
                                        id="date2"
                                        // minDate={now()}
                                        maxDate={new Date(moment().subtract(0, 'years'))}
                                        format="DD/MM/YYYY"
                                        name="date2"
                                        allowOnlyNumbers
                                        onChange={(e) => {
                                          datechange2();
                                        }}
                                      />
                                    </div>

                                    {isLoaderpaymentReceived1 === true ? (
                                      <div class="col-md-4">
                                        {' '}
                                        <div class="main_date_loader_duval">
                                          <div className="loader">
                                            <p class="loader-inner"></p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="main_date_stage date_lader date_style negavtive-text-indent">
                                    <SemanticDatepicker
                                      className="counrtyselext6 numbers input__w50_a"
                                      id="date2"
                                      // minDate={now()}
                                      maxDate={new Date(moment().subtract(0, 'years'))}
                                      format="DD/MM/YYYY"
                                      name="date2"
                                      value={
                                        user1 && user1.paymentToDuval
                                          ? new Date(setDateformateToValid2(user1.paymentToDuval))
                                          : ''
                                      }
                                      allowOnlyNumbers
                                      onChange={(e) => {
                                        datechange2();
                                      }}
                                    />

                                    {isLoaderpaymentReceived1 === true ? (
                                      <div class="col-md-4">
                                        {' '}
                                        <div class="main_date_loader_duval">
                                          <div className="loader">
                                            <p class="loader-inner"></p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  // <>{user1.paymentToDuval}</>
                                )
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                    <div className="history_main_wrapper">
                      <div className="history">
                        <Link to={{ pathname: `/History/${user1 ? user1.id : ''}` }}>
                          <i title="History" class="fa fa-history" aria-hidden="true"></i>
                        </Link>
                      </div>
                    </div>
                    {/* {(user.profile.includes("UR1") || user.profile.includes("UR2") || user.profile.includes("UR3") || user.profile.includes("UR4") ) ? (
                      <>
                        <div className="history_main_wrapper">
                          <div className="history">
                            <Link to={{ pathname: `/History/${user1 ? user1.id : ''}` }}>
                              <i title="History" class="fa fa-history" aria-hidden="true"></i>
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )} */}

                    {user.profile.includes("UR3") ? (
                      <div className="SubscriptionAgree_btn responsive__SubscriptionAgreement">
                        <Link
                          to={{ pathname: `/SubscriptionAgreement/${user1 ? user1.id : ''}` }}
                          className="BITTON__sign_directore"
                        >
                          <>
                            <div className="">
                              <div className="red_green_text">
                                {directodata !== undefined &&
                                  directodata !== 'undefined' &&
                                  directodata.map((user1ss) => {
                                    return (
                                      <>
                                        {user1ss.isLogin === true ? (
                                          <>
                                            {user1ss.subscriptionSign != null &&
                                              user1ss.subscriptionSign != '' ? (
                                              <>
                                                <div className="mobile_button d-block d-sm-none">
                                                  <button className="Sign__agreement button__green">
                                                    SIGN SUBSCRIPTION
                                                  </button>
                                                </div>
                                                <div className="Sign__agreement_ICON d-none  d-sm-inline-block d-md-inline-block d-lg-inline-block destop__view">
                                                  <p className="text__green">
                                                    <i
                                                      title="Sign all documents"
                                                      class="fa fa-pencil-square-o text__green"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </p>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="mobile_button d-block d-sm-none">
                                                  <button className="Sign__agreement button__red">
                                                    SIGN SUBSCRIPTION
                                                  </button>
                                                </div>

                                                <div className="Sign__agreement_ICON d-none  d-sm-inline-block d-md-inline-block d-lg-inline-block">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <div className="text_pending">
                                                        <p className="text__red">
                                                          <i
                                                            title="Sign all documents"
                                                            class="fa fa-pencil-square-o text__red"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </>
                        </Link>

                        {/* <Link
                          to={{ pathname: `/SignAdherenceAgreement/${user1 ? user1.id : ''}` }}
                          className="BITTON__sign_directore"
                        >
                          <>
                            <div className="">
                              <div className="red_green_text">
                                {directodata !== undefined &&
                                  directodata !== 'undefined' &&
                                  directodata.map((user1ss) => {
                                    return (
                                      <>
                                        {user1ss.isLogin === true ? (
                                          <>
                                            {user1ss.adherenceSign != null &&
                                            user1ss.adherenceSign != '' ? (
                                              <>
                                                <div className="mobile_button d-block d-sm-none">
                                                  <button className="Sign__agreement button__green">
                                                    SIGN ADHERENCE
                                                  </button>
                                                </div>
                                                <div className="Sign__agreement_ICON d-none d-sm-inline-block d-md-inline-block d-lg-inline-block">
                                                  <p className="text__green">
                                                    <i
                                                      title="Sign Adherence Agreement"
                                                      class="fa fa-handshake-o text__green"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </p>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="mobile_button d-block d-sm-none">
                                                  <button className="Sign__agreement button__red">
                                                    SIGN ADHERENCE
                                                  </button>
                                                </div>
                                                <div className="Sign__agreement_ICON d-none  d-sm-inline-block d-md-inline-block d-lg-inline-block">
                                                  <div className="text_pending">
                                                    <p className="text__red">
                                                      <i
                                                        title="Sign Adherence Agreement"
                                                        class="fa fa-handshake-o  text__red"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </p>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </>
                        </Link> */}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                {user.profile.includes("UR4") ? (
                  ''
                ) : (
                  <>
                    <div className="app_view_box">
                      <h4>Application Errors & Outstanding Items</h4>

                      <div className="main-wrapper_error">
                        <div className="primary_text">Primary</div>
                        <Table bordered className="table_dash">
                          <tbody>
                            {user1 && user1.identificationType == 'Biometric' ? (
                              <>
                                {user1 && user1.liveCheck === 0 ? (
                                  <tr>
                                    <td className="td__width">AML Biometrics </td>
                                    <td className="td__width">
                                      <p>Fail</p>
                                    </td>
                                  </tr>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : null}

                            {user1 && user1.addressCheck === 0 ? (
                              <tr>
                                <td className="td__width">AML Address</td>

                                <td className="td__width">
                                  <p>Fail</p>
                                </td>
                              </tr>
                            ) : (
                              ''
                            )}

                            {user1 ? (
                              <>
                                {user1 && user1.idCheck === 0 ? (
                                  <tr>
                                    <td className="td__width capitals">AML ID </td>

                                    <td className="td__width">
                                      <p>Fail</p>
                                    </td>
                                  </tr>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : null}

                            {user1 && user1.pepCheck == null ? (
                              <tr>
                                <td className="td__width">AML PEP </td>

                                <td className="td__width">
                                  <p>Fail</p>
                                </td>
                              </tr>
                            ) : (
                              ''
                            )}
                          </tbody>
                        </Table>

                        {user2 ? (
                          <>
                            <div className="">
                              <div className="primary_text">Secondary</div>
                              <Table bordered className="table_dash">
                                {/* <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Errors</th>
                                </tr>
                              </thead> */}

                                <tbody>
                                  {user2 && user2.identificationType == 'Biometric' ? (
                                    <>
                                      {user2 && user2.liveCheck === 0 ? (
                                        <tr>
                                          <td className="td__width ">AML Biometrics </td>

                                          <td className="td__width">
                                            <p>Fail</p>
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  ) : null}

                                  {user2 && user2.addressCheck === 0 ? (
                                    <tr>
                                      <td className="td__width">AML Address</td>

                                      <td className="td__width">
                                        <p>Fail</p>
                                      </td>
                                    </tr>
                                  ) : (
                                    ''
                                  )}

                                  {user2 ? (
                                    <>
                                      {user2 && user2.idCheck === 0 ? (
                                        <tr>
                                          <td className="td__width capitals">AML ID </td>

                                          <td className="td__width">
                                            <p>Fail</p>
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  ) : null}

                                  {user2.pepCheck == null ? (
                                    <tr>
                                      <td className="td__width">AML PEP </td>

                                      <td className="td__width">
                                        <p>Fail</p>
                                      </td>
                                    </tr>
                                  ) : (
                                    ''
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="app_view_box">
                <h4>Application Details</h4>
                <div className="show_responsive mb-4">
                  <div className="primary_text">
                    Primary
                    <div className="note-footer flex-note">
                      {ziploader === true ? (
                        <div className="note-main-loader">
                          <div className="loader zip_exp_loader"></div>
                        </div>
                      ) : user1 && user1.overAllAmlStatus === 1 && user1.applicationStage == "Submitted" ? (
                        <button
                          className="save zipexport"
                          onClick={() => getZipData(user1.id, user1.zipUrl)}
                        >
                          Zip Export
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  <div class="accordion" id="accordionExample">

                    {user1 && user1.investmentType == 'Joint' ?
                      < div className="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Entity Details
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-11">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    {user1 && user1.investmentType == 'Joint' ? (
                                      <label>
                                        Full Name of 1<sup>st</sup>Joint Account Holder
                                      </label>
                                    ) : (
                                      <label>Full Legal Name</label>
                                    )}
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <p>{user1 ? user1.fullLegalName : ''}</p>
                                  </div>
                                </div>
                                {user1 && user1.investmentType == 'Joint' ? (
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>
                                        dddd Share of Income Attributed to 1<sup>st</sup> Joint
                                        Account Holder
                                      </label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p>{user2 ? user2.SecondaryShareOfIncome - 100 : ''}</p>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>

                              {/* <div className="col-md-12 edit_icon PersonalInformationEdit">
                              {user.profile.includes("UR4") ||
                              user.profile.includes("UR2") ||
                              (user1 && user1.applicationStage == 'Active Client') ||
                              user.profile.includes("UR3") ? (
                                ''
                              ) : (
                                <i
                                  onClick={handleOpen}
                                  title="Edit"
                                  class="fa fa-pencil-square-o hover__Edit"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div> : ""}
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Personal Information
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="row">
                            <div className="col-md-11">
                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                  <label>Full Legal Name</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>{user1 ? user1.fullLegalName : ''}</p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                  <label>Preferred Name</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>{user1 ? user1.preferredName : ''}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                  <label>Date Of Birth</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>{user1 ? user1.dateOfBirth : ''}</p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                  <label>Email </label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>{user1 ? user1.userEmail : ''}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                  <label>Mobile Number</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>
                                    {user1 ? (
                                      user1.priContactNumber !== null &&
                                        user1.priContactCountryCode !== null ? (
                                        <p>
                                          {user1 && user1.priContactCountryCode !== ''
                                            ? '+' + user1.priContactCountryCode + ' '
                                            : ' '}
                                          {/* {user1 ? user1.priContactCountryCode : ''}{' '} */}
                                          {user1 ? user1.priContactNumber : ''}
                                        </p>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </p>
                                </div>
                              </div>

                              {user1 ? (
                                user1.isAlternateContact === 1 ? (
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6 col-6">
                                      <label> {user1.secContactType} Additional Number </label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6 col-6">
                                      {user1 ? (
                                        user1.secContactNumber !== null &&
                                          user1.secContactCountryCode !== null ? (
                                          <p>
                                            {user1 && user1.secContactCountryCode !== ''
                                              ? '+' + user1.secContactCountryCode + ' '
                                              : ' '}
                                            {user1 ? user1.secContactNumber : ''}
                                          </p>
                                        ) : (
                                          ''
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                ) : null
                              ) : null}


                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6 col-6">
                                  <label>Residential Address</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6 col-6">
                                  <p>{user1 ? user1.userResidentialAddress : ''}</p>
                                </div>
                              </div>

                              {user1 &&
                                user1.userResidentialAddress != null &&
                                user1.userResidentialAddress != '' ? (
                                <>
                                  {' '}
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6 col-6">
                                      <label>Postal Address</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6 col-6">
                                      <p>
                                        {user1 && user1.isUserAddressSame === 1
                                          ? 'Same as home address'
                                          : user1 && user1.userPostalAddress
                                            ? user1.userPostalAddress
                                            : ''}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ''
                              )}

                              {/* <div className="row">
                                <div className="col-md-6 col-sm-6 col-6 col-6">
                                  <label>Occupation</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6 col-6">
                                  <p>{user1 ? user1.occupation : ''}</p>
                                </div>
                              </div> */}
                              {user1 && user1.occupation === 'Other' ? (
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6 col-6">
                                    <label>Other Occupation</label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6 col-6">
                                    <p>{user1 ? user1.otherOccupation : ''}</p>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}

                              <>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6 col-6">
                                    <label>City/Town Born In</label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6 col-6">
                                    <p>{user1 ? user1.placeOfBirth : ''}</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6 col-6">
                                    <label>Country Born In</label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6 col-6">
                                    <p>{user1 ? getCountryName(user1.countryOfBirth) : ''}</p>
                                  </div>
                                  {/* ; */}
                                </div>

                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <label>Country of Residence</label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <p>{user1 ? getCountryName(user1.residingCountry) : ''}</p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    {/* <label>Ird Number <label> */}
                                    <label className="">IRD Number</label>
                                  </div>
                                  <div className="col-md-6  col-sm-6 col-6">
                                    <p>{user1 ? user1.userIrdNumber : ''}</p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <label className=""> Tax Type </label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <div className="rwttax">
                                      <div className="Main-wrapper-circle">
                                        <p className="circle">{user1 ? user1.taxType : ''}</p>
                                      </div>
                                    </div>

                                    {/* <p>{user1 ? user1.userIrdNumber : ''}</p> */}
                                  </div>
                                </div>

                                {user1 && user1.taxType == 'Resident Withholding Tax' ? (
                                  <div className="row">
                                    <div className="col-md-6  col-sm-6 col-6">
                                      <label className="">Resident Withholding Tax Rate</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <div className="rwttax">
                                        <div className="Main-wrapper-circle">
                                          <p className="circle">{user1 ? user1.rwtValue : ''}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}

                                {user1 && user1.rwtValue == 'Exempt' ? (
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Proof of Exemption</label>
                                    </div>

                                    <div className="col-md-6 document_download">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => NoteClick(user1.examptProof)}
                                        className="licedocument-downloade"
                                      >
                                        {' '}
                                        <div className="main-downloade-wrapper">
                                          {user1 &&
                                            user1.examptProof !== null &&
                                            user1.examptProof !== '' &&
                                            user1.examptProof &&
                                            'undefined' &&
                                            user1.examptProof !== undefined ? (
                                            <p className="downloadeMsg">
                                              <div className="downloads">
                                                <i className="fa fa-download downloadeStyle"></i>
                                              </div>{' '}
                                              View File{' '}
                                            </p>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}

                                {user1 && user1.taxType == 'Non Resident Withholding Tax' ? (
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label className="">Resident Withholding Tax Exemption</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <div className="rwttax">
                                        <div className="Main-wrapper-circle">
                                          <p>
                                            {user1.taxExemption == 0 || user1.taxExemption == false
                                              ? 'No'
                                              : 'Yes'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}


                              </>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          ID Verification
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="row">
                            <div className="col-md-11">
                              <>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <label>Type of ID </label>
                                  </div>

                                  <div className="col-md-6 col-sm-6 col-6">
                                    <p>{user1 ? user1.documentIdType : ''}</p>
                                  </div>
                                </div>
                                {user1 ? (
                                  user1.documentIdType != 'NZ Driver Licence' &&
                                    user1.documentIdType != 'NZ Passport' ? (
                                    <>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <label>Other Document Type</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <p>{user1 ? user1.otherDocumentName : ''}</p>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                              </>

                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                  <label>First Name</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>{user1 ? user1.firstName : ''}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                  <label>Middle Name</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>{user1 ? user1.middleName : ''}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                  <label>Last Name</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>{user1 ? user1.lastName : ''}</p>
                                </div>
                              </div>
                              {user1 ? (
                                user1.documentIdType == 'NZ Driver Licence' ? (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Licence Number</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>{user1 ? user1.documentNumber : ''}</p>
                                      </div>
                                    </div>
                                  </>
                                ) : user1.documentIdType == 'NZ Passport' ? (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6 col-6">
                                        <label>Passport Number</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6 col-6">
                                        <p>{user1 ? user1.documentNumber : ''}</p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6 col-6">
                                      <label>Document Number</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6 col-6">
                                      <p>{user1 ? user1.documentNumber : ''}</p>
                                    </div>
                                  </div>
                                )
                              ) : (
                                ''
                              )}

                              {user1 ? (
                                user1.documentIdType == 'NZ Driver Licence' ? (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Expiry Date</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>{user1 ? user1.documentExpiryDate : ''}</p>
                                      </div>
                                    </div>
                                  </>
                                ) : user1.documentIdType == 'NZ Passport' ? (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Expiry Date</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>{user1 ? user1.documentExpiryDate : ''}</p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Expiry Date</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p>{user1 ? user1.documentExpiryDate : ''}</p>
                                    </div>
                                  </div>
                                )
                              ) : (
                                ''
                              )}

                              {user1 ? (
                                user1.documentIdType == 'NZ Driver Licence' ? (
                                  <>
                                    {' '}
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Version Number</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <p>{user1 ? user1.documentVersionNumber : ''}</p>
                                      </div>
                                    </div>
                                  </>
                                ) : user1.documentIdType == 'NZ Passport' ? (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 ">
                                        <label>Country of Issue</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6">
                                        <p>{user1 ? user1.documentCountryOfIssue : ''}</p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="row">
                                    <div className="col-md-6  col-sm-6">
                                      <label>Country of Issue</label>
                                    </div>
                                    <div className="col-md-6  col-sm-6">
                                      <p>{user1 ? user1.documentCountryOfIssue : ''}</p>
                                    </div>
                                  </div>
                                )
                              ) : (
                                ''
                              )}

                              {user1 ? (
                                user1.documentIdType == 'NZ Driver Licence' ? (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <label>Document Proof</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <div className="document_download">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => NoteClick(user1.documentProof)}
                                            className="licedocument-downloade"
                                          >
                                            {' '}
                                            <div className="main-downloade-wrapper">
                                              {user1 &&
                                                user1.documentProof !== null &&
                                                user1.documentProof !== '' &&
                                                user1.documentProof &&
                                                'undefined' &&
                                                user1.documentProof !== undefined ? (
                                                <p className="downloadeMsg">
                                                  <div className="downloads">
                                                    <i className="fa fa-download downloadeStyle"></i>
                                                  </div>{' '}
                                                  VIEW FILE{' '}
                                                </p>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </a>

                                          <span id="name_tag" class="preview2"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : user1.documentIdType == 'NZ Passport' ? (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <label>Document Proof</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <div className="document_download">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => NoteClick(user1.documentProof)}
                                            className="licedocument-downloade"
                                          >
                                            {' '}
                                            <div className="main-downloade-wrapper">
                                              {user1 &&
                                                user1.documentProof !== null &&
                                                user1.documentProof !== '' &&
                                                user1.documentProof &&
                                                'undefined' &&
                                                user1.documentProof !== undefined ? (
                                                <p className="downloadeMsg">
                                                  <div className="downloads">
                                                    <i className="fa fa-download downloadeStyle"></i>
                                                  </div>{' '}
                                                  VIEW FILE{' '}
                                                </p>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </a>

                                          <span id="name_tag" class="preview2"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <label>Document Proof</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <div className="document_download">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => NoteClick(user1.documentProof)}
                                            className="licedocument-downloade"
                                          >
                                            {' '}
                                            <div className="main-downloade-wrapper">
                                              {user1 &&
                                                user1.documentProof !== null &&
                                                user1.documentProof !== '' &&
                                                user1.documentProof &&
                                                'undefined' &&
                                                user1.documentProof !== undefined ? (
                                                <p className="downloadeMsg">
                                                  <div className="downloads">
                                                    <i className="fa fa-download downloadeStyle"></i>
                                                  </div>{' '}
                                                  VIEW FILE{' '}
                                                </p>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </a>

                                          <span id="name_tag" class="preview2"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              ) : (
                                ''
                              )}


                            </div>
                            <div className="col-md-12 edit_icon PersonalInformationEdit">
                              {user.profile.includes("UR4") ||
                                (user1 && user1.applicationStage == 'Active Client') ||
                                user.profile.includes("UR2") ||
                                user.profile.includes("UR3") ? (
                                ''
                              ) : (
                                <i
                                  onClick={handleOpen}
                                  title="Edit"
                                  class="fa fa-pencil-square-o hover__Edit"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Bank Account
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        aria-labelledby="collapseFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="row">
                            <div className="col-md-11">
                              <div className="row">
                                <div className="col-md- col-sm-6 col-6">
                                  <label> NZ Bank Account</label>
                                </div>
                                <div className="col-md- col-sm-6 col-6">
                                  <p>{user1 && user1.isNZBank === 1 ? 'Yes' : 'No'}</p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md- col-sm-6 col-6">
                                  <label>Bank Account Name</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>{user1 ? user1.primaryBankAccountName : ''}</p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                  <label>Bank Account Number</label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                  <p>{user1 ? user1.primaryBankAccountNumber : ''}</p>
                                </div>
                              </div>
                              {user1 &&
                                user1.bankSwiftCode !== '' &&
                                user1.isNZBank !== 1 &&
                                user1.bankSwiftCode !== null ? (
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <label>Bank Swift Code</label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <p>{user1 ? user1.bankSwiftCode : ''}</p>
                                  </div>
                                </div>
                              ) : null}

                              <div className="row">
                                <div className="col-md-6   col-sm-6 col-6">
                                  <label>Document Proof</label>
                                </div>
                                <div className="col-md-6  col-sm-6 col-6">
                                  <div className="document_download">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => NoteClick(user1.primaryBankProof)}
                                      className="licedocument-downloade"
                                    >
                                      {' '}
                                      <div className="main-downloade-wrapper">
                                        {user1 &&
                                          user1.primaryBankProof !== null &&
                                          user1.primaryBankProof !== '' &&
                                          user1.primaryBankProof &&
                                          'undefined' &&
                                          user1.primaryBankProof !== undefined ? (
                                          <p className="downloadeMsg">
                                            <div className="downloads">
                                              <i className="fa fa-download downloadeStyle"></i>
                                            </div>{' '}
                                            VIEW FILE{' '}
                                          </p>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </a>

                                    <span id="name_tag" class="preview2"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="col-md-12 edit_icon PersonalInformationEdit">
                                {user.profile.includes("UR4") ||
                                  user.profile.includes("UR2") ||
                                  (user1 && user1.applicationStage == 'Active Client') ||
                                  user.profile.includes("UR3") ? (
                                  ''
                                ) : (
                                  <i
                                    onClick={handleOpen}
                                    title="Edit"
                                    class="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingooo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseooo"
                          aria-expanded="false"
                          aria-controls="collapseooo"
                        >
                          Wholesale Certificate
                        </button>
                      </h2>
                      <div
                        id="collapseooo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingooo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className='row'>
                            {user1 && user1.certificateDetails ? (
                              <div className="row whole_cert">
                                <div className="col-md-12">
                                  {user1.certificateDetails &&
                                    user1.certificateDetails.certificateNumber == '1' ? (
                                    <>
                                      {(user1 &&
                                        user1.certificateDetails &&
                                        user1.certificateDetails.largePersonValue != null &&
                                        user1 &&
                                        user1.certificateDetails.largePersonValue !==
                                        'undefined' &&
                                        user1.certificateDetails.largePersonValue != 'None' &&
                                        user1.certificateDetails.largePersonValue != '') ||
                                        (user1 &&
                                          user1.certificateDetails &&
                                          user1.certificateDetails.twoYearInvestEntity2 != null &&
                                          user1 &&
                                          user1.certificateDetails.twoYearInvestEntity2 !==
                                          'undefined' &&
                                          user1.certificateDetails.twoYearInvestEntity2 != 'None' &&
                                          user1.certificateDetails.twoYearInvestEntity2 != '') ? (
                                        <>
                                          {' '}
                                          <h3>Wholesale Certification: Large Investor</h3>
                                          <p>{user1.certificateDetails.largePersonValue}</p>
                                          <p>{user1.certificateDetails.twoYearInvestEntity2}</p>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      {/* 2nd */}

                                      <>
                                        {user1 &&
                                          ((user1.certificateDetails.investorPrincipalBusiness !=
                                            null &&
                                            user1.certificateDetails.investorPrincipalBusiness !=
                                            '') ||
                                            (user1.certificateDetails.investingFinancialProducts !=
                                              null &&
                                              user1.certificateDetails.investingFinancialProducts !=
                                              '') ||
                                            (user1.certificateDetails.actingUnderwriter != null &&
                                              user1.certificateDetails.actingUnderwriter != '') ||
                                            (user1.certificateDetails.financialAdviceService !=
                                              null &&
                                              user1.certificateDetails.financialAdviceService !=
                                              '') ||
                                            (user1.certificateDetails.clientMoneyPropertyService !=
                                              null &&
                                              user1.certificateDetails.clientMoneyPropertyService !=
                                              '') ||
                                            (user1.certificateDetails.tradingFinancialProducts !=
                                              null &&
                                              user1.certificateDetails.tradingFinancialProducts !=
                                              '') ||
                                            (user1.certificateDetails.registeredBankRBNZ != null &&
                                              user1.certificateDetails.registeredBankRBNZ != '') ||
                                            (user1.certificateDetails.NBDTInvestor != '' &&
                                              user1.certificateDetails.NBDTInvestor != null) ||
                                            (user1.certificateDetails.licensedIssuerInsurance !=
                                              '' &&
                                              user1.certificateDetails.licensedIssuerInsurance !=
                                              null) ||
                                            (user1.certificateDetails.registeredSchemeManager !=
                                              '' &&
                                              user1.certificateDetails.registeredSchemeManager !=
                                              null) ||
                                            (user1.certificateDetails.derivativesIssuer != '' &&
                                              user1.certificateDetails.derivativesIssuer != null) ||
                                            (user1.certificateDetails.FinancialAdvisor != null &&
                                              user1.certificateDetails.FinancialAdvisor != '')) ? (
                                          <>
                                            <h3>Wholesale Certification: Investment Business</h3>

                                            <p>
                                              {user1.certificateDetails.investorPrincipalBusiness}
                                            </p>
                                            <p>
                                              {
                                                user1.certificateDetails
                                                  .investingFinancialProducts
                                              }
                                            </p>
                                            <p>{user1.certificateDetails.actingUnderwriter}</p>
                                            <p>
                                              {user1.certificateDetails.financialAdviceService}
                                            </p>
                                            <p>
                                              {
                                                user1.certificateDetails
                                                  .clientMoneyPropertyService
                                              }
                                            </p>
                                            <p>
                                              {user1.certificateDetails.tradingFinancialProducts}
                                            </p>
                                            <p>{user1.certificateDetails.registeredBankRBNZ}</p>
                                            <p>{user1.certificateDetails.NBDTInvestor}</p>
                                            <p>
                                              {user1.certificateDetails.licensedIssuerInsurance}
                                            </p>
                                            <p>
                                              {user1.certificateDetails.registeredSchemeManager}
                                            </p>
                                            <p>{user1.certificateDetails.derivativesIssuer}</p>
                                            <p>{user1.certificateDetails.FinancialAdvisor}</p>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </>

                                      {/* 3rd page certificate */}

                                      <>
                                        {user1 &&
                                          ((user1.certificateDetails.ownedPortfolio2yrs != null &&
                                            user1.certificateDetails.ownedPortfolio2yrs != '') ||
                                            (user1.certificateDetails.carriedTransactions2yrs !=
                                              null &&
                                              user1.certificateDetails.carriedTransactions2yrs !=
                                              '') ||
                                            (user1.certificateDetails.investmentBusiness10yrs !=
                                              null &&
                                              user1.certificateDetails.investmentBusiness10yrs !=
                                              '') ||
                                            (user1.certificateDetails.equitySecurities != null &&
                                              user1.certificateDetails.equitySecurities != '') ||
                                            (user1.certificateDetails.managedFunds !== null &&
                                              user1.certificateDetails.managedFunds !== '') ||
                                            (user1.certificateDetails.derivativeInstruments !==
                                              null &&
                                              user1.certificateDetails.derivativeInstruments !==
                                              '') ||
                                            (user1.certificateDetails.debtSecurities !== null &&
                                              user1.certificateDetails.debtSecurities !== '')) ? (
                                          <>
                                            <h3>Wholesale Certification: Investement Criteria</h3>
                                            <p>{user1.certificateDetails.ownedPortfolio2yrs}</p>
                                            <p>
                                              {user1.certificateDetails.carriedTransactions2yrs}
                                            </p>
                                            <p>
                                              {user1.certificateDetails.investmentBusiness10yrs}
                                            </p>

                                            <p>{user1.certificateDetails.equitySecurities}</p>
                                            <p>{user1.certificateDetails.managedFunds}</p>
                                            <p>
                                              {user1.certificateDetails.derivativeInstruments}
                                            </p>
                                            <p>{user1.certificateDetails.debtSecurities}</p>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    </>
                                  ) : (
                                    ''
                                  )}

                                  <>
                                    {user1 &&
                                      user1.certificateDetails &&
                                      user1.certificateDetails.certificateNumber == '3' ? (
                                      <>
                                        {' '}
                                        {user1 &&
                                          ((user1.certificateDetails.startupOrBusiness != null &&
                                            user1.certificateDetails.startupOrBusiness != '') ||
                                            (user1.certificateDetails.investorPresentation !=
                                              null &&
                                              user1.certificateDetails.investorPresentation !=
                                              '') ||
                                            (user1.certificateDetails.capitalRaisingDirector !=
                                              null &&
                                              user1.certificateDetails.capitalRaisingDirector !=
                                              '') ||
                                            (user1.certificateDetails.investingSharesSecurities !=
                                              null &&
                                              user1.certificateDetails.investingSharesSecurities !=
                                              '') ||
                                            (user1.certificateDetails.myOwnInvestmentDecisions !=
                                              null &&
                                              user1.certificateDetails.myOwnInvestmentDecisions !=
                                              '') ||
                                            (user1.certificateDetails.valueAndRisksOfInvesting !=
                                              null &&
                                              user1.certificateDetails.valueAndRisksOfInvesting !=
                                              '') ||
                                            (user1.certificateDetails
                                              .acquiringDisposingFinancialProducts !== null &&
                                              user1.certificateDetails
                                                .acquiringDisposingFinancialProducts !== '') ||
                                            (user1.certificateDetails
                                              .acquiringDisposingFinancialProductsOther !== null &&
                                              user1.certificateDetails
                                                .acquiringDisposingFinancialProductsOther !==
                                              '')) ? (
                                          <>
                                            <h3>Wholesale Certification: Eligible Investor</h3>

                                            <p>
                                              <p>{user1.certificateDetails.startupOrBusiness}</p>
                                              <p>
                                                {user1.certificateDetails.investorPresentation}
                                              </p>
                                              <p>
                                                {user1.certificateDetails.capitalRaisingDirector}
                                              </p>
                                              <p>
                                                {
                                                  user1.certificateDetails
                                                    .investingSharesSecurities
                                                }
                                              </p>
                                              <p>
                                                {
                                                  user1.certificateDetails
                                                    .myOwnInvestmentDecisions
                                                }
                                              </p>
                                              <p>
                                                {
                                                  user1.certificateDetails
                                                    .valueAndRisksOfInvesting
                                                }
                                              </p>

                                              {/* change */}

                                              <p>
                                                {
                                                  user1.certificateDetails
                                                    .acquiringDisposingFinancialProducts
                                                }
                                              </p>
                                            </p>
                                            <p>
                                              {
                                                user1.certificateDetails
                                                  .acquiringDisposingFinancialProductsOther
                                              }
                                            </p>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </>

                                  {user1 &&
                                    user1.certificateDetails !== null &&
                                    user1.certificateDetails.signatureA ? (
                                    <>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-auto">
                                              <h5>SIGNED for and on behalf of</h5>
                                            </div>
                                            <div className="col-auto">
                                              <h5>
                                                {/* {userType == 'Company' ? ( */}
                                                <span className="behalf_text">
                                                  {user1 &&
                                                    user1.fullLegalName !== null &&
                                                    user1.fullLegalName != ''
                                                    ? user1.fullLegalName
                                                    : ''}
                                                </span>
                                                as Investor.
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <strong>Wholesale Certification: Signature</strong>

                                      <p>
                                        <img
                                          alt="dummy"
                                          src={
                                            user1 &&
                                              user1.certificateDetails !== null &&
                                              user1.certificateDetails.signatureA
                                              ? user1.certificateDetails.signatureA
                                              : ''
                                          }
                                        />
                                      </p>

                                      {/* <p>
                                                    <img
                                                      src={
                                                        user1 &&
                                                        user1.certificateDetails !== null &&
                                                        user1.certificateDetails.signatureB
                                                          ? user1.certificateDetails.signatureB
                                                          : ''
                                                      }
                                                    />
                                                  </p> */}
                                    </>
                                  ) : (
                                    ''
                                  )}

                                  {(user1.certificateDetails &&
                                    user1.certificateDetails.nominatedUserType == '') ||
                                    user1.certificateDetails.nominatedUserType == null ? (
                                    ''
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-6 col-sm-auto">
                                        <label>Personal Adviser Type</label>
                                      </div>
                                      <div className="col-md-6 col-sm-auto">
                                        <p>
                                          {user1 &&
                                            user1.certificateDetails.nominatedUserType != 'Select'
                                            ? user1.certificateDetails.nominatedUserType
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  {(user1.certificateDetails &&
                                    user1.certificateDetails.nominatedUserName == '') ||
                                    user1.certificateDetails.nominatedUserName == null ? (
                                    ''
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-6 col-sm-7">
                                        <label>Personal Advisor Name</label>
                                      </div>
                                      <div className="col-md-6 col-sm-5">
                                        <p>
                                          {user1
                                            ? user1.certificateDetails.nominatedUserName
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  {(user1.certificateDetails &&
                                    user1.certificateDetails.nominatedUserEmail == '') ||
                                    user1.certificateDetails.nominatedUserEmail == null ? (
                                    ''
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Email</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <p>
                                          {user1
                                            ? user1.certificateDetails.nominatedUserEmail
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="col-md-1 edit_icon"></div>
                              </div>
                            ) : (
                              ''
                            )}

                            <>
                              {user1 &&
                                user1.certificateDetails &&
                                user1.certificateDetails.certificateNumber == '2' ? (
                                <>
                                  <h3>Wholesale Certification: Amount Invested</h3>

                                  <p>
                                    {user1 && user1.certificateDetails.usualLegalRules !== null
                                      ? user1.certificateDetails.usualLegalRules
                                      : ''}
                                  </p>
                                  <p>
                                    {user1 &&
                                      user1.certificateDetails.notCompleteInformation !== null
                                      ? user1.certificateDetails.notCompleteInformation
                                      : ''}
                                  </p>
                                  <p>
                                    {user1 &&
                                      user1.certificateDetails.materialExtent10yrs !== null
                                      ? user1.certificateDetails.materialExtent10yrs
                                      : ''}
                                  </p>
                                  <p>
                                    {user1 &&
                                      user1.certificateDetails.fewerLegalProtection !== null
                                      ? user1.certificateDetails.fewerLegalProtection
                                      : ''}
                                  </p>
                                  <p>
                                    {user1 &&
                                      user1.certificateDetails.notSuitableInvestment !== null
                                      ? user1.certificateDetails.notSuitableInvestment
                                      : ''}
                                  </p>
                                  <p>
                                    {user1 &&
                                      user1.certificateDetails.seekFinancialAdvice !== null
                                      ? user1.certificateDetails.seekFinancialAdvice
                                      : ''}
                                  </p>

                                  {/* <div className="row">
                                    <div className="col-md-6 col-sm-auto">
                                      <label>Wholesale Certificate office held</label>
                                    </div>
                                    <div className="col-md-6 col-sm-auto">
                                      <p>
                                        {user1 &&
                                        user1.certificateDetails !== null &&
                                        user1.certificateDetails.WholesaleCertificateOfficeHeld
                                          ? user1.certificateDetails.WholesaleCertificateOfficeHeld
                                          : ''}
                                      </p>
                                    </div>
                                  </div> */}

                                  {user1 &&
                                    user1.certificateDetails !== null &&
                                    user1.certificateDetails.signatureA ? (
                                    <>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-auto">
                                              <h5>SIGNED for and on behalf of</h5>
                                            </div>
                                            <div className="col-auto">
                                              <h5>
                                                {/* {userType == 'Company' ? ( */}
                                                <span className="behalf_text">
                                                  {user1 &&
                                                    user1.fullLegalName !== null &&
                                                    user1.fullLegalName != ''
                                                    ? user1.fullLegalName
                                                    : ''}
                                                </span>
                                                as Investor.
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <strong>Wholesale Certification: Signature</strong>

                                      <p>
                                        <img
                                          src={
                                            user1 &&
                                              user1.certificateDetails !== null &&
                                              user1.certificateDetails.signatureA
                                              ? user1.certificateDetails.signatureA
                                              : ''
                                          }
                                        />
                                      </p>

                                      {/* <p>
                                        <img
                                          src={
                                            user1 &&
                                            user1.certificateDetails !== null &&
                                            user1.certificateDetails.signatureB
                                              ? user1.certificateDetails.signatureB
                                              : ''
                                          }
                                        />
                                      </p> */}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </>

                            {user.profile.includes("UR1") ||
                              user.profile == 'UR8' ||
                              user.profile.includes("UR2") ? (
                              <div className="row responsive__align align_item_Eligible">
                                <div className="col-md-6 col-sm-6">
                                  <label className="text_trns">
                                    Attach Confirmation of Eligible Status (if relevant):
                                  </label>
                                </div>

                                <div className="col-md-5 col-sm-5">
                                  <Row className="fullinput mt-2 mb-2">
                                    <Col>
                                      <div class="yes1_attach">
                                        <a className="click-hover Edit_button_style">
                                          <span class="btn_upload">
                                            <input
                                              type="file"
                                              id="imag2"
                                              title=""
                                              name="image"
                                              //   onChange={e => {
                                              //     uploadWithJSON_attach2(
                                              //       e.target.files[0],
                                              //       user1.id
                                              //     );
                                              //   }}

                                              onChange={(e) => {
                                                uploadWithJSON_attach2(
                                                  e.target.files[0],
                                                  user1.id
                                                );
                                              }}
                                            />
                                          </span>
                                          ATTACH
                                          {/* Attach */}
                                        </a>
                                      </div>

                                      <div className="Attch_file">
                                        <span id="name_tag" class="preview2"></span>
                                        <input
                                          type="button"
                                          id="removeImage2"
                                          value="x"
                                          class="btn-rmv2"
                                        />
                                      </div>

                                      {imageLoader === true ? (
                                        <div class="progress">
                                          <span
                                            class="progress-bar"
                                            style={{ width: '100%' }}
                                          ></span>
                                        </div>
                                      ) : null}
                                    </Col>
                                  </Row>

                                  {/* <button>ATTACH</button> */}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            <div className="row">
                              {user1 && user1.confirmationDocument != null ? (
                                <div className="col-md-6 col-sm-6">
                                  <label className="text_trns">
                                    Confirmation of Eligible Status:
                                  </label>
                                </div>
                              ) : (
                                ''
                              )}

                              <div className="col-md-6 col-sm-6">
                                <a
                                  href={user1 && user1.confirmationDocument}
                                  className="documentProof-downloade confirmationDocument"
                                >
                                  <div className="main-downloade-wrapper align__end">
                                    {user1 &&
                                      user1.confirmationDocument !== null &&
                                      user1.confirmationDocument !== '' &&
                                      user1.confirmationDocument &&
                                      'undefined' &&
                                      user1.confirmationDocument !== undefined ? (
                                      <p className="downloadeMsg">
                                        <div className="downloads">
                                          <i className="fa fa-download downloadeStyle"></i>
                                        </div>{' '}
                                        <p className="View__file">VIEW FILE</p>{' '}
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </a>
                              </div>
                            </div>

                            {user1 && user1.applicationStage != 'In Progress' ? (


                              <button
                                onClick={() => {
                                  // wholesale_certifiacte_pdf(user1.id);
                                  certifiacte_pdfs(
                                    user1 &&
                                      user1.certificateDetails !== null &&
                                      user1.certificateDetails.pdfUrl
                                      ? user1.certificateDetails.pdfUrl
                                      : ''
                                  );

                                  // console.log(user1.id, 'user1rrrrrrrrrridddddddddddddddddddd');
                                }}
                                className="PAdding__button"
                              >
                                Generate Wholesale Certification PDF
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSeven">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          Terms & Conditions
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="row">
                            <div className="col-md-11">
                              <div className="row">
                                <div className="col-md-6 col-sm-auto col-6">
                                  <label>Receive Future Offer Marketing Emails</label>
                                </div>
                                <div className="col-md-6 col-sm-auto col-6">
                                  <p>
                                    {user1
                                      ? user1.futureInvestementOpportunities == 1
                                        ? 'Yes'
                                        : 'No'
                                      : ''}
                                  </p>
                                </div>
                              </div>


                              {(user.profile.includes("UR1") ||
                                user.profile.includes("UR2") ||
                                user.profile.includes("UR4") ||
                                user.profile == 'UR8') &&
                                user1 &&
                                (user1.applicationStage == 'Awaiting Payment' ||
                                  user1.applicationStage == 'Active Client' ||
                                  user1.applicationStage == 'Awaiting Final Approval') ? (
                                <div className="main_wrappr_agrementspdf">
                                  <div className="agrementspdf__wrapper history__pdfr">
                                    <span className="agrementspdf" onClick={agrementpdf}>
                                      <button class="mb-bottom Subscription_button">
                                        Generate Subscription/Adherence PDF Agreements
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-1 edit_icon">
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="parent-modal-title"
                                aria-describedby="parent-modal-description"
                              >
                                <Box className="formPopUp">
                                  <h4>Terms & Agreements Edit</h4>

                                  <div className="edit_form">
                                    <div className="form_input fullinput">
                                      <label>Full Name of Authorised Person</label>
                                      <input
                                        type="text"
                                        placeholder="Full name of authorised person"
                                      />
                                    </div>

                                    <div className="form_input fullinput">
                                      <label>Office Held</label>
                                      <input type="text" placeholder="Enter office held" />
                                    </div>

                                    <div className="form_input fullinput">
                                      <label>Adherence Agreement Proof</label>
                                      <input
                                        type="text"
                                        placeholder="Enter adherence agreement proof"
                                      />
                                    </div>

                                    <div className="form_input fullinput">
                                      <label>Subscription Agreement Proof</label>
                                      <input
                                        type="text"
                                        placeholder="Enter subscription agreement proof"
                                      />
                                    </div>

                                    <div className="submit_btn">
                                      <button>Submit</button>
                                    </div>
                                  </div>
                                </Box>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="view_tabs  responsive_hide">
                  <div className="primary_text">
                    Primary
                    <div className="note-footer flex-note">
                      {ziploader === true ? (
                        <div className="note-main-loader">
                          <div className="loader zip_exp_loader"></div>
                        </div>
                      ) : user1 && user1.overAllAmlStatus === 1 && user1.applicationStage == "Submitted" ? (
                        <button
                          className="save zipexport"
                          onClick={() => getZipData(user1.id, user1.zipUrl)}
                        >
                          Zip Export
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {user1 ? (
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey={
                        user1 && user1.investmentType == 'Joint'
                          ? 'third'
                          : user1 && user1.investmentType == 'Individual'
                            ? 'second'
                            : 'third'
                      }
                    >
                      <Row>
                        <Col sm={5} md={3} className="border-right">
                          <Nav variant="pills" className="flex-column">
                            {/* <Nav.Item>
                            <Nav.Link eventKey="first">Investment Details</Nav.Link>
                          </Nav.Item> */}
                            {user1 && user1.investmentType == 'Joint' ? (
                              <Nav.Item>
                                <Nav.Link eventKey="third">Entity Details</Nav.Link>
                              </Nav.Item>
                            ) : (
                              ''
                            )}
                            <Nav.Item>
                              <Nav.Link eventKey="second">Personal Information</Nav.Link>
                            </Nav.Item>

                            {/* {user1 && user1.isMyFarm == 0 ? ( */}
                            <Nav.Item>
                              <Nav.Link eventKey="forth">ID Verification</Nav.Link>
                            </Nav.Item>
                            {/* )
                           : (
                            ''
                          )} */}
                            {/* {user1 && user1.isMyFarm == 0 ? ( */}
                            <Nav.Item>
                              <Nav.Link eventKey="five">Bank Account</Nav.Link>
                            </Nav.Item>
                            {/* ) : (
                            ''
                          )} */}

                            <Nav.Item>
                              <Nav.Link eventKey="sixs">Wholesale Certificate</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="sevens">Terms & Conditions</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col sm={7} md={9} className="formPopUp">
                          <Tab.Content>
                            <Tab.Pane eventKey="sevens">
                              <TermsCondition2 data={user1 && user1} />


                            </Tab.Pane>

                            <Tab.Pane eventKey="second">
                              <PersonalInformation2 data={user1 && user1} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="third">
                              <EntityDetails
                                data={user1 && user1}
                                percentage={user2 && user2.SecondaryShareOfIncome}
                                investmentTypes="Jointt"
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixs">
                              {user1 && user1.certificateDetails ? (
                                <div className="row whole_cert">
                                  <div className="col-md-12">
                                    {user1.certificateDetails &&
                                      user1.certificateDetails.certificateNumber == '1' ? (
                                      <>
                                        {(user1 &&
                                          user1.certificateDetails &&
                                          user1.certificateDetails.largePersonValue != null &&
                                          user1 &&
                                          user1.certificateDetails.largePersonValue !==
                                          'undefined' &&
                                          user1.certificateDetails.largePersonValue != 'None' &&
                                          user1.certificateDetails.largePersonValue != '') ||
                                          (user1 &&
                                            user1.certificateDetails &&
                                            user1.certificateDetails.twoYearInvestEntity2 != null &&
                                            user1 &&
                                            user1.certificateDetails.twoYearInvestEntity2 !==
                                            'undefined' &&
                                            user1.certificateDetails.twoYearInvestEntity2 != 'None' &&
                                            user1.certificateDetails.twoYearInvestEntity2 != '') ? (
                                          <>
                                            {' '}
                                            <h3>Wholesale Certification: Large Investor</h3>
                                            <p>{user1.certificateDetails.largePersonValue}</p>
                                            <p>{user1.certificateDetails.twoYearInvestEntity2}</p>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {/* 2nd */}

                                        <>
                                          {user1 &&
                                            ((user1.certificateDetails.investorPrincipalBusiness !=
                                              null &&
                                              user1.certificateDetails.investorPrincipalBusiness !=
                                              '') ||
                                              (user1.certificateDetails.investingFinancialProducts !=
                                                null &&
                                                user1.certificateDetails.investingFinancialProducts !=
                                                '') ||
                                              (user1.certificateDetails.actingUnderwriter != null &&
                                                user1.certificateDetails.actingUnderwriter != '') ||
                                              (user1.certificateDetails.financialAdviceService !=
                                                null &&
                                                user1.certificateDetails.financialAdviceService !=
                                                '') ||
                                              (user1.certificateDetails.clientMoneyPropertyService !=
                                                null &&
                                                user1.certificateDetails.clientMoneyPropertyService !=
                                                '') ||
                                              (user1.certificateDetails.tradingFinancialProducts !=
                                                null &&
                                                user1.certificateDetails.tradingFinancialProducts !=
                                                '') ||
                                              (user1.certificateDetails.registeredBankRBNZ != null &&
                                                user1.certificateDetails.registeredBankRBNZ != '') ||
                                              (user1.certificateDetails.NBDTInvestor != '' &&
                                                user1.certificateDetails.NBDTInvestor != null) ||
                                              (user1.certificateDetails.licensedIssuerInsurance !=
                                                '' &&
                                                user1.certificateDetails.licensedIssuerInsurance !=
                                                null) ||
                                              (user1.certificateDetails.registeredSchemeManager !=
                                                '' &&
                                                user1.certificateDetails.registeredSchemeManager !=
                                                null) ||
                                              (user1.certificateDetails.derivativesIssuer != '' &&
                                                user1.certificateDetails.derivativesIssuer != null) ||
                                              (user1.certificateDetails.FinancialAdvisor != null &&
                                                user1.certificateDetails.FinancialAdvisor != '')) ? (
                                            <>
                                              <h3>Wholesale Certification: Investment Business</h3>

                                              <p>
                                                {user1.certificateDetails.investorPrincipalBusiness}
                                              </p>
                                              <p>
                                                {
                                                  user1.certificateDetails
                                                    .investingFinancialProducts
                                                }
                                              </p>
                                              <p>{user1.certificateDetails.actingUnderwriter}</p>
                                              <p>
                                                {user1.certificateDetails.financialAdviceService}
                                              </p>
                                              <p>
                                                {
                                                  user1.certificateDetails
                                                    .clientMoneyPropertyService
                                                }
                                              </p>
                                              <p>
                                                {user1.certificateDetails.tradingFinancialProducts}
                                              </p>
                                              <p>{user1.certificateDetails.registeredBankRBNZ}</p>
                                              <p>{user1.certificateDetails.NBDTInvestor}</p>
                                              <p>
                                                {user1.certificateDetails.licensedIssuerInsurance}
                                              </p>
                                              <p>
                                                {user1.certificateDetails.registeredSchemeManager}
                                              </p>
                                              <p>{user1.certificateDetails.derivativesIssuer}</p>
                                              <p>{user1.certificateDetails.FinancialAdvisor}</p>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </>

                                        {/* 3rd page certificate */}

                                        <>
                                          {user1 &&
                                            ((user1.certificateDetails.ownedPortfolio2yrs != null &&
                                              user1.certificateDetails.ownedPortfolio2yrs != '') ||
                                              (user1.certificateDetails.carriedTransactions2yrs !=
                                                null &&
                                                user1.certificateDetails.carriedTransactions2yrs !=
                                                '') ||
                                              (user1.certificateDetails.investmentBusiness10yrs !=
                                                null &&
                                                user1.certificateDetails.investmentBusiness10yrs !=
                                                '') ||
                                              (user1.certificateDetails.equitySecurities != null &&
                                                user1.certificateDetails.equitySecurities != '') ||
                                              (user1.certificateDetails.managedFunds !== null &&
                                                user1.certificateDetails.managedFunds !== '') ||
                                              (user1.certificateDetails.derivativeInstruments !==
                                                null &&
                                                user1.certificateDetails.derivativeInstruments !==
                                                '') ||
                                              (user1.certificateDetails.debtSecurities !== null &&
                                                user1.certificateDetails.debtSecurities !== '')) ? (
                                            <>
                                              <h3>Wholesale Certification: Investement Criteria</h3>
                                              <p>{user1.certificateDetails.ownedPortfolio2yrs}</p>
                                              <p>
                                                {user1.certificateDetails.carriedTransactions2yrs}
                                              </p>
                                              <p>
                                                {user1.certificateDetails.investmentBusiness10yrs}
                                              </p>

                                              <p>{user1.certificateDetails.equitySecurities}</p>
                                              <p>{user1.certificateDetails.managedFunds}</p>
                                              <p>
                                                {user1.certificateDetails.derivativeInstruments}
                                              </p>
                                              <p>{user1.certificateDetails.debtSecurities}</p>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      </>
                                    ) : (
                                      ''
                                    )}

                                    <>
                                      {user1 &&
                                        user1.certificateDetails &&
                                        user1.certificateDetails.certificateNumber == '3' ? (
                                        <>
                                          {' '}
                                          {user1 &&
                                            ((user1.certificateDetails.startupOrBusiness != null &&
                                              user1.certificateDetails.startupOrBusiness != '') ||
                                              (user1.certificateDetails.investorPresentation !=
                                                null &&
                                                user1.certificateDetails.investorPresentation !=
                                                '') ||
                                              (user1.certificateDetails.capitalRaisingDirector !=
                                                null &&
                                                user1.certificateDetails.capitalRaisingDirector !=
                                                '') ||
                                              (user1.certificateDetails.investingSharesSecurities !=
                                                null &&
                                                user1.certificateDetails.investingSharesSecurities !=
                                                '') ||
                                              (user1.certificateDetails.myOwnInvestmentDecisions !=
                                                null &&
                                                user1.certificateDetails.myOwnInvestmentDecisions !=
                                                '') ||
                                              (user1.certificateDetails.valueAndRisksOfInvesting !=
                                                null &&
                                                user1.certificateDetails.valueAndRisksOfInvesting !=
                                                '') ||
                                              (user1.certificateDetails
                                                .acquiringDisposingFinancialProducts !== null &&
                                                user1.certificateDetails
                                                  .acquiringDisposingFinancialProducts !== '') ||
                                              (user1.certificateDetails
                                                .acquiringDisposingFinancialProductsOther !== null &&
                                                user1.certificateDetails
                                                  .acquiringDisposingFinancialProductsOther !==
                                                '')) ? (
                                            <>
                                              <h3>Wholesale Certification: Eligible Investor</h3>

                                              <p>
                                                <p>{user1.certificateDetails.startupOrBusiness}</p>
                                                <p>
                                                  {user1.certificateDetails.investorPresentation}
                                                </p>
                                                <p>
                                                  {user1.certificateDetails.capitalRaisingDirector}
                                                </p>
                                                <p>
                                                  {
                                                    user1.certificateDetails
                                                      .investingSharesSecurities
                                                  }
                                                </p>
                                                <p>
                                                  {
                                                    user1.certificateDetails
                                                      .myOwnInvestmentDecisions
                                                  }
                                                </p>
                                                <p>
                                                  {
                                                    user1.certificateDetails
                                                      .valueAndRisksOfInvesting
                                                  }
                                                </p>

                                                {/* change */}

                                                <p>
                                                  {
                                                    user1.certificateDetails
                                                      .acquiringDisposingFinancialProducts
                                                  }
                                                </p>
                                              </p>
                                              <p>
                                                {
                                                  user1.certificateDetails
                                                    .acquiringDisposingFinancialProductsOther
                                                }
                                              </p>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </>

                                    {user1 &&
                                      user1.certificateDetails !== null &&
                                      user1.certificateDetails.signatureA ? (
                                      <>
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-auto">
                                                <h5>SIGNED for and on behalf of</h5>
                                              </div>
                                              <div className="col-auto">
                                                <h5>
                                                  {/* {userType == 'Company' ? ( */}
                                                  <span className="behalf_text">
                                                    {user1 &&
                                                      user1.fullLegalName !== null &&
                                                      user1.fullLegalName != ''
                                                      ? user1.fullLegalName
                                                      : ''}
                                                  </span>
                                                  as Investor.
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <strong>Wholesale Certification: Signature</strong>

                                        <p>
                                          <img
                                            alt="dummy"
                                            src={
                                              user1 &&
                                                user1.certificateDetails !== null &&
                                                user1.certificateDetails.signatureA
                                                ? user1.certificateDetails.signatureA
                                                : ''
                                            }
                                          />
                                        </p>

                                        {/* <p>
                                                    <img
                                                      src={
                                                        user1 &&
                                                        user1.certificateDetails !== null &&
                                                        user1.certificateDetails.signatureB
                                                          ? user1.certificateDetails.signatureB
                                                          : ''
                                                      }
                                                    />
                                                  </p> */}
                                      </>
                                    ) : (
                                      ''
                                    )}

                                    {(user1.certificateDetails &&
                                      user1.certificateDetails.nominatedUserType == '') ||
                                      user1.certificateDetails.nominatedUserType == null ? (
                                      ''
                                    ) : (
                                      <div className="row">
                                        <div className="col-md-6 col-sm-auto">
                                          <label>Personal Adviser Type</label>
                                        </div>
                                        <div className="col-md-6 col-sm-auto">
                                          <p>
                                            {user1 &&
                                              user1.certificateDetails.nominatedUserType != 'Select'
                                              ? user1.certificateDetails.nominatedUserType
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                    )}

                                    {(user1.certificateDetails &&
                                      user1.certificateDetails.nominatedUserName == '') ||
                                      user1.certificateDetails.nominatedUserName == null ? (
                                      ''
                                    ) : (
                                      <div className="row">
                                        <div className="col-md-6 col-sm-7">
                                          <label>Personal Advisor Name</label>
                                        </div>
                                        <div className="col-md-6 col-sm-5">
                                          <p>
                                            {user1
                                              ? user1.certificateDetails.nominatedUserName
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                    )}

                                    {(user1.certificateDetails &&
                                      user1.certificateDetails.nominatedUserEmail == '') ||
                                      user1.certificateDetails.nominatedUserEmail == null ? (
                                      ''
                                    ) : (
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>Email</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <p>
                                            {user1
                                              ? user1.certificateDetails.nominatedUserEmail
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-md-1 edit_icon"></div>
                                </div>
                              ) : (
                                ''
                              )}

                              <>
                                {user1 &&
                                  user1.certificateDetails &&
                                  user1.certificateDetails.certificateNumber == '2' ? (
                                  <>
                                    <h3>Wholesale Certification: Amount Invested</h3>

                                    <p>
                                      {user1 && user1.certificateDetails.usualLegalRules !== null
                                        ? user1.certificateDetails.usualLegalRules
                                        : ''}
                                    </p>
                                    <p>
                                      {user1 &&
                                        user1.certificateDetails.notCompleteInformation !== null
                                        ? user1.certificateDetails.notCompleteInformation
                                        : ''}
                                    </p>
                                    <p>
                                      {user1 &&
                                        user1.certificateDetails.materialExtent10yrs !== null
                                        ? user1.certificateDetails.materialExtent10yrs
                                        : ''}
                                    </p>
                                    <p>
                                      {user1 &&
                                        user1.certificateDetails.fewerLegalProtection !== null
                                        ? user1.certificateDetails.fewerLegalProtection
                                        : ''}
                                    </p>
                                    <p>
                                      {user1 &&
                                        user1.certificateDetails.notSuitableInvestment !== null
                                        ? user1.certificateDetails.notSuitableInvestment
                                        : ''}
                                    </p>
                                    <p>
                                      {user1 &&
                                        user1.certificateDetails.seekFinancialAdvice !== null
                                        ? user1.certificateDetails.seekFinancialAdvice
                                        : ''}
                                    </p>

                                    {/* <div className="row">
                                    <div className="col-md-6 col-sm-auto">
                                      <label>Wholesale Certificate office held</label>
                                    </div>
                                    <div className="col-md-6 col-sm-auto">
                                      <p>
                                        {user1 &&
                                        user1.certificateDetails !== null &&
                                        user1.certificateDetails.WholesaleCertificateOfficeHeld
                                          ? user1.certificateDetails.WholesaleCertificateOfficeHeld
                                          : ''}
                                      </p>
                                    </div>
                                  </div> */}

                                    {user1 &&
                                      user1.certificateDetails !== null &&
                                      user1.certificateDetails.signatureA ? (
                                      <>
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-auto">
                                                <h5>SIGNED for and on behalf of</h5>
                                              </div>
                                              <div className="col-auto">
                                                <h5>
                                                  {/* {userType == 'Company' ? ( */}
                                                  <span className="behalf_text">
                                                    {user1 &&
                                                      user1.fullLegalName !== null &&
                                                      user1.fullLegalName != ''
                                                      ? user1.fullLegalName
                                                      : ''}
                                                  </span>
                                                  as Investor.
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <strong>Wholesale Certification: Signature</strong>

                                        <p>
                                          <img
                                            src={
                                              user1 &&
                                                user1.certificateDetails !== null &&
                                                user1.certificateDetails.signatureA
                                                ? user1.certificateDetails.signatureA
                                                : ''
                                            }
                                          />
                                        </p>

                                        {/* <p>
                                        <img
                                          src={
                                            user1 &&
                                            user1.certificateDetails !== null &&
                                            user1.certificateDetails.signatureB
                                              ? user1.certificateDetails.signatureB
                                              : ''
                                          }
                                        />
                                      </p> */}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                              </>

                              {user.profile.includes("UR1") ||
                                user.profile == 'UR8' ||
                                user.profile.includes("UR2") ? (
                                <div className="row responsive__align align_item_Eligible">
                                  <div className="col-md-6 col-sm-6">
                                    <label className="text_trns">
                                      Attach Confirmation of Eligible Status (if relevant):
                                    </label>
                                  </div>

                                  <div className="col-md-5 col-sm-5">
                                    <Row className="fullinput mt-2 mb-2">
                                      <Col>
                                        <div class="yes1_attach">
                                          <a className="click-hover Edit_button_style">
                                            <span class="btn_upload">
                                              <input
                                                type="file"
                                                id="imag2"
                                                title=""
                                                name="image"
                                                //   onChange={e => {
                                                //     uploadWithJSON_attach2(
                                                //       e.target.files[0],
                                                //       user1.id
                                                //     );
                                                //   }}

                                                onChange={(e) => {
                                                  uploadWithJSON_attach2(
                                                    e.target.files[0],
                                                    user1.id
                                                  );
                                                }}
                                              />
                                            </span>
                                            ATTACH
                                            {/* Attach */}
                                          </a>
                                        </div>

                                        <div className="Attch_file">
                                          <span id="name_tag" class="preview2"></span>
                                          <input
                                            type="button"
                                            id="removeImage2"
                                            value="x"
                                            class="btn-rmv2"
                                          />
                                        </div>

                                        {imageLoader === true ? (
                                          <div class="progress">
                                            <span
                                              class="progress-bar"
                                              style={{ width: '100%' }}
                                            ></span>
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>

                                    {/* <button>ATTACH</button> */}
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              <div className="row">
                                {user1 && user1.confirmationDocument != null ? (
                                  <div className="col-md-6 col-sm-6">
                                    <label className="text_trns">
                                      Confirmation of Eligible Status:
                                    </label>
                                  </div>
                                ) : (
                                  ''
                                )}

                                <div className="col-md-6 col-sm-6">
                                  <a
                                    href={user1 && user1.confirmationDocument}
                                    className="documentProof-downloade confirmationDocument"
                                  >
                                    <div className="main-downloade-wrapper align__end">
                                      {user1 &&
                                        user1.confirmationDocument !== null &&
                                        user1.confirmationDocument !== '' &&
                                        user1.confirmationDocument &&
                                        'undefined' &&
                                        user1.confirmationDocument !== undefined ? (
                                        <p className="downloadeMsg">
                                          <div className="downloads">
                                            <i className="fa fa-download downloadeStyle"></i>
                                          </div>{' '}
                                          <p className="View__file">VIEW FILE</p>{' '}
                                        </p>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </a>
                                </div>
                              </div>

                              {user1 && user1.applicationStage != 'In Progress' ? (
                                <button
                                  onClick={() => {
                                    // wholesale_certifiacte_pdf(user1.id);
                                    certifiacte_pdfs(
                                      user1 &&
                                        user1.certificateDetails !== null &&
                                        user1.certificateDetails.pdfUrl
                                        ? user1.certificateDetails.pdfUrl
                                        : ''
                                    );

                                    // console.log(user1.id, 'user1rrrrrrrrrridddddddddddddddddddd');
                                  }}
                                  className="PAdding__button"
                                >
                                  Generate Wholesale Certification PDF
                                </button>
                              ) : (
                                ''
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="forth">
                              <IdVerification2 data={user1 && user1} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="five">
                              <BankAccount2 data={user1 && user1} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="seven">
                              <div className="row">
                                <div className="col-md-11">
                                  <div className="row">
                                    <div className="col-md-6 col-sm-auto col-6">
                                      <label>Receive Future Offer Marketing Emails</label>
                                    </div>
                                    <div className="col-md-6 col-sm-auto col-6">
                                      <p>
                                        {user1
                                          ? user1.futureInvestementOpportunities == 1
                                            ? 'Yes'
                                            : 'No'
                                          : ''}
                                      </p>
                                    </div>
                                  </div>

                                  {/* <div className="row">
                                  <div className="col-md-6 col-sm-auto">
                                    <label>Date</label>
                                  </div>
                                  <div className="col-md-6 col-sm-auto">
                                    <p>{user1 ? user1.submittedAt : ''}</p>
                                  </div>
                                </div> */}

                                  {/* <div className="row signature_img">
                                  <div className="col-md-6 col-sm-auto">
                                    <label>Subscription Agreement Signature</label>
                                  </div>
                                  <div className="col-md-6">
                                    <p>
                                      <img
                                        src={
                                          user1 &&
                                          user1.certificateDetails !== null &&
                                          user1.certificateDetails.subscriptionSign
                                            ? user1.certificateDetails.subscriptionSign
                                            : ''
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6 col-sm-auto">
                                    <label>Subscription Office Held</label>
                                  </div>
                                  <div className="col-md-6 col-sm-auto">
                                    <p>
                                      {user1 &&
                                      user1.certificateDetails !== null &&
                                      user1.certificateDetails.subscriptionOfficeHeld
                                        ? user1.certificateDetails.subscriptionOfficeHeld
                                        : ''}
                                    </p>
                                  </div>
                                </div>

                                <div className="row signature_img">
                                  <div className="col-md-6 col-sm-auto">
                                    <label>Adherence Agreement Signature</label>
                                  </div>
                                  <div className="col-md-6">
                                    <p>
                                      <img
                                        src={
                                          user1 &&
                                          user1.certificateDetails !== null &&
                                          user1.certificateDetails.adherenceSign
                                            ? user1.certificateDetails.adherenceSign
                                            : ''
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 col-sm-auto">
                                    <label>Adherence Office Held</label>
                                  </div>
                                  <div className="col-md-6 col-sm-auto">
                                    {/* <p>{user1 ? user1.certificateDetails.adherenceOfficeHeld : ''}</p> */}
                                  {/* <p>
                                      {user1 &&
                                      user1.certificateDetails !== null &&
                                      user1.certificateDetails.adherenceOfficeHeld
                                        ? user1.certificateDetails.adherenceOfficeHeld
                                        : ''}
                                    </p>
                                  </div>
                                </div> */}

                                  {(user.profile.includes("UR1") ||
                                    user.profile.includes("UR2") ||
                                    user.profile.includes("UR4") ||
                                    user.profile == 'UR8') &&
                                    user1 &&
                                    (user1.applicationStage == 'Awaiting Payment' ||
                                      user1.applicationStage == 'Active Client' ||
                                      user1.applicationStage == 'Awaiting Final Approval') ? (
                                    <div className="main_wrappr_agrementspdf">
                                      <div className="agrementspdf__wrapper history__pdfr">
                                        <span className="agrementspdf" onClick={agrementpdf}>
                                          <button class="mb-bottom Subscription_button">
                                            Generate Subscription/Adherence PDF Agreements
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>

                                <div className="col-md-1 edit_icon">
                                  <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="parent-modal-title"
                                    aria-describedby="parent-modal-description"
                                  >
                                    <Box className="formPopUp">
                                      <h4>Terms & Agreements Edit</h4>

                                      <div className="edit_form">
                                        <div className="form_input fullinput">
                                          <label>Full Name of Authorised Person</label>
                                          <input
                                            type="text"
                                            placeholder="Full name of authorised person"
                                          />
                                          {/* <input
                                          type="text"
                                          name="documentNumber"
                                          onKeyPress={removeError}
                                          value={documentNumber}
                                          onChange={(e) => {
                                            setForm(e);
                                            removeError();
                                          }}
                                          placeholder="Enter number"
                                          className="digitsOnly"
                                        /> */}
                                        </div>

                                        <div className="form_input fullinput">
                                          <label>Office Held</label>
                                          <input type="text" placeholder="Enter office held" />
                                        </div>

                                        <div className="form_input fullinput">
                                          <label>Adherence Agreement Proof</label>
                                          <input
                                            type="text"
                                            placeholder="Enter adherence agreement proof"
                                          />
                                        </div>

                                        <div className="form_input fullinput">
                                          <label>Subscription Agreement Proof</label>
                                          <input
                                            type="text"
                                            placeholder="Enter subscription agreement proof"
                                          />
                                        </div>

                                        <div className="submit_btn">
                                          <button>Submit</button>
                                        </div>
                                      </div>
                                    </Box>
                                  </Modal>
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  ) : (
                    ''
                  )}
                </div>

                {
                  user2 ? (
                    <>
                      <div className="show_responsive">
                        <div className="primary_text">
                          Secondary
                          <div className="note-footer flex-note">
                            {ziploader === true ? (
                              <div className="note-main-loader">
                                <div className="loader zip_exp_loader"></div>
                              </div>
                            ) : user1 && user1.overAllAmlStatus === 1 && user1.applicationStage == "Submitted" ? (
                              <button
                                className="save zipexport"
                                onClick={() => getZipData(user1.id, user1.zipUrl)}
                              >
                                Zip Export
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        <div class="accordion" id="accordionExampleSecondary">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOneSec">
                              <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOneSec"
                                aria-expanded="true"
                                aria-controls="collapseOneSec"
                              >
                                Investment Details
                              </button>
                            </h2>
                            <div
                              id="collapseOneSec"
                              class="accordion-collapse collapse show"
                              aria-labelledby="headingOneSec"
                              data-bs-parent="#accordionExampleSecondary"
                            >
                              <div class="accordion-body">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Investment Type</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        {' '}
                                        <p>{user2 ? user2.investmentType : ''}</p>
                                      </div>
                                    </div>

                                    {/* <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label> Investment With MyFarm In The Past 2 Years</label>
                                    </div>
                                    <div className="col-md-6  col-sm-6 col-6">
                                      {' '}
                                      <p>{user2 ? (user2.isMyFarm == 0 ? 'No' : 'Yes') : ''}</p>
                                    </div>
                                  </div> */}

                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Investment Opportunity Fund</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>{user2 ? user2.fundCode : ''}</p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <label>Total Investment</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <p>
                                          {user2 && user2.totalInvestment != null ? <>$</> : ''}
                                          {/* {user2 && user2.totalInvestment != null
                                          ? (
                                              Math.round(user2.totalInvestment * 1000) / 1000
                                            ).toLocaleString()
                                          : ''} */}

                                          {user2 && user2.totalInvestment != null
                                            ? user1.totalInvestment
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-1 edit_icon">
                                    {user.profile.includes("UR4") ||
                                      user.profile.includes("UR2") ||
                                      (user1 && user1.applicationStage == 'Active Client') ||
                                      user.profile.includes("UR3") ? (
                                      ''
                                    ) : (
                                      <i
                                        onClick={handleOpen}
                                        title="Edit"
                                        class="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwoSec">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwoSec"
                                aria-expanded="false"
                                aria-controls="collapseTwoSec"
                              >
                                Entity Details
                              </button>
                            </h2>
                            <div
                              id="collapseTwoSec"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingTwoSec"
                              data-bs-parent="#accordionExampleSecondary"
                            >
                              <div class="accordion-body">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>
                                          Full Name of 2<sup>nd</sup> Joint Account Holder
                                        </label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <p>{user2 ? user2.fullLegalName : ''}</p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <label>
                                          Share of Income Attributed to 2<sup>nd</sup> Joint Account
                                          Holder
                                        </label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <p>{user2 ? user2.SecondaryShareOfIncome : ''}</p>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="col-md-1 edit_icon">
                                  {user.profile.includes("UR4") ||
                                  user.profile.includes("UR2") ||
                                  (user1 && user1.applicationStage == 'Active Client') ||
                                  user.profile.includes("UR3") ? (
                                    ''
                                  ) : (
                                    <i
                                      onClick={handleOpen}
                                      title="Edit"
                                      class="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThreeSec">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThreeSec"
                                aria-expanded="false"
                                aria-controls="collapseThreeSec"
                              >
                                Personal Information
                              </button>
                            </h2>
                            <div
                              id="collapseThreeSec"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingThreeSec"
                              data-bs-parent="#accordionExampleSecondary"
                            >
                              <div class="accordion-body">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-11">
                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Full Legal Name</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>{user2 ? user2.fullLegalName : ''}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Preferred Name</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>{user2 ? user2.preferredName : ''}</p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Date Of Birth</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>{user2 ? user2.dateOfBirth : ''}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Email </label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            {user2 && user2.userEmail === user2.userCode ? (
                                              <p>{user1 ? user1.userEmail : ''}</p>
                                            ) : (
                                              <p>{user2 ? user2.userEmail : ''}</p>
                                            )}
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Mobile Number</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>
                                              {user2 ? (
                                                user2.priContactNumber !== null &&
                                                  user2.priContactCountryCode !== null ? (
                                                  <p>
                                                    {user2 && user2.priContactCountryCode !== ''
                                                      ? '+' + user2.priContactCountryCode + ' '
                                                      : ' '}
                                                    {user2 ? user2.priContactNumber : ''}
                                                  </p>
                                                ) : (
                                                  ''
                                                )
                                              ) : (
                                                ''
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                        {user2 ? (
                                          user2.isAlternateContact === 1 ? (
                                            <div className="row">
                                              <div className="col-md-6  col-sm-6 col-6">
                                                <label>
                                                  {' '}
                                                  {user2.secContactType} Additional Number
                                                </label>
                                              </div>
                                              <div className="col-md-6  col-sm-6 col-6">
                                                {/* <p>
                                            {user1 ? user2.secContactCountryCode : ''}{' '}
                                            {user1 ? user2.secContactNumber : ''}
                                          </p> */}

                                                {user2 ? (
                                                  user2.secContactNumber !== null &&
                                                    user2.secContactCountryCode !== null ? (
                                                    <p>
                                                      {user2 && user2.secContactCountryCode !== ''
                                                        ? '+' + user2.secContactCountryCode + ' '
                                                        : ' '}
                                                      {user2 ? user2.secContactNumber : ''}
                                                    </p>
                                                  ) : (
                                                    ''
                                                  )
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </div>
                                          ) : null
                                        ) : null}

                                        {/* <>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <label>Intend To Invest With MyFarm</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <p>{user2 ? user2.intendToInvest : ''}</p>
                                          </div>
                                          {user2 && user2.intendToInvest == 'Other' ? (
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6 col-6">
                                                <label>Other Intend To Invest With MyFarm </label>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-6 col-6">
                                                <p className="myfarm_com22">
                                                  {user2 ? user2.otherIntendToInvest : ''}
                                                </p>
                                              </div>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <label>Total Intend To Invest With MyFarm</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <p>{user2 ? user2.totalIintend : ''}</p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <label>Choose To Invest In This Opportunity</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <p>{user2 ? user2.chooseToInvest : ''}</p>
                                          </div>
                                        </div>
                                        {user2 && user2.chooseToInvest == 'Other' ? (
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6 col-6">
                                              <label>
                                                Other Choose To Invest In This Opportunity
                                              </label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6 col-6">
                                              <p>{user2 ? user2.otherChooseToInvest : ''}</p>
                                            </div>
                                          </div>
                                        ) : (
                                          ''
                                        )}

                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <label>Occupation</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <p>{user2 ? user2.occupation : ''}</p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <label> Source of Funds For Your Investment</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6 col-6">
                                            <p>{user2 ? user2.sourceOfFund : ''}</p>
                                          </div>
                                        </div>
                                      </> */}

                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Residential Address</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>{user2 ? user2.userResidentialAddress : ''}</p>
                                          </div>
                                        </div>

                                        {user2 &&
                                          user2.userResidentialAddress != null &&
                                          user2.userResidentialAddress != '' ? (
                                          <></>
                                        ) : (
                                          ''
                                        )}

                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Postal Address</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>
                                              {user2 && user2.isUserAddressSame === 1
                                                ? 'Same as home address'
                                                : user2 && user2.userPostalAddress
                                                  ? user2.userPostalAddress
                                                  : ''}
                                            </p>
                                          </div>
                                        </div>

                                        {/* <div className="row">
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <label>Occupation</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <p>{user2 ? user2.occupation : ''}</p>
                                        </div>
                                      </div> */}
                                        {user2 && user2.occupation === 'Other' ? (
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Other Occupation</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>{user2 ? user2.otherOccupation : ''}</p>
                                            </div>
                                          </div>
                                        ) : (
                                          ''
                                        )}

                                        <>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>City/Town Born In</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>{user2 ? user2.placeOfBirth : ''}</p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Country Born In</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>
                                                {user2 ? getCountryName(user2.countryOfBirth) : ''}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>Country of Residence</label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <p>
                                                {user2 ? getCountryName(user2.residingCountry) : ''}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label className="">IRD Number</label>
                                              {/* <label>IRD NUMBER</label> */}
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>{user2 ? user2.userIrdNumber : ''}</p>
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label className=""> Tax Type </label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <div className="Main-wrapper-circle">
                                                <p className="circle">{user2 ? user2.taxType : ''}</p>
                                              </div>
                                            </div>
                                          </div>

                                          {user2 && user2.taxType == 'Resident Withholding Tax' ? (
                                            <div className="row">
                                              <div className="col-md-6  col-sm-6 col-6">
                                                <label className="">
                                                  Resident Withholding Tax Rate
                                                </label>
                                              </div>
                                              <div className="col-md-6  col-sm-6 col-6">
                                                <div className="rwttax">
                                                  <div className="Main-wrapper-circle">
                                                    {' '}
                                                    <p className="circle">
                                                      {user2 ? user2.rwtValue : ''}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                          {user2 && user2.rwtValue == 'Exempt' ? (
                                            <div className="row">
                                              <div className="col-md-6">
                                                <label>Proof of Exemption</label>
                                              </div>

                                              <div className="col-md-6 document_download">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() => NoteClick(user2.examptProof)}
                                                  className="licedocument-downloade"
                                                >
                                                  {' '}
                                                  <div className="main-downloade-wrapper">
                                                    {user2 &&
                                                      user2.examptProof !== null &&
                                                      user2.examptProof !== '' &&
                                                      user2.examptProof &&
                                                      'undefined' &&
                                                      user2.examptProof !== undefined ? (
                                                      <p className="downloadeMsg">
                                                        <div className="downloads">
                                                          <i className="fa fa-download downloadeStyle"></i>
                                                        </div>{' '}
                                                        View File{' '}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          ) : (
                                            ''
                                          )}

                                          {/* {user2 && user2.taxType == 'AIL 2%' ? (
                                            <div className="checkmystyle">
                                              {' '}
                                              <>
                                                <input
                                                  type="checkbox"
                                                  id="TickHere"
                                                  // value={checkboxx}
                                                  name="checkboxx"
                                                  // onChange={(e) => setCheckboxx(e.target.value)}
                                                />{' '}
                                                <span class="checkmark"></span>{' '}
                                                <label for="TickHere">
                                                  Tick Here If You Hold A Valid Resident Withholding
                                                  Tax/Non Resident Withholding Tax Exemption.
                                                </label>
                                              </>
                                            </div>
                                          ) : (
                                            ''
                                          )} */}

                                          {user2 &&
                                            user2.taxType == 'Non Resident Withholding Tax' ? (
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>Resident Withholding Tax Exemption</label>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <div className="rwttax">
                                                  <div className="Main-wrapper-circle">
                                                    <p>
                                                      {user2.taxExemption == 0 ||
                                                        user2.taxExemption == false
                                                        ? 'No'
                                                        : 'Yes'}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                          {/* 
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>
                                                Is Investor U.S. Citizen, or U.S. Resident, or a Tax
                                                Resident In Any Other Country?
                                              </label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <div className="">
                                                <div class="switch-field nospace">
                                                  <div className="check_YN_Bank1">
                                                    <p>
                                                      {user2 && user2.isOtherCitizen === 1
                                                        ? 'Yes'
                                                        : 'No'}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {user2 && user2.isOtherCitizen === 1 ? (
                                            <>
                                              <div className="main-padding-wrapper">
                                                <Row className="FlagsSelectcss residence">
                                                  <Col md={6} sm={6} xs={6}>
                                                    <div>
                                                      <label>Country of Tax Residence</label>
                                                    </div>
                                                  </Col>
                                                  <Col md={6} sm={6} xs={6}>
                                                    <p>
                                                      {user2 && user2.tinDetails != null
                                                        ? getCountryName(
                                                            user2.tinDetails[0].countryOfResidence
                                                          )
                                                        : ''}
                                                    </p>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col md={6} sm={6} xs={6}>
                                                    <div>
                                                      <label>Tax Identification Number (TIN)</label>
                                                    </div>
                                                  </Col>
                                                  <Col md={6} sm={6} xs={6}>
                                                    <p>
                                                      {user2 && user2.tinDetails != null
                                                        ? user2.tinDetails[0].tinNumber
                                                        : ''}
                                                    </p>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col md={6} sm={6} xs={6}>
                                                    <div>
                                                      <label>Reason if TIN is Not Available</label>
                                                    </div>
                                                  </Col>
                                                  <Col md={6} sm={6} xs={6}>
                                                    <p>
                                                      {user2 && user2.tinDetails != null
                                                        ? user2.tinDetails[0].tinReason
                                                        : ''}
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </>
                                          ) : (
                                            ''
                                          )} */}
                                        </>
                                      </div>
                                      {/* <div className="col-md-1 edit_icon">
                                      {user.profile.includes("UR4") ||
                                      user.profile.includes("UR2") ||
                                      (user1 && user1.applicationStage == 'Active Client') ||
                                      user.profile.includes("UR3") ? (
                                        ''
                                      ) : (
                                        <i
                                          onClick={handleOpen}
                                          title="Edit"
                                          class="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </div> */}
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <label>
                                          Share of Income Attributed to 2<sup>nd</sup> Joint Account
                                          Holder
                                        </label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <p>{user2 ? user2.SecondaryShareOfIncome : ''}</p>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="col-md-1 edit_icon">
                                  {user.profile.includes("UR4") ||
                                  user.profile.includes("UR2") ||
                                  (user1 && user1.applicationStage == 'Active Client') ||
                                  user.profile.includes("UR3") ? (
                                    ''
                                  ) : (
                                    <i
                                      onClick={handleOpen}
                                      title="Edit"
                                      class="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFourSec">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFourSec"
                                aria-expanded="false"
                                aria-controls="collapseFourSec"
                              >
                                ID Verification
                              </button>
                            </h2>
                            <div
                              id="collapseFourSec"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingFourSec"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <div className="row">
                                  <div className="col-md-11">
                                    <>
                                      <div className="row">
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <label>Type of ID </label>
                                        </div>
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <p>{user2 ? user2.documentIdType : ''}</p>
                                        </div>
                                      </div>

                                      {user2 ? (
                                        user2.documentIdType !== 'NZ Driver Licence' &&
                                          user2.documentIdType !== 'NZ Passport' ? (
                                          <>
                                            <div className="row">
                                              <div className="col-md-6  col-sm-6 col-6">
                                                <label>Other Document Type</label>
                                              </div>
                                              <div className="col-md-6  col-sm-6 col-6">
                                                col-6 <p>{user2 ? user2.otherDocumentName : ''}</p>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          ''
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </>

                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <label>First Name</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <p>{user2 ? user2.firstName : ''}</p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <label>Middle Name</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <p>{user2 ? user2.middleName : ''}</p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <label>Last Name</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6 col-6">
                                        <p>{user2 ? user2.lastName : ''}</p>
                                      </div>
                                    </div>
                                    {user2 ? (
                                      user2.documentIdType == 'NZ Driver Licence' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Licence Number</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>{user2 ? user2.documentNumber : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : user2.documentIdType == 'NZ Passport' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Passport Number</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>{user2 ? user2.documentNumber : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Document Number</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>{user2 ? user2.documentNumber : ''}</p>
                                          </div>
                                        </div>
                                      )
                                    ) : (
                                      ''
                                    )}

                                    {user2 ? (
                                      user2.documentIdType == 'NZ Driver Licence' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Expiry Date</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>{user2 ? user2.documentExpiryDate : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : user2.documentIdType == 'NZ Passport' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Expiry Date</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>{user2 ? user2.documentExpiryDate : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Expiry Date</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>{user2 ? user2.documentExpiryDate : ''}</p>
                                          </div>
                                        </div>
                                      )
                                    ) : (
                                      ''
                                    )}

                                    {user2 ? (
                                      user2.documentIdType == 'NZ Driver Licence' ? (
                                        <>
                                          {' '}
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Version Number</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>{user2 ? user2.documentVersionNumber : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : user2.documentIdType == 'NZ Passport' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Country of Issue</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <p>{user2 ? user2.documentCountryOfIssue : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Country of Issue</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>{user2 ? user2.documentCountryOfIssue : ''}</p>
                                          </div>
                                        </div>
                                      )
                                    ) : (
                                      ''
                                    )}

                                    {user2 ? (
                                      user2.documentIdType == 'NZ Driver Licence' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Document Proof</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              {/* <button>{ user2 ?user2.documentProof:""}</button> */}
                                              <div className="document_download">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() => NoteClick(user2.documentProof)}
                                                  className="licedocument-downloade"
                                                >
                                                  {' '}
                                                  <div className="main-downloade-wrapper">
                                                    {user2 &&
                                                      user2.documentProof !== null &&
                                                      user2.documentProof !== '' &&
                                                      user2.documentProof &&
                                                      'undefined' &&
                                                      user2.documentProof !== undefined ? (
                                                      <p className="downloadeMsg">
                                                        <div className="downloads">
                                                          <i className="fa fa-download downloadeStyle"></i>
                                                        </div>{' '}
                                                        VIEW FILE{' '}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                </a>

                                                <span id="name_tag" class="preview22"></span>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : user2.documentIdType == 'NZ Passport' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Document Proof</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <div className="document_download">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() => NoteClick(user2.documentProof)}
                                                  className="licedocument-downloade"
                                                >
                                                  {' '}
                                                  <div className="main-downloade-wrapper">
                                                    {user2 &&
                                                      user2.documentProof !== null &&
                                                      user2.documentProof !== '' &&
                                                      user2.documentProof &&
                                                      'undefined' &&
                                                      user2.documentProof !== undefined ? (
                                                      <p className="downloadeMsg">
                                                        <div className="downloads">
                                                          <i className="fa fa-download downloadeStyle"></i>
                                                        </div>{' '}
                                                        VIEW FILE{' '}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                </a>

                                                <span id="name_tag" class="preview2"></span>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <label>Document Proof</label>
                                            </div>
                                            <div className="col-md-6  col-sm-6 col-6">
                                              <div className="document_download">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() => NoteClick(user2.documentProof)}
                                                  className="licedocument-downloade"
                                                >
                                                  {' '}
                                                  <div className="main-downloade-wrapper">
                                                    {user2 &&
                                                      user2.documentProof !== null &&
                                                      user2.documentProof !== '' &&
                                                      user2.documentProof &&
                                                      'undefined' &&
                                                      user2.documentProof !== undefined ? (
                                                      <p className="downloadeMsg">
                                                        <div className="downloads">
                                                          <i className="fa fa-download downloadeStyle"></i>
                                                        </div>{' '}
                                                        VIEW FILE{' '}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                </a>

                                                <span id="name_tag" class="preview2"></span>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    ) : (
                                      ''
                                    )}

                                    {/* {user2 ? (
                                    user2.documentIdType == 'NZ Driver Licence' ? (
                                      <div className="row">
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <label>Secondary Identity Proof</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <div className="document_download">
                                            <a
                                              href={
                                                user1 &&
                                                user1.documentIdentificationProofSecondary !==
                                                  null &&
                                                user1.documentIdentificationProofSecondary !== '' &&
                                                user1.documentIdentificationProofSecondary &&
                                                'undefined' &&
                                                user1.documentIdentificationProofSecondary !==
                                                  undefined
                                                  ? user1.documentIdentificationProofSecondary
                                                  : ''
                                              }
                                              className="licedocument-downloade"
                                            >
                                              {' '}
                                              <div className="main-downloade-wrapper">
                                                {user1 &&
                                                user1.documentIdentificationProofSecondary !==
                                                  null &&
                                                user1.documentIdentificationProofSecondary !== '' &&
                                                user1.documentIdentificationProofSecondary &&
                                                'undefined' &&
                                                user1.documentIdentificationProofSecondary !==
                                                  undefined ? (
                                                  <p className="downloadeMsg">
                                                    <div className="downloads">
                                                      <i className="fa fa-download downloadeStyle"></i>
                                                    </div>{' '}
                                                    VIEW FILE{' '}
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </a>

                                            <span id="name_tag" class="preview2"></span>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    ''
                                  )} */}
                                  </div>
                                  <div className="col-md-1 edit_icon">
                                    {user.profile.includes("UR4") ||
                                      (user1 && user1.applicationStage == 'Active Client') ||
                                      user.profile.includes("UR2") ||
                                      user.profile.includes("UR3") ? (
                                      ''
                                    ) : (
                                      <i
                                        onClick={handleOpen}
                                        title="Edit"
                                        class="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {user2 && user2.primaryBankProof != null ? (
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingFiveSec">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFiveSec"
                                  aria-expanded="false"
                                  aria-controls="collapseFiveSec"
                                >
                                  Bank Account
                                </button>
                              </h2>
                              <div
                                id="collapseFiveSec"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingFiveSec"
                                data-bs-parent="#accordionExampleSecondary"
                              >
                                <div class="accordion-body">
                                  <div className="row">
                                    <div className="col-md-11">
                                      <div className="row">
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <label> NZ Bank Account</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <p>{user2 && user2.isNZBank === 1 ? 'Yes' : 'No'}</p>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <label>Bank Account Name</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <p>{user2 ? user2.primaryBankAccountName : ''}</p>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <label>Bank Account Number</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <p>{user2 ? user2.primaryBankAccountNumber : ''}</p>
                                        </div>
                                      </div>

                                      {user2 &&
                                        user2.bankSwiftCode !== '' &&
                                        user2.isNZBank !== 1 &&
                                        user2.bankSwiftCode !== null ? (
                                        <div className="row">
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <label>Bank Swift Code</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6 col-6">
                                            <p>{user2 ? user2.bankSwiftCode : ''}</p>
                                          </div>
                                        </div>
                                      ) : null}

                                      <div className="row">
                                        <div className="col-md-6  col-sm-6 col-6">
                                          <label>Document Proof</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6 col-6">
                                          {/* <button>{ user2 ?user2.documentProof:""}</button> */}
                                          <div className="document_download">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => NoteClick(user2.primaryBankProof)}
                                              className="licedocument-downloade"
                                            >
                                              {' '}
                                              <div className="main-downloade-wrapper">
                                                {user2 &&
                                                  user2.primaryBankProof !== null &&
                                                  user2.primaryBankProof !== '' &&
                                                  user2.primaryBankProof &&
                                                  'undefined' &&
                                                  user2.primaryBankProof !== undefined ? (
                                                  <p className="downloadeMsg">
                                                    <div className="downloads">
                                                      <i className="fa fa-download downloadeStyle"></i>
                                                    </div>{' '}
                                                    VIEW FILE
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </a>

                                            <span id="name_tag" class="preview2"></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-1 edit_icon">
                                      {user.profile.includes("UR4") ||
                                        user.profile.includes("UR2") ||
                                        (user1 && user1.applicationStage == 'Active Client') ||
                                        user.profile.includes("UR3") ? (
                                        ''
                                      ) : (
                                        <i
                                          onClick={handleOpen}
                                          title="Edit"
                                          class="fa fa-pencil-square-o hover__Edit_eye"
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}

                          {user1 ? (
                            user1.investmentType === 'Joint' ? (
                              <>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingSevenSec">
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseSevenSec"
                                      aria-expanded="false"
                                      aria-controls="collapseSevenSec"
                                    >
                                      Terms & Conditions
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseSevenSec"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingSevenSec"
                                    data-bs-parent="#accordionExampleSecondary"
                                  >
                                    <div class="accordion-body">
                                      <div className="row">
                                        <div className="col-md-11">
                                          <div className="row">
                                            <div className="col-md-6 col-sm-auto col-6">
                                              <label>Receive Future Offer Marketing Emails</label>
                                            </div>
                                            <div className="col-md-6 col-sm-auto col-6">
                                              <p>
                                                {user1
                                                  ? user1.futureInvestementOpportunities == 1
                                                    ? 'Yes'
                                                    : 'No'
                                                  : ''}
                                              </p>
                                            </div>
                                          </div>
                                          {/* <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <label>Full Name of Authorised Person</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <p>{user2 ? user2.fullLegalName : ''}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <label>Date</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <p>{user2 ? user2.submittedAt : ''}</p>
                                          </div>
                                        </div> */}
                                          {/* 
                                        <div className="row signature_img">
                                          <div className="col-md-6 col-sm-6">
                                            <label>Subscription Agreement Signature</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                            <p>
                                              <img
                                                src={
                                                  user2 &&
                                                  user2.certificateDetails !== null &&
                                                  user2.certificateDetails.subscriptionSign
                                                    ? user2.certificateDetails.subscriptionSign
                                                    : ''
                                                }
                                              />
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <label>Subscription Office Held</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <p>
                                              {user2 &&
                                              user2.certificateDetails !== null &&
                                              user2.certificateDetails.subscriptionOfficeHeld
                                                ? user2.certificateDetails.subscriptionOfficeHeld
                                                : ''}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="row signature_img">
                                          <div className="col-md-6 col-sm-6">
                                            <label>Adherence Agreement Signature</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                            <p>
                                              <img
                                                src={
                                                  user2 &&
                                                  user2.certificateDetails !== null &&
                                                  user2.certificateDetails.adherenceSign
                                                    ? user2.certificateDetails.adherenceSign
                                                    : ''
                                                }
                                              />
                                            </p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <label>Adherence Office Held</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6">
                                            {/* <p>{user2 ? user2.certificateDetails.adherenceOfficeHeld : ''}</p> */}
                                          {/* <p>
                                              {user1 &&
                                              user1.certificateDetails !== null &&
                                              user1.certificateDetails.adherenceOfficeHeld
                                                ? user1.certificateDetails.adherenceOfficeHeld
                                                : ''}
                                            </p>
                                          </div>
                                        </div> */}

                                          {(user.profile.includes("UR1") ||
                                            user.profile.includes("UR2") ||
                                            user.profile.includes("UR4") ||
                                            user.profile == 'UR8') &&
                                            user1 &&
                                            (user1.applicationStage == 'Awaiting Payment' ||
                                              user1.applicationStage == 'Active Client' ||
                                              user1.applicationStage == 'Awaiting Final Approval') ? (
                                            <div className="main_wrappr_agrementspdf">
                                              <div className="agrementspdf__wrapper history__pdfr">
                                                <span className="agrementspdf" onClick={agrementpdf}>
                                                  <button class="mb-bottom Subscription_button">
                                                    Generate Subscription/Adherence PDF Agreements
                                                  </button>
                                                </span>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>

                                        <div className="col-md-1 edit_icon">
                                          <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="parent-modal-title"
                                            aria-describedby="parent-modal-description"
                                          >
                                            <Box className="formPopUp">
                                              <h4>Terms & Agreements Edit</h4>

                                              <div className="edit_form">
                                                <div className="form_input fullinput">
                                                  <label>Full Name of Authorised Person</label>
                                                  <input
                                                    type="text"
                                                    placeholder="Full name of authorised person"
                                                  />
                                                </div>

                                                <div className="form_input fullinput">
                                                  <label>Office Held</label>
                                                  <input
                                                    type="text"
                                                    placeholder="Enter office held"
                                                  />
                                                </div>

                                                <div className="form_input fullinput">
                                                  <label>Adherence Agreement Proof</label>
                                                  <input
                                                    type="text"
                                                    placeholder="Enter adherence agreement proof"
                                                  />
                                                </div>

                                                <div className="form_input fullinput">
                                                  <label>Subscription Agreement Proof</label>
                                                  <input
                                                    type="text"
                                                    placeholder="Enter subscription agreement proof"
                                                  />
                                                </div>

                                                <div className="submit_btn">
                                                  <button>Submit</button>
                                                </div>
                                              </div>
                                            </Box>
                                          </Modal>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <div className="view_tabs mt-4 responsive_hide">
                        <div className="primary_text">
                          Secondary
                          <div className="note-footer flex-note">
                            {ziploader1 === true ? (
                              <div className="note-main-loader">
                                <div className="loader zip_exp_loader"></div>
                              </div>
                            ) : user1 && user1.overAllAmlStatus === 1 && user1.applicationStage == "Submitted" ? (
                              <button
                                className="save zipexport"
                                onClick={() => getZipData2(user2.id, user2.zipUrl)}
                              >
                                Zip Export
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {/* <Tab.Container id="left-tabs-example" {user1 && user1.investmentType =="Joint" ?defaultActiveKey="third" : defaultActiveKey="second"}> */}
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey={
                            user1 && user1.investmentType == 'Joint' ? 'third' : 'second'
                          }
                        >
                          <Row>
                            <Col md={3} sm={5} className="border-right">
                              <Nav variant="pills" className="flex-column">
                                {/* <Nav.Item>
                                <Nav.Link eventKey="first">Investment Details</Nav.Link>
                              </Nav.Item> */}
                                <Nav.Item>
                                  <Nav.Link eventKey="third">Entity Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="second">Personal Information</Nav.Link>
                                </Nav.Item>

                                {/* {user2 && user2.isMyFarm == 0 ? ( */}
                                <Nav.Item>
                                  <Nav.Link eventKey="forth">ID Verification</Nav.Link>
                                </Nav.Item>
                                {/* )
                               :
                                (
                                ''
                              )
                              } */}

                                {/* {user2 && user2.isMyFarm == 0 ? ( */}
                                <>
                                  <Nav.Item>
                                    <Nav.Link eventKey="five">Bank Account</Nav.Link>
                                  </Nav.Item>
                                </>
                                {/* )
                               :
                               (
                                ''
                              )} */}

                                {user1 ? (
                                  user1.investmentType === 'Joint' ? (
                                    <>
                                      <Nav.Item>
                                        <Nav.Link eventKey="sixs">Wholesale Certificate</Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="sevens">Terms & Conditions</Nav.Link>
                                      </Nav.Item>
                                    </>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                              </Nav>
                            </Col>
                            <Col md={9} sm={7} className="formPopUp">
                              <Tab.Content>
                                <Tab.Pane eventKey="sevens">
                                  <TermsCondition2 data={user1 && user1} />

                                  {/* <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-sm-auto">
                                        <label>Full Name of Authorised Person</label>
                                      </div>
                                      <div className="col-md-6 col-sm-auto">
                                        <p>{user1 ? user1.fullLegalName : ''}</p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6 col-sm-auto">
                                        <label>Date</label>
                                      </div>
                                      <div className="col-md-6 col-sm-auto">
                                        <p>{user1 ? user1.submittedAt : ''}</p>
                                      </div>
                                    </div>

                                    <div className="row signature_img">
                                      <div className="col-md-6 col-sm-auto">
                                        <label>Subscription Agreement Signature</label>
                                      </div>
                                      <div className="col-md-6">
                                        <p>
                                          <img
                                            src={
                                              user1 &&
                                              user1.certificateDetails &&
                                              user1.certificateDetails.subscriptionSign
                                                ? user1.certificateDetails.subscriptionSign
                                                : ''
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      
                                      <div className="col-md-6 col-sm-auto">
                                        <p>
                                          {user1 &&
                                          user1.certificateDetails &&
                                          user1.certificateDetails.subscriptionOfficeHeld
                                            ? user1.certificateDetails.subscriptionOfficeHeld
                                            : ''}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row signature_img">
                                      <div className="col-md-6 col-sm-auto">
                                        <label>Adherence Agreement Signature</label>
                                      </div>
                                      <div className="col-md-6">
                                        <p>
                                          <img
                                            src={
                                              user1 &&
                                              user1.certificateDetails &&
                                              user1.certificateDetails.adherenceSign
                                                ? user1.certificateDetails.adherenceSign
                                                : ''
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      
                                      <div className="col-md-6 col-sm-auto">
                                        <p>
                                          {user1 &&
                                          user1.certificateDetails &&
                                          user1.certificateDetails.adherenceOfficeHeld
                                            ? user1.certificateDetails.adherenceOfficeHeld
                                            : ''}
                                        </p>
                                      </div>
                                    </div>

                                    {(user.profile.includes("UR1") ||
                                      user.profile.includes("UR2") ||
                                      user.profile.includes("UR4") ||
                                      user.profile == 'UR8') &&
                                    user1 &&
                                    (user1.applicationStage == 'Awaiting Payment' ||
                                      user1.applicationStage == 'Active Client' ||
                                      user1.applicationStage == 'Awaiting Final Approval') ? (
                                      <div className="main_wrappr_agrementspdf">
                                        <div className="agrementspdf__wrapper history__pdfr">
                                          <span
                                            className="agrementspdf"
                                            onClick={() => agrementpdf(user1.id)}
                                          >
                                            <button class="mb-bottom Subscription_button">
                                              Generate Subscription/Adherence PDF Agreements
                                            </button>
                                          </span>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="col-md-1 edit_icon">
                                    <Modal
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="parent-modal-title"
                                      aria-describedby="parent-modal-description"
                                    >
                                      <Box className="formPopUp">
                                        <h4>Terms & Agreements Edit</h4>

                                        <div className="edit_form">
                                          <div className="form_input fullinput">
                                            <label>Full Name of Authorised Person</label>
                                            <input
                                              type="text"
                                              placeholder="Enter full name of authorised person"
                                            />
                                           
                                          </div>

                                          <div className="form_input fullinput">
                                            <label>Office Held</label>
                                            <input type="text" placeholder="Enter Office held" />
                                          </div>

                                          <div className="form_input fullinput">
                                            <label>Adherence Agreement Proof</label>
                                            <input
                                              type="text"
                                              placeholder="Enter Adherence agreement proof"
                                            />
                                          </div>

                                          <div className="form_input fullinput">
                                            <label>Subscription Agreement Proof</label>
                                            <input
                                              type="text"
                                              placeholder="Enter Subscription agreement proof"
                                            />
                                          </div>

                                          <div className="submit_btn">
                                            <button>F</button>
                                          </div>
                                        </div>
                                      </Box>
                                    </Modal>
                                  </div>
                                </div> */}
                                </Tab.Pane>
                                <Tab.Pane eventKey="sixs">
                                  {user1 && user1.certificateDetails ? (
                                    <div className="row whole_cert">
                                      <div className="col-md-12">
                                        {user1.certificateDetails &&
                                          user1.certificateDetails.certificateNumber == '1' ? (
                                          <>
                                            {(user1 &&
                                              user1.certificateDetails &&
                                              user1.certificateDetails.largePersonValue != null &&
                                              user1 &&
                                              user1.certificateDetails.largePersonValue !==
                                              'undefined' &&
                                              user1.certificateDetails.largePersonValue != 'None' &&
                                              user1.certificateDetails.largePersonValue != '') ||
                                              (user1 &&
                                                user1.certificateDetails &&
                                                user1.certificateDetails.twoYearInvestEntity2 != null &&
                                                user1 &&
                                                user1.certificateDetails.twoYearInvestEntity2 !==
                                                'undefined' &&
                                                user1.certificateDetails.twoYearInvestEntity2 !=
                                                'None' &&
                                                user1.certificateDetails.twoYearInvestEntity2 != '') ? (
                                              <>
                                                {' '}
                                                <h3>Wholesale Certification: Large Investor</h3>
                                                <p>{user1.certificateDetails.largePersonValue}</p>
                                                <p>{user1.certificateDetails.twoYearInvestEntity2}</p>
                                              </>
                                            ) : (
                                              ''
                                            )}
                                            {/* 2nd */}

                                            <>
                                              {user1 &&
                                                ((user1.certificateDetails.investorPrincipalBusiness !=
                                                  null &&
                                                  user1.certificateDetails.investorPrincipalBusiness !=
                                                  '') ||
                                                  (user1.certificateDetails
                                                    .investingFinancialProducts != null &&
                                                    user1.certificateDetails
                                                      .investingFinancialProducts != '') ||
                                                  (user1.certificateDetails.actingUnderwriter != null &&
                                                    user1.certificateDetails.actingUnderwriter != '') ||
                                                  (user1.certificateDetails.financialAdviceService !=
                                                    null &&
                                                    user1.certificateDetails.financialAdviceService !=
                                                    '') ||
                                                  (user1.certificateDetails
                                                    .clientMoneyPropertyService != null &&
                                                    user1.certificateDetails
                                                      .clientMoneyPropertyService != '') ||
                                                  (user1.certificateDetails.tradingFinancialProducts !=
                                                    null &&
                                                    user1.certificateDetails.tradingFinancialProducts !=
                                                    '') ||
                                                  (user1.certificateDetails.registeredBankRBNZ !=
                                                    null &&
                                                    user1.certificateDetails.registeredBankRBNZ !=
                                                    '') ||
                                                  (user1.certificateDetails.NBDTInvestor != '' &&
                                                    user1.certificateDetails.NBDTInvestor != null) ||
                                                  (user1.certificateDetails.licensedIssuerInsurance !=
                                                    '' &&
                                                    user1.certificateDetails.licensedIssuerInsurance !=
                                                    null) ||
                                                  (user1.certificateDetails.registeredSchemeManager !=
                                                    '' &&
                                                    user1.certificateDetails.registeredSchemeManager !=
                                                    null) ||
                                                  (user1.certificateDetails.derivativesIssuer != '' &&
                                                    user1.certificateDetails.derivativesIssuer !=
                                                    null) ||
                                                  (user1.certificateDetails.FinancialAdvisor != null &&
                                                    user1.certificateDetails.FinancialAdvisor !=
                                                    '')) ? (
                                                <>
                                                  <h3>
                                                    Wholesale Certification: Investment Business
                                                  </h3>

                                                  <p>
                                                    {
                                                      user1.certificateDetails
                                                        .investorPrincipalBusiness
                                                    }
                                                  </p>
                                                  <p>
                                                    {
                                                      user1.certificateDetails
                                                        .investingFinancialProducts
                                                    }
                                                  </p>
                                                  <p>{user1.certificateDetails.actingUnderwriter}</p>
                                                  <p>
                                                    {user1.certificateDetails.financialAdviceService}
                                                  </p>
                                                  <p>
                                                    {
                                                      user1.certificateDetails
                                                        .clientMoneyPropertyService
                                                    }
                                                  </p>
                                                  <p>
                                                    {
                                                      user1.certificateDetails
                                                        .tradingFinancialProducts
                                                    }
                                                  </p>
                                                  <p>{user1.certificateDetails.registeredBankRBNZ}</p>
                                                  <p>{user1.certificateDetails.NBDTInvestor}</p>
                                                  <p>
                                                    {user1.certificateDetails.licensedIssuerInsurance}
                                                  </p>
                                                  <p>
                                                    {user1.certificateDetails.registeredSchemeManager}
                                                  </p>
                                                  <p>{user1.certificateDetails.derivativesIssuer}</p>
                                                  <p>{user1.certificateDetails.FinancialAdvisor}</p>
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </>

                                            {/* 3rd page certificate */}

                                            <>
                                              {user1 &&
                                                ((user1.certificateDetails.ownedPortfolio2yrs != null &&
                                                  user1.certificateDetails.ownedPortfolio2yrs != '') ||
                                                  (user1.certificateDetails.carriedTransactions2yrs !=
                                                    null &&
                                                    user1.certificateDetails.carriedTransactions2yrs !=
                                                    '') ||
                                                  (user1.certificateDetails.investmentBusiness10yrs !=
                                                    null &&
                                                    user1.certificateDetails.investmentBusiness10yrs !=
                                                    '') ||
                                                  (user1.certificateDetails.equitySecurities != null &&
                                                    user1.certificateDetails.equitySecurities != '') ||
                                                  (user1.certificateDetails.managedFunds !== null &&
                                                    user1.certificateDetails.managedFunds !== '') ||
                                                  (user1.certificateDetails.derivativeInstruments !==
                                                    null &&
                                                    user1.certificateDetails.derivativeInstruments !==
                                                    '') ||
                                                  (user1.certificateDetails.debtSecurities !== null &&
                                                    user1.certificateDetails.debtSecurities !== '')) ? (
                                                <>
                                                  <h3>
                                                    Wholesale Certification: Investement Criteria
                                                  </h3>
                                                  <p>{user1.certificateDetails.ownedPortfolio2yrs}</p>
                                                  <p>
                                                    {user1.certificateDetails.carriedTransactions2yrs}
                                                  </p>
                                                  <p>
                                                    {user1.certificateDetails.investmentBusiness10yrs}
                                                  </p>

                                                  <p>{user1.certificateDetails.equitySecurities}</p>
                                                  <p>{user1.certificateDetails.managedFunds}</p>
                                                  <p>
                                                    {user1.certificateDetails.derivativeInstruments}
                                                  </p>
                                                  <p>{user1.certificateDetails.debtSecurities}</p>
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </>
                                          </>
                                        ) : (
                                          ''
                                        )}

                                        <>
                                          {user1 &&
                                            user1.certificateDetails &&
                                            user1.certificateDetails.certificateNumber == '3' ? (
                                            <>
                                              {' '}
                                              {user1 &&
                                                ((user1.certificateDetails.startupOrBusiness != null &&
                                                  user1.certificateDetails.startupOrBusiness != '') ||
                                                  (user1.certificateDetails.investorPresentation !=
                                                    null &&
                                                    user1.certificateDetails.investorPresentation !=
                                                    '') ||
                                                  (user1.certificateDetails.capitalRaisingDirector !=
                                                    null &&
                                                    user1.certificateDetails.capitalRaisingDirector !=
                                                    '') ||
                                                  (user1.certificateDetails.investingSharesSecurities !=
                                                    null &&
                                                    user1.certificateDetails
                                                      .investingSharesSecurities != '') ||
                                                  (user1.certificateDetails.myOwnInvestmentDecisions !=
                                                    null &&
                                                    user1.certificateDetails.myOwnInvestmentDecisions !=
                                                    '') ||
                                                  (user1.certificateDetails.valueAndRisksOfInvesting !=
                                                    null &&
                                                    user1.certificateDetails.valueAndRisksOfInvesting !=
                                                    '') ||
                                                  (user1.certificateDetails
                                                    .acquiringDisposingFinancialProducts !== null &&
                                                    user1.certificateDetails
                                                      .acquiringDisposingFinancialProducts !== '') ||
                                                  (user1.certificateDetails
                                                    .acquiringDisposingFinancialProductsOther !==
                                                    null &&
                                                    user1.certificateDetails
                                                      .acquiringDisposingFinancialProductsOther !==
                                                    '')) ? (
                                                <>
                                                  <h3>Wholesale Certification: Eligible Investor</h3>

                                                  <p>
                                                    <p>
                                                      {user1.certificateDetails.startupOrBusiness}
                                                    </p>
                                                    <p>
                                                      {user1.certificateDetails.investorPresentation}
                                                    </p>
                                                    <p>
                                                      {
                                                        user1.certificateDetails
                                                          .capitalRaisingDirector
                                                      }
                                                    </p>
                                                    <p>
                                                      {
                                                        user1.certificateDetails
                                                          .investingSharesSecurities
                                                      }
                                                    </p>
                                                    <p>
                                                      {
                                                        user1.certificateDetails
                                                          .myOwnInvestmentDecisions
                                                      }
                                                    </p>
                                                    <p>
                                                      {
                                                        user1.certificateDetails
                                                          .valueAndRisksOfInvesting
                                                      }
                                                    </p>

                                                    {/* change */}

                                                    <p>
                                                      {
                                                        user1.certificateDetails
                                                          .acquiringDisposingFinancialProducts
                                                      }
                                                    </p>
                                                  </p>
                                                  <p>
                                                    {
                                                      user1.certificateDetails
                                                        .acquiringDisposingFinancialProductsOther
                                                    }
                                                  </p>
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </>

                                        {user1 &&
                                          user1.certificateDetails !== null &&
                                          user1.certificateDetails.signatureA ? (
                                          <>
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div className="col-auto">
                                                    <h5>SIGNED for and on behalf of</h5>
                                                  </div>
                                                  <div className="col-auto">
                                                    <h5>
                                                      {/* {userType == 'Company' ? ( */}
                                                      <span className="behalf_text">
                                                        {user2 &&
                                                          user2.fullLegalName !== null &&
                                                          user2.fullLegalName != ''
                                                          ? user2.fullLegalName
                                                          : ''}
                                                      </span>
                                                      as Investor.
                                                    </h5>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <strong>Wholesale Certification: Signature</strong>

                                            <p>
                                              <img
                                                alt="dummy"
                                                src={
                                                  user2 &&
                                                    user2.certificateDetails !== null &&
                                                    user2.certificateDetails.signatureA
                                                    ? user2.certificateDetails.signatureA
                                                    : ''
                                                }
                                              />
                                            </p>

                                            {/* <p>
                                                    <img
                                                      src={
                                                        user1 &&
                                                        user1.certificateDetails !== null &&
                                                        user1.certificateDetails.signatureB
                                                          ? user1.certificateDetails.signatureB
                                                          : ''
                                                      }
                                                    />
                                                  </p> */}
                                          </>
                                        ) : (
                                          ''
                                        )}

                                        {(user1.certificateDetails &&
                                          user1.certificateDetails.nominatedUserType == '') ||
                                          user1.certificateDetails.nominatedUserType == null ? (
                                          ''
                                        ) : (
                                          <div className="row">
                                            <div className="col-md-6 col-sm-auto">
                                              <label>Personal Adviser Type</label>
                                            </div>
                                            <div className="col-md-6 col-sm-auto">
                                              <p>
                                                {user1 &&
                                                  user1.certificateDetails.nominatedUserType != 'Select'
                                                  ? user1.certificateDetails.nominatedUserType
                                                  : ''}
                                              </p>
                                            </div>
                                          </div>
                                        )}

                                        {(user1.certificateDetails &&
                                          user1.certificateDetails.nominatedUserName == '') ||
                                          user1.certificateDetails.nominatedUserName == null ? (
                                          ''
                                        ) : (
                                          <div className="row">
                                            <div className="col-md-6 col-sm-7">
                                              <label>Personal Advisor Name</label>
                                            </div>
                                            <div className="col-md-6 col-sm-5">
                                              <p>
                                                {user1
                                                  ? user1.certificateDetails.nominatedUserName
                                                  : ''}
                                              </p>
                                            </div>
                                          </div>
                                        )}

                                        {(user1.certificateDetails &&
                                          user1.certificateDetails.nominatedUserEmail == '') ||
                                          user1.certificateDetails.nominatedUserEmail == null ? (
                                          ''
                                        ) : (
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6">
                                              <label>Email</label>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                              <p>
                                                {user1
                                                  ? user1.certificateDetails.nominatedUserEmail
                                                  : ''}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      <div className="col-md-1 edit_icon"></div>
                                    </div>
                                  ) : (
                                    ''
                                  )}

                                  <>
                                    {user1 &&
                                      user1.certificateDetails &&
                                      user1.certificateDetails.certificateNumber == '2' ? (
                                      <>
                                        <h3>Wholesale Certification: Amount Invested</h3>

                                        <p>
                                          {user1 && user1.certificateDetails.usualLegalRules !== null
                                            ? user1.certificateDetails.usualLegalRules
                                            : ''}
                                        </p>
                                        <p>
                                          {user1 &&
                                            user1.certificateDetails.notCompleteInformation !== null
                                            ? user1.certificateDetails.notCompleteInformation
                                            : ''}
                                        </p>
                                        <p>
                                          {user1 &&
                                            user1.certificateDetails.materialExtent10yrs !== null
                                            ? user1.certificateDetails.materialExtent10yrs
                                            : ''}
                                        </p>
                                        <p>
                                          {user1 &&
                                            user1.certificateDetails.fewerLegalProtection !== null
                                            ? user1.certificateDetails.fewerLegalProtection
                                            : ''}
                                        </p>
                                        <p>
                                          {user1 &&
                                            user1.certificateDetails.notSuitableInvestment !== null
                                            ? user1.certificateDetails.notSuitableInvestment
                                            : ''}
                                        </p>
                                        <p>
                                          {user1 &&
                                            user1.certificateDetails.seekFinancialAdvice !== null
                                            ? user1.certificateDetails.seekFinancialAdvice
                                            : ''}
                                        </p>

                                        {/* <div className="row">
                                    <div className="col-md-6 col-sm-auto">
                                      <label>Wholesale Certificate office held</label>
                                    </div>
                                    <div className="col-md-6 col-sm-auto">
                                      <p>
                                        {user1 &&
                                        user1.certificateDetails !== null &&
                                        user1.certificateDetails.WholesaleCertificateOfficeHeld
                                          ? user1.certificateDetails.WholesaleCertificateOfficeHeld
                                          : ''}
                                      </p>
                                    </div>
                                  </div> */}

                                        {user1 &&
                                          user1.certificateDetails !== null &&
                                          user1.certificateDetails.signatureA ? (
                                          <>
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div className="col-auto">
                                                    <h5>SIGNED for and on behalf of</h5>
                                                  </div>
                                                  <div className="col-auto">
                                                    <h5>
                                                      {/* {userType == 'Company' ? ( */}
                                                      <span className="behalf_text">
                                                        {user1 &&
                                                          user1.fullLegalName !== null &&
                                                          user1.fullLegalName != ''
                                                          ? user1.fullLegalName
                                                          : ''}
                                                      </span>
                                                      as Investor.
                                                    </h5>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <strong>Wholesale Certification: Signature</strong>

                                            <p>
                                              <img
                                                src={
                                                  user1 &&
                                                    user1.certificateDetails !== null &&
                                                    user1.certificateDetails.signatureA
                                                    ? user1.certificateDetails.signatureA
                                                    : ''
                                                }
                                              />
                                            </p>

                                            {/* <p>
                                        <img
                                          src={
                                            user1 &&
                                            user1.certificateDetails !== null &&
                                            user1.certificateDetails.signatureB
                                              ? user1.certificateDetails.signatureB
                                              : ''
                                          }
                                        />
                                      </p> */}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </>

                                  {user.profile.includes("UR1") ||
                                    user.profile == 'UR8' ||
                                    user.profile.includes("UR2") ? (
                                    < div className="row responsive__align align_item_Eligible" >
                                      <div className="col-md-6 col-sm-6">
                                        <label className="text_trns">
                                          Attach Confirmation of Eligible Status (if relevant):
                                        </label>
                                      </div>

                                      <div className="col-md-5 col-sm-5">
                                        <Row className="fullinput mt-2 mb-2">
                                          <Col>
                                            <div class="yes1_attach">
                                              <a className="click-hover Edit_button_style">
                                                <span class="btn_upload">
                                                  <input
                                                    type="file"
                                                    id="imag2"
                                                    title=""
                                                    name="image"
                                                    //   onChange={e => {
                                                    //     uploadWithJSON_attach2(
                                                    //       e.target.files[0],
                                                    //       user1.id
                                                    //     );
                                                    //   }}

                                                    onChange={(e) => {
                                                      uploadWithJSON_attach2(
                                                        e.target.files[0],
                                                        user1.id
                                                      );
                                                    }}
                                                  />
                                                </span>
                                                ATTACH
                                                {/* Attach */}
                                              </a>
                                            </div>

                                            <div className="Attch_file">
                                              <span id="name_tag" class="preview2"></span>
                                              <input
                                                type="button"
                                                id="removeImage2"
                                                value="x"
                                                class="btn-rmv2"
                                              />
                                            </div>

                                            {imageLoader === true ? (
                                              <div class="progress">
                                                <span
                                                  class="progress-bar"
                                                  style={{ width: '100%' }}
                                                ></span>
                                              </div>
                                            ) : null}
                                          </Col>
                                        </Row>

                                        {/* <button>ATTACH</button> */}
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="row">
                                    {user1 && user1.confirmationDocument != null ? (
                                      <div className="col-md-6 col-sm-6">
                                        <label className="text_trns">
                                          Confirmation of Eligible Status:
                                        </label>
                                      </div>
                                    ) : (
                                      ''
                                    )}

                                    <div className="col-md-6 col-sm-6">
                                      <a
                                        href={user1 && user1.confirmationDocument}
                                        className="documentProof-downloade confirmationDocument"
                                      >
                                        <div className="main-downloade-wrapper align__end">
                                          {user1 &&
                                            user1.confirmationDocument !== null &&
                                            user1.confirmationDocument !== '' &&
                                            user1.confirmationDocument &&
                                            'undefined' &&
                                            user1.confirmationDocument !== undefined ? (
                                            <p className="downloadeMsg">
                                              <div className="downloads">
                                                <i className="fa fa-download downloadeStyle"></i>
                                              </div>{' '}
                                              <p className="View__file">VIEW FILE</p>{' '}
                                            </p>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </a>
                                    </div>
                                  </div>

                                  {user1 && user1.applicationStage != 'In Progress' ? (
                                    <button
                                      onClick={() => {
                                        wholesale_certifiacte_pdf(user1.id);
                                        console.log(user1.id, 'user1rrrrrrrrrridddddddddddddddddddd');
                                      }}
                                      className="PAdding__button"
                                    >
                                      Generate Wholesale Certification PDF
                                    </button>
                                  ) : (
                                    ''
                                  )}
                                </Tab.Pane>

                                <Tab.Pane eventKey="second">
                                  <PersonalInformation2 data={user2 && user2} />
                                  {/* <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6">
                                        <label>Full Legal Name</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6">
                                        <p>{user2 ? user2.fullLegalName : ''}</p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6  col-sm-6">
                                        <label>Preferred Name</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6">
                                        <p>{user2 ? user2.preferredName : ''}</p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6">
                                        <label>Date Of Birth</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6">
                                        <p>{user2 ? user2.dateOfBirth : ''}</p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6  col-sm-6">
                                        <label>Email </label>
                                      </div>
                                      <div className="col-md-6  col-sm-6">
                                        {user2 && user2.userEmail === user2.userCode ? (
                                          <p>{user1 ? user1.userEmail : ''}</p>
                                        ) : (
                                          <p>{user2 ? user2.userEmail : ''}</p>
                                        )}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6  col-sm-6">
                                        <label>Mobile Number</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6">
                                        <p>
                                          {user2 ? (
                                            user2.priContactNumber !== null &&
                                            user2.priContactCountryCode !== null ? (
                                              <p>
                                                {user2 && user2.priContactCountryCode !== ''
                                                  ? '+' + user2.priContactCountryCode + ' '
                                                  : ' '}
                                                {user2 ? user2.priContactNumber : ''}
                                              </p>
                                            ) : (
                                              ''
                                            )
                                          ) : (
                                            ''
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    {user2 ? (
                                      user2.isAlternateContact === 1 ? (
                                        <div className="row">
                                          <div className="col-md-6  col-sm-6">
                                            <label> {user2.secContactType} Additional Number</label>
                                          </div>
                                          <div className="col-md-6  col-sm-6">
                                            

                                            {user2 ? (
                                              user2.secContactNumber !== null &&
                                              user2.secContactCountryCode !== null ? (
                                                <p>
                                                  {user2 && user2.secContactCountryCode !== ''
                                                    ? '+' + user2.secContactCountryCode + ' '
                                                    : ' '}
                                                  {user2 ? user2.secContactNumber : ''}
                                                </p>
                                              ) : (
                                                ''
                                              )
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </div>
                                      ) : null
                                    ) : null}

                                    

                                    <div className="row">
                                      <div className="col-md-6  col-sm-6">
                                        <label>Residential Address</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6">
                                        <p>{user2 ? user2.userResidentialAddress : ''}</p>
                                      </div>
                                    </div>

                                    {user2 &&
                                    user2.userResidentialAddress != null &&
                                    user2.userResidentialAddress != '' ? (
                                      <></>
                                    ) : (
                                      ''
                                    )}

                                    <div className="row">
                                      <div className="col-md-6  col-sm-6">
                                        <label>Postal Address</label>
                                      </div>
                                      <div className="col-md-6  col-sm-6">
                                        <p>
                                          {user2 && user2.isUserAddressSame === 1
                                            ? 'Same as home address'
                                            : user2 && user2.userPostalAddress
                                            ? user2.userPostalAddress
                                            : ''}
                                        </p>
                                      </div>
                                    </div>

                                    
                                    {user2 && user2.occupation === 'Other' ? (
                                      <div className="row">
                                        <div className="col-md-6  col-sm-6">
                                          <label>Other Occupation</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6">
                                          <p>{user2 ? user2.otherOccupation : ''}</p>
                                        </div>
                                      </div>
                                    ) : (
                                      ''
                                    )}

                                    <>
                                      <div className="row">
                                        <div className="col-md-6  col-sm-6">
                                          <label>City/Town Born In</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6">
                                          <p>{user2 ? user2.placeOfBirth : ''}</p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6  col-sm-6">
                                          <label>Country Born In</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6">
                                          <p>{user2 ? getCountryName(user2.countryOfBirth) : ''}</p>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>Country of Residence</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <p>
                                            {user2 ? getCountryName(user2.residingCountry) : ''}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <label className="">IRD Number</label>
                                        </div>
                                        <div className="col-md-6  col-sm-6">
                                          <p>{user2 ? user2.userIrdNumber : ''}</p>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-6  col-sm-6">
                                          <label className=""> Tax Type </label>
                                        </div>
                                        <div className="col-md-6  col-sm-6">
                                          <div className="Main-wrapper-circle">
                                            <p className="circle">{user2 ? user2.taxType : ''}</p>
                                          </div>
                                        </div>
                                      </div>

                                      {user2 && user2.taxType == 'Resident Withholding Tax' ? (
                                        <div className="row">
                                          <div className="col-md-6  col-sm-6">
                                            <label className="">
                                              Resident Withholding Tax Rate
                                            </label>
                                          </div>
                                          <div className="col-md-6  col-sm-6">
                                            <div className="rwttax">
                                              <div className="Main-wrapper-circle">
                                                {' '}
                                                <p className="circle">
                                                  {user2 ? user2.rwtValue : ''}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}

                                      {user2 && user2.rwtValue == 'Exempt' ? (
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Proof of Exemption</label>
                                          </div>

                                          <div className="col-md-6 document_download">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => NoteClick(user2.examptProof)}
                                              className="licedocument-downloade"
                                            >
                                              {' '}
                                              <div className="main-downloade-wrapper">
                                                {user2 &&
                                                user2.examptProof !== null &&
                                                user2.examptProof !== '' &&
                                                user2.examptProof &&
                                                'undefined' &&
                                                user2.examptProof !== undefined ? (
                                                  <p className="downloadeMsg">
                                                    <div className="downloads">
                                                      <i className="fa fa-download downloadeStyle"></i>
                                                    </div>{' '}
                                                    View File{' '}
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}

                                      
                                      {user2 && user2.taxType == 'Non Resident Withholding Tax' ? (
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6">
                                            <label>Resident Withholding Tax Exemption</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                            <div className="rwttax">
                                              <div className="Main-wrapper-circle">
                                                <p>
                                                  {user2.taxExemption == 0 ||
                                                  user2.taxExemption == false
                                                    ? 'No'
                                                    : 'Yes'}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}

                                      
                                    </>
                                  </div>
                                  <div className="col-md-1 edit_icon">
                                    {user.profile.includes("UR4") ||
                                    user.profile.includes("UR2") ||
                                    (user1 && user1.applicationStage == 'Active Client') ||
                                    user.profile.includes("UR3")
                                      ? ''
                                      : 
                                        ''}
                                  </div>
                                </div> */}
                                </Tab.Pane>

                                <Tab.Pane eventKey="third">
                                  <EntityDetails data={user2 && user2} />
                                </Tab.Pane>

                                <Tab.Pane eventKey="forth">
                                  <IdVerification2 data={user2 && user2} />
                                </Tab.Pane>

                                <Tab.Pane eventKey="five">
                                  <BankAccount2 data={user2 && user2} />
                                </Tab.Pane>

                                <Tab.Pane eventKey="seven">
                                  <div className="row">
                                    <div className="col-md-11">
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <label>Receive Future Offer Marketing Emails</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <p>
                                            {user1
                                              ? user1.futureInvestementOpportunities == 1
                                                ? 'Yes'
                                                : 'No'
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                      {/* <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Full Name of Authorised Person</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <p>{user2 ? user2.fullLegalName : ''}</p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Date</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <p>{user2 ? user2.submittedAt : ''}</p>
                                      </div>
                                    </div> */}
                                      {/* 
                                    <div className="row signature_img">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Subscription Agreement Signature</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <p>
                                          <img
                                            src={
                                              user2 &&
                                              user2.certificateDetails !== null &&
                                              user2.certificateDetails.subscriptionSign
                                                ? user2.certificateDetails.subscriptionSign
                                                : ''
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Subscription Office Held</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <p>
                                          {user2 &&
                                          user2.certificateDetails !== null &&
                                          user2.certificateDetails.subscriptionOfficeHeld
                                            ? user2.certificateDetails.subscriptionOfficeHeld
                                            : ''}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row signature_img">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Adherence Agreement Signature</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <p>
                                          <img
                                            src={
                                              user2 &&
                                              user2.certificateDetails !== null &&
                                              user2.certificateDetails.adherenceSign
                                                ? user2.certificateDetails.adherenceSign
                                                : ''
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Adherence Office Held</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        {/* <p>{user2 ? user2.certificateDetails.adherenceOfficeHeld : ''}</p> */}
                                      {/* <p>
                                          {user1 &&
                                          user1.certificateDetails !== null &&
                                          user1.certificateDetails.adherenceOfficeHeld
                                            ? user1.certificateDetails.adherenceOfficeHeld
                                            : ''}
                                        </p>
                                      </div>
                                    </div> */}

                                      {(user.profile.includes("UR1") ||
                                        user.profile.includes("UR2") ||
                                        user.profile.includes("UR4") ||
                                        user.profile == 'UR8') &&
                                        user1 &&
                                        (user1.applicationStage == 'Awaiting Payment' ||
                                          user1.applicationStage == 'Active Client' ||
                                          user1.applicationStage == 'Awaiting Final Approval') ? (
                                        <div className="main_wrappr_agrementspdf">
                                          <div className="agrementspdf__wrapper history__pdfr">
                                            <span className="agrementspdf" onClick={agrementpdf}>
                                              <button class="mb-bottom Subscription_button">
                                                Generate Subscription/Adherence PDF Agreements
                                              </button>
                                            </span>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="col-md-1 edit_icon">
                                      <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="parent-modal-title"
                                        aria-describedby="parent-modal-description"
                                      >
                                        <Box className="formPopUp">
                                          <h4>Terms & Agreements Edit</h4>

                                          <div className="edit_form">
                                            <div className="form_input fullinput">
                                              <label>Full Name of Authorised Person</label>
                                              <input
                                                type="text"
                                                placeholder="Full name of authorised person"
                                              />
                                            </div>

                                            <div className="form_input fullinput">
                                              <label>Office Held</label>
                                              <input type="text" placeholder="Enter office held" />
                                            </div>

                                            <div className="form_input fullinput">
                                              <label>Adherence Agreement Proof</label>
                                              <input
                                                type="text"
                                                placeholder="Enter adherence agreement proof"
                                              />
                                            </div>

                                            <div className="form_input fullinput">
                                              <label>Subscription Agreement Proof</label>
                                              <input
                                                type="text"
                                                placeholder="Enter subscription agreement proof"
                                              />
                                            </div>

                                            <div className="submit_btn">
                                              <button>Submit</button>
                                            </div>
                                          </div>
                                        </Box>
                                      </Modal>
                                    </div>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>
                    </>
                  ) : null
                }
              </div >
              <div></div>

              {/* {user1 && user1.isMyFarm == 1 ? (
                ''
              ) : ( */}
              <>
                <div className="app_view_box">
                  <h4>
                    <span className="text_upercas">AML</span> Details
                  </h4>

                  <div className="primary_text">
                    <div>Primary</div>

                    <div className="aml_Details">
                      {user1 && user1.amlStatus == 1 ? (
                        <div className="aml_pass pass__aml-right">AML PASS</div>
                      ) : (
                        <div className="aml_fail pass__aml-right">AML FAIL</div>
                      )}
                    </div>
                  </div>

                  <div className="show_responsive">
                    <div class="accordion" id="accordionExampleAML">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOneAml">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOneAml"
                            aria-expanded="true"
                            aria-controls="collapseOneAml"
                          >
                            Verification Type
                          </button>
                        </h2>
                        <div
                          id="collapseOneAml"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOneAml"
                          data-bs-parent="#accordionExampleAML"
                        >
                          <div class="accordion-body">
                            <div className="row">
                              <div className="col-md-11">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6 ">
                                    <label>Verification Type</label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <p>
                                      {user1
                                        ? user1.identificationType == 'Biometric'
                                          ? 'Biometrics'
                                          : 'Certified Copy of ID'
                                        : ''}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwoAml">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwoAml"
                            aria-expanded="false"
                            aria-controls="collapseTwoAml"
                          >
                            Name & ID
                          </button>
                        </h2>
                        <div
                          id="collapseTwoAml"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwoAml"
                          data-bs-parent="#accordionExampleAML"
                        >
                          <div class="accordion-body">
                            <div className="row">
                              <div className="col-md-11">
                                <>
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>
                                        <span class="text_upercas capitals">AML ID</span>{' '}
                                      </label>
                                    </div>
                                    <div className="col-md-3 col-6">
                                      <div className="aml_Details">
                                        {user1 && user1.idCheck == 1 ? (
                                          <div className="aml_pass">ID AML PASS</div>
                                        ) : (
                                          <div className="aml_fail">ID AML FAIL</div>
                                        )}
                                      </div>
                                    </div>
                                    {user1 && user1.isMyFarm == 1 ? (
                                      ''
                                    ) : (
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>
                                          Type of <span className=""></span>ID
                                        </label>
                                      </div>
                                    )}
                                    <div className="col-md-3 col-sm-6 col-6">
                                      <p>
                                        {user1
                                          ? user1.isMyFarm == 1
                                            ? ''
                                            : user1.documentIdType
                                          : ''}
                                      </p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-6"></div>
                                  </div>
                                </>

                                {user1 ? (
                                  user1.identificationType == 'Biometric' &&
                                    user1.documentIdType == '' ? (
                                    ''
                                  ) : (
                                    <>
                                      {user1.identificationType == 'document' &&
                                        user1.documentIdType != 'NZ Driver Licence' &&
                                        user1.documentIdType != 'NZ Passport' ? (
                                        <>
                                          <>
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>Other Document Type</label>
                                              </div>
                                              <div className="col-md-4 col-sm-6 col-6">
                                                <p>
                                                  {user1
                                                    ? user1.isMyFarm == 1
                                                      ? ''
                                                      : user1.otherDocumentName
                                                    : ''}
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  )
                                ) : (
                                  ''
                                )}

                                {user1 && user1.isMyFarm == 1 ? (
                                  ''
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>First Name</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>
                                          {user1
                                            ? user1.isMyFarm == 1
                                              ? ''
                                              : user1.firstName
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Middle Name</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>
                                          {user1
                                            ? user1.isMyFarm == 1
                                              ? ''
                                              : user1.middleName
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Last Name</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>
                                          {user1 ? (user1.isMyFarm == 1 ? '' : user1.lastName) : ''}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {user1 ? (
                                  user1.documentIdType == 'NZ Driver Licence' ? (
                                    <>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <label>Licence Number</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <p>{user1 ? user1.documentNumber : ''}</p>
                                        </div>
                                      </div>
                                    </>
                                  ) : user1.documentIdType == 'NZ Passport' ? (
                                    <>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <label>Passport Number</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <p>{user1 ? user1.documentNumber : ''}</p>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Document Number</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>{user1 ? user1.documentNumber : ''}</p>
                                      </div>
                                    </div>
                                  )
                                ) : (
                                  ''
                                )}

                                <>
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Expiry Date</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p>{user1 ? user1.documentExpiryDate : ''}</p>
                                    </div>
                                  </div>
                                </>

                                {user1 ? (
                                  user1.documentIdType == 'NZ Driver Licence' ? (
                                    <>
                                      {' '}
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <label>Version Number</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <p>{user1 ? user1.documentVersionNumber : ''}</p>
                                        </div>
                                      </div>
                                    </>
                                  ) : user1.documentIdType == 'NZ Passport' ? (
                                    <>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <label>Country of Issue</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <p>{user1 ? user1.documentCountryOfIssue : ''}</p>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Country of Issue</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>{user1 ? user1.documentCountryOfIssue : ''}</p>
                                      </div>
                                    </div>
                                  )
                                ) : (
                                  ''
                                )}

                                {user1 && user1.isMyFarm == 1 ? (
                                  ''
                                ) : (
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>Date Of Birth</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p>
                                        {user1
                                          ? user1.isMyFarm == 1
                                            ? ''
                                            : user1.dateOfBirth
                                          : ''}
                                      </p>
                                    </div>

                                    {user1 &&
                                      user1.identityProof != null &&
                                      user1 &&
                                      user1.identityProof != '' ? (
                                      <>
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <label>Manual Approval</label>
                                        </div>

                                        {user1 && user1.isMyFarm == 1 ? (
                                          ''
                                        ) : (
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => NoteClick(user1.identityProof)}
                                            >
                                              <div className="main-downloade-wrapper">
                                                {user1 &&
                                                  user1.identityProof !== null &&
                                                  user1.identityProof !== '' &&
                                                  user1.identityProof &&
                                                  'undefined' &&
                                                  user1.identityProof !== undefined ? (
                                                  <p className="downloadeMsg">
                                                    <div className="downloads">
                                                      <i className="fa fa-download downloadeStyle"></i>

                                                      {/* <img src={user1.confirmationDocument} /></i> */}
                                                    </div>{' '}
                                                    <p className="View__file">VIEW FILE</p>{' '}
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </a>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                )}

                                <div className="col-md-1 edit_icon">
                                  {
                                    (user.profile.includes("UR1") ||

                                      user.profile.includes("UR2")) && ((user1 && user1.applicationStage == 'Submitted') &&
                                        (user1 && user1.idCheck != 1)) ? (
                                      <>
                                        <i
                                          onClick={toggle}
                                          title="Edit"
                                          class="fa fa-pencil-square-o hover__Edit"
                                          aria-hidden="true"
                                        ></i>


                                      </>
                                    ) : (
                                      ""
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThreeAml">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThreeAml"
                            aria-expanded="false"
                            aria-controls="collapseThreeAml"
                          >
                            Address
                          </button>
                        </h2>
                        <div
                          id="collapseThreeAml"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThreeAml"
                          data-bs-parent="#accordionExampleAML"
                        >
                          <div class="accordion-body">
                            <div className="row">
                              <div className="col-md-11">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <label>AML Address </label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    {user1 && user1.addressCheck == 1 ? (
                                      <>
                                        <div className="aml_pass">ADDRESS AML PASS</div>
                                      </>
                                    ) : (
                                      <div className="aml_fail">ADDRESS AML FAIL</div>
                                    )}
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <label>Address </label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <p>{user1 ? user1.userResidentialAddress : ''}</p>
                                  </div>

                                  {user1 &&
                                    user1.addressProof != null &&
                                    user1.addressProof != null &&
                                    user1 &&
                                    user1.addressProof != '' ? (
                                    <>
                                      {' '}
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label> Manual Approval</label>
                                      </div>
                                      {user1 && user1.isMyFarm == 1 ? (
                                        ''
                                      ) : (
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => NoteClick(user1.addressDetails)}
                                            className=""
                                          >
                                            <div className="main-downloade-wrapper">
                                              {user1 &&
                                                user1.addressDetails != null &&
                                                user1.addressProof !== '' &&
                                                user1.addressProof &&
                                                user1.addressProof !== null &&
                                                'undefined' &&
                                                user1.addressProof !== undefined ? (
                                                <p className="downloadeMsg">
                                                  <div className="downloads">
                                                    <i className="fa fa-download downloadeStyle"></i>
                                                  </div>{' '}
                                                  <p className="View__file">VIEW FILE</p>{' '}
                                                </p>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </a>

                                          <a href="javascript:void(0)">
                                            {/* <img src={UploadfileData} /> */}
                                          </a>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>

                              <div className="col-md-1 edit_icon">
                                {((user1 && user1.applicationStage == 'Submitted') && (user1 && user1.addressCheck != 1))
                                  &&

                                  (user.profile.includes("UR2") ||
                                    user.profile.includes("UR1")) ? (
                                  <>
                                    <i
                                      onClick={toggle12}
                                      title="Edit"
                                      class="fa fa-pencil-square-o hover__Edit"
                                      aria-hidden="true"
                                    ></i>

                                    {/* <Modal isOpen={modal12} show={show12} className={className}>
                                      <ModalHeader toggle={toggle12}>Edit Address</ModalHeader>
                                      <ModalBody>
                                        <label>Please select AML verification type </label>

                                        <select
                                          id="select_option1"
                                          name="amltype"
                                          className="mb-3"
                                          onChange={(e) => setAmltype12(e.target.value)}
                                        >
                                          <option value="electronics">
                                            Re-Check Electronic Verification
                                          </option>
                                          <option value="viaDoc">Manual Approval</option>
                                        </select>
                                      </ModalBody>
                                    </Modal> */}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFourAml">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourAml"
                            aria-expanded="false"
                            aria-controls="collapseFourAml"
                          >
                            PEP
                          </button>
                        </h2>
                        <div
                          id="collapseFourAml"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingFourAml"
                          data-bs-parent="#accordionExampleAML"
                        >
                          <div class="accordion-body">
                            <div className="row">
                              <div className="col-md-11">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <label>
                                      <span className="text_upercas">PEP</span>{' '}
                                    </label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    {user1 && user1.pepCheck != null ? (
                                      <>
                                        {user1 && user1.pepCheck == 1 ? (
                                          <div className="aml_pass">PEP PASS</div>
                                        ) : (
                                          <div className="aml_fail">PEP FAIL</div>
                                        )}
                                      </>
                                    ) : (
                                      <div className="aml_fail">PEP NOT CHECKED</div>
                                    )}
                                  </div>

                                  {/* {user1 &&
                                user1.isManualPep != 0 &&
                                user1 &&
                                user1.isManualPep != '' ? (
                                  <>
                                    <div className="col-md-6 col-sm-6">
                                      <label>Manual Approval</label>
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )} */}
                                </div>
                              </div>
                              <div className="col-md-1 edit_icon">
                                {(user.profile.includes("UR1") ||
                                  user.profile.includes("UR2")) &&
                                  ((user1 && user1.applicationStage == 'Submitted') &&
                                    (user1 && user1.pepCheck != 1))
                                  ? (
                                    <>
                                      <i
                                        onClick={toggle23}
                                        title="Edit"
                                        class="fa fa-pencil-square-o hover__Edit"
                                        aria-hidden="true"
                                      ></i>
                                      <Modal isOpen={modal23} show={show23} className={className}>
                                        <ModalHeader toggle={toggle23}>
                                          {' '}
                                          PEP - Manually Approve
                                        </ModalHeader>
                                        <ModalBody>
                                          <Form
                                            className="pop_width-100"
                                            name="basic"
                                            initialValues={{ remember: true }}
                                            onFinish={onFinish23}
                                          >
                                            <Form.Item
                                              name="pep"
                                              className="input_check"
                                              valuePropName="checked"
                                              rules={[
                                                {
                                                  validator: (_, value) =>
                                                    value
                                                      ? Promise.resolve()
                                                      : Promise.reject(
                                                        new Error('Please Confirm Manual PEP Pass')
                                                      )
                                                }
                                              ]}
                                            >
                                              <Checkbox>
                                                <>
                                                  I CONFIRM THAT I HAVE MANUALLY REVIEWED THE PEP
                                                  RESULTS AND POSSIBLE MATCHES AND CONFIRM THAT THE
                                                  PEP CHECK HAS ACHIEVED A PASS RESULT.
                                                </>
                                              </Checkbox>
                                            </Form.Item>

                                            <Form.Item>
                                              <Button
                                                className="coffee-color right-side overwrite d-table ml-auto mt-3 Edit_button_style"
                                                type="primary"
                                                htmlType="submit"
                                              >
                                                Confirm Manual PEP Pass
                                              </Button>
                                            </Form.Item>
                                          </Form>
                                        </ModalBody>
                                      </Modal>
                                    </>
                                  ) : (
                                    ""
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {user1 && user1.identificationType == 'document' ? (
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFiveAml">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFiveAml"
                              aria-expanded="false"
                              aria-controls="collapseFiveAml"
                            >
                              Certified Copy of ID
                            </button>
                          </h2>
                          <div
                            id="collapseFiveAml"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFiveAml"
                            data-bs-parent="#accordionExampleAML"
                          >
                            <div class="accordion-body">
                              <div className="row">
                                <div className="col-md-11">
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>
                                        <span className="text_upercas">AML</span>{' '}
                                      </label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p>
                                        {user1 && user1.idCheck === 1 ? (
                                          <div className="aml_pass">ID AML PASS</div>
                                        ) : (
                                          <div className="aml_fail">ID AML FAIL</div>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingSevenAml">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSevenAml"
                              aria-expanded="false"
                              aria-controls="collapseSevenAml"
                            >
                              Biometrics
                            </button>
                          </h2>
                          <div
                            id="collapseSevenAml"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingSevenAml"
                            data-bs-parent="#accordionExampleAML"
                          >
                            <div class="accordion-body">
                              <div className="row">
                                <div className="col-md-11">
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <label>
                                        <span className="text_upercas">AML</span>{' '}
                                      </label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <p>
                                        {user1 && user1.liveCheck === 1 ? (
                                          <div className="aml_pass">
                                            <p className="aml_pass_aml Pass">AML Biometrics Pass</p>
                                          </div>
                                        ) : (
                                          <div className="aml_fail ">
                                            <p className="aml_fail__bio">
                                              <p className="aml_fail__aml ">AML Biometrics</p>
                                              {user1 ? user1.liveCheckStatus : ''}
                                            </p>
                                          </div>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-1 edit_icon">
                                  <>
                                    <i
                                      onClick={toggleBio}
                                      title="Edit"
                                      class="fa fa-pencil-square-o hover__Edit"
                                      aria-hidden="true"
                                    ></i>
                                    <Modal
                                      isOpen={modalBio}
                                      show={showBioSec}
                                      className={className}
                                    >
                                      <ModalHeader toggle={toggleBio}>
                                        {' '}
                                        BIOMETRICS - Manually Approve
                                      </ModalHeader>
                                      <ModalBody>
                                        <Form
                                          className="pop_width-100"
                                          name="basic"
                                          initialValues={{ remember: true }}
                                          onFinish={onFinishBio}
                                        // onFinishFailed={onFinishFailed}
                                        >
                                          <Form.Item
                                            name="pep"
                                            className="input_check"
                                            valuePropName="checked"
                                            rules={[
                                              {
                                                validator: (_, value) =>
                                                  value
                                                    ? Promise.resolve()
                                                    : Promise.reject(
                                                      new Error('Please Confirm Manual PEP Pass')
                                                    )
                                              }
                                            ]}
                                          >
                                            <Checkbox>
                                              <>
                                                I CONFIRM THAT I HAVE MANUALLY REVIEWED THE
                                                BIOMETRICS IMAGES AND RESULTS, AND CONFIRM THAT I AM
                                                SATISFIED THAT THE BIOMETRICS HAS ACHIEVED A PASS
                                                RESULT.
                                              </>
                                            </Checkbox>
                                          </Form.Item>

                                          <Form.Item>
                                            <Button
                                              className="coffee-color right-side overwrite d-table ml-auto mt-3 Edit_button_style"
                                              type="primary"
                                              htmlType="submit"
                                            >
                                              CONFIRM MANUAL BIOMETRIC PASS
                                            </Button>
                                          </Form.Item>
                                        </Form>
                                      </ModalBody>
                                    </Modal>
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSixAml">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSixAml"
                            aria-expanded="false"
                            aria-controls="collapseSixAml"
                          >
                            AML Result Documents
                          </button>
                        </h2>
                        <div
                          id="collapseSixAml"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingSixAml"
                          data-bs-parent="#accordionExampleAML"
                        >
                          <div class="accordion-body">
                            <div className="row">
                              <div className="col-md-11">
                                <div className="row">
                                  <div className="col-md-6  col-sm-6 col-6">
                                    <label>Identity PEP Verification </label>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6">
                                    <div className="document_download">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => NoteClick(user1.identityResult)}
                                        className="licedocument-downloade"
                                      >
                                        {' '}
                                        <div className="main-downloade-wrapper">
                                          {user1 &&
                                            user1.identityResult !== null &&
                                            user1.identityResult !== '' &&
                                            user1.identityResult &&
                                            'undefined' &&
                                            user1.identityResult !== undefined ? (
                                            <p className="downloadeMsg">
                                              <div className="downloads">
                                                <i className="fa fa-download downloadeStyle"></i>
                                              </div>{' '}
                                              VIEW FILE{' '}
                                            </p>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </a>

                                      <span id="name_tag" class="preview2"></span>
                                    </div>
                                  </div>
                                </div>

                                {(user1 && user1.identificationType == 'Biometric') ||
                                  (user1 &&
                                    user1.liveResult !== null &&
                                    user1.liveResult !== '' &&
                                    user1.liveResult &&
                                    'undefined' &&
                                    user1.liveResult !== undefined) ? (
                                  <div className="row">
                                    <div className="col-md-6  col-sm-6 col-6">
                                      <label>
                                        <span className="text_upercas">Biometrics </span>{' '}
                                        Verification
                                      </label>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                      <div className="document_download">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => NoteClick(user1.liveResult)}
                                          className="licedocument-downloade"
                                        >
                                          {' '}
                                          <div className="main-downloade-wrapper">
                                            {user1 &&
                                              user1.liveResult !== null &&
                                              user1.liveResult !== '' &&
                                              user1.liveResult &&
                                              'undefined' &&
                                              user1.liveResult !== undefined ? (
                                              <p className="downloadeMsg">
                                                <div className="downloads">
                                                  <i className="fa fa-download downloadeStyle"></i>
                                                </div>{' '}
                                                VIEW FILE{' '}
                                              </p>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </a>

                                        <span id="name_tag" class="preview2"></span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}

                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6 ">
                                    <label>Address Verification</label>
                                  </div>
                                  {user1 && user1.isMyFarm == 1 ? (
                                    ''
                                  ) : (
                                    <div className="col-md-6 col-sm-6  col-6 document_download">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => NoteClick(user1.addressResult)}
                                        className="licedocument-downloade"
                                      >
                                        {' '}
                                        <div className="main-downloade-wrapper">
                                          {user1 &&
                                            user1.addressResult !== null &&
                                            user1.addressResult !== '' &&
                                            user1.addressResult &&
                                            'undefined' &&
                                            user1.addressResult !== undefined ? (
                                            <p className="downloadeMsg">
                                              <div className="downloads">
                                                <i className="fa fa-download downloadeStyle"></i>
                                              </div>{' '}
                                              VIEW FILE{' '}
                                            </p>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="view_tabs responsive_hide">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                      <Row>
                        <Col md={3} sm={5} className="border-right">
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="first">Verification Type</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">Name & ID</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="third">Address</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="forth">PEP</Nav.Link>
                            </Nav.Item>
                            {user1 && user1.identificationType == 'document' ? (
                              <Nav.Item>
                                <Nav.Link eventKey="five">Certified Copy of ID</Nav.Link>
                              </Nav.Item>
                            ) : (
                              <Nav.Item>
                                {user1 &&
                                  user1.identificationType == null &&
                                  user1.isMyFarm == 1 ? (
                                  ''
                                ) : (
                                  <Nav.Link eventKey="five">Biometrics</Nav.Link>
                                )}
                              </Nav.Item>
                            )}

                            <Nav.Item>
                              <Nav.Link eventKey="six">AML Result Documents</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>

                        <Col md={9} sm={7} className="formPopUp">
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div className="row">
                                <div className="col-md-11">
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                      <label>Verification Type</label>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                      <p>
                                        {user1
                                          ? user1.identificationType == 'Biometric'
                                            ? 'Biometrics'
                                            : 'Certified Copy of ID'
                                          : ''}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            { }
                            <Tab.Pane eventKey="second">
                              <div className="row">
                                <div className="col-md-11">
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>
                                          <span class="text_upercas capitals">AML ID</span>{' '}
                                        </label>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="aml_Details">
                                          {user1 && user1.idCheck == 1 ? (
                                            <div className="aml_pass">ID AML PASS</div>
                                          ) : (
                                            <div className="aml_fail">ID AML FAIL</div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-md-6 col-sm-6">
                                        {/* {user1 && user1.isMyFarm == 0 ? ( */}
                                        <label>Type of ID </label>
                                        {/* )
                                         :
                                          (
                                          ''
                                        )} */}
                                      </div>
                                      <div className="col-md-3 col-sm-6">
                                        <p>
                                          {user1
                                            ? user1.isMyFarm == 1
                                              ? ''
                                              : user1.documentIdType
                                            : ''}
                                        </p>
                                      </div>
                                      <div className="col-md-3 col-sm-6"></div>
                                    </div>
                                  </>

                                  {user1 ? (
                                    user1.identificationType == 'Biometric' &&
                                      user1.documentIdType == '' ? (
                                      ''
                                    ) : (
                                      <>
                                        {user1.identificationType == 'document' &&
                                          user1.documentIdType != 'NZ Driver Licence' &&
                                          user1.documentIdType != 'NZ Passport' ? (
                                          <>
                                            <>
                                              <div className="row">
                                                <div className="col-md-6 col-sm-6">
                                                  <label>Other Document Type</label>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                  <p>{user1 ? user1.otherDocumentName : ''}</p>
                                                </div>
                                              </div>
                                            </>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )
                                  ) : (
                                    ''
                                  )}

                                  {user1 && user1.isMyFarm == 1 ? (
                                    ''
                                  ) : (
                                    <>
                                      {' '}
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>First Name</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <p>
                                            {user1
                                              ? user1.isMyFarm == 1
                                                ? ''
                                                : user1.firstName
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>Middle Name</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <p>
                                            {user1
                                              ? user1.isMyFarm == 1
                                                ? ''
                                                : user1.middleName
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>Last Name</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <p>
                                            {user1
                                              ? user1.isMyFarm == 1
                                                ? ''
                                                : user1.lastName
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {user1 ? (
                                    user1.documentIdType == 'NZ Driver Licence' ? (
                                      <>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6">
                                            <label>Licence Number</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                            <p>{user1 ? user1.documentNumber : ''}</p>
                                          </div>
                                        </div>
                                      </>
                                    ) : user1.documentIdType == 'NZ Passport' ? (
                                      <>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6">
                                            <label>Passport Number</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                            <p>{user1 ? user1.documentNumber : ''}</p>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>Document Number</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <p>
                                            {user1
                                              ? user1.isMyFarm == 1
                                                ? ''
                                                : user1.documentNumber
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  ) : (
                                    ''
                                  )}

                                  <>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Expiry Date</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <p>
                                          {user1
                                            ? user1.isMyFarm == 1
                                              ? ''
                                              : user1.documentExpiryDate
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                  </>

                                  {user1 ? (
                                    user1.documentIdType == 'NZ Driver Licence' ? (
                                      <>
                                        {' '}
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6">
                                            <label>Version Number</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                            <p>{user1 ? user1.documentVersionNumber : ''}</p>
                                          </div>
                                        </div>
                                      </>
                                    ) : user1.documentIdType == 'NZ Passport' ? (
                                      <>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6">
                                            <label>Country of Issue</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                            <p>{user1 ? user1.documentCountryOfIssue : ''}</p>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>Country of Issue</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <p>{user1 ? user1.documentCountryOfIssue : ''}</p>
                                        </div>
                                      </div>
                                    )
                                  ) : (
                                    ''
                                  )}

                                  {user1 && user1.isMyFarm == 1 ? (
                                    ''
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>Date Of Birth</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <p>
                                          {user1
                                            ? user1.isMyFarm == 1
                                              ? ''
                                              : user1.dateOfBirth
                                            : ''}
                                        </p>
                                      </div>

                                      {user1 &&
                                        user1.identityProof != null &&
                                        user1 &&
                                        user1.identityProof != '' ? (
                                        <>
                                          <div className="col-md-6 col-sm-6">
                                            <label>Manual Approval</label>
                                          </div>
                                          {user1.isMyFarm == 1 ? (
                                            ''
                                          ) : (
                                            <div className="col-md-6 col-sm-6">
                                              <a
                                                href="javascript:void(0)"
                                                onClick={() => NoteClick(user1.identityProof)}
                                                className=""
                                              >
                                                <div className="main-downloade-wrapper">
                                                  {user1 &&
                                                    user1.identityProof !== null &&
                                                    user1.identityProof !== '' &&
                                                    user1.identityProof &&
                                                    'undefined' &&
                                                    user1.identityProof !== undefined ? (
                                                    <p className="downloadeMsg">
                                                      <div className="downloads">
                                                        <i className="fa fa-download downloadeStyle"></i>

                                                        {/* <img src={user1.confirmationDocument} /></i> */}
                                                      </div>{' '}
                                                      <p className="View__file">VIEW FILE</p>{' '}
                                                    </p>
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              </a>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-1 edit_icon">
                                  {(user.profile.includes("UR1") ||
                                    user.profile.includes("UR2")) &&
                                    ((user1 && user1.applicationStage == 'Submitted') &&
                                      (user1 && user1.idCheck != 1))
                                    ? (
                                      <>
                                        <i
                                          onClick={toggle}
                                          title="Edit"
                                          class="fa fa-pencil-square-o hover__Edit"
                                          aria-hidden="true"
                                        ></i>

                                        <Modal isOpen={modal} show={show} className={className}>
                                          <ModalHeader toggle={toggle}>
                                            Update Name & ID Details
                                          </ModalHeader>

                                          <ModalBody>
                                            <label>Please Select AML Verification type </label>

                                            <select
                                              id="select_option1"
                                              name="amltype"
                                              className="mb-3"
                                              onChange={(e) => setAmltype(e.target.value)}
                                            >
                                              <option value="electronics">
                                                Re-Check Electronic Verification
                                              </option>
                                              <option value="viaDoc"> Manual Approval</option>
                                            </select>

                                            <Form
                                              className="pop_width-100"
                                              name="basic"
                                              initialValues={{ remember: true }}
                                              onFinish={onFinish}
                                            >
                                              <Row>
                                                <Col xs={12}>
                                                  <div className="custom-select-btn">
                                                    <label>Please select your id type</label>
                                                    <select
                                                      id="select_option1"
                                                      value={idTypee}
                                                      name="identificationType"
                                                      onChange={(e) => setIdTypee(e.target.value)}
                                                      className="mb-3"
                                                    >
                                                      <option value="NZ Driver Licence">
                                                        NZ Driver Licence
                                                      </option>
                                                      <option value="NZ Passport">NZ Passport</option>
                                                      <option value="other">Other</option>
                                                    </select>
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Form.Item
                                                label="First Name:"
                                                name="firstName"
                                                initialValue={user1 ? user1.firstName : ''}
                                                className="quantity"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please enter First Name'
                                                  }
                                                ]}
                                              >
                                                <Input
                                                  placeholder="Enter first name(s)"
                                                  defaultValue={user1 ? user1.firstName : ''}
                                                  maxlength="50"
                                                  className="mb-2 quantity"
                                                  onPaste={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                  }}
                                                  required
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                label="Middle Name:"
                                                name="middleName"
                                                initialValue={user1 ? user1.middleName : ''}
                                              >
                                                <Input
                                                  placeholder="Enter middle name(s)"
                                                  defaultValue={user1 ? user1.middleName : ''}
                                                  maxlength="50"
                                                  className="mb-2 quantity"
                                                  onPaste={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                  }}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                label="Last Name:"
                                                name="lastName"
                                                initialValue={user1 ? user1.lastName : ''}
                                                className="quantity"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please Enter last name'
                                                  }
                                                ]}
                                              >
                                                <Input
                                                  placeholder="Enter last name(s)"
                                                  defaultValue={user1 ? user1.lastName : ''}
                                                  maxlength="50"
                                                  required
                                                  className="mb-2 quantity"
                                                  onPaste={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                  }}
                                                />
                                              </Form.Item>
                                              <Row>
                                                <Col sm={12}>
                                                  <label className="label">Date Of Birth</label>

                                                  <Form.Item
                                                    name="dateOfBirth"
                                                    className="date_width"
                                                  >
                                                    <div class="custom_datepiker">
                                                      <SemanticDatepicker
                                                        id="dateOfBirth"
                                                        format="DD/MM/YYYY"
                                                        className="dateOfBirth mb-2 widthSET"
                                                        allowOnlyNumbers
                                                        onPaste={(e) => {
                                                          e.preventDefault();
                                                          return false;
                                                        }}
                                                        required
                                                        maxDate={
                                                          new Date(moment().subtract(18, 'years'))
                                                        }
                                                        value={
                                                          user1 && user1.dateOfBirth
                                                            ? new Date(
                                                              setDateformateToValid(
                                                                user1.dateOfBirth
                                                              )
                                                            )
                                                            : ''
                                                        }
                                                      />
                                                    </div>
                                                  </Form.Item>
                                                </Col>
                                              </Row>
                                              {idTypee == 'NZ Driver Licence' ? (
                                                <div>
                                                  <Form.Item
                                                    label="Licence Number:"
                                                    name="documentNumber"
                                                    initialValue={user1 ? user1.documentNumber : ''}
                                                    className="numbersOnly"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: 'Please enter licence number'
                                                      }
                                                    ]}
                                                  >
                                                    <Input
                                                      placeholder="Enter licence number"
                                                      defaultValue={user1 ? user1.documentNumber : ''}
                                                      className="mb-2 digitsOnly"
                                                      maxlength="50"
                                                      required
                                                    />
                                                  </Form.Item>

                                                  <Form.Item
                                                    label="Version Number:"
                                                    name="documentVersionNumber"
                                                    initialValue={
                                                      user1 ? user1.documentVersionNumber : ' '
                                                    }
                                                    className="numbers"
                                                  >
                                                    <Input
                                                      placeholder="Enter version number"
                                                      defaultValue={
                                                        user1 ? user1.documentVersionNumber : ''
                                                      }
                                                      className="mb-2 numbers"
                                                      maxlength="3"
                                                      minLength={3}
                                                      required
                                                      maxLength={3}
                                                      onPaste={(e) => {
                                                        e.preventDefault();
                                                        return false;
                                                      }}
                                                    />
                                                  </Form.Item>
                                                </div>
                                              ) : idTypee == 'NZ Passport' ? (
                                                <div>
                                                  <Form.Item
                                                    label="Passport Number:"
                                                    name="documentNumber"
                                                    autoComplete="off"
                                                    initialValue={user1 ? user1.documentNumber : ''}
                                                    className="digitsOnly"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: 'Please Enter passport number'
                                                      }
                                                    ]}
                                                  >
                                                    <Input
                                                      autoComplete="off"
                                                      placeholder="Enter passport number"
                                                      maxlength="50"
                                                      defaultValue={user1 ? user1.documentNumber : ''}
                                                      className="mb-2 digitsOnly"
                                                      onPaste={(e) => {
                                                        e.preventDefault();
                                                        return false;
                                                      }}
                                                    />
                                                  </Form.Item>
                                                </div>
                                              ) : (
                                                <>
                                                  <div>
                                                    <Form.Item
                                                      label="Document Number:"
                                                      name="documentNumber"
                                                      autoComplete="off"
                                                      initialValue={user1 ? user1.documentNumber : ''}
                                                      className="digitsOnly"
                                                    >
                                                      <Input
                                                        autoComplete="off"
                                                        placeholder="Enter document number"
                                                        maxlength="50"
                                                        defaultValue={
                                                          user1 ? user1.documentNumber : ''
                                                        }
                                                        className="mb-2 digitsOnly"
                                                        required
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                  <div>
                                                    <Form.Item
                                                      label="Document Type:"
                                                      name="documentIdType"
                                                      autoComplete="off"
                                                      initialValue={
                                                        user1 ? user1.otherDocumentName : ''
                                                      }
                                                      className="digitsOnly"
                                                    >
                                                      <Input
                                                        autoComplete="off"
                                                        placeholder="Enter Document Type"
                                                        maxlength="50"
                                                        defaultValue={
                                                          user1 ? user1.documentIdType : ''
                                                        }
                                                        className="mb-2 digitsOnly"
                                                        required
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                </>
                                              )}
                                              <Row>
                                                <Col sm={12}>
                                                  <label className="label">Expiry Date</label>

                                                  <Form.Item
                                                    name="documentExpiryDate"
                                                    className="date_width"
                                                  >
                                                    <div class="custom_datepiker">
                                                      <SemanticDatepicker
                                                        id="documentExpiryDate"
                                                        filterDate={(date) => {
                                                          const now = new Date();
                                                          return date >= now;
                                                        }}
                                                        onPaste={(e) => {
                                                          e.preventDefault();
                                                          return false;
                                                        }}
                                                        onKeyPress={(date) => {
                                                          const now = new Date();
                                                          return date >= now;
                                                        }}
                                                        format="DD/MM/YYYY"
                                                        className="documentExpiryDate mb-2 widthSET"
                                                        allowOnlyNumbers
                                                        required
                                                        minDate={now()}
                                                        // onChange={checkDate}
                                                        value={
                                                          user1 && user1.documentExpiryDate
                                                            ? new Date(
                                                              setDateformateToValid(
                                                                user1.documentExpiryDate
                                                              )
                                                            )
                                                            : ''
                                                        }
                                                      />
                                                    </div>
                                                    <span className="error ExpiryDate"></span>
                                                  </Form.Item>
                                                </Col>
                                              </Row>
                                              {amltype === 'viaDoc' ? (
                                                <>
                                                  <Row className="fullinput mt-2 mb-2">
                                                    <Col>
                                                      <Col xs={12}>
                                                        <div class="yes1 attach_aml">
                                                          <a className="click-hover Edit_button_style">
                                                            {' '}
                                                            <span class="btn_upload">
                                                              <input
                                                                type="file"
                                                                id="imag2"
                                                                title=""
                                                                name="image"
                                                                onChange={(e) => {
                                                                  uploadWithJSON(e.target.files[0]);
                                                                }}
                                                              />
                                                            </span>
                                                            Attach ID Proof
                                                          </a>
                                                          <p>{documentFile64}</p>
                                                        </div>
                                                      </Col>
                                                    </Col>
                                                  </Row>
                                                </>
                                              ) : null}
                                              <Form.Item>
                                                <Button
                                                  className="coffee-color right-side overwrite d-table ml-auto mt-3 "
                                                  type="primary"
                                                  htmlType="submit"
                                                >
                                                  {amltype === 'viaDoc'
                                                    ? 'Submit & Manually Approve ID'
                                                    : 'Re-Check Electronic AML'}
                                                </Button>
                                              </Form.Item>
                                            </Form>
                                          </ModalBody>
                                        </Modal>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                </div>
                              </div>
                            </Tab.Pane>
                            {user1 ? (
                              <Tab.Pane eventKey="third">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>AML Address </label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        {user1 && user1.addressCheck == 1 ? (
                                          <>
                                            <div className="aml_pass">ADDRESS AML PASS</div>
                                          </>
                                        ) : (
                                          <div className="aml_fail">ADDRESS AML FAIL</div>
                                        )}
                                      </div>
                                    </div>
                                    {user1 && user1.isMyFarm == 1 ? (
                                      ''
                                    ) : (
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>Address </label>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <p>
                                            {user1
                                              ? user1.isMyFarm == 1
                                                ? ''
                                                : user1.userResidentialAddress
                                              : ''}
                                          </p>
                                        </div>

                                        {user1 &&
                                          user1.addressDetails != null &&
                                          user1.addressProof != null &&
                                          user1 &&
                                          user1.addressProof != '' ? (
                                          <>
                                            {' '}
                                            <div className="col-md-6 col-sm-6">
                                              <label> Manual Approval</label>
                                            </div>
                                            {user1.isMyFarm == 1 ? (
                                              ''
                                            ) : (
                                              <div className="col-md-6 col-sm-6">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() => NoteClick(user1.addressProof)}
                                                  className=""
                                                >
                                                  <div className="main-downloade-wrapper">
                                                    {user1 &&
                                                      user1.addressDetails != null &&
                                                      user1.addressProof !== null &&
                                                      user1.addressProof !== '' &&
                                                      user1.addressProof &&
                                                      'undefined' &&
                                                      user1.addressProof !== undefined ? (
                                                      <p className="downloadeMsg">
                                                        <div className="downloads">
                                                          <i className="fa fa-download downloadeStyle"></i>
                                                        </div>{' '}
                                                        <p className="View__file">VIEW FILE</p>{' '}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                </a>

                                                <a href="javascript:void(0)">
                                                  {/* <img src={UploadfileData} /> */}
                                                </a>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-1 edit_icon">
                                    {(user.profile.includes("UR1") || user.profile.includes("UR2")) &&
                                      ((user1 && user1.applicationStage == 'Submitted') &&
                                        (user1 && user1.addressCheck != 1))
                                      ? (
                                        <>
                                          <i
                                            onClick={toggle12}
                                            title="Edit"
                                            class="fa fa-pencil-square-o hover__Edit"
                                            aria-hidden="true"
                                          ></i>

                                          <Modal isOpen={modal12} show={show12} className={className}>
                                            <ModalHeader toggle={toggle12}>Edit Address</ModalHeader>
                                            <ModalBody>
                                              <label>Please select AML verification type </label>

                                              <select
                                                id="select_option1"
                                                name="amltype"
                                                className="mb-3"
                                                onChange={(e) => setAmltype12(e.target.value)}
                                              >
                                                <option value="electronics">
                                                  Re-Check Electronic Verification
                                                </option>
                                                <option value="viaDoc">Manual Approval</option>
                                              </select>

                                              <Form
                                                className="pop_width-100"
                                                name="basic"
                                                initialValues={{ remember: true }}
                                                onFinish={onFinish12}
                                              // onFinishFailed={onFinishFailed}
                                              >
                                                <Form.Item
                                                  label="First Name:"
                                                  name="firstName"
                                                  initialValue={user1 ? user1.firstName : ''}
                                                  className="quantity"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'Please enter First Name'
                                                    }
                                                  ]}
                                                >
                                                  <Input
                                                    placeholder="Enter first name(s)"
                                                    defaultValue={user1 ? user1.firstName : ''}
                                                    maxlength="50"
                                                    className="mb-2 quantity"
                                                    onPaste={(e) => {
                                                      e.preventDefault();
                                                      return false;
                                                    }}
                                                    required
                                                  />
                                                </Form.Item>
                                                <Form.Item
                                                  label="Middle Name:"
                                                  name="middleName"
                                                  initialValue={user1 ? user1.middleName : ''}
                                                >
                                                  <Input
                                                    placeholder="Enter middle name(s)"
                                                    defaultValue={user1 ? user1.middleName : ''}
                                                    maxlength="50"
                                                    className="mb-2 quantity"
                                                    onPaste={(e) => {
                                                      e.preventDefault();
                                                      return false;
                                                    }}
                                                  />
                                                </Form.Item>
                                                <Form.Item
                                                  label="Last Name:"
                                                  name="lastName"
                                                  initialValue={user1 ? user1.lastName : ''}
                                                  className="quantity"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'Please Enter last name'
                                                    }
                                                  ]}
                                                >
                                                  <Input
                                                    placeholder="Enter last name(s)"
                                                    defaultValue={user1 ? user1.lastName : ''}
                                                    maxlength="50"
                                                    required
                                                    className="mb-2 quantity"
                                                    onPaste={(e) => {
                                                      e.preventDefault();
                                                      return false;
                                                    }}
                                                  />
                                                </Form.Item>

                                                <Row>
                                                  <Col sm={12}>
                                                    <label className="label">Date Of Birth</label>

                                                    <Form.Item
                                                      name="dateOfBirth"
                                                      className="date_width"
                                                    >
                                                      <div class="custom_datepiker">
                                                        <SemanticDatepicker
                                                          id="addateOfBirth"
                                                          format="DD/MM/YYYY"
                                                          className="addateOfBirth mb-2 widthSET"
                                                          allowOnlyNumbers
                                                          onPaste={(e) => {
                                                            e.preventDefault();
                                                            return false;
                                                          }}
                                                          required
                                                          maxDate={
                                                            new Date(moment().subtract(18, 'years'))
                                                          }
                                                          value={
                                                            user1 && user1.dateOfBirth
                                                              ? new Date(
                                                                setDateformateToValid(
                                                                  user1.dateOfBirth
                                                                )
                                                              )
                                                              : ''
                                                          }
                                                        />
                                                      </div>
                                                    </Form.Item>
                                                  </Col>
                                                </Row>

                                                <Form.Item
                                                  className="formtext"
                                                  label="Address:"
                                                  name="addressLine1"
                                                  initialValue={
                                                    user1
                                                      ? user1.addressDetails !== null &&
                                                        user1.addressDetails[0].addressLine1 !==
                                                        null &&
                                                        user1.addressDetails[0].addressLine1 != ' '
                                                        ? user1.addressDetails[0].addressLine1
                                                        : ''
                                                      : ''
                                                  }
                                                // rules={[{ required: true, message: "Please enter First Name" }]}
                                                >
                                                  <Input
                                                    placeholder="Enter AddressLine"
                                                    maxlength="200"
                                                    defaultValue={
                                                      user1
                                                        ? user1.addressDetails !== null &&
                                                          user1.addressDetails[0].addressLine1 !==
                                                          null &&
                                                          user1.addressDetails[0].addressLine1 != ' '
                                                          ? user1.addressDetails[0].addressLine1
                                                          : ''
                                                        : ''
                                                    }
                                                    required
                                                  />
                                                </Form.Item>
                                                <Form.Item
                                                  label="Suburb:"
                                                  name="suburb"
                                                  initialValue={
                                                    user1
                                                      ? user1.addressDetails !== null &&
                                                        user1.addressDetails[0].suburb !== null
                                                        ? user1.addressDetails[0].suburb
                                                        : ''
                                                      : ''
                                                  }
                                                >
                                                  <Input
                                                    placeholder="Enter  Suburb"
                                                    defaultValue={
                                                      user1
                                                        ? user1.addressDetails !== null &&
                                                          user1.addressDetails[0].suburb !== null
                                                          ? user1.addressDetails[0].suburb
                                                          : ''
                                                        : ''
                                                    }
                                                    maxlength="20"
                                                    className="mb-2 numbers"
                                                  // className="numbers"
                                                  />
                                                </Form.Item>
                                                <Form.Item
                                                  className="formtext"
                                                  label="City"
                                                  name="city"
                                                  initialValue={
                                                    user1
                                                      ? user1.addressDetails !== null &&
                                                        user1.addressDetails[0].city !== null
                                                        ? user1.addressDetails[0].city
                                                        : ''
                                                      : ''
                                                  }
                                                >
                                                  <Input
                                                    placeholder="Enter  City"
                                                    maxlength="50"
                                                    defaultValue={
                                                      user1
                                                        ? user1.addressDetails !== null &&
                                                          user1.addressDetails[0].city !== null
                                                          ? user1.addressDetails[0].city
                                                          : ''
                                                        : ''
                                                    }
                                                    required
                                                  />
                                                </Form.Item>

                                                <Form.Item
                                                  className="formtext"
                                                  label="Postal Code "
                                                  name="postalCode"
                                                  initialValue={
                                                    user1
                                                      ? user1.addressDetails !== null &&
                                                        user1.addressDetails[0].postalCode !== null
                                                        ? user1.addressDetails[0].postalCode
                                                        : ''
                                                      : ''
                                                  }
                                                >
                                                  <Input
                                                    // className="mb-3"
                                                    placeholder="Enter  Postal Code"
                                                    maxlength="10"
                                                    defaultValue={
                                                      user1
                                                        ? user1.addressDetails !== null &&
                                                          user1.addressDetails[0].postalCode !== null
                                                          ? user1.addressDetails[0].postalCode
                                                          : ''
                                                        : ''
                                                    }
                                                    className="numbers"
                                                    // maxlength="4"
                                                    required
                                                  />
                                                </Form.Item>
                                                <Form.Item
                                                  label="Country:"
                                                  name="country"
                                                  initialValue={
                                                    user1
                                                      ? user1.addressDetails !== null &&
                                                        user1.addressDetails[0].country !== null
                                                        ? user1.addressDetails[0].country
                                                        : ''
                                                      : ''
                                                  }
                                                >
                                                  <Input
                                                    type="text"
                                                    placeholder="Enter  Country"
                                                    maxlength="20"
                                                    defaultValue={
                                                      user1
                                                        ? user1.addressDetails !== null &&
                                                          user1.addressDetails[0].country !== null
                                                          ? user1.addressDetails[0].country
                                                          : ''
                                                        : ''
                                                    }
                                                    required
                                                    className="mb-2"
                                                  />
                                                </Form.Item>

                                                {amltype12 === 'viaDoc' ? (
                                                  <>
                                                    <Row className="fullinput mt-2 mb-2">
                                                      <Col>
                                                        <Col xs={12}>
                                                          <div class="yes1 attach_aml">
                                                            <a className="click-hover Edit_button_style">
                                                              {' '}
                                                              <span class="btn_upload">
                                                                <input
                                                                  type="file"
                                                                  id="imag2"
                                                                  title=""
                                                                  name="image"
                                                                  // onClick={removeError}
                                                                  onChange={(e) => {
                                                                    uploadWithJSON12(
                                                                      e.target.files[0]
                                                                    );
                                                                    // removeError();
                                                                  }}
                                                                />
                                                                {/* <img src={attch} alt="fileuploade" /> */}
                                                              </span>
                                                              Attach Address Proof
                                                            </a>
                                                            <p>{documentFile6412}</p>
                                                          </div>
                                                        </Col>
                                                      </Col>
                                                    </Row>
                                                  </>
                                                ) : null}

                                                <Form.Item>
                                                  <Button
                                                    className="coffee-color right-side overwrite d-table ml-auto mt-3 text__aml_button"
                                                    type="primary"
                                                    htmlType="submit"
                                                  >
                                                    {amltype12 === 'viaDoc'
                                                      ? 'Submit & Manually Approve Address'
                                                      : 'Re-Check Electronic AML'}
                                                  </Button>
                                                </Form.Item>
                                              </Form>
                                            </ModalBody>
                                          </Modal>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                  </div>
                                </div>
                              </Tab.Pane>
                            ) : (
                              ''
                            )}

                            <Tab.Pane eventKey="forth">
                              <div className="row">
                                <div className="col-md-11">
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                      <label>
                                        <span className="text_upercas">PEP</span>{' '}
                                      </label>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                      {user1 && user1.pepCheck != null ? (
                                        <>
                                          {user1 && user1.pepCheck == 1 ? (
                                            <div className="aml_pass">PEP PASS</div>
                                          ) : (
                                            <div className="aml_fail">PEP FAIL</div>
                                          )}
                                        </>
                                      ) : (
                                        <div className="aml_fail">PEP NOT CHECKED</div>
                                      )}
                                    </div>

                                    {/* {user1 &&
                                  user1.isManualPep != 0 &&
                                  user1 &&
                                  user1.isManualPep != '' ? (
                                    <>
                                      <div className="col-md-6 col-sm-6">
                                        <label>Manual Approval</label>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )} */}
                                  </div>
                                </div>
                                <div className="col-md-1 edit_icon">
                                  {(user.profile.includes("UR1") || user.profile.includes("UR2")) &&
                                    ((user1 && user1.applicationStage == 'Submitted') &&
                                      (user1 && user1.pepCheck != 1))
                                    ? (
                                      <>
                                        <i
                                          onClick={toggle23}
                                          title="Edit"
                                          class="fa fa-pencil-square-o hover__Edit"
                                          aria-hidden="true"
                                        ></i>
                                        <Modal isOpen={modal23} show={show23} className={className}>
                                          <ModalHeader toggle={toggle23}>
                                            {' '}
                                            PEP - Manually Approve
                                          </ModalHeader>
                                          <ModalBody>
                                            <Form
                                              className="pop_width-100"
                                              name="basic"
                                              initialValues={{ remember: true }}
                                              onFinish={onFinish23}
                                            >
                                              <Form.Item
                                                name="pep"
                                                className="input_check"
                                                valuePropName="checked"
                                                rules={[
                                                  {
                                                    validator: (_, value) =>
                                                      value
                                                        ? Promise.resolve()
                                                        : Promise.reject(
                                                          new Error(
                                                            'Please Confirm Manual PEP Pass'
                                                          )
                                                        )
                                                  }
                                                ]}
                                              >
                                                <Checkbox>
                                                  <>
                                                    I CONFIRM THAT I HAVE MANUALLY REVIEWED THE PEP
                                                    RESULTS AND POSSIBLE MATCHES AND CONFIRM THAT THE
                                                    PEP CHECK HAS ACHIEVED A PASS RESULT.
                                                  </>
                                                </Checkbox>
                                              </Form.Item>

                                              <Form.Item>
                                                <Button
                                                  className="coffee-color right-side overwrite d-table ml-auto mt-3"
                                                  type="primary"
                                                  htmlType="submit"
                                                >
                                                  Confirm Manual PEP Pass
                                                </Button>
                                              </Form.Item>
                                            </Form>
                                          </ModalBody>
                                        </Modal>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                </div>
                              </div>
                            </Tab.Pane>

                            {user1 && user1.identificationType == 'document' ? (
                              <Tab.Pane eventKey="five">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>
                                          <span className="text_upercas">AML</span>{' '}
                                        </label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <p>
                                          {user1 && user1.idCheck === 1 ? (
                                            <div className="aml_pass">ID AML PASS</div>
                                          ) : (
                                            <div className="aml_fail">ID AML FAIL</div>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                            ) : (
                              <Tab.Pane eventKey="five">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <label>
                                          <span className="text_upercas">AML</span>{' '}
                                        </label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>
                                          {user1 && user1.liveCheck === 1 ? (
                                            <div className="aml_pass">
                                              <p className="aml_pass_aml Pass">
                                                AML Biometrics Pass
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="aml_fail ">
                                              <p className="aml_fail__bio">
                                                <p className="aml_fail__aml ">AML Biometrics</p>
                                                {user1 ? user1.liveCheckStatus : ''}
                                              </p>
                                            </div>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-1 edit_icon">
                                    {user1 && user1.liveCheck != 1 && user1.applicationStage == 'Submitted' ? (
                                      <>
                                        <i
                                          onClick={toggleBio}
                                          title="Edit"
                                          class="fa fa-pencil-square-o hover__Edit"
                                          aria-hidden="true"
                                        ></i>
                                        <Modal
                                          isOpen={modalBio}
                                          show={showBioSec}
                                          className={className}
                                        >
                                          <ModalHeader toggle={toggleBio}>
                                            {' '}
                                            BIOMETRICS - Manually Approve
                                          </ModalHeader>
                                          <ModalBody>
                                            <Form
                                              className="pop_width-100"
                                              name="basic"
                                              initialValues={{ remember: true }}
                                              onFinish={onFinishBio}
                                            // onFinishFailed={onFinishFailed}
                                            >
                                              <Form.Item
                                                name="pep"
                                                className="input_check"
                                                valuePropName="checked"
                                                rules={[
                                                  {
                                                    validator: (_, value) =>
                                                      value
                                                        ? Promise.resolve()
                                                        : Promise.reject(
                                                          new Error(
                                                            'Please Confirm Manual PEP Pass'
                                                          )
                                                        )
                                                  }
                                                ]}
                                              >
                                                <Checkbox>
                                                  <>
                                                    I CONFIRM THAT I HAVE MANUALLY REVIEWED THE
                                                    BIOMETRICS IMAGES AND RESULTS, AND CONFIRM THAT
                                                    I AM SATISFIED THAT THE BIOMETRICS HAS ACHIEVED
                                                    A PASS RESULT.
                                                  </>
                                                </Checkbox>
                                              </Form.Item>

                                              <Form.Item>
                                                <Button
                                                  className="coffee-color right-side overwrite d-table ml-auto mt-3 "
                                                  type="primary"
                                                  htmlType="submit"
                                                >
                                                  CONFIRM MANUAL BIOMETRIC PASS
                                                </Button>
                                              </Form.Item>
                                            </Form>
                                          </ModalBody>
                                        </Modal>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </Tab.Pane>
                            )}

                            <Tab.Pane eventKey="six">
                              <div className="row">
                                <div className="col-md-11">
                                  <div className="row">
                                    <div className="col-md-6  col-sm-6">
                                      <label>
                                        <span className="text_upercas"> Identity</span> PEP
                                        Verification{' '}
                                      </label>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                      <div className="document_download">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => NoteClick(user1.identityResult)}
                                          className="licedocument-downloade"
                                        >
                                          {' '}
                                          <div className="main-downloade-wrapper">
                                            {user1 &&
                                              user1.identityResult !== null &&
                                              user1.identityResult !== '' &&
                                              user1.identityResult &&
                                              'undefined' &&
                                              user1.identityResult !== undefined ? (
                                              <p className="downloadeMsg">
                                                <div className="downloads">
                                                  <i className="fa fa-download downloadeStyle"></i>
                                                </div>{' '}
                                                VIEW FILE{' '}
                                              </p>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </a>

                                        <span id="name_tag" class="preview2"></span>
                                      </div>
                                    </div>
                                  </div>

                                  {(user1 && user1.identificationType == 'Biometric') ||
                                    (user1 &&
                                      user1.liveResult !== null &&
                                      user1.liveResult !== '' &&
                                      user1.liveResult &&
                                      'undefined' &&
                                      user1.liveResult !== undefined) ? (
                                    <div className="row">
                                      <div className="col-md-6  col-sm-6">
                                        <label>
                                          <span className="text_upercas">Biometrics </span>{' '}
                                          Verification
                                        </label>
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <div className="document_download">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => NoteClick(user1.liveResult)}
                                            className="licedocument-downloade"
                                          >
                                            {' '}
                                            <div className="main-downloade-wrapper">
                                              {user1 &&
                                                user1.liveResult !== null &&
                                                user1.liveResult !== '' &&
                                                user1.liveResult &&
                                                'undefined' &&
                                                user1.liveResult !== undefined ? (
                                                <p className="downloadeMsg">
                                                  <div className="downloads">
                                                    <i className="fa fa-download downloadeStyle"></i>
                                                  </div>{' '}
                                                  VIEW FILE{' '}
                                                </p>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </a>

                                          <span id="name_tag" class="preview2"></span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}

                                  <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                      <label>Address Verification</label>
                                    </div>
                                    {user1 && user1.isMyFarm == 1 ? (
                                      ''
                                    ) : (
                                      <div className="col-md-6 col-sm-6 document_download">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => NoteClick(user1.addressResult)}
                                          className="licedocument-downloade"
                                        >
                                          {' '}
                                          <div className="main-downloade-wrapper">
                                            {user1 &&
                                              user1.addressResult !== null &&
                                              user1.addressResult !== '' &&
                                              user1.addressResult &&
                                              'undefined' &&
                                              user1.addressResult !== undefined ? (
                                              <p className="downloadeMsg">
                                                <div className="downloads">
                                                  <i className="fa fa-download downloadeStyle"></i>
                                                </div>{' '}
                                                VIEW FILE{' '}
                                              </p>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                  <br />
                  {user2 ? (
                    <div className="vgv">
                      <div className="primary_text">
                        <div>Secondary</div>

                        <div className="aml_Details">
                          {user2.overAllAmlStatus == 1 ? (
                            <div className="aml_pass pass__aml-right">AML PASS</div>
                          ) : (
                            <div className="aml_fail pass__aml-right">AML FAIL</div>
                          )}
                        </div>
                      </div>

                      <div className="show_responsive">
                        <div class="accordion" id="accordionExampleAmlSec">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOneAmlSec">
                              <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOneAmlSec"
                                aria-expanded="true"
                                aria-controls="collapseOneAmlSec"
                              >
                                Verification Type
                              </button>
                            </h2>
                            <div
                              id="collapseOneAmlSec"
                              class="accordion-collapse collapse show"
                              aria-labelledby="headingOneAmlSec"
                              data-bs-parent="#accordionExampleAmlSec"
                            >
                              <div class="accordion-body">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <label>Verification Type</label>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-6">
                                        <p>
                                          {user2
                                            ? user2.identificationType == 'Biometric'
                                              ? 'Biometrics'
                                              : 'Certified Copy of ID'
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-1 edit_icon">
                                  {user.profile.includes("UR4") ||
                                  (user2 && user2.applicationStage == 'Active Client') ||
                                  user.profile.includes("UR2") ||
                                  user.profile.includes("UR3") ? (
                                    ''
                                  ) : (
                                    <i
                                      onClick={handleOpen}
                                      title="Edit"
                                      class="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwoAmlSec">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwoAmlSec"
                                aria-expanded="false"
                                aria-controls="collapseTwoAmlSec"
                              >
                                Name & ID
                              </button>
                            </h2>
                            <div
                              id="collapseTwoAmlSec"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingTwoAmlSec"
                              data-bs-parent="#accordionExampleAmlSec"
                            >
                              <div class="accordion-body">
                                <div className="row">
                                  <div className="col-md-11">
                                    <>
                                      <div className="row">
                                        <div className="col-md-6 col-6">
                                          <label>
                                            <span class="text_upercas capitals">AML ID</span>{' '}
                                          </label>
                                        </div>
                                        <div className="col-md-3 col-6">
                                          {user2 && user2.idCheck == 1 ? (
                                            <div className="aml_pass">ID AML PASS</div>
                                          ) : (
                                            <div className="aml_fail">ID AML FAIL</div>
                                          )}
                                        </div>

                                        <div className="col-md-6 col-6">
                                          <label>Type of ID </label>
                                        </div>
                                        <div className="col-md-3 col-6">
                                          <p>{user2 ? user2.documentIdType : ''}</p>
                                        </div>
                                      </div>
                                    </>

                                    {user2 ? (
                                      user2.identificationType == 'Biometric' &&
                                        user2.documentIdType == '' ? (
                                        ''
                                      ) : (
                                        <>
                                          {user2.identificationType == 'document' &&
                                            user2.documentIdType != 'NZ Driver Licence' &&
                                            user2.documentIdType != 'NZ Passport' ? (
                                            <>
                                              <>
                                                <div className="row">
                                                  <div className="col-md-6 col-6">
                                                    <label>Other Document Type</label>
                                                  </div>
                                                  <div className="col-md-4 col-6">
                                                    <p>
                                                      {user2
                                                        ? user2.isMyFarm == 1
                                                          ? ''
                                                          : user2.otherDocumentName
                                                        : ''}
                                                    </p>
                                                  </div>
                                                </div>
                                              </>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      )
                                    ) : (
                                      ''
                                    )}

                                    {user2 && user1.isMyFarm == 1 ? (
                                      ''
                                    ) : (
                                      <>
                                        {' '}
                                        <div className="row">
                                          <div className="col-md-6 col-6">
                                            <label>First Name</label>
                                          </div>
                                          <div className="col-md-6 col-6">
                                            <p>
                                              {user2
                                                ? user2.isMyFarm == 1
                                                  ? ''
                                                  : user2.firstName
                                                : ''}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6 col-6">
                                            <label>Middle Name</label>
                                          </div>
                                          <div className="col-md-6 col-6">
                                            <p>
                                              {user2
                                                ? user2.isMyFarm == 1
                                                  ? ''
                                                  : user2.middleName
                                                : ''}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6 col-6">
                                            <label>Last Name</label>
                                          </div>
                                          <div className="col-md-6 col-6">
                                            <p>
                                              {user2
                                                ? user2.isMyFarm == 1
                                                  ? ''
                                                  : user2.lastName
                                                : ''}
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {user2 ? (
                                      user2.documentIdType == 'NZ Driver Licence' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6 col-6">
                                              <label>Licence Number</label>
                                            </div>
                                            <div className="col-md-6 col-6">
                                              <p>{user2 ? user2.documentNumber : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : user2.documentIdType == 'NZ Passport' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6 col-6">
                                              <label>Passport Number</label>
                                            </div>
                                            <div className="col-md-6 col-6">
                                              <p>{user2 ? user2.documentNumber : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="row">
                                          <div className="col-md-6 col-6">
                                            <label>Document Number</label>
                                          </div>
                                          <div className="col-md-6 col-6">
                                            <p>{user2 ? user2.documentNumber : ''}</p>
                                          </div>
                                        </div>
                                      )
                                    ) : (
                                      ''
                                    )}

                                    <>
                                      <div className="row">
                                        <div className="col-md-6 col-6">
                                          <label>Expiry Date</label>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <p>{user2 ? user2.documentExpiryDate : ''}</p>
                                        </div>
                                      </div>
                                    </>

                                    {user2 ? (
                                      user2.documentIdType == 'NZ Driver Licence' ? (
                                        <>
                                          {' '}
                                          <div className="row">
                                            <div className="col-md-6 col-6">
                                              <label>Version Number</label>
                                            </div>
                                            <div className="col-md-6 col-6">
                                              <p>{user2 ? user2.documentVersionNumber : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : user2.documentIdType == 'NZ Passport' ? (
                                        <>
                                          <div className="row">
                                            <div className="col-md-6 col-6">
                                              <label>Country of Issue</label>
                                            </div>
                                            <div className="col-md-6 col-6">
                                              <p>{user2 ? user2.documentCountryOfIssue : ''}</p>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="row">
                                          <div className="col-md-6 col-6">
                                            <label>Country of Issue</label>
                                          </div>
                                          <div className="col-md-6 col-6">
                                            <p>{user2 ? user2.documentCountryOfIssue : ''}</p>
                                          </div>
                                        </div>
                                      )
                                    ) : (
                                      ''
                                    )}

                                    {user2 && user1.isMyFarm == 1 ? (
                                      ''
                                    ) : (
                                      <div className="row">
                                        <div className="col-md-6 col-6">
                                          <label>Date Of Birth</label>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <p>
                                            {user2
                                              ? user2.isMyFarm == 1
                                                ? ''
                                                : user2.dateOfBirth
                                              : ''}
                                          </p>
                                        </div>

                                        {user2 &&
                                          user2.identityProof != null &&
                                          user2 &&
                                          user2.identityProof != '' ? (
                                          <>
                                            <div className="col-md-6 col-6">
                                              <label>Manual Approval</label>
                                            </div>
                                            {user1.isMyFarm == 1 ? (
                                              ''
                                            ) : (
                                              <div className="col-md-6 col-6">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() => NoteClick(user2.identityProof)}
                                                  className=""
                                                >
                                                  <div className="main-downloade-wrapper">
                                                    {user2 &&
                                                      user2.identityProof !== null &&
                                                      user2.identityProof !== '' &&
                                                      user2.identityProof &&
                                                      'undefined' &&
                                                      user2.identityProof !== undefined ? (
                                                      <p className="downloadeMsg">
                                                        <div className="downloads">
                                                          <i className="fa fa-download downloadeStyle"></i>

                                                          {/* <img src={user1.confirmationDocument} /></i> */}
                                                        </div>{' '}
                                                        <p className="View__file">VIEW FILE</p>{' '}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                </a>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-1 edit_icon">
                                    {
                                      (user.profile.includes("UR1") || user.profile.includes("UR2")) &&
                                        ((user2 && user2.applicationStage == 'Submitted') &&
                                          (user2 && user2.idCheck != 1))
                                        ? (
                                          <>
                                            <i
                                              onClick={toggle1}
                                              title="Edit"
                                              class="fa fa-pencil-square-o hover__Edit"
                                              aria-hidden="true"
                                            ></i>


                                          </>
                                        ) : (
                                          ""
                                        )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThreeAmlSec">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThreeAmlSec"
                                aria-expanded="false"
                                aria-controls="collapseThreeAmlSec"
                              >
                                Address
                              </button>
                            </h2>
                            <div
                              id="collapseThreeAmlSec"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingThreeAmlSec"
                              data-bs-parent="#accordionExampleAmlSec"
                            >
                              <div class="accordion-body">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-6">
                                        <label>AML Address </label>
                                      </div>
                                      <div className="col-md-6 col-6">
                                        {user2 && user2.addressCheck == 1 ? (
                                          <div className="aml_pass">ADDRESS AML PASS</div>
                                        ) : (
                                          <div className="aml_fail">ADDRESS AML FAIL</div>
                                        )}
                                      </div>
                                    </div>
                                    {user2 && user1.isMyFarm == 1 ? (
                                      ''
                                    ) : (
                                      <div className="row">
                                        <div className="col-md-6 col-6">
                                          <label>Address </label>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <p>
                                            {user2 && user2.userResidentialAddress !== null
                                              ? user2.isMyFarm == 1
                                                ? ''
                                                : user2.userResidentialAddress
                                              : ''}
                                            {/* {user2 ? user2.userResidentialAddress : ''} */}
                                          </p>
                                        </div>

                                        {user2 &&
                                          user2.addressDetails != null &&
                                          user2.addressProof != null &&
                                          user2 &&
                                          user2.addressProof != '' ? (
                                          <>
                                            {' '}
                                            <div className="col-md-6 col-6">
                                              <label> Manual Approval</label>
                                            </div>
                                            {user1.isMyFarm == 1 ? (
                                              ''
                                            ) : (
                                              <div className="col-md-6 col-6">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() => NoteClick(user2)}
                                                  className=""
                                                >
                                                  <div className="main-downloade-wrapper">
                                                    {user2 &&
                                                      user2.addressDetails != null &&
                                                      user2.addressProof !== null &&
                                                      user2.addressProof !== '' &&
                                                      user2.addressProof &&
                                                      'undefined' &&
                                                      user2.addressProof !== undefined ? (
                                                      <p className="downloadeMsg">
                                                        <div className="downloads">
                                                          <i className="fa fa-download downloadeStyle"></i>
                                                        </div>{' '}
                                                        <p className="View__file">VIEW FILE</p>{' '}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                </a>

                                                <a href="javascript:void(0)">
                                                  {/* <img src={UploadfileData} /> */}
                                                </a>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-1 edit_icon">
                                    {
                                      ((user2 && user2.applicationStage == 'Submitted') &&
                                        (user2 && user2.addressCheck != 1)) &&
                                        (user.profile.includes("UR1") ||
                                          user.profile.includes("UR2")) ? (
                                        <>
                                          <i
                                            onClick={toggle22}
                                            title="Edit"
                                            class="fa fa-pencil-square-o hover__Edit"
                                            aria-hidden="true"
                                          ></i>

                                        </>
                                      ) : (
                                        ""
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFourAmlSec">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFourAmlSec"
                                aria-expanded="false"
                                aria-controls="collapseFourAmlSec"
                              >
                                PEP
                              </button>
                            </h2>
                            <div
                              id="collapseFourAmlSec"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingFourAmlSec"
                              data-bs-parent="#accordionExampleAmlSec"
                            >
                              <div class="accordion-body">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-6">
                                        <label>
                                          <span className="text_upercas">PEP</span>{' '}
                                        </label>
                                      </div>
                                      <div className="col-md-6 col-6">
                                        {user2 && user2.pepCheck != null ? (
                                          <>
                                            {user2 && user2.pepCheck == 1 ? (
                                              <div className="aml_pass">PEP PASS</div>
                                            ) : (
                                              <div className="aml_fail">PEP FAIL</div>
                                            )}
                                          </>
                                        ) : (
                                          <div className="aml_fail">PEP NOT CHECKED</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-1 edit_icon">
                                    {
                                      ((user2 && user2.applicationStage == 'Submitted') &&
                                        (user2 && user2.pepCheck != 1)) &&
                                        (user.profile.includes("UR1") ||
                                          user.profile.includes("UR2")) ? (
                                        <>
                                          <i
                                            onClick={toggle24}
                                            title="Edit"
                                            class="fa fa-pencil-square-o hover__Edit"
                                            aria-hidden="true"
                                          ></i>
                                          <Modal isOpen={modal24} show={show24} className={className}>
                                            <ModalHeader toggle={toggle24}>
                                              {' '}
                                              PEP - Manually Approve
                                            </ModalHeader>
                                            <ModalBody>
                                              <Form
                                                className="pop_width-100"
                                                name="basic"
                                                initialValues={{ remember: true }}
                                                onFinish={onFinish24}
                                              // onFinishFailed={onFinishFailed}
                                              >
                                                <Form.Item
                                                  name="pep"
                                                  className="input_check"
                                                  valuePropName="checked"
                                                  rules={[
                                                    {
                                                      validator: (_, value) =>
                                                        value
                                                          ? Promise.resolve()
                                                          : Promise.reject(
                                                            new Error(
                                                              'Please Confirm Manual PEP Pass'
                                                            )
                                                          )
                                                    }
                                                  ]}
                                                >
                                                  <Checkbox>
                                                    <>
                                                      I CONFIRM THAT I HAVE MANUALLY REVIEWED THE PEP
                                                      RESULTS AND POSSIBLE MATCHES AND CONFIRM THAT
                                                      THE PEP CHECK HAS ACHIEVED A PASS RESULT.
                                                    </>
                                                  </Checkbox>
                                                </Form.Item>

                                                <Form.Item>
                                                  <Button
                                                    className="coffee-color right-side overwrite d-table ml-auto mt-3"
                                                    type="primary"
                                                    htmlType="submit"
                                                  >
                                                    Confirm Manual PEP Pass
                                                  </Button>
                                                </Form.Item>
                                              </Form>
                                            </ModalBody>
                                          </Modal>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {user2 && user2.identificationType == 'document' ? (
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingThreeAmlSec">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFiveAmlSec"
                                  aria-expanded="false"
                                  aria-controls="collapseFiveAmlSec"
                                >
                                  Certified Copy of ID
                                </button>
                              </h2>
                              <div
                                id="collapseFiveAmlSec"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingFiveAmlSec"
                                data-bs-parent="#accordionExampleAmlSec"
                              >
                                <div class="accordion-body">
                                  <>
                                    <div className="row">
                                      <div className="col-md-11">
                                        <div className="row">
                                          <div className="col-md-6 col-6">
                                            <label>
                                              <span className="text_upercas">AML</span>{' '}
                                            </label>
                                          </div>
                                          <div className="col-md-6 col-6">
                                            <p>
                                              {user2 && user2.idCheck === 1 ? (
                                                <div className="aml_pass">ID AML PASS</div>
                                              ) : (
                                                <div className="aml_fail">ID AML FAIL</div>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingSevenAmlSec">
                                <button
                                  class="accordion-button collapse"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseSevenAmlSec"
                                  aria-expanded="false"
                                  aria-controls="collapseSevenAmlSec"
                                >
                                  Biometrics
                                </button>
                              </h2>
                              <div
                                id="collapseSevenAmlSec"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingSevenAmlSec"
                                data-bs-parent="#accordionExampleAmlSec"
                              >
                                <div class="accordion-body">
                                  <div className="row">
                                    <div className="col-md-11">
                                      <div className="row">
                                        <div className="col-md-6 col-6">
                                          <label>
                                            <span className="text_upercas">AML</span>{' '}
                                          </label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <p>
                                            {user2 && user2.liveCheck === 1 ? (
                                              <div className="aml_pass">
                                                <p className="aml_pass_aml Pass">
                                                  AML Biometrics Pass
                                                </p>
                                              </div>
                                            ) : (
                                              <div className="aml_fail ">
                                                <p className="aml_fail__bio">
                                                  <p className="aml_fail__aml ">AML Biometrics</p>
                                                  {user2 ? user2.liveCheckStatus : ''}
                                                </p>
                                              </div>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-1 edit_icon">
                                      {user2 && user2 && user2.liveCheck === 1 ? (
                                        ''
                                      ) : (
                                        <>
                                          <i
                                            onClick={toggleBioSec}
                                            title="Edit"
                                            class="fa fa-pencil-square-o hover__Edit"
                                            aria-hidden="true"
                                          ></i>
                                          <Modal
                                            isOpen={modalBioSec}
                                            show={showBioSec}
                                            className={className}
                                          >
                                            <ModalHeader toggle={toggleBioSec}>
                                              {' '}
                                              BIOMETRICS - Manually Approve
                                            </ModalHeader>
                                            <ModalBody>
                                              <Form
                                                className="pop_width-100"
                                                name="basic"
                                                initialValues={{ remember: true }}
                                                onFinish={onFinishBioSec}
                                              // onFinishFailed={onFinishFailed}
                                              >
                                                <Form.Item
                                                  name="pep"
                                                  className="input_check"
                                                  valuePropName="checked"
                                                  rules={[
                                                    {
                                                      validator: (_, value) =>
                                                        value
                                                          ? Promise.resolve()
                                                          : Promise.reject(
                                                            new Error(
                                                              'Please Confirm Manual PEP Pass'
                                                            )
                                                          )
                                                    }
                                                  ]}
                                                >
                                                  <Checkbox>
                                                    <>
                                                      I CONFIRM THAT I HAVE MANUALLY REVIEWED THE
                                                      BIOMETRICS IMAGES AND RESULTS, AND CONFIRM
                                                      THAT I AM SATISFIED THAT THE BIOMETRICS HAS
                                                      ACHIEVED A PASS RESULT.
                                                    </>
                                                  </Checkbox>
                                                </Form.Item>

                                                <Form.Item>
                                                  <Button
                                                    className="coffee-color right-side overwrite d-table ml-auto mt-3"
                                                    type="primary"
                                                    htmlType="submit"
                                                  >
                                                    CONFIRM MANUAL BIOMETRIC PASS
                                                  </Button>
                                                </Form.Item>
                                              </Form>
                                            </ModalBody>
                                          </Modal>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingSixAmlSec">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSixAmlSec"
                                aria-expanded="false"
                                aria-controls="collapseSixAmlSec"
                              >
                                AML Result Documents
                              </button>
                            </h2>
                            <div
                              id="collapseSixAmlSec"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingSixAmlSec"
                              data-bs-parent="#accordionExampleAmlSec"
                            >
                              <div class="accordion-body">
                                <div className="row">
                                  <div className="col-md-11">
                                    <div className="row">
                                      <div className="col-md-6 col-6">
                                        <label>Identity PEP Verification </label>
                                      </div>
                                      <div className="col-md-6 col-6">
                                        <div className="document_download">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => NoteClick(user2.identityResult)}
                                            className="licedocument-downloade"
                                          >
                                            {' '}
                                            <div className="main-downloade-wrapper">
                                              {user2 &&
                                                user2.identityResult !== null &&
                                                user2.identityResult !== '' &&
                                                user2.identityResult &&
                                                'undefined' &&
                                                user2.identityResult !== undefined ? (
                                                <p className="downloadeMsg">
                                                  <div className="downloads">
                                                    <i className="fa fa-download downloadeStyle"></i>
                                                  </div>{' '}
                                                  VIEW FILE{' '}
                                                </p>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </a>
                                          <span id="name_tag" class="preview2"></span>
                                        </div>
                                      </div>
                                    </div>

                                    {(user2 && user2.identificationType == 'Biometric') ||
                                      (user2 &&
                                        user2.liveResult !== null &&
                                        user2.liveResult !== '' &&
                                        user2.liveResult &&
                                        'undefined' &&
                                        user2.liveResult !== undefined) ? (
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label>
                                            <span className="text_upercas">Biometrics</span>{' '}
                                            Verification
                                          </label>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <div className="document_download">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => NoteClick(user2.liveResult)}
                                              className="licedocument-downloade"
                                            >
                                              {' '}
                                              <div className="main-downloade-wrapper">
                                                {user2 &&
                                                  user2.liveResult !== null &&
                                                  user2.liveResult !== '' &&
                                                  user2.liveResult &&
                                                  'undefined' &&
                                                  user2.liveResult !== undefined ? (
                                                  <p className="downloadeMsg">
                                                    <div className="downloads">
                                                      <i className="fa fa-download downloadeStyle"></i>
                                                    </div>{' '}
                                                    VIEW FILE{' '}
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </a>

                                            <span id="name_tag" class="preview2"></span>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ''
                                    )}

                                    <div className="row">
                                      <div className="col-md-6 col-6">
                                        <label>Address Verification </label>
                                      </div>
                                      {user1 && user1.isMyFarm == 1 ? (
                                        ''
                                      ) : (
                                        <div className="col-md-6 document_download col-6">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => NoteClick(user2.addressResult)}
                                            className="licedocument-downloade"
                                          >
                                            {' '}
                                            <div className="main-downloade-wrapper">
                                              {user2 &&
                                                user2.addressResult !== null &&
                                                user2.addressResult !== '' &&
                                                user2.addressResult &&
                                                'undefined' &&
                                                user2.addressResult !== undefined ? (
                                                <p className="downloadeMsg">
                                                  <div className="downloads">
                                                    <i className="fa fa-download downloadeStyle"></i>
                                                  </div>{' '}
                                                  VIEW FILE{' '}
                                                </p>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="view_tabs responsive_hide">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                          <Row>
                            <Col sm={3} className="border-right">
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="first">Verification Type</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="second">Name & ID</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="third">Address</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="forth">PEP</Nav.Link>
                                </Nav.Item>
                                {user2 && user2.identificationType == 'document' ? (
                                  <Nav.Item>
                                    <Nav.Link eventKey="five">Certified Copy of ID</Nav.Link>
                                  </Nav.Item>
                                ) : (
                                  <Nav.Item>
                                    {user2 &&
                                      user2.identificationType == null &&
                                      user1.isMyFarm == 1 ? (
                                      ''
                                    ) : (
                                      <Nav.Link eventKey="five">Biometrics</Nav.Link>
                                    )}
                                  </Nav.Item>
                                )}
                                <Nav.Item>
                                  <Nav.Link eventKey="six">AML Result Documents</Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Col>

                            <Col sm={9} className="formPopUp">
                              <Tab.Content>
                                <Tab.Pane eventKey="first">
                                  <div className="row">
                                    <div className="col-md-11">
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <label>Verification Type</label>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                          <p>
                                            {user2
                                              ? user2.identificationType == 'Biometric'
                                                ? 'Biometrics'
                                                : 'Certified Copy of ID'
                                              : ''}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-1 edit_icon">
                                  {user.profile.includes("UR4") ||
                                  (user2 && user2.applicationStage == 'Active Client') ||
                                  user.profile.includes("UR2") ||
                                  user.profile.includes("UR3") ? (
                                    ''
                                  ) : (
                                    <i
                                      onClick={handleOpen}
                                      title="Edit"
                                      class="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </div> */}
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="second">
                                  <div className="row">
                                    <div className="col-md-11">
                                      <>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <label>
                                              <span class="text_upercas capitals">AML ID</span>{' '}
                                            </label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6">
                                            {user2 && user2.idCheck == 1 ? (
                                              <div className="aml_pass">ID AML PASS</div>
                                            ) : (
                                              <div className="aml_fail">ID AML FAIL</div>
                                            )}
                                          </div>

                                          <div className="col-md-6 col-sm-6 col-6">
                                            <label>Type of ID </label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <p>{user2 ? user2.documentIdType : ''}</p>
                                          </div>
                                        </div>
                                      </>

                                      {user2 ? (
                                        user2.identificationType == 'Biometric' &&
                                          user2.documentIdType == '' ? (
                                          ''
                                        ) : (
                                          <>
                                            {user2.identificationType == 'document' &&
                                              user2.documentIdType != 'NZ Driver Licence' &&
                                              user2.documentIdType != 'NZ Passport' ? (
                                              <>
                                                <>
                                                  <div className="row">
                                                    <div className="col-md-6 col-sm-6 col-6">
                                                      <label>Other Document Type</label>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-6">
                                                      <p>{user2 ? user2.otherDocumentName : ''}</p>
                                                    </div>
                                                  </div>
                                                </>
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        )
                                      ) : (
                                        ''
                                      )}

                                      {user2 && user1.isMyFarm == 1 ? (
                                        ''
                                      ) : (
                                        <>
                                          {' '}
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>First Name</label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <p>
                                                {user2
                                                  ? user2.isMyFarm == 1
                                                    ? ''
                                                    : user2.firstName
                                                  : ''}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>Middle Name</label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <p>
                                                {user2
                                                  ? user2.isMyFarm == 1
                                                    ? ''
                                                    : user2.middleName
                                                  : ''}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>Last Name</label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <p>
                                                {user2
                                                  ? user2.isMyFarm == 1
                                                    ? ''
                                                    : user2.lastName
                                                  : ''}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      {user2 ? (
                                        user2.documentIdType == 'NZ Driver Licence' ? (
                                          <>
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>Licence Number</label>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <p>{user2 ? user2.documentNumber : ''}</p>
                                              </div>
                                            </div>
                                          </>
                                        ) : user2.documentIdType == 'NZ Passport' ? (
                                          <>
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>Passport Number</label>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <p>{user2 ? user2.documentNumber : ''}</p>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>Document Number</label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <p>{user2 ? user2.documentNumber : ''}</p>
                                            </div>
                                          </div>
                                        )
                                      ) : (
                                        ''
                                      )}

                                      <>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <label>Expiry Date</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <p>{user2 ? user2.documentExpiryDate : ''}</p>
                                          </div>
                                        </div>
                                      </>

                                      {user2 ? (
                                        user2.documentIdType == 'NZ Driver Licence' ? (
                                          <>
                                            {' '}
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>Version Number</label>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <p>{user2 ? user2.documentVersionNumber : ''}</p>
                                              </div>
                                            </div>
                                          </>
                                        ) : user2.documentIdType == 'NZ Passport' ? (
                                          <>
                                            <div className="row">
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>Country of Issue</label>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <p>{user2 ? user2.documentCountryOfIssue : ''}</p>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <label>Country of Issue</label>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                              <p>{user2 ? user2.documentCountryOfIssue : ''}</p>
                                            </div>
                                          </div>
                                        )
                                      ) : (
                                        ''
                                      )}

                                      {user2 && user1.isMyFarm == 1 ? (
                                        ''
                                      ) : (
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <label>Date Of Birth</label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <p>
                                              {user2
                                                ? user2.isMyFarm == 1
                                                  ? ''
                                                  : user2.dateOfBirth
                                                : ''}
                                            </p>
                                          </div>

                                          {user2 &&
                                            user2.identityProof != null &&
                                            user2 &&
                                            user2.identityProof != '' ? (
                                            <>
                                              <div className="col-md-6 col-sm-6 col-6">
                                                <label>Manual Approval</label>
                                              </div>
                                              {user1.isMyFarm == 1 ? (
                                                ''
                                              ) : (
                                                <div className="col-md-6 col-sm-6 col-6">
                                                  <a
                                                    href="javascript:void(0)"
                                                    onClick={() => NoteClick(user2.identityProof)}
                                                    className=""
                                                  >
                                                    <div className="main-downloade-wrapper">
                                                      {user2 &&
                                                        user2.identityProof !== null &&
                                                        user2.identityProof !== '' &&
                                                        user2.identityProof &&
                                                        'undefined' &&
                                                        user2.identityProof !== undefined ? (
                                                        <p className="downloadeMsg">
                                                          <div className="downloads">
                                                            <i className="fa fa-download downloadeStyle"></i>

                                                            {/* <img src={user1.confirmationDocument} /></i> */}
                                                          </div>{' '}
                                                          <p className="View__file">VIEW FILE</p>{' '}
                                                        </p>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </div>
                                                  </a>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-1 edit_icon">
                                      {(user.profile.includes("UR2") || user.profile.includes("UR1")) &&

                                        ((user2 && user2.applicationStage == 'Submitted') &&
                                          (user2 && user2.idCheck != 1))
                                        ? (
                                          <>
                                            <i
                                              onClick={toggle1}
                                              title="Edit"
                                              class="fa fa-pencil-square-o hover__Edit"
                                              aria-hidden="true"
                                            ></i>

                                            <Modal isOpen={modal1} show={show1} className={className}>
                                              <ModalHeader toggle={toggle1}>
                                                Update Name & ID Details
                                              </ModalHeader>

                                              <ModalBody>
                                                <label>Please select AML verification type </label>

                                                <select
                                                  id="select_option1"
                                                  name="amltype1"
                                                  className="mb-3"
                                                  onChange={(e) => setAmltype1(e.target.value)}
                                                >
                                                  <option value="electronics">
                                                    Re-Check Electronic Verification
                                                  </option>
                                                  <option value="viaDoc">Manual Approval</option>
                                                </select>

                                                <Form
                                                  className="pop_width-100"
                                                  name="basic"
                                                  initialValues={{ remember: true }}
                                                  onFinish={onFinish1}
                                                // onFinishFailed={onFinishFailed}
                                                >
                                                  <Row>
                                                    <Col xs={12}>
                                                      <div className="custom-select-btn">
                                                        {/* <p>Which type of ID are you providing</p> */}
                                                        <label>Please select your id type</label>
                                                        <select
                                                          id="select_option1"
                                                          value={idTypee2}
                                                          name="identificationType"
                                                          onChange={(e) =>
                                                            setIdTypee2(e.target.value)
                                                          }
                                                          className="mb-3"
                                                        >
                                                          <option value="NZ Driver Licence">
                                                            NZ Driver Licence
                                                          </option>
                                                          <option value="NZ Passport">
                                                            NZ Passport
                                                          </option>
                                                          <option value="other">Other</option>
                                                        </select>
                                                      </div>
                                                    </Col>
                                                  </Row>

                                                  <Form.Item
                                                    label="First Name:"
                                                    name="firstName"
                                                    initialValue={user2 ? user2.firstName : ''}
                                                    className="quantity"
                                                  >
                                                    <Input
                                                      placeholder="Enter first name(s)"
                                                      defaultValue={user2 ? user2.firstName : ''}
                                                      maxlength="50"
                                                      className="mb-2 quantity"
                                                      onPaste={(e) => {
                                                        e.preventDefault();
                                                        return false;
                                                      }}
                                                      required
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    label="Middle Name:"
                                                    name="middleName"
                                                    initialValue={user2 ? user2.middleName : ''}
                                                  >
                                                    <Input
                                                      placeholder="Enter middle name(s)"
                                                      defaultValue={user2 ? user2.middleName : ''}
                                                      maxlength="50"
                                                      className="mb-2 quantity"
                                                      onPaste={(e) => {
                                                        e.preventDefault();
                                                        return false;
                                                      }}
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    label="Last Name:"
                                                    name="lastName"
                                                    initialValue={user2 ? user2.lastName : ''}
                                                    className="quantity"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: 'Please Enter last name'
                                                      }
                                                    ]}
                                                  >
                                                    <Input
                                                      placeholder="Enter last name(s)"
                                                      defaultValue={user2 ? user2.lastName : ''}
                                                      maxlength="50"
                                                      required
                                                      className="mb-2 quantity"
                                                      onPaste={(e) => {
                                                        e.preventDefault();
                                                        return false;
                                                      }}
                                                    // required
                                                    />
                                                  </Form.Item>
                                                  <Row>
                                                    <Col sm={12}>
                                                      <label className="label">Date Of Birth</label>

                                                      <Form.Item
                                                        name="dateOfBirth"
                                                        className="date_width"
                                                      >
                                                        <div class="custom_datepiker">
                                                          <SemanticDatepicker
                                                            id="dateOfBirth"
                                                            format="DD/MM/YYYY"
                                                            // filterDate={(date) => {
                                                            //   const now = new Date(
                                                            //     moment().subtract(18, 'years')
                                                            //   );
                                                            //   return date >= now;
                                                            // }}
                                                            onPaste={(e) => {
                                                              e.preventDefault();
                                                              return false;
                                                            }}
                                                            className="dateOfBirth1 mb-2 widthSET"
                                                            allowOnlyNumbers
                                                            required
                                                            maxDate={
                                                              new Date(moment().subtract(18, 'years'))
                                                            }
                                                            value={
                                                              user2 && user2.dateOfBirth
                                                                ? new Date(
                                                                  setDateformateToValid(
                                                                    user2.dateOfBirth
                                                                  )
                                                                )
                                                                : ''
                                                            }
                                                          />
                                                        </div>
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>

                                                  {idTypee2 == 'NZ Driver Licence' ? (
                                                    <div>
                                                      <Form.Item
                                                        label="Licence Number:"
                                                        name="documentNumber"
                                                        initialValue={
                                                          user2 ? user2.documentNumber : ''
                                                        }
                                                        className="numbersOnly"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'Please enter licence number'
                                                          }
                                                        ]}
                                                      >
                                                        <Input
                                                          placeholder="Enter licence number"
                                                          defaultValue={
                                                            user2 ? user2.documentNumber : ''
                                                          }
                                                          className="mb-2 digitsOnly"
                                                          maxlength="50"
                                                          required
                                                        />
                                                      </Form.Item>

                                                      <Form.Item
                                                        label="Version Number:"
                                                        name="documentVersionNumber"
                                                        initialValue={
                                                          user2 ? user2.documentVersionNumber : ' '
                                                        }
                                                        className="numbers"
                                                      >
                                                        <Input
                                                          placeholder="Enter version number"
                                                          defaultValue={
                                                            user2 ? user2.documentVersionNumber : ''
                                                          }
                                                          className="mb-2 numbers"
                                                          maxlength="3"
                                                          minLength={3}
                                                          required
                                                          maxLength={3}
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  ) : idTypee2 == 'NZ Passport' ? (
                                                    <div>
                                                      <Form.Item
                                                        label="Passport Number:"
                                                        name="documentNumber"
                                                        autoComplete="off"
                                                        initialValue={
                                                          user2 ? user2.documentNumber : ''
                                                        }
                                                        className="digitsOnly"
                                                      >
                                                        <Input
                                                          autoComplete="off"
                                                          placeholder="Enter passport number"
                                                          maxlength="50"
                                                          defaultValue={
                                                            user2 ? user2.documentNumber : ''
                                                          }
                                                          className="mb-2 digitsOnly"
                                                          required
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  ) : (
                                                    <>
                                                      <div>
                                                        <Form.Item
                                                          label="Document Number:"
                                                          name="documentNumber"
                                                          autoComplete="off"
                                                          initialValue={
                                                            user2 ? user2.documentNumber : ''
                                                          }
                                                          className="digitsOnly"
                                                        >
                                                          <Input
                                                            autoComplete="off"
                                                            placeholder="Enter document number"
                                                            maxlength="50"
                                                            defaultValue={
                                                              user2 ? user2.documentNumber : ''
                                                            }
                                                            className="mb-2 digitsOnly"
                                                            required
                                                          />
                                                        </Form.Item>
                                                      </div>
                                                      <div>
                                                        <Form.Item
                                                          label="Document Type:"
                                                          name="documentIdType"
                                                          autoComplete="off"
                                                          initialValue={
                                                            user2 ? user2.otherDocumentName : ''
                                                          }
                                                          className="digitsOnly"
                                                        >
                                                          <Input
                                                            autoComplete="off"
                                                            placeholder="Enter Document Type"
                                                            maxlength="50"
                                                            defaultValue={
                                                              user2 ? user2.documentIdType : ''
                                                            }
                                                            className="mb-2 digitsOnly"
                                                            required
                                                          />
                                                        </Form.Item>
                                                      </div>
                                                    </>
                                                  )}
                                                  <Row>
                                                    <Col sm={12}>
                                                      <label className="label">Expiry Date</label>

                                                      <Form.Item
                                                        name="documentExpiryDate"
                                                        className="date_width"
                                                      >
                                                        <div class="custom_datepiker">
                                                          <SemanticDatepicker
                                                            id="documentExpiryDate"
                                                            format="DD/MM/YYYY"
                                                            filterDate={(date) => {
                                                              const now = new Date();

                                                              return date >= now;
                                                            }}
                                                            onPaste={(e) => {
                                                              e.preventDefault();
                                                              return false;
                                                            }}
                                                            onKeyPress={matchesYearAndMonth}
                                                            className="documentExpiryDate1 mb-2 widthSET"
                                                            allowOnlyNumbers
                                                            required
                                                            minDate={now()}
                                                            // onChange={checkDate}
                                                            value={
                                                              user2 && user2.documentExpiryDate
                                                                ? new Date(
                                                                  setDateformateToValid(
                                                                    user2.documentExpiryDate
                                                                  )
                                                                )
                                                                : ''
                                                            }
                                                          />
                                                        </div>
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>
                                                  {amltype1 === 'viaDoc' ? (
                                                    <>
                                                      <Row className="fullinput mt-2 mb-2">
                                                        <Col>
                                                          <Col xs={12}>
                                                            <div class="yes1 attach_aml">
                                                              <a className="click-hover Edit_button_style">
                                                                {' '}
                                                                <span class="btn_upload">
                                                                  <input
                                                                    type="file"
                                                                    id="imag2"
                                                                    title=""
                                                                    name="image"
                                                                    // onClick={removeError}
                                                                    onChange={(e) => {
                                                                      uploadWithJSON1(
                                                                        e.target.files[0]
                                                                      );
                                                                      // removeError();
                                                                    }}
                                                                  />
                                                                  {/* <img src={attch} alt="fileuploade" /> */}
                                                                </span>
                                                                Attach ID Proof
                                                              </a>
                                                              <p>{documentFile641}</p>
                                                            </div>
                                                          </Col>
                                                        </Col>
                                                      </Row>
                                                    </>
                                                  ) : null}

                                                  <Form.Item>
                                                    <Button
                                                      className="coffee-color right-side overwrite d-table ml-auto mt-3 "
                                                      type="primary"
                                                      htmlType="submit"
                                                    >
                                                      {amltype1 === 'viaDoc'
                                                        ? 'Submit & Manually Approve ID'
                                                        : 'Re-Check Electronic AML'}
                                                    </Button>
                                                  </Form.Item>
                                                </Form>
                                              </ModalBody>
                                            </Modal>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="third">
                                  <div className="row">
                                    <div className="col-md-11">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label>AML Address </label>
                                        </div>
                                        <div className="col-md-6">
                                          {user2 && user2.addressCheck == 1 ? (
                                            <div className="aml_pass">ADDRESS AML PASS</div>
                                          ) : (
                                            <div className="aml_fail">ADDRESS AML FAIL</div>
                                          )}
                                        </div>
                                      </div>
                                      {user2 && user2.isMyFarm == 1 ? (
                                        ''
                                      ) : (
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Address </label>
                                          </div>
                                          <div className="col-md-6">
                                            <p>
                                              {user2 && user2.userResidentialAddress !== null
                                                ? user2.isMyFarm == 1
                                                  ? ''
                                                  : user2.userResidentialAddress
                                                : ''}
                                              {/* {user2 ? user2.userResidentialAddress : ''} */}
                                            </p>
                                          </div>

                                          {user2 &&
                                            user2.addressDetails != null &&
                                            user2.addressProof != null &&
                                            user2 &&
                                            user2.addressProof != '' ? (
                                            <>
                                              {' '}
                                              <div className="col-md-6">
                                                <label> Manual Approval</label>
                                              </div>
                                              {user1.isMyFarm == 1 ? (
                                                ''
                                              ) : (
                                                <div className="col-md-6">
                                                  <a
                                                    href="javascript:void(0)"
                                                    onClick={() => NoteClick(user2.addressProof)}
                                                  >
                                                    <div className="main-downloade-wrapper">
                                                      {user2 &&
                                                        user2.addressDetails != null &&
                                                        user2.addressProof !== null &&
                                                        user2.addressProof !== '' &&
                                                        user2.addressProof &&
                                                        'undefined' &&
                                                        user2.addressProof !== undefined ? (
                                                        <p className="downloadeMsg">
                                                          <div className="downloads">
                                                            <i className="fa fa-download downloadeStyle"></i>
                                                          </div>{' '}
                                                          <p className="View__file">VIEW FILE</p>{' '}
                                                        </p>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </div>
                                                  </a>

                                                  <a href="javascript:void(0)">
                                                    {/* <img src={UploadfileData} /> */}
                                                  </a>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-1 edit_icon">
                                      {
                                        ((user2 && user2.applicationStage == 'Submitted') &&
                                          (user2 && user2.addressCheck != 1)) &&
                                          (user.profile.includes("UR1") ||
                                            user.profile.includes("UR2")) ? (
                                          <>
                                            <i
                                              onClick={toggle22}
                                              title="Edit"
                                              class="fa fa-pencil-square-o hover__Edit"
                                              aria-hidden="true"
                                            ></i>
                                            <Modal
                                              isOpen={modal22}
                                              show={show22}
                                              className={className}
                                            >
                                              <ModalHeader toggle={toggle22}>
                                                Edit Address
                                              </ModalHeader>
                                              <ModalBody>
                                                <label>Please select AML verification type </label>

                                                <select
                                                  id="select_option1"
                                                  name="amltype"
                                                  className="mb-3"
                                                  onChange={(e) => setAmltype22(e.target.value)}
                                                >
                                                  <option value="electronics">
                                                    Re-Check Electronic Verification
                                                  </option>
                                                  <option value="viaDoc">Manual Approval</option>
                                                </select>

                                                <Form
                                                  className="pop_width-100"
                                                  name="basic"
                                                  initialValues={{ remember: true }}
                                                  onFinish={onFinish22}
                                                // onFinishFailed={onFinishFailed}
                                                >
                                                  <Form.Item
                                                    label="First Name:"
                                                    name="firstName"
                                                    initialValue={user2 ? user2.firstName : ''}
                                                    className="quantity"
                                                  >
                                                    <Input
                                                      placeholder="Enter first name(s)"
                                                      defaultValue={user2 ? user2.firstName : ''}
                                                      maxlength="50"
                                                      className="mb-2 quantity"
                                                      onPaste={(e) => {
                                                        e.preventDefault();
                                                        return false;
                                                      }}
                                                      required
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    label="Middle Name:"
                                                    name="middleName"
                                                    initialValue={user2 ? user2.middleName : ''}
                                                  >
                                                    <Input
                                                      placeholder="Enter middle name(s)"
                                                      defaultValue={user2 ? user2.middleName : ''}
                                                      maxlength="50"
                                                      className="mb-2 quantity"
                                                      onPaste={(e) => {
                                                        e.preventDefault();
                                                        return false;
                                                      }}
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    label="Last Name:"
                                                    name="lastName"
                                                    initialValue={user2 ? user2.lastName : ''}
                                                    className="quantity"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: 'Please Enter last name'
                                                      }
                                                    ]}
                                                  >
                                                    <Input
                                                      placeholder="Enter last name(s)"
                                                      defaultValue={user2 ? user2.lastName : ''}
                                                      maxlength="50"
                                                      required
                                                      onPaste={(e) => {
                                                        e.preventDefault();
                                                        return false;
                                                      }}
                                                      className="mb-2 quantity"
                                                    />
                                                  </Form.Item>

                                                  <Row>
                                                    <Col sm={12}>
                                                      <label className="label">Date Of Birth</label>

                                                      <Form.Item
                                                        name="dateOfBirth"
                                                        className="date_width"
                                                      >
                                                        <div class="custom_datepiker">
                                                          <SemanticDatepicker
                                                            id="addateOfBirth"
                                                            format="DD/MM/YYYY"
                                                            // filterDate={(date) => {
                                                            //   const now = new Date(
                                                            //     moment().subtract(18, 'years')
                                                            //   );
                                                            //   return date >= now;
                                                            // }}
                                                            onPaste={(e) => {
                                                              e.preventDefault();
                                                              return false;
                                                            }}
                                                            className="addateOfBirth1 mb-2 widthSET"
                                                            allowOnlyNumbers
                                                            required
                                                            maxDate={
                                                              new Date(moment().subtract(18, 'years'))
                                                            }
                                                            value={
                                                              user2 && user2.dateOfBirth
                                                                ? new Date(
                                                                  setDateformateToValid(
                                                                    user2.dateOfBirth
                                                                  )
                                                                )
                                                                : ''
                                                            }
                                                          />
                                                        </div>
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>

                                                  <Form.Item
                                                    className="formtext"
                                                    label="Address:"
                                                    name="addressLine1"
                                                    initialValue={
                                                      user2
                                                        ? user2.addressDetails !== null &&
                                                          user2.addressDetails[0].addressLine1 !==
                                                          null &&
                                                          user2.addressDetails[0].addressLine1 != ' '
                                                          ? user2.addressDetails[0].addressLine1
                                                          : ''
                                                        : ''
                                                    }
                                                  // rules={[{ required: true, message: "Please enter First Name" }]}
                                                  >
                                                    <Input
                                                      // className="mb-3"
                                                      placeholder="Enter AddressLine"
                                                      maxlength="200"
                                                      defaultValue={
                                                        user2
                                                          ? user2.addressDetails !== null &&
                                                            user2.addressDetails[0].addressLine1 !==
                                                            null &&
                                                            user2.addressDetails[0].addressLine1 !=
                                                            ' '
                                                            ? user2.addressDetails[0].addressLine1
                                                            : ''
                                                          : ''
                                                      }
                                                      required
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    label="Suburb:"
                                                    name="suburb"
                                                    initialValue={
                                                      user2
                                                        ? user2.addressDetails !== null &&
                                                          user2.addressDetails[0].suburb !== null
                                                          ? user2.addressDetails[0].suburb
                                                          : ''
                                                        : ''
                                                    }
                                                  >
                                                    <Input
                                                      // type="number"
                                                      placeholder="Enter  Suburb"
                                                      defaultValue={
                                                        user2
                                                          ? user2.addressDetails !== null &&
                                                            user2.addressDetails[0].suburb !== null
                                                            ? user2.addressDetails[0].suburb
                                                            : ''
                                                          : ''
                                                      }
                                                      maxlength="20"
                                                      className="mb-2 numbers"
                                                    // className="numbers"
                                                    />
                                                  </Form.Item>

                                                  <Form.Item
                                                    className="formtext"
                                                    label="City"
                                                    name="city"
                                                    initialValue={
                                                      user2
                                                        ? user2.addressDetails !== null &&
                                                          user2.addressDetails[0].city !== null
                                                          ? user2.addressDetails[0].city
                                                          : ''
                                                        : ''
                                                    }
                                                  // rules={[{ required: true, message: 'Please Enter last name' }]}
                                                  >
                                                    <Input
                                                      // className="mb-3"
                                                      placeholder="Enter City"
                                                      maxlength="50"
                                                      defaultValue={
                                                        user2
                                                          ? user2.addressDetails !== null &&
                                                            user2.addressDetails[0].city !== null
                                                            ? user2.addressDetails[0].city
                                                            : ''
                                                          : ''
                                                      }
                                                      required
                                                    />
                                                  </Form.Item>

                                                  <Form.Item
                                                    className="formtext"
                                                    label="Postal Code "
                                                    name="postalCode"
                                                    initialValue={
                                                      user2
                                                        ? user2.addressDetails !== null &&
                                                          user2.addressDetails[0].postalCode !== null
                                                          ? user2.addressDetails[0].postalCode
                                                          : ''
                                                        : ''
                                                    }
                                                  // className="mb-3"
                                                  // rules={[{ required: true, message: 'Please Enter last name' }]}
                                                  >
                                                    <Input
                                                      // className="mb-3"
                                                      placeholder="Enter Postal Code"
                                                      maxlength="10"
                                                      defaultValue={
                                                        user2
                                                          ? user2.addressDetails !== null &&
                                                            user2.addressDetails[0].postalCode !==
                                                            null
                                                            ? user2.addressDetails[0].postalCode
                                                            : ''
                                                          : ''
                                                      }
                                                      className="numbers"
                                                      // maxlength="4"
                                                      required
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    label="Country:"
                                                    name="country"
                                                    initialValue={
                                                      user2
                                                        ? user2.addressDetails !== null &&
                                                          user2.addressDetails[0].country !== null
                                                          ? user2.addressDetails[0].country
                                                          : ''
                                                        : ''
                                                    }
                                                  >
                                                    <Input
                                                      type="text"
                                                      placeholder="Enter  Country"
                                                      maxlength="20"
                                                      defaultValue={
                                                        user2
                                                          ? user2.addressDetails !== null &&
                                                            user2.addressDetails[0].country !== null
                                                            ? user2.addressDetails[0].country
                                                            : ''
                                                          : ''
                                                      }
                                                      required
                                                      className="mb-2"
                                                    />
                                                  </Form.Item>

                                                  {amltype22 === 'viaDoc' ? (
                                                    <>
                                                      <Row className="fullinput mt-2 mb-2">
                                                        <Col>
                                                          <Col xs={12}>
                                                            <div class="yes1 attach_aml">
                                                              <a className="click-hover Edit_button_style">
                                                                {' '}
                                                                <span class="btn_upload">
                                                                  <input
                                                                    type="file"
                                                                    id="imag2"
                                                                    title=""
                                                                    name="image"
                                                                    // onClick={removeError}
                                                                    onChange={(e) => {
                                                                      uploadWithJSON22(
                                                                        e.target.files[0]
                                                                      );
                                                                      // removeError();
                                                                    }}
                                                                  />
                                                                  {/* <img src={attch} alt="fileuploade" /> */}
                                                                </span>
                                                                Attach Address Proof
                                                              </a>
                                                              <p>{UploadfileData}</p>
                                                            </div>
                                                          </Col>
                                                        </Col>
                                                      </Row>
                                                    </>
                                                  ) : null}

                                                  <Form.Item>
                                                    <Button
                                                      className="coffee-color right-side overwrite d-table ml-auto mt-3  text__aml_button"
                                                      type="primary"
                                                      htmlType="submit"
                                                    >
                                                      {amltype22 === 'viaDoc'
                                                        ? 'Submit & Manually Approve Address'
                                                        : 'Re-Check Electronic AML'}
                                                    </Button>
                                                  </Form.Item>
                                                </Form>
                                              </ModalBody>
                                            </Modal>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="forth">
                                  <div className="row">
                                    <div className="col-md-11">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label>
                                            <span className="text_upercas">PEP</span>{' '}
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          {user2 && user2.pepCheck != null ? (
                                            <>
                                              {user2 && user2.pepCheck == 1 ? (
                                                <div className="aml_pass">PEP PASS</div>
                                              ) : (
                                                <div className="aml_fail">PEP FAIL</div>
                                              )}
                                            </>
                                          ) : (
                                            <div className="aml_fail">PEP NOT CHECKED</div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-1 edit_icon">
                                      {
                                        ((user2 && user2.applicationStage == 'Submitted') &&
                                          (user2 && user2.pepCheck != 1)) &&
                                          (user.profile.includes("UR1") ||
                                            user.profile.includes("UR2")) ? (
                                          <>
                                            <i
                                              onClick={toggle24}
                                              title="Edit"
                                              class="fa fa-pencil-square-o hover__Edit"
                                              aria-hidden="true"
                                            ></i>
                                            <Modal
                                              isOpen={modal24}
                                              show={show24}
                                              className={className}
                                            >
                                              <ModalHeader toggle={toggle24}>
                                                {' '}
                                                PEP - Manually Approve
                                              </ModalHeader>
                                              <ModalBody>
                                                <Form
                                                  className="pop_width-100"
                                                  name="basic"
                                                  initialValues={{ remember: true }}
                                                  onFinish={onFinish24}
                                                // onFinishFailed={onFinishFailed}
                                                >
                                                  <Form.Item
                                                    name="pep"
                                                    className="input_check"
                                                    valuePropName="checked"
                                                    rules={[
                                                      {
                                                        validator: (_, value) =>
                                                          value
                                                            ? Promise.resolve()
                                                            : Promise.reject(
                                                              new Error(
                                                                'Please Confirm Manual PEP Pass'
                                                              )
                                                            )
                                                      }
                                                    ]}
                                                  >
                                                    <Checkbox>
                                                      <>
                                                        I CONFIRM THAT I HAVE MANUALLY REVIEWED THE
                                                        PEP RESULTS AND POSSIBLE MATCHES AND CONFIRM
                                                        THAT THE PEP CHECK HAS ACHIEVED A PASS RESULT.
                                                      </>
                                                    </Checkbox>
                                                  </Form.Item>

                                                  <Form.Item>
                                                    <Button
                                                      className="coffee-color right-side overwrite d-table ml-auto mt-3"
                                                      type="primary"
                                                      htmlType="submit"
                                                    >
                                                      Confirm Manual PEP Pass
                                                    </Button>
                                                  </Form.Item>
                                                </Form>
                                              </ModalBody>
                                            </Modal>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                    </div>
                                  </div>
                                </Tab.Pane>

                                {user2 && user2.identificationType == 'document' ? (
                                  <Tab.Pane eventKey="five">
                                    <div className="row">
                                      <div className="col-md-11">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>
                                              <span className="text_upercas">AML</span>{' '}
                                            </label>
                                          </div>
                                          <div className="col-md-6">
                                            <p>
                                              {user2 && user2.idCheck === 1 ? (
                                                <div className="aml_pass">ID AML PASS</div>
                                              ) : (
                                                <div className="aml_fail">ID AML FAIL</div>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                ) : (
                                  <Tab.Pane eventKey="five">
                                    <div className="row">
                                      <div className="col-md-11">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>
                                              <span className="text_upercas">AML</span>{' '}
                                            </label>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-6">
                                            <p>
                                              {user2 && user2.liveCheck === 1 ? (
                                                <div className="aml_pass">
                                                  <p className="aml_pass_aml Pass">
                                                    AML Biometrics Pass
                                                  </p>
                                                </div>
                                              ) : (
                                                <div className="aml_fail ">
                                                  <p className="aml_fail__bio">
                                                    <p className="aml_fail__aml ">AML Biometrics</p>
                                                    {user2 ? user2.liveCheckStatus : ''}
                                                  </p>
                                                </div>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-1 edit_icon">
                                        {user2 && user2.liveCheck != 1 && user2.applicationStage == 'Submitted' ? (
                                          <>
                                            <i
                                              onClick={toggleBioSec}
                                              title="Edit"
                                              class="fa fa-pencil-square-o hover__Edit"
                                              aria-hidden="true"
                                            ></i>
                                            <Modal
                                              isOpen={modalBioSec}
                                              show={showBioSec}
                                              className={className}
                                            >
                                              <ModalHeader toggle={toggleBioSec}>
                                                {' '}
                                                BIOMETRICS - Manually Approve
                                              </ModalHeader>
                                              <ModalBody>
                                                <Form
                                                  className="pop_width-100"
                                                  name="basic"
                                                  initialValues={{ remember: true }}
                                                  onFinish={onFinishBioSec}
                                                // onFinishFailed={onFinishFailed}
                                                >
                                                  <Form.Item
                                                    name="pep"
                                                    className="input_check"
                                                    valuePropName="checked"
                                                    rules={[
                                                      {
                                                        validator: (_, value) =>
                                                          value
                                                            ? Promise.resolve()
                                                            : Promise.reject(
                                                              new Error(
                                                                'Please Confirm Manual PEP Pass'
                                                              )
                                                            )
                                                      }
                                                    ]}
                                                  >
                                                    <Checkbox>
                                                      <>
                                                        I CONFIRM THAT I HAVE MANUALLY REVIEWED THE
                                                        BIOMETRICS IMAGES AND RESULTS, AND CONFIRM
                                                        THAT I AM SATISFIED THAT THE BIOMETRICS HAS
                                                        ACHIEVED A PASS RESULT.
                                                      </>
                                                    </Checkbox>
                                                  </Form.Item>

                                                  <Form.Item>
                                                    <Button
                                                      className="coffee-color right-side overwrite d-table ml-auto mt-3 "
                                                      type="primary"
                                                      htmlType="submit"
                                                    >
                                                      CONFIRM MANUAL BIOMETRIC PASS
                                                    </Button>
                                                  </Form.Item>
                                                </Form>
                                              </ModalBody>
                                            </Modal>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                )}
                                <Tab.Pane eventKey="six">
                                  <div className="row">
                                    <div className="col-md-11">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label>
                                            <span className="text_upercas">Identity</span> PEP
                                            Verification{' '}
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="document_download">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => NoteClick(user2.identityResult)}
                                              className="licedocument-downloade"
                                            >
                                              {' '}
                                              <div className="main-downloade-wrapper">
                                                {user2 &&
                                                  user2.identityResult !== null &&
                                                  user2.identityResult !== '' &&
                                                  user2.identityResult &&
                                                  'undefined' &&
                                                  user2.identityResult !== undefined ? (
                                                  <p className="downloadeMsg">
                                                    <div className="downloads">
                                                      <i className="fa fa-download downloadeStyle"></i>
                                                    </div>{' '}
                                                    VIEW FILE{' '}
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </a>

                                            <span id="name_tag" class="preview2"></span>
                                            {/* <input
                                          type="button"
                                          id="removeImage2"
                                          value="x"
                                          class="btn-rmv2"
                                        /> */}
                                          </div>
                                        </div>
                                      </div>

                                      {(user2 && user2.identificationType == 'Biometric') ||
                                        (user2 &&
                                          user2.liveResult !== null &&
                                          user2.liveResult !== '' &&
                                          user2.liveResult &&
                                          'undefined' &&
                                          user2.liveResult !== undefined) ? (
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>
                                              <span className="text_upercas">Biometrics</span>{' '}
                                              Verification
                                            </label>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="document_download">
                                              {/* <a href={ user2 ?user2.documentProof:""} className="licedocument-downloade">
                                 <div className="main-downloade-wrapper">
                                   <div className="downloads">
                                     <i className="fa fa-download downloadeStyle"></i>
                                   </div>
                                   <p className="downloadeMsg">VIEW FILE</p>
                                 </div>
                               </a> */}

                                              <a
                                                href="javascript:void(0)"
                                                onClick={() => NoteClick(user2.liveResult)}
                                                className="licedocument-downloade"
                                              >
                                                {' '}
                                                <div className="main-downloade-wrapper">
                                                  {user2 &&
                                                    user2.liveResult !== null &&
                                                    user2.liveResult !== '' &&
                                                    user2.liveResult &&
                                                    'undefined' &&
                                                    user2.liveResult !== undefined ? (
                                                    <p className="downloadeMsg">
                                                      <div className="downloads">
                                                        <i className="fa fa-download downloadeStyle"></i>
                                                      </div>{' '}
                                                      VIEW FILE{' '}
                                                    </p>
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              </a>

                                              <span id="name_tag" class="preview2"></span>
                                              {/* <input
                                            type="button"
                                            id="removeImage2"
                                            value="x"
                                            class="btn-rmv2"
                                          /> */}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}

                                      <div className="row">
                                        <div className="col-md-6">
                                          <label>Address Verification </label>
                                        </div>
                                        {user1 && user1.isMyFarm == 1 ? (
                                          ''
                                        ) : (
                                          <div className="col-md-6 document_download">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => NoteClick(user2.addressResult)}
                                              className="licedocument-downloade"
                                            >
                                              {' '}
                                              <div className="main-downloade-wrapper">
                                                {user2 &&
                                                  user2.addressResult !== null &&
                                                  user2.addressResult !== '' &&
                                                  user2.addressResult &&
                                                  'undefined' &&
                                                  user2.addressResult !== undefined ? (
                                                  <p className="downloadeMsg">
                                                    <div className="downloads">
                                                      <i className="fa fa-download downloadeStyle"></i>
                                                    </div>{' '}
                                                    VIEW FILE{' '}
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {/* <div className="col-md-1 edit_icon">
                                  {user.profile.includes("UR4") ||
                                  user.profile.includes("UR2") ||
                                  (user2 && user2.applicationStage == 'Active Client') ||
                                  user.profile.includes("UR3") ? (
                                    ''
                                  ) : (
                                    <i
                                      onClick={handleOpen}
                                      title="Edit"
                                      class="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </div> */}
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
              {/* )} */}

              <div className="app_view_box">
                <h4>Notes</h4>

                <div className="note new">
                  {noteLoder === true ? (
                    <div className="note-main-loader note-load noteload_loader">
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <div className="notes">
                      <textarea
                        className="overflow"
                        rows="8"
                        placeholder="Type to add a note..."
                        value={noteText}
                        onChange={(e) => {
                          setNoteText(e.target.value);
                        }}
                      ></textarea>

                      <div className="row justify__align">
                        <div className="col-md-5 col-6">
                          <div class="yes1_attach__notes">
                            <button className="notes_buttonAttach">
                              <a className="click-hover Edit_button_style_note">
                                {' '}
                                <span class="btn_upload_note">
                                  <input
                                    type="file"
                                    id="imag2_note"
                                    title=""
                                    name="image_note"
                                    onChange={(e) => {
                                      uploadWithJSON_attach__Notess(e.target.files[0]);
                                    }}
                                  />
                                </span>
                                ATTACH
                                {/* Attach */}
                              </a>
                            </button>
                            <p>{documentFileAttachNotes64}</p>
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="note-footer flex-note">
                            <button className="save" onClick={handleSaveClick}>
                              SAVE
                            </button>
                          </div>
                          <p></p>
                        </div>
                      </div>
                      <div className="Attch_Note"></div>
                    </div>
                  )}

                  {notess != undefined &&
                    notess != 'undefined' &&
                    notess != null &&
                    notess != '' &&
                    notess
                      .slice(0)
                      .reverse()
                      .map((user1) => {
                        return (
                          <div className="notes">
                            <div className="note_date">
                              <p className="note_date_right">{user1.createdAt}</p>
                              <p>{user1.note}</p>
                            </div>
                            <div className="posta">
                              <p>Notes By </p>
                              <p>{user1.createdBy}</p>
                            </div>

                            <div className="main_attach">
                              {user1.noteDocuments && user1.noteDocuments.length > 0
                                ? user1.noteDocuments.map((notesDoc) => {
                                  return (
                                    <div className="flex__msg">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => NoteClick(notesDoc.documentUrl)}
                                      >
                                        <div className="downloadeMsg">
                                          <div className="downloads">
                                            <i className="fa fa-download downloadeStyle"></i>
                                          </div>{' '}
                                          {/* <p className="View__file"></p>{' '} */}
                                        </div>
                                      </a>
                                    </div>
                                  );
                                })
                                : ''}
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div >
          </div >
        </div >
      )
      }
    </>
  );
};

export default ApplicationDetailsIndi;
