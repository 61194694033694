/*eslint-disable eqeqeq*/

import { atom, selector } from 'recoil';

export const userapiState = atom({
    key: 'userState',
    default: 0
});

export const userdataState = selector({
    key: 'userdataState',
    get: ({ get }) => {
        const text = get(userapiState);
        return text;
    }
});
export const allusersdata = atom({
    key: 'alluserdatak',
    default: {
        email: '',
        phone: ''
    }
});
export const syndicatevalue = atom({
    key: 'sync',
    default: 0
});
export const removeUsers = atom({
    key: 'rId',
    default: 0
});

// export const setallusers = selector({
//   key: 'alldatak',
//   get: ({ get }) => {
//     const text = get(allusersdata);
//     return text;
//   }
// });
