

// complete task
import moment from 'moment';
// import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { mainUrl } from 'src/auth/Configuration';
import { useMemo, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';


const ExpirePopup = (props) => {
    let invest = props.invest;

    let datess = props.expirationDates;
    const [year, month, day] = datess.split(" ")[0].split("-")
    var newyear = parseInt(year, 10) + 2;

    var expiration = newyear + "-" + month + "-" + day
    var current_date = moment().format("YYYY-MM-DD");
    var days = moment(expiration).diff(current_date, 'days');
    const [modal, setModal] = useState(true);
    const show = () => setModal(!modal);
    const toggle = () => setModal(!modal);
    const popupOf = () => {
        setModal(false);
    }
    sessionStorage.setItem('isIdPop', false);
    // useMemo(() => Ff(), [])
    const SendUrl2 = () => {
        window.open(`${mainUrl}?name=true&update=${invest}&type=wholesale`)

    }
    return (

        <>
            {(days < 5 && days > 0) ?

                <>
                    <Modal isOpen={modal} show={show} >
                        <ModalHeader className='dashbord_popup' toggle={toggle}>
                            {/* Update Email */}
                        </ModalHeader>

                        <ModalBody className='email_updated_pro email_updated'>
                            Some of the compliance information we hold about your account is due to expire in {days} day (this may be your photo ID, or your Wholesale Certification status).

                            You can update this now to ensure that your account continues to operate smoothly. Alternatively, once this information has expired, you will be required to update the relevant information before you can make any new investments.
                        </ModalBody>
                        <ModalFooter className='dashbord_popup_footer'>
                            {/* <Link to={"/profilePage"}><button>Update Now</button></Link> */}
                            <button onClick={() => {
                                SendUrl2();
                            }}>Update Now</button>
                            <button onClick={popupOf}>Remind Me Again Later</button>
                        </ModalFooter>
                    </Modal>
                </> : ""
            }
            {(days < 0) ?
                <>
                    <Modal isOpen={modal} show={show}  >
                        <ModalHeader className='dashbord_popup' toggle={toggle}>
                            {/* Update Email */}
                        </ModalHeader>

                        <ModalBody className='email_updated_pro email_updated text-center pt-0'>
                            Your Wholesale Certificate has Expired.

                        </ModalBody>
                        <ModalFooter className='dashbord_popup_footer'>
                            {/* <Link to={"/profilePage"}>Update Now</Link> */}
                            <button onClick={() => {
                                SendUrl2();
                            }}>Update Now</button>
                            {/* <button onClick={popupOf}>Remind Me Again Later</button> */}
                        </ModalFooter>
                    </Modal>
                </> : ""
            }

        </>
    )
}

export default ExpirePopup;