// /*eslint-disable eqeqeq*/

// import { Link } from 'react-router-dom';

// const NewResearch = () => {
//   return (
//     <>
//       <div className="right_content">

//         <div className="heading__top ht_heading">
//           <h2>
//             <Link to="/dashbord2">
//               <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
//             </Link>
//             News & Research
//           </h2>
//         </div>

//         <div className="form app_view_form"></div>
//       </div>
//     </>
//   );
// };
// export default NewResearch;

/*eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { audience, urlapi } from '../../auth/Configuration';

import { Button } from 'react-bootstrap';

import Swal from 'sweetalert2';

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import $ from 'jquery';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Input } from 'antd';

const NewsResearch = () => {
  const [options, setOptions] = useState([]);

  const { user, getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState();
  const [loader, setLoader] = useState();

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          setToken(accessidToken.__raw);
          let token = accessidToken.__raw;
          //    formData.token =
          //    sessionStorage.setItem("token",formData.token )

          application(token);
        } else {
        }
      }
    } catch (e) { }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);

  const application = async (token) => {
    setLoader(true);

    if (isAuthenticated) {
      // if(token !== "" && token !== null && token !== "undefined" && token !== undefined){
      // duval/admin/clients
      const applicationdata12 = await axios
        .get(`${urlapi}/investor/news/research`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          if (res.status == 200) {
            setLoader(false);

            setOptions(res.data);
            // setUsers(res.data);
          } else {
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
          Swal.fire('', 'Please try after sometimes!', '');
        });
      // }
    }
  };
  // alert(user.profile)

  $.fn.dataTable.ext.errMode = 'none';

  $(document).ready(function () {
    $('#History_table').DataTable({
      scrollX: true,
      sScrollXInner: '100%'
    });
  });
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const show = () => setModal(!modal);






  return (
    <>

      {loader === true ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="right_content">
          <div className="heading__top ht_heading d-flex align-items-center justify-content-between mb-2">
            <h2>
              {/* <Link to="/client">
                <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
              </Link> */}
              News & Research
            </h2>

          </div>
          <div className=" ht_heading11">
            <div className=" table-responsive">
              <table class="table table-striped new_search_table">
                <tbody>
                  {options &&
                    options.map((deta) => {
                      return (
                        <tr>
                          <td className="text-start  new_search_table_text ">
                            {deta.doucmentName}
                          </td>
                          <td className="new_search_table_buttons text-end">
                            <a
                              className="document_btn d-inline-block"
                              target={'_blank'}
                              // deepcode ignore DOMXSS: <please specify a reason of ignoring this>
                              href={`${deta.doucmentUrl}`} rel="noreferrer"
                            >
                              <button>
                                <i class="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </a>

                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default NewsResearch;

