import React from 'react';

import { Link } from 'react-router-dom';

import TableData from './TableData';

const AllApplication = () => {
  return (
    <>
      <div className="right_content">
        <div className="heading__top ht_heading">
          <h2>
            {/* <Link to="/Dashboard1">
              <i class="fa fa-arrow-left ICON__Arrow" aria-hidden="true"></i>
            </Link> */}
            APPLICATIONS
          </h2>
        </div>
        <div className="form director">
          <div className="myfarm_com" key={1}>
            {' '}
            <TableData
              apiPath1={'/admin/inprogress'}
              tableTitle={'IN PROGRESS'}
              tableid={'table_id3'}
              key={1}
            />
          </div>
          <div className="myfarm_com" key={2}>
            {' '}
            <TableData
              apiPath1={'/admin/pending/users'}
              tableTitle={'SUBMITTED - PENDING AML'}
              tableid={'table_id1'}
              key={2}
            />
          </div>

          <div className="myfarm_com" key={3}>
            {' '}
            <TableData
              apiPath1={'/admin/passed/users'}
              tableTitle={'SUBMITTED - PASSED AML'}
              tableid={'table_id2'}
              key={3}
              pass="passedApplication"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllApplication;
